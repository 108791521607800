<template>
    <div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <!-- <span class="mask bg-white"></span> -->
        <div class="container">
          <div class="row">
            <div class="notifications">
              <material-alert
                v-if="error"
                color="danger"
                icon="ni ni-like-2 ni-lg"
                dismissible
              >
                {{ error }}
              </material-alert>
            </div>
            <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadDoneData">
              <div class="card-body mb-5" style="padding: 0rem 0rem">
                <div class="pb-2 font-weight-bolder px-2 pt-2 row">
                  <h4 class="ps-0 col-lg-6 col-md-6 col-6 standard-rule">
                    POINT NAME
                  </h4>
                  <div class="mt-2 p-0">
                    <material-input
                      type="text"
                      v-model="pointName"
                      id="point-name"
                      name="point-name`"
                      placeholder="Enter point name"
                      style="max-width: 500px;"
                    />
                  </div>
                </div>
                <div class="pb-2 font-weight-bolder px-2 pt-2 row">
                  <h4 class="ps-0 col-lg-6 col-md-6 col-6 standard-rule">
                    {{ $t('UPLOAD_ICON') }}
                  </h4>
                  <div class="mt-2 p-0">
                    <div class="dowload-img" v-if="pointIconPreview || pointIcon">
                      <div id="preview" style="position: relative">
                        <img
                          class="image-url"
                          v-if="pointIconPreview || pointIcon"
                          :src="pointIconPreview || pointIcon"
                        />
                        <i
                          class="fa fa-times icon-close-sidenav-main danger"
                          v-if="pointIconPreview || pointIcon"
                          style="
                            position: absolute;
                            color: #f44335;
                            font-size: 20px;
                            right: 5px;
                            top: 8px;
                          "
                          aria-hidden="true"
                          @click="clearImage()"
                        ></i>
                        <div class="btn-edit-img">
                          <i
                            class="fas fa-pencil-alt text-dark"
                            v-if="pointIconPreview || pointIcon"
                            aria-hidden="true"
                            @click="uploadFile()"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div
                      class="dowload-img"
                      @click="uploadFile()"
                      v-show="!pointIconPreview && !pointIcon"
                    >
                      <material-input
                        style="display: none"
                        type="file"
                        id="pointIcon"
                        @change="handleFileUpload($event)"
                        accept="image/*"
                      />
                      <i class="material-icons css-icon-upload">backup</i>
                    </div>
                    <div
                      class="is-invalid d-flex align-items-center pe-2"
                    >
                    </div>
                  </div>
                  <div
                    class="is-invalid d-flex align-items-center ps-0 mt-2"
                    v-if="fieldRequired.isImageRequire"
                  >
                    {{ messageImageError }}
                  </div>
                </div>
                <div class="mt-3">
                  <material-button
                    @click="addPointIcon()"
                    class="btn-background btn-sm"
                  >
                    Save
                  </material-button></div>
                <div class="pt-3">
                  <div class="color-common pb-2 font-weight-bolder px-2 pt-2 row">
                    <h4 class="ps-0 col-lg-6 col-md-6 col-6 standard-rule">
                      {{ $t('STANDARD_RULE') }}
                    </h4>
                    <div class="col-6 d-flex justify-content-end">
                      <material-button
                        @click="addRow(1)"
                        :disabled="formStandard.length >= 3 ? true : false"
                        class="btn-background btn-sm"
                      >
                        Add Standard Rule
                      </material-button>
                    </div>
                  </div>
                  <PointRule
                    :save-point="savePoint"
                    :remove-point="confirmDelete"
                    :field-required-standard="fieldRequiredStandard"
                    :form="formStandard"
                    type-point="1"
                    :text-required-standard-date-custom="
                      textRequiredStandardDateCustom
                    "
                    :pendingTimeUsePoint="pendingTimeUsePoint"
                    :expireOfPointAmount="expireOfPointAmount"
                  />
                  <div v-if="formStandard.length == 0" class="text-center p-5">
                    No Point Standard Rules Yet!
                  </div>
                </div>
                <div class="pt-3">
                  <div class="color-common pb-2 font-weight-bolder px-2 pt-2 row">
                    <h4 class="ps-0 col-lg-6 col-md-6 col-6 standard-rule">
                      {{ $t('SUPERIOR_RULE') }}
                    </h4>
                    <div class="col-6 d-flex justify-content-end">
                      <material-button
                        @click="addRow(2)"
                        :disabled="formSuperior.length >= 2 ? true : false"
                        class="btn-background-superior btn-background btn-sm"
                      >
                        Add Superior Rule
                      </material-button>
                    </div>
                  </div>
                  <PointRule
                    :save-point="savePoint"
                    :remove-point="confirmDelete"
                    type-point="2"
                    :text-required-superior-date-custom="
                      textRequiredSuperiorDateCustom
                    "
                    :field-required-superior="fieldRequiredSuperior"
                    :form="formSuperior"
                    :pendingTimeUsePoint="pendingTimeUsePoint"
                    :expireOfPointAmount="expireOfPointAmount"
                  />
                  <div v-if="formSuperior.length == 0" class="text-center p-5">
                    No Point Superior Rules Yet!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalConfirm
          message="Are you sure you want to delete ?"
          :process-yes="processYes"
        />
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
      </div>
    </div>
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import PointRule from "@/components/PointRule.vue";
  import "bootstrap/js/dist/modal";
  import MaterialButton from "@/components/MaterialButton.vue";
  import "@vuepic/vue-datepicker/dist/main.css";
  import SettingPointService from "../../services/settingPoint.service";
  import ModalConfirm from "@/components/ModalConfirm.vue";
  import MaterialInput from "@/components/MaterialInput.vue";
  import { reactive } from "vue";
  import moment from "moment";
  import $ from "jquery";

  export default {
    name: "find-store",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      MaterialButton,
      PointRule,
      ModalConfirm,
      MaterialInput
    },
    data() {
      return {
        message: "",
        snackbar: null,
        loaderActive: false,
        standardPoints: [],
        superiorPoints: [],
        formStandard: [],
        formSuperior: [],
        fieldRequiredStandard: [],
        fieldRequiredSuperior: [],
        pendingTimeUsePoint: [],
        expireOfPointAmount: [],
        indexItem: 0,
        typePointDelete: null,
        objDataSave: {},
        textRequiredStandardDateCustom: "",
        textRequiredSuperiorDateCustom: "",
        isLoadDoneData: false,
        pointIcon: null,
        pointIconPreview: null,
        isErrorUploadpointIcon: false,
        isIconTooSmall: false,
        messageIconError: "",
        fieldRequired: {},
        pointName: 'Point',
      };
    },
    created() {
      this.getPointList();
    },
    methods: {
      uploadFile() {
        $("#pointIcon").click();
      },

      clearImage() {
        this.pointIcon = null;
        this.pointIconPreview = null;
        this.isErrorUploadpointIcon = false;
        this.isIconTooSmall = false;
        // this.addPointIcon('Delete Icon Success');
      },

      handleFileUpload(event) {
        this.isImageTooSmall = false;
        let file = event.target.files[0];
        if (file != null || file != undefined) {
          this.pointIcon = null;
          this.pointIconPreview = null;
          if (
            !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
              file.type
            )
          ) {
            this.isErrorUploadImage = false;
            this.fieldRequired.isImageRequire = true;
            this.messageImageError = `File must be an image.`;
          } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (evt) => {
              let img = new Image();
              img.onload = () => {
                let width = img.width;
                let height = img.height;
                if (width != 500 || height != 500) {
                  this.messageImageError = `The image must dimensions 500 x 500.`;
                  this.fieldRequired.isImageRequire = true;
                  this.isErrorUploadImage = true;
                  this.isImageTooSmall = true;
                } else {
                  this.fieldRequired.isImageRequire = false;
                  this.isErrorUploadImage = false;
                  this.isImageTooSmall = false;
                  this.messageImageError = "";
                  this.pointIcon = file;
                  this.pointIconPreview = URL.createObjectURL(file);
                  // this.addPointIcon('Update Icon Success');
                }
              };
              img.src = evt.target.result;
            };
          }
        } else {
          this.pointIcon = null;
          this.pointIconPreview = null;
          this.isErrorUploadImage = false;
          this.messageImageError = "";
        }
        $("#pointIcon")[0].value = '';
      },

      addPointIcon() {
        var dataForm = {
          name: this.pointName,
          icon: this.pointIcon
        }

        SettingPointService.addPointIcon(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Update Success";
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      },

      dateTimeFormat(startDate, startTime) {
        if (startDate == null) {
          return null;
        } else {
          if (startTime == null) {
            return moment(startDate).format("YYYY-MM-DD") + " " + "00:00:00";
          } else {
            return (
              moment(startDate).format("YYYY-MM-DD") +
              " " +
              moment(startTime).format("H:m")
            );
          }
        }
      },

      closeSnackbar() {
        this.snackbar = null;
      },
  
      setTimeOutNotification() {
        setTimeout(() => {
          this.snackbar = null;
        }, 3000);
      },
  
      addRow(type) {
        if (type == 1) {
          this.formStandard.push({
            id: null,
            rule_name: "",
            spend_every: 0,
            rebate: 0,
            dolar_exchange: 1,
            form_of_point: 1,
            start_date: null,
            end_date: null,
            no_end_date: 0,
            start_time: null,
            end_time: null,
            pending_time_use_point: 1,
            expire_of_point_amount: 1,
            type_point: 1,
          });
  
          this.fieldRequiredStandard.push({
            isRuleNameRequire: false,
            isSpendEveryRequire: false,
            isRebateRequire: false,
            isDateRequire: false,
          });
        } else {
          this.formSuperior.push({
            id: null,
            rule_name: "",
            spend_every: 0,
            rebate: 0,
            dolar_exchange: 1,
            form_of_point: 1,
            start_date: null,
            end_date: null,
            no_end_date: 0,
            start_time: null,
            end_time: null,
            pending_time_use_point: 1,
            expire_of_point_amount: 1,
            type_point: 2,
          });
  
          this.fieldRequiredSuperior.push({
            isRuleNameRequire: false,
            isSpendEveryRequire: false,
            isRebateRequire: false,
            isDateRequire: false,
          });
        }
      },
  
      getPointList() {
        this.standardPoints = [];
        this.showLoader();
        this.isLoadDoneData = false;
        SettingPointService.getDataPoint().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.standardPoints = response.data.data.stardard_points;
              this.superiorPoints = response.data.data.superior_points;
              this.pendingTimeUsePoint = response.data.data.pendingTimeUsePoint
              this.expireOfPointAmount = response.data.data.expireOfPointAmount
              this.formStandard = reactive(this.standardPoints);
              this.formSuperior = reactive(this.superiorPoints);
              this.pointIcon = response.data.data.point_icon;
              this.pointName = response.data.data.point_name;
  
              this.formStandard.forEach(() => {
                this.fieldRequiredStandard.push({
                  isRuleNameRequire: false,
                  isSpendEveryRequire: false,
                  isRebateRequire: false,
                  isDateRequire: false,
                });
              });
              this.formSuperior.forEach(() => {
                this.fieldRequiredSuperior.push({
                  isRuleNameRequire: false,
                  isSpendEveryRequire: false,
                  isRebateRequire: false,
                  isDateRequire: false,
                });
              });
              this.isLoadDoneData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      },
  
      savePoint(type, index) {
        this.showLoader();
        if (this.processValidate(type, index)) {
          this.objDataSave = {};
          if (type == 1) {
            this.objDataSave = this.formStandard[index];
          } else {
            this.objDataSave = this.formSuperior[index];
          }
          this.objDataSave.start_date = this.dateTimeFormat(
            this.objDataSave.start_date,
            this.objDataSave.start_time
          );
          this.objDataSave.end_date = this.dateTimeFormat(
            this.objDataSave.end_date,
            this.objDataSave.end_time
          );
          SettingPointService.addOrUpdatePoint(this.objDataSave).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Save Rule Success";
                if (type == 1) {
                  this.formStandard[index].id = response.data.data.id;
                } else {
                  this.formSuperior[index].id = response.data.data.id;
                }
                this.setTimeOutNotification();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          this.hideLoader();
        }
      },
      processYes() {
        $(".btn-close-modal").click();
        const type = this.typePointDelete;
        const index = this.indexItem;
        if (type == 1) {
          this.fieldRequiredStandard.forEach((item, index) => {
            this.resetRequired(type, index);
          });
        } else {
          this.fieldRequiredSuperior.forEach((item, index) => {
            this.resetRequired(type, index);
          });
        }
  
        this.showLoader();
        if (type == 1) {
          if (this.formStandard[index].id == null) {
            this.removeRow(type, index);
            this.hideLoader();
          } else {
            if (this.formStandard[index].id) {
              SettingPointService.deletePoint(
                this.formStandard[index].id
              ).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.removeRow(type, index);
                    this.snackbar = "success";
                    this.message = "Delete Rule Success";
                    this.setTimeOutNotification();
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.hideLoader();
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoader();
                  this.setTimeOutNotification();
                }
              );
            }
          }
        } else {
          if (this.formSuperior[index].id == null) {
            this.removeRow(type, index);
            this.hideLoader();
          } else {
            if (this.formSuperior[index].id) {
              SettingPointService.deletePoint(
                this.formSuperior[index].id
              ).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                    this.removeRow(type, index);
                    this.snackbar = "success";
                    this.message = "Delete Rule Success";
                    this.setTimeOutNotification();
                  } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                  }
                  this.hideLoader();
                },
                (error) => {
                  this.snackbar = "danger";
                  this.message = error;
                  this.hideLoader();
                  this.setTimeOutNotification();
                }
              );
            }
          }
        }
      },
      confirmDelete(type, index) {
        this.typePointDelete = type;
        this.indexItem = index;
        $(".btn-confirm-modal").click();
      },
  
      removeRow(type, index) {
        if (type == 1) {
          if (this.formStandard.length > 0) {
            this.formStandard.splice(index, 1);
          }
        } else {
          if (this.formSuperior.length > 0) {
            this.formSuperior.splice(index, 1);
          }
        }
      },
  
      resetRequired(type, index) {
        if (type == 1) {
          this.fieldRequiredStandard[index].isRuleNameRequire = false;
          this.fieldRequiredStandard[index].isSpendEveryRequire = false;
          this.fieldRequiredStandard[index].isRebateRequire = false;
          this.fieldRequiredStandard[index].isDateRequire = false;
        } else {
          this.fieldRequiredSuperior[index].isRuleNameRequire = false;
          this.fieldRequiredSuperior[index].isSpendEveryRequire = false;
          this.fieldRequiredSuperior[index].isRebateRequire = false;
          this.fieldRequiredSuperior[index].isDateRequire = false;
        }
      },
  
      processValidate(type, index) {
        this.resetRequired(type, index);
        var isValid = true;
        if (type == 1) {
          if (
            this.formStandard[index].rule_name == "" ||
            this.formStandard[index].rule_name == null
          ) {
            this.fieldRequiredStandard[index].isRuleNameRequire = true;
            isValid = false;
          }
          if (
            this.formStandard[index].spend_every == "" ||
            this.formStandard[index].spend_every == null
          ) {
            this.fieldRequiredStandard[index].isSpendEveryRequire = true;
            isValid = false;
          }
          if (
            this.formStandard[index].rebate == "" ||
            this.formStandard[index].rebate == null
          ) {
            this.fieldRequiredStandard[index].isRebateRequire = true;
            isValid = false;
          }
  
          const dateFrom = this.dateTimeFormat(
            this.formStandard[index].start_date,
            this.formStandard[index].start_time
          );
          const dateTo = this.dateTimeFormat(
            this.formStandard[index].end_date,
            this.formStandard[index].end_time
          );
          if (dateFrom == null && dateTo != null) {
            this.fieldRequiredStandard[index].isDateRequire = true;
            this.textRequiredStandardDateCustom =
              "The start date field is required.";
            isValid = false;
          } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
            this.fieldRequiredStandard[index].isDateRequire = true;
            this.textRequiredStandardDateCustom =
              "The end date must be a date after start date.";
            isValid = false;
          }
        } else {
          if (
            this.formSuperior[index].rule_name == "" ||
            this.formSuperior[index].rule_name == null
          ) {
            this.fieldRequiredSuperior[index].isRuleNameRequire = true;
            isValid = false;
          }
          if (
            this.formSuperior[index].spend_every == "" ||
            this.formSuperior[index].spend_every == null
          ) {
            this.fieldRequiredSuperior[index].isSpendEveryRequire = true;
            isValid = false;
          }
          if (
            this.formSuperior[index].rebate == "" ||
            this.formSuperior[index].rebate == null
          ) {
            this.fieldRequiredSuperior[index].isRebateRequire = true;
            isValid = false;
          }
  
          const dateFrom = this.dateTimeFormat(
            this.formSuperior[index].start_date,
            this.formSuperior[index].start_time
          );
          const dateTo = this.dateTimeFormat(
            this.formSuperior[index].end_date,
            this.formSuperior[index].end_time
          );
          if (dateFrom == null && dateTo != null) {
            this.fieldRequiredSuperior[index].isDateRequire = true;
            this.textRequiredSuperiorDateCustom =
              "The start date field is required.";
            isValid = false;
          } else if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
            this.fieldRequiredSuperior[index].isDateRequire = true;
            this.textRequiredSuperiorDateCustom =
              "The end date must be a date after start date.";
            isValid = false;
          }
        }
  
        return isValid;
      },
  
      showLoader() {
        this.loaderActive = true;
      },
      hideLoader() {
        this.loaderActive = false;
      },
    },
  };
  </script>
  <style scoped>
  .standard-rule {
    color: black !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }

  .dowload-img {
    cursor: pointer;
    background: hsla(0, 0%, 100%, .5);
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .css-icon-upload {
    font-size: 45px !important;
  }

  #preview, 
  .image-url {
    width: 120px;
    height: 120px;
  }

  .btn-edit-img {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    color: #f44335;
    font-size: 14px;
    right: 30px;
    top: 5px;
    background: #ffffff;
    border-radius: 50%;
  }
  </style>