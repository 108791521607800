<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-12 col-md-12 col-12 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">DASHBOARD AND REPORTS</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto mt-4">
            <div class="row">
              <strong>VOUCHER REDEMPTION REPORT</strong>
            </div>
            <div class="row mt-3">
              <div class="col-lg-1 col-md-1 col-2">{{ $t('Voucher_Status') }}</div>
              <div class="col-lg-11 col-md-11 col-10">
                <div class="row">
                  <div class="col-lg-2 col-md-2 col-12 d-flex">
                    <MaterialRadio
                      v-model="voucher_used"
                      id="all_voucher"
                      value
                      class
                    >{{ $t('All') }}</MaterialRadio>
                  </div>
                  <div class="col-lg-2 col-md-2 col-12 d-flex">
                    <MaterialRadio
                      v-model="voucher_used"
                      id="redeemable"
                      value="0"
                      class
                    >{{ $t('Redeemable') }}</MaterialRadio>
                  </div>
                  <div class="col-lg-2 col-md-2 col-12 d-flex">
                    <MaterialRadio
                      v-model="voucher_used"
                      id="redeemed"
                      value="1"
                      class
                    >{{ $t('Redeemed') }}</MaterialRadio>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >{{ $t('Time') }}</div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect
                      v-model="time_type"
                      :options="times"
                      :canClear="false"
                      @change="timeChange($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row pt-custtom">
                  <div
                    class="col-lg-4 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >{{ $t('Discount_Amount') }}</div>
                  <div class="col-lg-8 col-md-4 col-6">
                    <Multiselect
                      v-model="discount_amount_type"
                      :options="dicountCompareTypes"
                      :canClear="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row pt-custtom">
                  <div class="col-lg-4 col-md-4 col-3">
                    <material-input
                      v-model="discount_amount_value"
                      placeholder="Enter value to compare"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="isShowTimeCustom">
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >From</div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Datepicker
                      :enableTimePicker="false"
                      class="date-time-dashboad"
                      v-model="from_date"
                      :format="format"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12 pt-custtom">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >To</div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Datepicker
                      :enableTimePicker="false"
                      class="date-time-dashboad"
                      v-model="to_date"
                      :format="format"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >{{ $t('Voucher_Type') }}</div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect v-model="reward_type" :options="voucherTypes" :canClear="false" />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >{{ $t('Member_Gender') }}</div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect v-model="gender" :options="genders" :canClear="false" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-1 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >{{ $t('Search') }}</div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <input
                      class="nosubmit"
                      type="search"
                      id="keyword_search"
                      v-model="searchKeyword"
                      extraClass
                      name="keyword_search"
                      placeholder="Enter voucher ID, Code, Title, Description..."
                      @keydown.enter="handleSearchEnter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 ps-3">
              <div class="col-lg-9 col-md-9 col-3 d-flex">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle btn-sm"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    :disabled="disabled"
                  >{{ $t('download') }}</button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
                    </li>
                    <li>
                      <a class="dropdown-item" @click="exportFile('xlsx')" href="#">Export Excel</a>
                    </li>
                    <li>
                      <a class="dropdown-item" @click="exportFile('pdf')" href="#">Export PDF</a>
                    </li>
                  </ul>
                </div>
                <div style="margin-left: 3vh;">
                  <material-button
                    class="btn-background btn-sm"
                    @click="filterData()"
                    style="width: 162.52px;"
                  >Run</material-button>
                </div>
              </div>
            </div>
            <div class="row mt-1 ps-3">
              <div
                class="col-lg-9 col-md-12 col-12 d-flex justify-content-lg-start align-items-end"
              >{{ $t('Count') }}: {{ totalRecords }}</div>
              <div class="col-lg-3 col-md-3 col-3 d-flex justify-content-end">
                <ShowHideColumn
                  :columns="columns"
                  :changeCheckbox="changeCheckbox"
                  :columnCheckedCache="columnChecked"
                  :key="key"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th
                              class="text-center font-weight-bolder w-5"
                              v-if="columnShowed.includes('row_no')"
                            >{{ $t('Row_No') }}</th>
                            <template v-for="(item, index) in headersData" :key="index">
                              <th
                                class="text-center font-weight-bolder"
                                v-if="columnShowed.includes(item.field)"
                              >
                                <span
                                  :class="[
                                    !!item.hasSortBy
                                      ? 'wrapper-sortby pointer'
                                      : '',
                                    ESortDirectionSelect.idx == (index + 1)
                                      ? ESortDirectionSelect.direction === ESortDirection.Desc
                                        ? 'sort-desc'
                                        : 'sort-asc'
                                      : '',
                                  ]"
                                  @click="
                                    () => {
                                      !!item.hasSortBy &&
                                        handleChangeSortColumn(index + 1);
                                    }
                                  "
                                >{{ item.label }}</span>
                              </th>
                            </template>
                          </tr>
                        </thead>
                        <tbody v-if="vouchers.length">
                          <tr v-for="(value, key) in vouchers" :key="key">
                            <td
                              class="text-center text-xss w-5"
                              v-if="columnShowed.includes('row_no')"
                            >{{ key + 1 }}</td>
                            <td
                              class="text-left text-xss w-25"
                              v-if="columnShowed.includes('voucher_name')"
                            >{{ value.reward_title }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('voucher_type')"
                            >{{ value.reward_type_format }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('voucher_code')"
                            >{{ value.code }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('discount')"
                            >{{ value.voucher_amount_format }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('bill_total')"
                            >{{ value.payment_amount_format }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_name')"
                            >{{ value.name }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_id')"
                            >{{ value.member_id_format }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_email')"
                            >{{ value.email }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_mobile')"
                            >{{ value.phone_number }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('issue_date')"
                            >{{ value.issue_date_format }}</td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('redeem_date')"
                            >{{ value.redeemed_date_format }}</td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="12" class="text-center">No item available</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title
          date
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <!-- <div class="position-fixed bottom-1 end-2 z-index-2">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" :disabled="disabled">
            Download
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
            </li>
            <li>
              <a class="dropdown-item" @click="exportFile('xlsx')" href="#">Export Excel</a>
            </li>
          </ul>
        </div>
      </div>-->
      <material-loading :active="loaderActive" />
    </div>
    <form :action="`${actionDownload}/merchant/download-voucher-report`" method="POST">
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCode" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="voucher_used" :value="voucher_used" />
      <input type="hidden" name="list_field_show" :value="columnShowed" />
      <input type="hidden" name="search_keyword" :value="searchKeyword" />
      <input type="hidden" name="member_id" :value="member_id" />
      <input type="hidden" name="phone_number" :value="phone_number" />
      <input type="hidden" name="email" :value="email" />
      <input type="hidden" name="gender" :value="gender" />
      <input type="hidden" name="time_type" :value="time_type" />
      <input type="hidden" name="from_date" :value="dateFormat(from_date)" />
      <input type="hidden" name="to_date" :value="dateFormat(to_date)" />
      <input type="hidden" name="reward_type" :value="reward_type" />
      <input type="hidden" name="discount_amount_type" :value="discount_amount_type" />
      <input type="hidden" name="discount_amount_value" :value="discount_amount_value" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input type="hidden" name="checkExport" :value="checkExport" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import "@vuepic/vue-datepicker/dist/main.css";
import Multiselect from "@vueform/multiselect";
import MaterialRadio from "@/components/MaterialRadio.vue";
import "@vueform/multiselect/themes/default.css";
import MaterialButton from "@/components/MaterialButton.vue";
import ShowHideColumn from "@/components/ShowHideColumn.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import DashboardReportService from "../../services/dashboardReport.service";
import $ from "jquery";
import TokenService from "../../services/token.service";
import CommonFunctionService from "../../services/commonFunction.service";

export default {
  name: "campaign-list",
  components: {
    MaterialSnackbar,
    MaterialInput,
    MaterialLoading,
    MaterialButton,
    Pagination,
    Multiselect,
    Datepicker,
    ShowHideColumn,
    MaterialRadio,
  },
  data() {
    return {
      token: TokenService.getLocalAccessToken(),
      actionDownload: process.env.VUE_APP_BASE_URL,
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCode: localStorage.getItem("MERCHANT_CODE", ""),
      message: "",
      snackbar: null,
      loaderActive: false,
      vouchers: [],
      searchKeyword: "",
      searchTimerId: null,
      pagination: {},
      page: 1,
      voucher_used: "",
      voucherStatus: [
        { value: "", label: "Select Voucher Status" },
        { value: 0, label: "Voucher not used" },
        { value: 1, label: "Voucher used" },
      ],
      columns: [
        { value: "all", label: "All" },
        { value: "row_no", label: "Row No" },
        { value: "voucher_name", label: "Voucher Name" },
        { value: "voucher_type", label: "Voucher Type" },
        { value: "voucher_code", label: "Voucher Code" },
        { value: "discount", label: "discount" },
        { value: "bill_total", label: "Bill Total" },
        { value: "member_name", label: "Member Name" },
        { value: "member_id", label: "Member Id" },
        { value: "member_email", label: "Member Email" },
        { value: "member_mobile", label: "Member Mobile" },
        { value: "issue_date", label: "Issue Date" },
        { value: "redeem_date", label: "Redeem Date" },
      ],
      reward_type: 0,
      time_type: 0,
      times: [
        { value: 0, label: "Select Time" },
        { value: 1, label: "Today" },
        { value: 2, label: "Yesterday" },
        { value: 3, label: "Last 7 days" },
        { value: 4, label: "Last 30 days" },
        { value: 5, label: "This month" },
        { value: 6, label: "Last 3 months" },
        { value: 7, label: "This year" },
        { value: 8, label: "Last year" },
        { value: 9, label: "Custom" },
      ],
      from_date: null,
      to_date: null,
      voucherTypes: [
        { value: 0, label: "Select voucher type" },
        { value: 1, label: "Percentage Off" },
        { value: 2, label: "Fixed Amount Off" },
        { value: 3, label: "Free Item" },
      ],
      discount_amount_type: 1,
      dicountCompareTypes: [
        { value: 1, label: "Equal to" },
        { value: 2, label: "Greater than" },
        { value: 3, label: "Less than" },
      ],
      discount_amount_value: "",
      genders: [
        { value: 0, label: "Select gender" },
        { value: 1, label: "Male" },
        { value: 2, label: "Female" },
      ],
      member_id: null,
      phone_number: "",
      email: "",
      gender: 0,
      isShowTimeCustom: false,
      action: "list",
      action_export: "csv",
      totalRecords: 0,
      columnShowed: [],
      checkExport: false,
      ESortDirectionSelect: {
        idx: null,
        direction: null
      },
      ESortDirection: {
        Asc: "asc",
        Desc: "desc",
      },
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (window.localStorage.getItem("VoucherRedemptionColumn")) {
      this.columnChecked = window.localStorage
        .getItem("VoucherRedemptionColumn")
        .split(",");
      this.key = Math.random();
    }
  },
  computed: {
    headersData() {
      return [
        {
          label: this.$t("Voucher_Name"),
          field: "voucher_name",
          hasSortBy: true,
        },
        {
          label: this.$t("Voucher_Type"),
          field: "voucher_type",
          hasSortBy: true,
        },
        {
          label: this.$t("Voucher_Code"),
          field: "voucher_code",
          hasSortBy: true,
        },
        {
          label: this.$t("Discount"),
          field: "discount",
          hasSortBy: true,
        },
        {
          label: this.$t("Bill_Total"),
          field: "bill_total",
          hasSortBy: true,
        },
        {
          label: this.$t("Member_Name"),
          field: "member_name",
          hasSortBy: true,
        },
        {
          label: this.$t("Member_ID"),
          field: "member_id",
          hasSortBy: true,
        },
        {
          label: this.$t("Member_Email"),
          field: "member_email",
          hasSortBy: true,
        },
        {
          label: this.$t("Member_Mobile"),
          field: "member_mobile",
          hasSortBy: true,
        },
        {
          label: this.$t("Issue_Date"),
          field: "issue_date",
          hasSortBy: true,
        },
        {
          label: this.$t("Redeem_Date"),
          field: "redeem_date",
          hasSortBy: true,
        },
      ];
    },
  },
  methods: {
    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },
    exportFile(type = "csv") {
      if (this.columnShowed.length == 0) {
        this.snackbar = "danger";
        this.message = "You must select least 1 column to export file.";
        this.setTimeOutNotification();
      } else {
        this.action_export = type;
        setTimeout(() => {
          $("#downloadForm").click();
        }, 150);
      }
    },
    changeCheckbox(columnShowed) {
      this.columnShowed = columnShowed;
      window.localStorage.setItem("VoucherRedemptionColumn", columnShowed);
    },
    timeChange($event) {
      if ($event == 9) {
        this.isShowTimeCustom = true;
      } else {
        this.isShowTimeCustom = false;
      }
    },

    filterData() {
      this.getList(this.page);
    },

    dateFormat(date) {
      if (date == null) {
        return null;
      } else {
        return moment(date).format("YYYY-MM-DD");
      }
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    handleChangeSortColumn(idx) {
      if (this.ESortDirectionSelect.idx && this.ESortDirectionSelect.idx == idx) {
        this.ESortDirectionSelect.direction = this.ESortDirectionSelect.direction === this.ESortDirection.Asc ? this.ESortDirection.Desc : this.ESortDirection.Asc;
      } else {
        this.ESortDirectionSelect = {
          idx: idx,
          direction: this.ESortDirection.Asc
        }
      }
      
      if (this.vouchers.length) {
        this.getList(this.page);
      }
    },

    getList(page) {
      this.totalRecords = 0;
      this.showLoader();
      var dataForm = {
        action: this.action,
        voucher_used: this.voucher_used,
        search_keyword: this.searchKeyword,
        member_id: this.member_id,
        phone_number: this.phone_number,
        email: this.email,
        gender: this.gender,
        time_type: this.time_type,
        from_date: this.dateFormat(this.from_date),
        to_date: this.dateFormat(this.to_date),
        reward_type: this.reward_type,
        discount_amount_type: this.discount_amount_type,
        discount_amount_value: this.discount_amount_value,
        action_export: this.action_export,
        checkExport: this.checkExport,
        page: page,
        sortCol: this.ESortDirectionSelect.idx,
        sortType: this.ESortDirectionSelect.direction
      };
      DashboardReportService.getData(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.vouchers = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.totalRecords = this.pagination.total;
            if (this.pagination.current_page > this.pagination.last_page) {
              this.page = 1;
              this.getList(this.page);
            }
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            });
            this.checkExport = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    handleSearchEnter() {
      this.getList(this.page);
    },
  },
};
</script>
<style scoped>
table thead th:not(:first-child) {
  min-width: 150px;
}

table thead th > span {
  position: relative;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.date-time-dashboad .dp__input_wrap {
  min-width: 200px !important;
  width: 250px;
}

.custom-control-label label {
  padding-top: 14px !important;
}

@media only screen and (max-width: 991px) {
  .pt-custtom {
    padding-top: 7px;
  }
}
</style>