<template>
    <div>
      <div class="mb-2 pe-4">
        <div class="d-flex align-items-center">
          <div class="color-common p-2">
            <h3 class="title-page mb-0 ps-3">BOOKING OVERVIEW</h3>
          </div>
        </div>
      </div>
      <div class="page-header align-items-start min-vh-100">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
              <div class="d-flex mt-4 s-branch align-items-center">
                <div class="w-100px">Branch</div>
                <Multiselect
                  v-model="searchForm.branch_id"
                  :options="branches"
                  placeholder="Please select a Branch"
                  :canClear="false"
                  @change="selectBranch"
                />
              </div>
              <div class="mt-3 d-flex">
                <div class="d-flex align-items-center me-5">
                  <div class="w-150px">Transaction From</div>
                  <Datepicker
                    :model-value="searchForm.transaction_from_date"
                    :enableTimePicker="false"
                    class="date-time-dashboad"
                    @update:model-value="onChangeDate($event, 1)"
                    :format="format"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <div class="w-150px">Transaction To</div>
                  <Datepicker
                    :model-value="searchForm.transaction_to_date"
                    :enableTimePicker="false"
                    class="date-time-dashboad"
                    @update:model-value="onChangeDate($event, 2)"
                    :format="format"
                  />
                </div>
              </div>
              <div class="mt-3 d-flex">
                <div class="d-flex align-items-center me-5">
                  <div class="w-150px">Booking From</div>
                  <Datepicker
                    :model-value="searchForm.booking_from_date"
                    :enableTimePicker="false"
                    class="date-time-dashboad"
                    @update:model-value="onChangeDate($event, 3)"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <div class="w-150px">Booking To</div>
                  <Datepicker
                    :model-value="searchForm.booking_to_date"
                    :enableTimePicker="false"
                    class="date-time-dashboad"
                    @update:model-value="onChangeDate($event, 4)"
                  />
                </div>
              </div>
              <div class="mt-5">
                <div class="row d-flex justify-content-center">
                  <ul id="general-tab" class="nav nav-tabs">
                    <li class="nav-item nav-first">
                      <a
                        id="nav_item_1"
                        class="nav-link"
                        :class="{ active: searchForm.status == 1 }"
                        @click="changeTab(1)"
                        >Upcoming</a
                      >
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_2" class="nav-link" @click="changeTab(2)"
                        >Completed</a
                      >
                    </li>
                    <li class="nav-item">
                      <a id="nav_item_-1" class="nav-link" @click="changeTab(-1)"
                        >Cancelled</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="px-0 d-flex justify-content-end mt-3">
                  <input
                    class="nosubmit"
                    type="search"
                    v-model="searchForm.searchKeyword"
                    id="keyword_search"
                    extraClass
                    name="keyword_search"
                    placeholder="Search ..."
                    @input="onChangesearchKeyword"
                  />
                </div>
                <div class="row mt-3">
                  <div class="px-0 mt-4">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder">No.</th>
                            <th class="text-center font-weight-bolder">
                              Booking No
                            </th>
                            <th class="text-center font-weight-bolder">
                              Member ID
                            </th>
                            <th class="text-center font-weight-bolder">
                              Member Name
                            </th>
                            <th class="text-center font-weight-bolder">
                              Phone No
                            </th>
                            <th class="text-center font-weight-bolder w-5">
                              Transaction Date
                            </th>
                            <th class="text-center font-weight-bolder">
                              Booking Date
                            </th>
                            <th class="text-center font-weight-bolder">
                              Branch
                            </th>
                            <th class="text-center font-weight-bolder">
                              Service
                            </th>
                            <th class="text-center font-weight-bolder">
                              Type
                            </th>
                            <th class="text-center font-weight-bolder">
                              Timeslot
                            </th>
                            <th class="text-center font-weight-bolder">
                              Sub Total
                            </th>
                            <th class="text-center font-weight-bolder">
                              GST
                            </th>
                            <th class="text-center font-weight-bolder">
                              Total
                            </th>
                            <th class="text-center font-weight-bolder">
                              Payment Method
                            </th>
                            <th class="text-center font-weight-bolder">
                              Status
                            </th>
                            <th class="text-center font-weight-bolder" v-if="searchForm.status == 1">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="bookingList.length">
                          <tr v-for="(item, index) in bookingList" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">{{ item.booking_code }}</td>
                            <td class="text-center">{{ item.member_id_format }}</td>
                            <td class="text-center">{{ item.member_name }}</td>
                            <td class="text-center">{{ item.phone_number }}</td>
                            <td class="text-center">{{ item.created_at_format }}</td>
                            <td class="text-center">{{ item.booking_date_format }}</td>
                            <td class="text-center">{{ item.branch_name }}</td>
                            <td class="text-center">{{ item.venue_name }}</td>
                            <td class="text-center">{{ item.venue_type_name }}</td>
                            <td class="text-center">{{ item.timeslot_text }}</td>
                            <td class="text-center">{{ item.price_format }}</td>
                            <td class="text-center">{{ item.gst_format }}</td>
                            <td class="text-center">{{ item.total_price_format }}</td>
                            <td class="text-center">{{ item.payment_method }}</td>
                            <td class="text-center">{{ item.status_text }}</td>
                            <td class="text-center" v-if="searchForm.status == 1">
                              <material-button
                                class="btn-background btn-sm"
                                style="min-width: 100px; padding: 6px 30px !important;"
                                @click="cancelBooking(item)"
                              >
                                Cancel
                              </material-button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else class="no-data">
                          <tr>
                            <td colspan="10" class="text-center">
                              Empty, no venue.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title
        date
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
    <CancelBooking :bookingInfo="bookingSelect" @cancel-booking-success="cancelBookingSuccess()"/>
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import Datepicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import BookingService from "../../services/booking.service";
  import TestService from "../../services/test.service";
  import Multiselect from "@vueform/multiselect";
  import Pagination from "@/components/Pagination.vue";
  import MaterialButton from "@/components/MaterialButton.vue";
  import CancelBooking from "@/components/ModalCancelBooking.vue";
  import $ from "jquery";
  import CommonFunctionService from "../../services/commonFunction.service";
  
  export default {
    name: "create-menu",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      Multiselect,
      Pagination,
      Datepicker,
      MaterialButton,
      CancelBooking
    },
    data() {
      return {
        snackbar: null,
        message: "",
        loaderActive: false,
        branches: [],
        branchIdSelect: this.$route.query.branch_id ?? 0,
        messageConfirm: "",
        venueIdSelect: 0,
        bookingList: [],
        pagination: {},
        searchForm: {
          branch_id: 0,
          transaction_from_date: null,
          transaction_to_date: null,
          booking_from_date: null,
          booking_to_date: null,
          search_keyword: "",
          status: 1,
          page: 1
        },
        searchTimerId: null,
        bookingSelect: {}
      };
    },
    async created() {
      await this.getBranches();
      this.getList(this.searchForm.page);
    },
    methods: {
      format(date) {
        return CommonFunctionService.formatDateShow(date)
      },

      async changeTab(status) {
        this.searchForm.status = status;
        this.searchForm.page = 1;
        $("#general-tab .nav-link").removeClass("active");
        $("#nav_item_" + status).addClass("active");
        this.showLoader();
        await this.getList(this.searchForm.page);
        this.hideLoader();
      },
  
      async getBranches() {
        this.showLoader();
        await TestService.getBranches().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.branches = response.data.data;
              this.branches.unshift({
                value: 0,
                label: '-- All Branches --'
              });
            } else {
              this.message = response.data.result.message;
            }
            this.hideLoader();
          },
          (error) => {
            this.message = error;
            this.hideLoader();
          }
        );
      },
  
      async selectBranch(branchId) {
        this.searchForm.branch_id = branchId;
        this.showLoader();
        await this.getList(this.searchForm.page);
        this.hideLoader();
      },
  
      async getList(page) {
        this.searchForm.page = page;

        await BookingService.listBooking(this.searchForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.bookingList = response.data.data.items;
              this.pagination = response.data.data;
              this.searchForm.page = this.pagination.current_page;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
      },

      onChangesearchKeyword(event) {
        this.searchForm.search_keyword = event.target.value;
        clearTimeout(this.searchTimerId);
        this.searchTimerId = setTimeout(async () => {
          this.showLoader();
          await this.getList(this.searchForm.page);
          this.hideLoader();
        }, 1000);
      },

      async onChangeDate(date, type) {
        if (type == 1) {
          this.searchForm.transaction_from_date = date;
        } else if (type == 2) {
          this.searchForm.transaction_to_date = date;
        } else if (type == 3) {
          this.searchForm.booking_from_date = date;
        } else if (type == 4) {
          this.searchForm.booking_to_date = date;
        }

        this.showLoader();
        await this.getList(this.searchForm.page);
        this.hideLoader();
      },

      cancelBooking(booking) {
        this.bookingSelect = booking;
        $('#show-modal-cancel-booking').click();
      },

      async cancelBookingSuccess() {
        $('#show-modal-cancel-booking').click();
        this.snackbar = 'success';
        this.message = "Booking Cancelled!";
        this.setTimeOutNotification();
        this.showLoader();
        await this.getList(this.searchForm.page);
        this.hideLoader();
      },
  
      showLoader() {
        this.loaderActive = true;
      },
  
      hideLoader() {
        this.loaderActive = false;
      },
  
      setTimeOutNotification() {
        setTimeout(() => {
          this.snackbar = null;
        }, 3000);
      },
    },
  };
  </script>
  <style scoped>
  .page-header .container {
    color: #222 !important;
  }
  
  .nav-first {
    position: relative;
  }
  .nav-link {
    font-weight: bold;
  }
  
  .nav-first .count-order {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 1px solid red;
    background: red;
    color: #ffffff;
    border-radius: 5px;
    padding: 0px 7px;
  }
  
  .multiselect {
    margin: 0 !important;
    max-width: 400px !important;
  }
  
  #general-tab .nav-item {
    width: 25%;
    text-align: center;
  }
  
  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
    width: 100%;
    max-width: 500px;
  }
  
  input[type="search"]::placeholder {
    color: #bbb;
    text-align: left;
    font-weight: normal;
  }
  
  input[type="search"]:focus {
    border-color: #72959d;
    outline: none;
    border: none;
    border-bottom: 2px solid #72959d;
  }
  
  .nosubmit-parent {
    border: none;
    padding: 0;
  }
  
  input.nosubmit {
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }

  table thead th:not(:first-child) {
    min-width: 150px;
  }
  
  .cl-22 {
    color: #222 !important;
  }
  
  .show-order-info {
    font-size: 28px !important;
    font-weight: bold;
    color: #007bff;
    cursor: pointer;
  }
  .btn-confirm-order {
    min-width: 50px !important;
    padding: 5px 10px !important;
    color: #28a745 !important;
    border-color: #28a745 !important;
  }
  
  .btn-confirm-order:hover {
    background: #28a745 !important;
    color: #ffffff !important;
  }
  
  .timeslot-tag {
    width: fit-content;
    padding: 1px 7px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 4px;
  }

  .w-100px {
    width: 100px !important;
  }

  .w-150px {
    width: 150px !important;
  }
  </style>
  