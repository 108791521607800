import api from "../api";

class ModifierOptionGroupService {
  getList(dataForm) {
    return api.get("/merchant/modifier-option-group", { params: dataForm });
  }

  store(dataForm) {
    return api.post("/merchant/modifier-option-group", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  detail(id, categoryId = 0, type = 0) {
    return api.get("/merchant/modifier-option-group/" + id + '?category_id=' + categoryId + '&type=' + type);
  }

  update(id, dataForm) {
    return api.post("/merchant/modifier-option-group/update/"+id, dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteOption(id, type = 0) {
    return api.delete("/merchant/modifier-option-group/" + id + '?type=' + type);
  }

  checkDeleteOption(id, type = 0) {
    return api.post("/merchant/modifier-option-group/check-delete/" + id + '?type=' + type);
  }

  getListByMenuSet(dataForm) {
    return api.post("/merchant/modifier-option-group/get-list-by-menuset", dataForm);
  }

  getAppliedPriceList(dataForm) {
    return api.post("/merchant/modifier-option-group/get-applied-price", dataForm);
  }

  attachedToItem(dataForm) {
    return api.post("/merchant/modifier-option-group/attached-to-item", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  getAttachedToItem(dataForm) {
    return api.post("/merchant/modifier-option-group/get-attached-to-item", dataForm);
  }

  deleteItem(itemId) {
    return api.post('/merchant/modifier-option-group/delete-item/' + itemId);
  }

  delete(itemId) {
    return api.post('/merchant/modifier-option-group/delete/' + itemId);
  }

  updateNameModifierOptionGroup(dataForm) {
    return api.post("/merchant/modifier-option-group/update-name-modifier-option-group", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  moveUpDown(dataForm) {
    return api.post("/merchant/modifier-option-group/move-up-down", dataForm);
  }

  getListSelectByMenuSet(dataForm) {
    return api.post("/merchant/modifier-option-group/get-list-select-by-menuset", dataForm);
  }

  preview(dataForm) {
    return api.post("/merchant/modifier-option-group/preview", dataForm);
  }
}

export default new ModifierOptionGroupService();
