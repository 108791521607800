<template>
    <div>
        <div class="row mb-2 pe-4">
            <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
                <div class="color-common">
                    <h3 class="title-page mb-0 ps-3">BULK VOUCHER ISSUANCE LIST</h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
                <material-button
                    @click="createBulk()"
                    :disabled="disableBtnSubmit"
                    class="mx-2 btn btn-sm btn-background"
                >
                    ISSUANCE BULK VOUCHER
                </material-button>
            </div>
        </div>
        <div class="page-header align-items-start min-vh-100 my-transacions">
            <span class="mask bg-white"></span>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12 mx-auto">
                        <div class="row my-3 d-flex align-items-left">
                            
                        </div>
        
                        <div class="row mt-1">
                            <div class="col-12">
                                <div class="card-custom">
                                    <div class="px-0 pb-2">
                                        <div class="table-responsive p-0 custom-table">
                                            <table class="table align-items-center mb-0">
                                                <thead>
                                                    <tr>
                                                    <!-- <th class="text-center font-weight-bolder w-5">
                                                        <div class="form-check" style="padding-left: 23px;">
                                                            <input class="form-check-input" type="checkbox">
                                                        </div>
                                                    </th> -->
                                                    <th class="text-center font-weight-bolder w-5">{{ $t('Batch ID') }}</th>
                                                    <th class="text-center font-weight-bolder">
                                                        {{ $t('Voucher Type') }}
                                                    </th>
                                                    <th class="text-left font-weight-bolder w-10">{{ $t('Voucher ID') }}</th>
                                                    <th class="text-center font-weight-bolder">{{ $t('Voucher Name') }}</th>
                                                    <th class="text-center font-weight-bolder">
                                                        {{ $t('Issued') }}
                                                    </th>
                                                    <th class="text-center font-weight-bolder">
                                                        {{ $t('Redeemed') }}
                                                    </th>
                                                    <th class="text-center font-weight-bolder">
                                                        {{ $t('Issued Date') }}
                                                    </th>
                                                    <th class="text-center font-weight-bolder">
                                                        {{ $t('Expiry date') }}
                                                    </th>
                                                    <th class="text-center font-weight-bolder">
                                                        {{ $t('action') }}
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="data.length">
                                                    <tr v-for="(value, key) in data" :key="key">
                                                        <!-- <td>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox">
                                                            </div>
                                                        </td> -->
                                                        <td class="text-center text-xss w-5">
                                                            {{ value.batch_id }}
                                                        </td>
                                                        <td class="text-center text-xss w-25">
                                                            {{ value.voucher_set_detail.reward_type }}
                                                        </td>
                                                        <td class="text-center text-xss w-10">
                                                            VOU{{ value.voucher_set_detail.id }}
                                                        </td>
                                                        <td class="text-center text-xss">
                                                            {{ value.voucher_set_detail.reward_title }}
                                                        </td>
                                                        <td class="text-center text-xss">
                                                            {{ value.quantity }}
                                                        </td>
                                                        <td class="text-center text-xss">
                                                            {{ value.redeemed }}
                                                        </td>
                                                        <td class="text-center text-xss">
                                                            {{ getCreatedDate(value) }}
                                                        </td>
                                                        <td class="text-center text-xss">
                                                            {{ getExpiryDate(value) }}
                                                        </td>
                                                        <td class="w-10 align-middle text-right text-sm">
                                                            <a
                                                            @click="viewDetailBulk(value.id)"
                                                            class="btn btn-link text-dark px-2 mb-0"
                                                            >
                                                            View Detail
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td colspan="8" class="text-center">
                                                        No item available
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <pagination
                                        v-if="pagination.last_page > 1"
                                        :pagination="pagination"
                                        :current_page="pagination.current_page"
                                        :last_page="pagination.last_page"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-fixed top-1 end-1 z-index-2 message-success">
                <material-snackbar
                    v-if="snackbar === 'danger' || snackbar === 'success'"
                    title=""
                    date=""
                    :description="message"
                    :icon="{ component: 'campaign', color: 'white' }"
                    :color="snackbar"
                    :close-handler="closeSnackbar"
                />
            </div>
        </div>
    </div>
</template>
  
<script>
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import "bootstrap/dist/js/bootstrap.bundle";
    // import MaterialLoading from "@/components/MaterialLoading.vue";
    import Pagination from "@/components/Pagination.vue";
    import "bootstrap/js/dist/modal";
    import MaterialButton from "@/components/MaterialButton.vue";
    import "@vuepic/vue-datepicker/dist/main.css";
    // import ModalConfirm from "@/components/ModalConfirm.vue";
    import TokenService from "../../services/token.service";
    import BulkVoucherIssue from "../../services/bulkVoucherIssue.service";
    // import $ from "jquery";
    import moment from "moment";
    
    export default {
        name: "find-store",
        components: {
        MaterialSnackbar,
        //   MaterialLoading,
        MaterialButton,
        //   ModalConfirm,
        Pagination,
        },
        data() {
        return {
            message: "",
            snackbar: null,
            loaderActive: false,
            searchKeyword: "",
            pagination: {},
            page: 1,
            isLoadData: false,
            merchantCode: "",
            token: TokenService.getLocalAccessToken(),
            merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
            data: []
        };
        },
    created() {
        if (this.$route.query.page) {
            this.page = this.$route.query.page;
        }
        this.getList(this.page);
    },
    mounted() {
        const code = localStorage.getItem("MERCHANT_CODE", "");
        this.merchantCode = code ? `/${code}` : "";
    },
    methods: {
        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
  
        onChangesearchKeyword(event) {
            this.searchKeyword = event.target.value;
            clearTimeout(this.searchTimerId);
            this.searchTimerId = setTimeout(() => {
            this.getList(this.page);
            }, 1000);
        },
  
        getList(page) {
            this.isLoadData = false;
            this.showLoader();
            var dataForm = {
                page: page
            };
            this.isLoadData = false;
            this.showLoader();
            BulkVoucherIssue.getDataBulkVoucherIssues(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.data = response.data.data.items;
                        this.pagination = response.data.data;
                        this.page = this.pagination.current_page;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
  
        createBulk() {
            this.$router.push("/bulk-voucher-issuance-generate-voucher/0");
        },
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        viewDetailBulk(id) {
            this.$router.push("/bulk-voucher-issuance-generate-voucher/"+id);
        },
        getExpiryDate(item) {
            if (item.voucher_set_detail.fixed_date === 1) {
                return item.voucher_set_detail.expiry_date ? moment(item.voucher_set_detail.expiry_date).format('D MMMM YYYY') : 'N/A';
            } else if (item.voucher_set_detail.expired_after === 1 && item.voucher_set_detail.time_number !== null) {
                const currentDate = item.created_at ? moment(item.created_at) : moment() ;
                return currentDate.add(item.voucher_set_detail.time_number, 'days').format('D MMMM YYYY');
            }
        },
        getCreatedDate(item) {
            return moment(item.created_at).format('D MMMM YYYY')
        }
    },
  };
  </script>
  <style scoped>
  .text-title {
    color: #133067 !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .text-label {
    color: #5b848d !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .checkbox-auto-save label {
    padding-top: 11px !important;
  }
  
  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }
  
  input[type="search"]::placeholder {
    color: #bbb;
    text-align: left;
    font-weight: normal;
  }
  
  input[type="search"]:focus {
    border-color: #72959d;
    outline: none;
    border: none;
    border-bottom: 2px solid #72959d;
  }
  
  .nosubmit-parent {
    border: none;
    padding: 0;
  }
  
  input.nosubmit {
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }
  
  .custom-title {
    word-break: break-all;
    max-width: 200px;
    white-space: normal;
  }
  .link {
    color: rgb(7, 7, 95);
  }
  .link:hover {
    cursor: pointer;
  }
  </style>