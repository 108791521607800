<template>
    <div>
        <div class="row mb-2 pe-4">
            <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
            <div class="color-common">
                <!-- <h3 class="title-page mb-0 ps-3">POS STAFF</h3> -->
            </div>
            </div>
            <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
            <material-button
                @click="addMemberPreference()"
                :disabled="disableBtnSubmit"
                class="mx-2 btn-background btn-sm"
            >
                Save
            </material-button>
            <material-button
                @click="addNewRow()"
                class="mx-2 btn-background btn-sm"
            >
                Add new row
            </material-button>
            </div>
        </div>
        <div class="page-header align-items-start min-vh-100 my-transacions">
            <span class="mask bg-white"></span>
            <div class="container">
                <div class="row">
                    <div class="notifications">
                    <material-alert
                        v-if="error"
                        color="danger"
                        icon="ni ni-like-2 ni-lg"
                        dismissible
                    >
                        {{ error }}
                    </material-alert>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12 mx-auto" >
                        <div  v-for="(value, key) in data" :key="key" class="row mt-4">
                            <div class="col-5">
                                Label
                                <material-input
                                    v-model="value.label"
                                    extraClass="ml-5"
                                    placeholder="Enter label"
                                />
                            </div>
                            <div class="col-5">
                                Value
                                <material-input
                                    v-model="value.value"
                                    extraClass="ml-5"
                                    placeholder="Enter Value"
                                />
                            </div>
                            <div class="col-2" v-if="key > 0">
                                <a
                                    class="btn btn-link text-dark px-3 mb-0"
                                    @click="removeRow(key)"
                                >
                                    <i class="fas fa-minus text-dark me-2" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar
                v-if="snackbar === 'danger' || snackbar === 'success'"
                title=""
                date=""
                :description="message"
                :icon="{ component: 'campaign', color: 'white' }"
                :color="snackbar"
                :close-handler="closeSnackbar"
            />
            </div>
            <material-loading :active="loaderActive" />
        </div>
    </div>
</template>
  
<script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import "bootstrap/js/dist/modal";
  import MaterialButton from "@/components/MaterialButton.vue";
  import "@vuepic/vue-datepicker/dist/main.css";
//   import ModalConfirm from "@/components/ModalConfirm.vue";
  import "@vueform/multiselect/themes/default.css";
  import MaterialInput from "@/components/MaterialInput.vue";
//   import $ from "jquery";
  import TokenService from "../../../services/token.service";
//   import MaterialRadio from "@/components/MaterialRadio.vue";
  import MemberPreferenceService from "../../../services/memberPreference.service";
  
  export default {
    name: "pos-staff",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      MaterialButton,
    //   ModalConfirm,
      MaterialInput,
    //   MaterialRadio,
    },
    data() {
        return {
            message: "",
            snackbar: null,
            loaderActive: false,
            data: [
                {
                    label: "",
                    value: ""
                }
            ],
            isLoadData: false,
            token: TokenService.getLocalAccessToken(),
            merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
  
        getData() {
            this.isLoadData = false;
            // this.data = [];
            MemberPreferenceService.getList().then(
            (response) => {
                if (response.data.result.isSuccess) {
                    if(response.data.data && response.data.data.length > 0) {
                        this.data = response.data.data;
                    }
                    this.isLoadData = true;
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
            }
            );
        },
  
        addMemberPreference() {
            
            let validate = false;

            this.data.forEach(obj => {
                if (obj.label.trim() === "" || obj.value.trim() === "") {
                    validate = true;
                }
            });

            if(validate) {
                this.snackbar = "danger";
                this.message = "Please fill in the blank inputs";
                this.setTimeOutNotification();
            } else {
                let dataPost = this.data.map(obj => ({
                    id: obj.id ? obj.id : 0,
                    label: obj.label,
                    value: obj.value.split(',').map(item => ({ name: item.trim() }))
                }));

                this.showLoader();
                MemberPreferenceService.add(dataPost).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.snackbar = "success";
                        this.message = "Save Member Preference Success";
                        this.setTimeOutNotification();
                        this.getData();
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
                );
            }
        },
  
        showLoader() {
            this.loaderActive = true;
        },
  
        hideLoader() {
            this.loaderActive = false;
        },

        addNewRow() {
            if(this.data && this.data.length < 8) {
                this.data.push({ label: '', value: '' });
            }
        },

        removeRow(key) {
            this.data.splice(key, 1);
        }
    },
  };
  </script>
  <style scoped>
    .text-title {
        color: #133067 !important;
        font-weight: 600 !important;
        font-size: 20px !important;
        font-family: "Roboto Slab", sans-serif !important;
    }
    
    .text-label {
        color: #5b848d !important;
        font-weight: 500 !important;
        font-size: 17px !important;
        font-family: "Roboto Slab", sans-serif !important;
    }
    
    .checkbox-auto-save label {
        padding-top: 11px !important;
    }
    
    .custom-name {
        word-break: break-all;
        max-width: 200px;
        white-space: normal;
    }
    
    .wrap-action .form-check:first-child {
        padding-left: 2px;
    }
    
    .multiselect {
        min-width: 135px;
    }
    
    .r-no-expiry .form-check {
        padding-left: 0 !important;
    }
    
    #preview img {
        width: 120px;
        height: 60px;
        object-fit: cover;
    }
    
    input[type="search"] {
        border: none;
        background: transparent;
        margin: 0;
        padding: 7px 8px;
        font-size: 14px;
        color: inherit;
        border: 1px solid transparent;
        border-radius: inherit;
    }
    
    input[type="search"]::placeholder {
        color: #bbb;
        text-align: left;
        font-weight: normal;
    }
    
    input[type="search"]:focus {
        border-color: #72959d;
        outline: none;
        border: none;
        border-bottom: 2px solid #72959d;
    }
    
    input.nosubmit {
        border: none;
        border-bottom: 1px solid #d2d2d2;
        width: 100%;
        padding: 9px 4px 9px 40px;
        background: transparent
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat 13px center;
    }
    
    @media (min-width: 576px) {
        .modal-dialog {
        max-width: 50%;
        margin: 1.75rem auto;
        }
    }
  </style>
  