import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/custom.css";
import MaterialDashboard from "./material-dashboard";
import setupInterceptors from './services/setupInterceptors';
import i18nPlugin from './config/initLanguage'
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import CKEditor from '@ckeditor/ckeditor5-vue';
import eventBus from "vue3-eventbus";
import print from 'vue3-print-nb'
import Tooltip from 'primevue/tooltip';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

const clickOutside = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = event => {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted: el => {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

const appInstance = createApp(App);
appInstance.directive("click-outside", clickOutside)
appInstance.use(store);
appInstance.use(router);
appInstance.use(MaterialDashboard);
appInstance.use(Vue3ColorPicker)
appInstance.use(CKEditor)
setupInterceptors();
appInstance.use(eventBus);
appInstance.use(i18nPlugin)
appInstance.use(print);
appInstance.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});
appInstance.directive('tooltip', Tooltip);
appInstance.mount("#app");
