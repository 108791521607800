<template>
<div>
    <div class="row mb-2 pe-4">
        <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
            <div class="color-common">
                <h3 class="title-page mb-0 ps-3">
                    MEMBERSHIP TIER
                </h3>
            </div>
        </div>
        <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
            <material-button @click="addRow()"
                class="btn-background btn-sm">
                Add New Tier
            </material-button>
        </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
        <!-- <span class="mask bg-white"></span> -->
        <div class="container">
            <div class="row mt-3">
                <div class="col-lg-12 col-md-12 col-12 mx-auto ps-0 pe-0" v-if="isLoadDoneData">
                    <div class="ps-0 pe-0">
                        <TierDetail :save-tier="saveTier" :remove-tier="confirmDelete" :show-accordion="showAccordion"
                            :field-required="fieldRequired" :form="form" :data-voucher="dataVoucher"
                            :discount-type="discountType" :rebate-type="rebateType"
                            :reward-store-price-level="rewardStorePriceLevel"
                            :standard-cashback-rule="standardCashbackRule"
                            :superior-cashback-rule="superiorCashbackRule"
                            :standard-point-rule="standardPointRule" 
                            :superior-point-rule="superiorPointRule"
                            :time-unit="timeUnit" :duration-unit="durationUnit" :top-up-type="topUpType"
                            :handle-file-upload="handleFileUpload" :url-image-preview="urlImagePreview"
                            :clear-image="clearImage" :message-image-error="messageImageError"
                            :tiersOpenning="tiersOpenning"
                            :member-type="memberType"
                            :membership-fee-unit="membershipFeeUnit"
                            :member-module="memberModule"
                            :check-module="checkModule"
                            :change-member-type="changeMemberType"
                            :reward-type="rewardType"
                            @update-membership-fee-total="updateMembershipFeeTotal"
                            />
                        <div v-if="form.length == 0" class="text-center p-5">
                            No Tiers Yet!
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalConfirm message="Are you sure you want to delete ?" :process-yes="processYes" />
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
                :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
                :close-handler="closeSnackbar" />
        </div>
        <material-loading :active="loaderActive" />
    </div>
</div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import 'bootstrap/dist/js/bootstrap.bundle'
import MaterialLoading from "@/components/MaterialLoading.vue";
import TierDetail from "@/components/TierDetail.vue";
import 'bootstrap/js/dist/modal'
import MaterialButton from "@/components/MaterialButton.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import SettingTierService from "../../services/settingTier.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import { reactive } from 'vue';
import $ from "jquery";

export default {
    name: "find-store",
    components: {
        MaterialSnackbar,
        MaterialLoading,
        MaterialButton,
        TierDetail,
        ModalConfirm
    },
    data() {
        return {
            message: '',
            snackbar: null,
            loaderActive: false,
            tiers: [],
            form: [],
            fieldRequired: [],
            indexItem: 0,
            isLoadDoneData: false,
            dataVoucher: [],
            discountType: [],
            rebateType: [],
            rewardStorePriceLevel: [],
            standardCashbackRule: [],
            superiorCashbackRule: [],
            standardPointRule: [],
            superiorPointRule: [],
            timeUnit: [],
            durationUnit: [],
            memberType: [],
            membershipFeeUnit: [],
            memberModule: [],
            memberModuleId: [],
            topUpType: [],
            urlImagePreview: [],
            image: [],
            messageImageError: [],
            isErrorUploadImage: [],
            isImageTooSmall: [],
            tiersOpenning: [],
            newVoucherId: 0,
            rewardType: 0
        };
    },
    created() {
        this.getTierList()
    },
    methods: {
        closeSnackbar() {
            this.snackbar = null;
        },

        setTimeOutNotification() {
            setTimeout(() => { this.snackbar = null; }, 3000);
        },

        closeAllCollapse() {
            $('div.accordion-collapse').removeClass("show")
            $(".accordion-button").attr("aria-expanded", false)
        },

        openCollapse() {
            let divAccordionCollapse = document.querySelectorAll("div.accordion-collapse");
            let lastAccordionCollapse = divAccordionCollapse.length - 1
            $(`#accordionRental_${lastAccordionCollapse} div.accordion-collapse`).addClass("show")
            $(`#accordionRental_${lastAccordionCollapse} button.accordion-button`).attr("aria-expanded", true)
        },

        showAccordion(index) {
            console.log(index)
            // this.closeAllCollapse();
            // $(`#accordionRental_${index} div.accordion-collapse`).addClass("show")
            // $(`#accordionRental_${index} button.accordion-button`).attr("aria-expanded", true)
        },

        async addRow() {
            this.closeAllCollapse()
            await this.addForm()
            this.openCollapse()
        },

        addForm() {
            this.form.push({
                id: null,
                tier_name: '',
                tier_badge: '',
                description: '',
                show_tier_description: 0,
                show_tier_condition: 0,
                spending_achieve_tier: 0,
                spending_achieve_tier_unit: 1,
                duration_achieve_membership: 0,
                duration_achieve_membership_unit: 2,
                spending_maintain_tier: 0,
                spending_maintain_tier_unit: 2,
                cashback_standard_rule: 0,
                cashback_superior_rule: 0,
                point_standard_rule: 0,
                point_superior_rule: 0,
                welcome_voucher: [],
                welcome_direct_cashback: 0,
                welcome_direct_point: 0,
                birthday_voucher: [],
                birthday_direct_cashback: 0,
                birthday_extra_rebase: 1,
                // birthday_extra_rebase_rule_only: 0,
                birthday_direct_point: 0,
                birthday_extra_point: 0,
                // birthday_extra_point_rule_only: 0,
                discount_type: 0,
                top_up_value_type: 0,
                reward_store_price_level: 0,
                share_full_profile: 0,
                is_paid_member_ship: 0,
                membership_fee: 0,
                membership_fee_total: 0,
                gst: 0,
                already_achieved_a_paid_tier_previously: 0,
                membership_fee_unit: 1,
                member_module: [],
                upgrade_tier_link: ""
            })

            this.fieldRequired.push({
                isTierNameRequire: false,
                isTierBadgeRequire: false,
            })
        },

        handleFileUpload(event, index) {
            this.isImageTooSmall[index] = false
            let file = event.target.files[0];
            if (file != null || file != undefined) {
                this.form[index].tier_badge = null
                this.urlImagePreview[index] = null
                if (!['image/png', 'image/jpg', 'image/jpeg', 'image/svg'].includes(file.type)) {
                    this.fieldRequired[index].isTierBadgeRequire = true;
                    this.isErrorUploadImage[index] = false
                    this.messageImageError[index] = `File must be an image.`
                } else {
                    this.form[index].tier_badge = file;
                    this.urlImagePreview[index] = URL.createObjectURL(this.form[index].tier_badge);

                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = evt => {
                        let img = new Image();
                        img.onload = () => {
                            let width = img.width;
                            let height = img.height;
                            if (width < 150 || height < 150) {
                                this.messageImageError[index] = `The image width ${ width } x ${ height } is too small.`
                                this.fieldRequired[index].isTierBadgeRequire = true;
                                this.isErrorUploadImage[index] = true
                                this.isImageTooSmall[index] = true
                            } else {
                                this.fieldRequired[index].isTierBadgeRequire = false;
                                this.isErrorUploadImage[index] = false
                                this.isImageTooSmall[index] = false
                            }
                        }
                        img.src = evt.target.result;
                    }
                }
            }
            else {
                this.form[index].tier_badge = null
                this.urlImagePreview[index] = null
                this.fieldRequired[index].isTierBadgeRequire = true;
                this.isErrorUploadImage[index] = false
                this.messageImageError[index] = `File must be an image.`
            }
        },

        clearImage(index) {
            this.form[index].tier_badge = null
            this.urlImagePreview[index] = null
            this.fieldRequired[index].isTierBadgeRequire = false;
            this.isErrorUploadImage[index] = false
            this.isImageTooSmall[index] = false
        },

        getTierList() {
            this.tiersOpenning = []
            let divAccordionCollapse = document.querySelectorAll("div.accordion-collapse");
            for (let i = 0;i < divAccordionCollapse.length;i++) {
               if( divAccordionCollapse[i].classList.contains('show') && divAccordionCollapse[i].getAttribute('tier_id') != null) {
                this.tiersOpenning.push(divAccordionCollapse[i].getAttribute('tier_id'))
               }
            }
            this.tiers = [];
            this.showLoader()
            this.isLoadDoneData = false;
            SettingTierService.getDataTier().then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.tiers = response.data.data.data_setting
                        this.rewardType = response.data.data.reward_type;
                        this.dataVoucher = response.data.data.data_dump.data_voucher
                        this.discountType = response.data.data.data_dump.discount_type
                        this.rebateType = response.data.data.data_dump.rebate_type
                        this.rewardStorePriceLevel = response.data.data.data_dump.reward_store_price_level
                        this.standardCashbackRule = response.data.data.data_dump.standard_cashback_rule
                        this.superiorCashbackRule = response.data.data.data_dump.superior_cashback_rule
                        this.standardPointRule = response.data.data.data_dump.standard_point_rule
                        this.superiorPointRule = response.data.data.data_dump.superior_point_rule
                        this.timeUnit = response.data.data.data_dump.time_unit
                        this.durationUnit = response.data.data.data_dump.duration_unit
                        this.memberType = response.data.data.data_dump.member_type
                        this.membershipFeeUnit = response.data.data.data_dump.membership_fee_unit
                        this.memberModule = response.data.data.data_dump.member_module
                        this.memberModuleId = response.data.data.data_dump.member_module_id
                        this.topUpType = response.data.data.data_dump.top_up_type
                        this.form = reactive(
                            this.tiers
                        )
                        this.form.forEach(() => {
                            this.fieldRequired.push({
                                isTierNameRequire: false,
                                isTierBadgeRequire: false
                            })
                        })
                        this.isLoadDoneData = true;
                    } else {
                        this.snackbar = 'danger'
                        this.message = response.data.result.message
                        this.setTimeOutNotification()
                    }
                    this.hideLoader()
                },
                (error) => {
                    this.snackbar = 'danger'
                    this.message = error
                    this.hideLoader()
                    this.setTimeOutNotification()
                }
            )
        },

        changeMemberType(type, index) {
            if (type) {
                // this.form[index].member_module = this.memberModuleId;
                this.form[index].already_achieved_a_paid_tier_previously = 0;
                this.form[index].membership_fee = 0;
                this.form[index].membership_fee_total = 0;
                this.form[index].gst = 0;
                this.form[index].membership_fee_unit = 1;
            }
        },

        checkModule(id, index) {
            if (this.form[index].member_module.includes(id)) {
                this.form[index].member_module.splice(this.form[index].member_module.indexOf(id), 1);
            } else {
                this.form[index].member_module.push(id);
            }
        },

        saveTier(index) {
            this.showLoader()
            if(this.isImageTooSmall[index]) {
                this.isErrorUploadImage[index] == true
                this.fieldRequired[index].isTierBadgeRequire = true;
            } else {
                this.fieldRequired[index].isTierBadgeRequire = false;
            }

            if (this.processValidate(index) && !this.isErrorUploadImage[index]) {
                this.form[index].show_tier_description = this.form[index].show_tier_description ? 1 : 0;
                this.form[index].show_tier_condition = this.form[index].show_tier_condition ? 1 : 0;
                // this.form[index].birthday_extra_rebase_rule_only = this.form[index].birthday_extra_rebase_rule_only ? 1 : 0;
                // this.form[index].birthday_extra_point_rule_only = this.form[index].birthday_extra_point_rule_only ? 1 : 0;
                this.form[index].share_full_profile = this.form[index].share_full_profile ? 1 : 0;
                SettingTierService.addOrUpdateTier(this.form[index]).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.newVoucherId = response.data.data.id;
                            this.snackbar = 'success'
                            this.message = 'Save Tier Success'
                            this.getTierList()
                            this.setTimeOutNotification()
                        } else {
                            this.snackbar = 'danger'
                            this.message = response.data.result.message
                            this.setTimeOutNotification()
                        }
                        this.hideLoader()
                    },
                    (error) => {
                        this.snackbar = 'danger'
                        this.message = error
                        this.hideLoader()
                        this.setTimeOutNotification()
                    }
                )
            } else {
                this.hideLoader()
            }
        },

        processYes() {
            $(".btn-close-modal").click();
            const index = this.indexItem;
            this.fieldRequired.forEach((item, index) => {
                this.resetRequired(index)
            })
            this.fieldRequired[index].isTierBadgeRequire = false;
            this.isErrorUploadImage[index] = false

            this.showLoader()
            if (this.form[index].id == null) {
                this.removeRow(index)
                this.hideLoader()
            } else {
                if (this.form[index].id) {
                    SettingTierService.deleteTier(this.form[index].id).then(
                        (response) => {
                            if (response.data.result.isSuccess) {
                                this.removeRow(index)
                                this.snackbar = 'success'
                                this.message = 'Delete Tier Success'
                                this.setTimeOutNotification()
                            } else {
                                this.snackbar = 'danger'
                                this.message = response.data.result.message
                                this.setTimeOutNotification()
                            }
                            this.hideLoader()
                        },
                        (error) => {
                            this.snackbar = 'danger'
                            this.message = error
                            this.hideLoader()
                            this.setTimeOutNotification()
                        }
                    )
                }
            }
        },
        confirmDelete(index) {
            this.indexItem = index;
            $(".btn-confirm-modal").click();
        },

        removeRow(index) {
            if (this.form.length > 0) {
                this.form.splice(index, 1)
            }
        },

        resetRequired(index) {
            this.fieldRequired[index].isTierNameRequire = false;
        },

        processValidate(index) {
            this.resetRequired(index);
            var isValid = true;
            if (this.form[index].tier_name == '' || this.form[index].tier_name == null) {
                this.fieldRequired[index].isTierNameRequire = true;
                isValid = false;
            }
            return isValid;
        },

        updateMembershipFeeTotal(total, index) {
            this.form[index].membership_fee_total = total;
        },

        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
    },
};
</script>
<style scoped>
</style>