<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalPreviewOption"
      id="show-modal-preview-option"
    ></material-button>
    <div
      class="modal fade"
      id="modalPreviewOption"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 600px">
        <div class="modal-content" style="padding: 0px">
          <div class="modal-header" style="padding-bottom: 0px">
            <button
              type="button"
              style="display: none"
              class="close btn-close-update-mog-for-item-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div>
              <h5
                class="modal-title"
                id="exampleModalLabel"
                style="color: black"
              >
                Options: {{ option.name }}
              </h5>
            </div>
            <i
              style="
                cursor: pointer;
                position: absolute;
                top: 18px;
                right: 18px;
              "
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div
              style="
                border: 1px solid lightgray;
                padding: 5px;
                border-radius: 5px;
              "
            >
              <div class="p-2">
                <div style="display: flex; align-items: center">
                  <i class="material-icons me-1">edit</i>
                  <div
                    class="fs-16 font-weight-bold modifierGroupName"
                    style="color: black"
                  >
                    {{ option.name }}
                  </div>

                  <div class="fs-11 ms-1" style="margin-top: 2px">
                    <span
                      class="font-inter"
                      v-if="option.min_qty == 0 && option.max_qty > 0"
                    >
                      Select up to {{ option.max_qty }}
                    </span>
                    <span
                      class="font-inter"
                      v-else-if="option.min_qty == 1 && option.max_qty == 1"
                    >
                      Select 1
                    </span>
                    <span
                      class="font-inter"
                      v-else-if="
                        option.min_qty == option.max_qty && option.max_qty > 1
                      "
                    >
                      Select {{ option.max_qty }}
                    </span>
                    <span class="font-inter" v-else>
                      Select {{ option.min_qty }} to
                      {{ option.max_qty }}
                    </span>
                  </div>
                </div>
                <div
                  class="d-inline-block signature-highlight modifierGroupSignature mt-1"
                >
                  <div style="font-weight: bold">
                    {{ option.min_qty == 0 ? "Optional" : "Compulsory" }}
                  </div>
                </div>
                <div style="margin-top: 20px"></div>
                <div v-for="(item, index) in option.items" :key="index">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <div style="display: flex; gap: 10px">
                      <div class="toggle_container pt-1 active">
                        <MaterialToggleButton
                          labelEnableText
                          labelDisableText
                          class="mb-0"
                        />
                      </div>
                      <div style="margin-top: 4px">
                        {{ item.item_name }}
                      </div>
                    </div>
                    <div>{{ item.price_format }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModifierOptionGroupService from "../services/product/modifierOptionGroup";
import MaterialToggleButton from "@/components/MaterialToggleButton.vue";

export default {
  name: "preivew-option",
  components: {
    MaterialButton,
    MaterialLoading,
    MaterialToggleButton,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    optionId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loaderActive: false,
      snackbar: null,
      message: "",
      option: {},
    };
  },
  methods: {
    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    loadData() {
      this.showLoader();
      var dataForm = {
        id: this.optionId,
        type: 1,
      };

      ModifierOptionGroupService.preview(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.option = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    clearData() {
      this.option = {};
    },
  },
  watch: {
    async keyReload() {
      this.clearData();
      this.loadData();
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}

.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}

.font-18 {
  font-size: 18px;
}

.font-inter {
  font-family: "Inter", sans-serif;
  color: #666666;
}

.fs-11 {
  font-size: 11px !important;
}

.signature-highlight {
  display: flex !important;
  align-items: center;
  border-radius: 10px;
  background-color: #96b7d8;
  height: 15px;
  color: black;
  width: fit-content;
  padding: 10px;
  font-size: 10px;
}

.responsive-box {
  width: 200px;
}

@media (max-width: 768px) {
  .responsive-box {
    width: 150px;
  }
}

.responsive-text {
  width: 100%;
}

@media (max-width: 768px) {
  .responsive-text {
    width: 60%;
  }
}
</style>
