import api from "../api";

class ItemService {
  getList(dataForm) {
    return api.get("/merchant/item", { params: dataForm });
  }

  getAllItem(dataForm) {
    return api.post("/merchant/item/get-all", dataForm);
  }

  getMainItem(dataForm) {
    return api.post("/merchant/item/get-main-item", dataForm);
  }

  store(dataForm) {
    return api.post("/merchant/item", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  addItemToCategory(dataForm) {
    return api.post("/merchant/item/category/add-item", dataForm);
  }

  addExistingItem(dataForm) {
    return api.post("/merchant/item/category/add-existing-item", dataForm);
  }

  attachModifier(dataForm) {
    return api.post("/merchant/item/attach-modifier", dataForm);
  }

  handleEnableOrDisable(dataForm) {
    return api.post("/merchant/item/enable-or-disable", dataForm);
  }

  handleEnableOrDisableByMenuset(dataForm) {
    return api.post("/merchant/item/enable-or-disable-by-menuset", dataForm);
  }

  handleEnableOrDisableAll(dataForm) {
    return api.post("/merchant/item/enable-or-disable-all", dataForm);
  }

  getMenuSetDropdown() {
    return api.post("/merchant/item/get-menu-set-dropdown");
  }

  update(id, dataForm) {
    return api.post("/merchant/item/update-item-list/"+id, dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteItem(id) {
    return api.delete("/merchant/item/" + id);
  }

  itemDetail(id, menusetId = 0, categoryId = 0, price_list_id = '') {
    return api.get("/merchant/item/" + id + '?set_id=' + menusetId + '&categoryId=' + categoryId + '&price_list_id=' + price_list_id);
  }

  moveUpDown(dataForm) {
    return api.post("/merchant/item/move-up-down", dataForm);
  }

  createCombo(dataForm) {
    return api.post("/merchant/item/create-combo", dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  comboDetail(id) {
    return api.post("/merchant/item/detail-combo/" + id);
  }

  updateCombo(id, dataForm) {
    return api.post("/merchant/item/update-combo/" + id, dataForm, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  handleAvailable(dataForm) {
    return api.post("/merchant/item/handle-available", dataForm);
  }

  getReportGroup() {
    return api.post("/merchant/item/get-report-group");
  }

  getListTag() {
    return api.post("/merchant/item/get-tag");
  }

  getAllStockItems(dataForm) {
    return api.get("/merchant/item/get-all-stock-items", { params: dataForm });
  }

  getCategoryByMenuSetDropdown(dataForm) {
    return api.post("/merchant/item/get-category-by-menu-set-dropdown", dataForm);
  }

  updateStockBalanceByItem(dataForm) {
    return api.post("/merchant/item/update-stock-balance-by-item", dataForm);
  }

  handleEnableOrDisableAvailable(dataForm) {
    return api.post("/merchant/item/enable-or-disable-available", dataForm);
  }

  handleEnableOrDisableAvailableAll(dataForm) {
    return api.post("/merchant/item/enable-or-disable-available-all", dataForm);
  }
  
  checkPasscodeStockPage(dataForm) {
    return api.post("/merchant/check-passcode-stock-page", dataForm);
  }

  handleStockOnOff(dataForm) {
    return api.post("/merchant/item/stock-on-off", dataForm);
  }
}

export default new ItemService();
