<template>
  <div v-if="this.nodeEnv === 'staging'">
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">PRODUCTS GROUP LIST</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button
          @click="addGroup()"
          :disabled="disableBtnSubmit"
          class="mx-2 btn-background btn-sm"
        >
          {{ $t('ADD_GROUP') }}
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="notifications">
            <material-alert
              v-if="error"
              color="danger"
              icon="ni ni-like-2 ni-lg"
              dismissible
            >
              {{ error }}
            </material-alert>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
            <div class="row my-3 d-flex align-items-left">
              <div class="col-lg-6 col-md-6 col-12">
                <input
                  class="nosubmit"
                  type="search"
                  id="keyword_search"
                  v-model="searchKeyword"
                  extraClass
                  name="keyword_search"
                  placeholder="Search by name, description"
                  @input="onChangesearchKeyword"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-end">
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" style="margin-top: 13px;"
                    data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                    {{ $t('download') }}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
                    </li>
                    <li>
                      <a class="dropdown-item" @click="exportFile('xlsx')" href="#">Export Excel</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder w-5">
                              {{ $t('Group_ID') }}
                            </th>
                            <th class="text-center font-weight-bolder w-20">
                              {{ $t('Group_Name') }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Group_Description') }}
                            </th>
                            <th class="text-center font-weight-bolder w-10">
                              {{ $t('status') }}
                            </th>
                            <th class="text-center font-weight-bolder w-10">
                              {{ $t('action') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="categories.length">
                          <tr v-for="(value, key) in categories" :key="key">
                            <td class="text-center text-xss">
                              {{ value.id_text }}
                            </td>
                            <td class="text-left text-xss">
                              {{ value.category_name }}
                            </td>
                            <td class="text-left text-xss">
                              {{ value.category_desc }}
                            </td>
                            <td class="text-center text-xss custom-name">
                              <span
                                class="badge badge-sm"
                                v-bind:class="
                                  value.status == 1
                                    ? 'bg-gradient-success'
                                    : 'bg-danger'
                                "
                                >{{ value.status_text }}</span
                              >
                            </td>
                            <td class="align-middle text-center text-sm">
                              <a
                                class="btn btn-link text-dark px-3 mb-0"
                                @click="editGroup(value.id)"
                              >
                                <i
                                  class="fas fa-pencil-alt text-dark me-2"
                                  aria-hidden="true"
                                ></i
                                >Edit
                              </a>
                              <a
                                class="
                                  btn btn-link
                                  text-danger text-gradient
                                  px-3
                                  mb-0
                                "
                                data-bs-toggle="modal"
                                data-bs-target="#modalConfirm"
                                href="javascript:;"
                                @click="confirmDelete(value.id)"
                              >
                                <i
                                  class="far fa-trash-alt me-2"
                                  aria-hidden="true"
                                ></i
                                >Delete
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="5" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure you want to delete ?"
        :process-yes="processYes"
      />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />

      <div>
        <button
          style="display: none"
          type="button"
          id="action-group"
          data-bs-toggle="modal"
          data-bs-target="#modalEdit"
        ></button>
        <div class="modal fade" id="modalEdit" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ labelGroup }}</h5>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label class="control-label" style="margin-left: 0px"
                    ><strong> Group Name</strong></label
                  >
                  <div>
                    <material-input
                      v-model="dataGroup.category_name"
                      extraClass="ml-5"
                      placeholder="Enter product group name"
                    />
                    <span class="is-invalid" v-if="nameIsRequired"
                      >The field is required.</span
                    >
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label class="control-label" style="margin-left: 0px"
                    ><strong>Product Group Description</strong></label
                  >
                  <div>
                    <material-textarea
                      v-model="dataGroup.category_desc"
                      extraClass="ml-5"
                      placeholder="Enter product group description"
                    />
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label class="control-label"
                    ><strong>Product Group Status</strong></label
                  >
                  <div class="d-flex pt-1 wrap-action">
                    <MaterialRadio
                      id="status_1"
                      v-model="dataGroup.status"
                      value="1"
                    >
                      Active
                    </MaterialRadio>
                    <MaterialRadio
                      id="status_2"
                      v-model="dataGroup.status"
                      value="0"
                    >
                      Deactivated
                    </MaterialRadio>
                  </div>
                </div>
              </div>
              <!-- Modal content-->
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-success btn-background btn-sm"
                  @click="saveGroup"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn-close-modal btn-sm"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form :action="`${actionDownload}/merchant/download-product-groups`" method="POST">
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="search_keyword" :value="searchKeyword" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import GroupService from "../../services/group.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import TokenService from "../../services/token.service";
import $ from "jquery";
export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    Pagination,
    MaterialInput,
    MaterialRadio,
    MaterialTextarea,
  },
  data() {
    return {
      nameIsRequired: false,
      message: "",
      snackbar: null,
      loaderActive: false,
      categories: [],
      searchKeyword: "",
      searchTimerId: null,
      pagination: {},
      page: 1,
      groupId: null,
      isLoadData: false,
      merchantCode: "",
      labelGroup: "ADD NEW GROUP",
      dataGroup: {
        id: 0,
        category_name: "",
        category_desc: "",
        status: 1,
      },
      action_export: 'csv',
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
      nodeEnv: process.env.VUE_APP_NODE_ENV,
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getList(this.page);
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    exportFile(type = 'csv') {
      this.action_export = type;
      setTimeout(() => {
        $('#downloadForm').click();
      }, 150);
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    getList(page) {
      this.isLoadData = false;
      this.categories = [];
      this.showLoader();
      var dataForm = {
        search_keyword: this.searchKeyword,
        page: page,
      };
      GroupService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.categories = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            });
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    saveGroup() {
      GroupService.save(this.dataGroup).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Save Product Group Success";
            this.setTimeOutNotification();
            $(".btn-close-modal").click();
            this.getList();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    addGroup() {
      this.labelGroup = "ADD NEW GROUP";
      this.dataGroup.id = 0;
      this.dataGroup.category_name = "";
      this.dataGroup.category_desc = "";
      this.dataGroup.status = 1;
      const modalAdd = document.getElementById("action-group");
      modalAdd.click();
    },

    async editGroup(groupId) {
      this.isLoadDoneData = false;
      this.showLoader();
      await GroupService.detail(groupId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            const category = response.data.data.category;
            this.isLoadDoneData = true;
            this.dataGroup.id = category.id ?? 0;
            this.dataGroup.category_name = category.category_name ?? "";
            this.dataGroup.category_desc = category.category_desc ?? "";
            this.dataGroup.status = category.status ?? 1;
            this.labelGroup = `EDIT GROUP [${category.category_name}]`;
            const editGroup = document.getElementById("action-group");
            editGroup.click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    processYes() {
      $(".btn-close-modal").click();
      this.showLoader();
      GroupService.delete(this.groupId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            if (this.categories.length == 1) {
              this.page = this.page - 1;
            }
            this.getList(this.page);
            this.snackbar = "success";
            this.message = "Delete product group success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    confirmDelete(groupId) {
      this.groupId = groupId;
      $(".btn-confirm-modal").click();
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.text-title {
  color: #133067  !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.text-label {
  color: #5b848d !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.checkbox-auto-save label {
  padding-top: 11px !important;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 1px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.custom-name {
  word-break: break-all;
  max-width: 200px;
  white-space: normal;
}
.wrap-action .form-check:first-child {
  padding-left: 2px;
}
</style>