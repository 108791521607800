import api from './api';

class SettingStoreService {
  getDataBranches(dataForm) {
    return api.post('/merchant/branches', dataForm);
  }

  deleteBranch(branchId) {
    return api.post('/merchant/branch/delete/' + branchId);
  }

  createNewBranch() {
    return api.post('/merchant/branch');
  }

  getDataBranchDetail(branchId) {
    if(branchId) {
      return api.post('/merchant/branch/' + branchId);
    } else {
      return api.post('/merchant/branch');
    }
  }

  addOrUpdateBranch(dataForm) {
    return api.post('/merchant/branch-create-or-update', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  changeStatus(branchId) {
    return api.post('/merchant/branch/change-status/' + branchId);
  }

  getOrderByBranch(dataForm) {
    return api.post('/merchant/order/get-list-by-branch', dataForm);
  }

  orderDetail(dataForm) {
    return api.post('/merchant/order/detail', dataForm);
  }

  moveOrder(dataForm) {
    return api.post('/merchant/order/move', dataForm);
  }

  changeWaitingTime(dataForm) {
    return api.post('/merchant/branch-change-waiting-time', dataForm);
  }

  stopOrdering(dataForm) {
    return api.post('/merchant/branch-stop-ordering/', dataForm);
  }

  checkPasscodeOrderPage(dataForm) {
    return api.post('/merchant/order/check-passcode', dataForm);
  }
}

export default new SettingStoreService();
