<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4
          @click="backToVenueList()"
          class="text-back d-flex align-items-center ps-2 cursor-pointer"
        >
          <i class="fa fa-chevron-circle-left pe-2" aria-hidden="true"></i>
          <div>BACK</div>
        </h4>
      </div>
    </div>
    <div class="mb-2 pe-4">
      <div class="d-flex align-items-center">
        <div
          class="color-common p-2 d-flex justify-content-between w-100 align-items-center"
        >
          <div class="d-flex">
            <h3 class="title-page mb-0">
              VENUE & SLOT SETTING
              {{ isLoadDone ? " > " + venue.name.toUpperCase() : "" }}
            </h3>
            <span
              v-if="isLoadDone"
              :class="
                venue.status
                  ? 'badge badge-sm bg-gradient-success'
                  : 'badge badge-sm bg-gradient-danger'
              "
              style="margin-left: 20px"
              >{{ venue.status ? "Active" : "Deactive" }}</span
            >
          </div>
          <material-button
            class="btn-background btn-sm"
            @click="saveTimeSlot()"
          >
            Save
          </material-button>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="d-flex mt-4 s-branch align-items-center">
              <Multiselect
                :options="timeList"
                v-model="timeSelect"
                placeholder="Select timeslot"
                :canClear="false"
                @change="getListTimeSlotByTime"
              />
              <material-button
                class="btn-background btn-sm ms-3"
                @click="addTimeSlot()"
              >
                Add TimeSlot
              </material-button>
            </div>
            <div class="mt-5">
              <div class="row mt-5">
                <div class="px-0 mt-4">
                  <div class="table-responsive p-0 custom-table">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center font-weight-bolder w-5"></th>
                          <th class="text-center font-weight-bolder w-10">
                            No.
                          </th>
                          <th class="text-center font-weight-bolder w-20">
                            Timeslot Name
                          </th>
                          <th class="text-center font-weight-bolde w-25">
                            Times
                          </th>
                          <th class="text-center font-weight-bolder w-15">
                            Active
                          </th>
                          <th class="text-center font-weight-bolder w-10"></th>
                        </tr>
                      </thead>
                      <draggable
                        v-if="timeSlot.length"
                        v-model="timeSlot"
                        group="timeSlot"
                        @start="drag = true"
                        item-key="id"
                        tag="tbody"
                      >
                        <template #item="{ element, index }">
                          <tr class="timeslot">
                            <td class="text-center">
                              <div class="ms-3 d-flex align-items-center">
                                <i class="material-icons css-icon-upload"
                                  >swap_vert</i
                                >
                              </div>
                            </td>
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                              <material-input
                                v-model="element.name"
                                type="text"
                                id="slot_name"
                                name="slot_name"
                                placeholder="Enter timeslot name"
                              />
                              <div
                                class="w-100 text-start mt-2"
                                v-if="element.fieldRequired.isNameRequire"
                              >
                                <span class="is-invalid"
                                  >The field is required.</span
                                >
                              </div>
                            </td>
                            <td class="text-center">
                              <div
                                class="d-flex align-items-center justify-content-center"
                              >
                                <Datepicker
                                  v-model="element.start_time"
                                  timePicker
                                  class="date-time"
                                  placeholder="From"
                                />
                                <div class="mx-3">_</div>
                                <Datepicker
                                  v-model="element.end_time"
                                  timePicker
                                  class="date-time"
                                  placeholder="To"
                                />
                              </div>
                              <div
                                class="w-100 text-start mt-2"
                                v-if="element.fieldRequired.isTimeRequire"
                              >
                                <span class="is-invalid"
                                  >The field is required.</span
                                >
                              </div>
                            </td>
                            <td class="text-center">
                              <Multiselect
                                v-model="element.status"
                                :options="statusList"
                                placeholder="Select status"
                                :canClear="false"
                              />
                            </td>
                            <td class="text-center">
                              <div class="ms-3 d-flex align-items-center">
                                <i
                                  class="fas fa-trash-alt cursor-pointer ms-2"
                                  @click="confirmDeleteTimeSlot(index)"
                                ></i>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </draggable>
                      <tbody v-else class="no-data">
                        <tr>
                          <td colspan="10" class="text-center">
                            Empty, no timeslot.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
  <ModalConfirm
    message="Are you sure you want to delete?"
    :process-yes="deleteTimeSlot"
  />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import BookingService from "../../services/booking.service";
import Multiselect from "@vueform/multiselect";
import ModalConfirm from "@/components/ModalConfirm.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import Datepicker from "@vuepic/vue-datepicker";
import $ from "jquery";
import moment from "moment";
import draggable from "vuedraggable";

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Multiselect,
    ModalConfirm,
    MaterialInput,
    Datepicker,
    draggable,
  },
  data() {
    return {
      isLoadDone: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      venueIdSelect: this.$route.params.id ?? 0,
      branchIdSelect: this.$route.query.branch_id ?? 0,
      venue: [],
      timeSelect: 9, //everyday
      timeList: [],
      statusList: [
        { value: 0, label: "Deactive" },
        { value: 1, label: "Bookable" },
      ],
      timeSlot: [],
      timeSlotIdSelect: 0,
    };
  },
  created() {
    this.getListTimeSlot();
  },
  methods: {
    addTimeSlot() {
      this.timeSlot.push({
        id: 0,
        name: "",
        start_time: null,
        end_time: null,
        status: 0,
        fieldRequired: {
          isNameRequire: false,
          isTimeRequire: false,
        },
      });
    },

    backToVenueList() {
      var url = "/booking/venue";
  
      if (this.branchIdSelect > 0) {
        url += "?branch_id=" + this.branchIdSelect;
      }

      this.$router.push(url);
    },

    confirmDeleteTimeSlot(index) {
      this.timeSlotIndexSelect = index;
      $(".btn-confirm-modal").click();
    },

    deleteTimeSlot() {
      $(".btn-close-modal").click();
      this.timeSlot.splice(this.timeSlotIndexSelect, 1);
      this.timeSlotIndexSelect = 0;
    },

    getListTimeSlot() {
      this.showLoader();
      this.isLoadDone = false;

      if (this.venueIdSelect) {
        var dataForm = {
          venue_id: this.venueIdSelect,
          time_select: this.timeSelect,
        };

        BookingService.listTimeSlot(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.venue = response.data.data.venue;
              this.timeSlot = this.venue.time_slot;
              this.timeList = response.data.data.time_select;
              this.isLoadDone = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },

    getListTimeSlotByTime(timeId) {
      this.showLoader();

      if (this.venueIdSelect) {
        var dataForm = {
          venue_id: this.venueIdSelect,
          time_select: timeId,
        };

        BookingService.listTimeSlotByTime(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.timeSlot = response.data.data;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },

    saveTimeSlot() {
      if (this.venueIdSelect) {
        if (this.processValidate()) {
          var dataForm = {
            venue_id: this.venueIdSelect,
            time_select: this.timeSelect,
            time_slot: this.timeSlot,
          };

          BookingService.saveTimeSlot(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.getListTimeSlot();
                this.snackbar = "success";
                this.message = "Add Timeslot Success";
                this.setTimeOutNotification();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    processValidate() {
      var isValid = true;

      this.timeSlot.forEach((item, index) => {
        this.timeSlot[index].fieldRequired.isNameRequire = false;
        this.timeSlot[index].fieldRequired.isTimeRequire = false;

        if (item.name == "" || item.name == null) {
          this.timeSlot[index].fieldRequired.isNameRequire = true;
          isValid = false;
        }

        if (
          item.start_time == "" ||
          item.start_time == null ||
          item.end_time == "" ||
          item.end_time == null
        ) {
          this.timeSlot[index].fieldRequired.isTimeRequire = true;
          isValid = false;
        } else {
          this.timeSlot[index].start_time = moment(item.start_time).format(
            "H:mm"
          );
          this.timeSlot[index].end_time = moment(item.end_time).format("H:mm");
        }
      });

      return isValid;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}

.nav-first {
  position: relative;
}
.nav-link {
  font-weight: bold;
}

.nav-first .count-order {
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid red;
  background: red;
  color: #ffffff;
  border-radius: 5px;
  padding: 0px 7px;
}

.multiselect {
  margin: 0 !important;
  max-width: 400px !important;
}

#general-tab .nav-item {
  width: 25%;
  text-align: center;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  width: 100%;
  max-width: 500px;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.cl-22 {
  color: #222 !important;
}

.show-order-info {
  font-size: 28px !important;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}
.btn-confirm-order {
  min-width: 50px !important;
  padding: 5px 10px !important;
  color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-confirm-order:hover {
  background: #28a745 !important;
  color: #ffffff !important;
}

.timeslot:last-child {
  height: 220px;
}

.badge {
  height: fit-content;
}

.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}
</style>
