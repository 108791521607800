<template>
    <div v-if="this.nodeEnv === 'staging'">
      <div class="row my-2 d-flex">
        <div class="col-lg-6 col-md-6 col-12 text-title">SUBSCRIPTION</div>
        
      </div>
      <div class="col-lg-12 col-md-12 col-12">Select a subscription package that matches your need to start building your own customer loyalty program.</div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 mx-auto">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="card-custom">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder">{{ $t('select') }}</th>
                            <th class="text-center font-weight-bolder">{{ $t('package') }}</th>
                            <th class="text-center font-weight-bolder">{{ $t('price') }}</th>
                            <th class="text-center font-weight-bolder">{{ $t('nextBiling') }}</th>
                            <th class="text-center font-weight-bolder">{{ $t('dueAmount') }}</th>
                            <th class="text-center font-weight-bolder">{{ $t('paymentStatus') }}</th>
                            <th class="text-center font-weight-bolder">{{ $t('status') }}</th>
                            <th class="text-center font-weight-bolder">{{ $t('action') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in subscriptions"
                            :index="index"
                            :key="index"
                            :style="item.active ? 'background-color: lightgrey;color: black;' : ''"
                          >
                            <td class="text-center text-xss w-1">
                                <input type="radio" :value="item.id" v-model="picked" :checked="item.active" :disabled="item.active || item.isCancel" />
                            </td>
                            <td class="text-center text-xss w-5">
                                {{ item.name }}
                            </td>
                            <td class="text-center text-xss w-5">
                                {{ item.price }}
                            </td>
                            <td class="text-center text-xss w-5">
                                {{ item.next_biling && item.isCancel ? " This subscription will expire on "+item.next_biling : item.next_biling }}
                            </td>
                            <td class="text-center text-xss w-5">
                                {{ item.due_amount }}
                            </td>
                            <td class="text-center text-xss w-5">
                                {{ item.payment_status_text }}
                            </td>
                            <td class="text-center text-xss w-5">
                                {{ item.status_text }}
                            </td>
                            <td class="text-center text-xss w-5">
                                <a
                                    class="btn-link text-dark text-underline"
                                    @click="cancelSubscription(item.id)"
                                    v-if="item.active && !item.isCancel"
                                >
                                    Cancel
                                </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination
                      v-if="pagination.last_page > 1"
                      :pagination="pagination"
                      :current_page="pagination.current_page"
                      :last_page="pagination.last_page"
                    />
                    <br />
                    <table class="table align-items-center mb-0" v-if="this.subscriptions && this.subscriptions.length > 0">
                        <tbody>
                          <tr>
                            <td class="text-center text-xss w-1" 
                                
                            >
                                
                            </td>
                            <td class="text-center text-xss w-11 float-right">
                                <material-button class="btn mb-0 btn-success btn-md btn-background margin-right-btn"
                                    @click="purchase()"
                                    v-if="this.getCurrenlySubscriptionByStatus() === false && !loaderActive"
                                >
                                    Purchase
                                    {{  }}
                                </material-button>
                                <material-button
                                    class="btn mb-0 btn-success btn-md btn-background margin-right-btn"
                                    @click="viewMonthlyReport()"
                                    v-if="this.getCurrenlySubscriptionByStatus() === true"
                                >
                                    View Subscription Report
                                </material-button>
                                <material-button
                                    class="btn mb-0 btn-success btn-md btn-background"
                                    @click="changePackage()"
                                    v-if="this.getCurrenlySubscriptionByStatus() === true && !loaderActive"
                                >
                                    Change Package
                                </material-button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
          <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
          />
        </div>
        <material-loading :active="loaderActive" />
      </div>
    </div>
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import Pagination from "@/components/Pagination.vue";
  import "bootstrap/js/dist/modal";
  import "@vuepic/vue-datepicker/dist/main.css";
  import Subscriptions from "../../services/subscriptions.service";
  
  export default {
    name: "find-store",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      Pagination
    },
    created() {
        var subscription_id = this.$route.query.subscription_id;
        var session_id = this.$route.query.session_id;
        var price = this.$route.query.price;
        var duration = this.$route.query.duration;
        if(subscription_id && session_id && price && duration) { // call back after pay success - stripe
            this.showLoader();
            this.isLoadDone = false;
            Subscriptions.saveSubscriptionsHistory({subscription_id, session_id, price, duration}).then(
                (response) => {
                  if (response.data.result.isSuccess) {
                      this.getData();
                      this.snackbar = "success";
                      this.message = "Subscription Successfully!";
                      this.setTimeOutNotification();
                  } else {
                      this.snackbar = "danger";
                      this.message = response.data.result.message;
                      this.setTimeOutNotification();
                      window.location.href = '/subscriptions'
                  }
                  this.isLoadDone = true;
                  this.hideLoader();
                }
            );
        }
    },
    mounted() {
        const code = localStorage.getItem("MERCHANT_CODE", "");
        this.merchantCode = code ? `/${code}` : "";
        this.getData();
    },
    data() {
      return {
        message: "",
        snackbar: null,
        loaderActive: false,
        picked: '',
        subscriptions: [],
        pagination: {},
        merchantCode: '',
        nodeEnv: process.env.VUE_APP_NODE_ENV,
      };
    },
    methods: {
        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
  
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        purchase() { 
            var data = this.getCurrenlySubscriptionById();
            if(data) {
                this.showLoader();
                this.isLoadDone = false;
                var url = window.location.href;
                var dataPost = {
                  subscription_id: data.id,
                  success_url: url+"?session_id={CHECKOUT_SESSION_ID}&subscription_id="+data.id+"&price="+(parseFloat(data.price.split(" ")[1]) || 0)+"&duration="+data.duration,
                  cancel_url: url
                }
                Subscriptions.createSubscriptions(dataPost).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                          if(response.data.data.url) {
                            window.location.href = response.data.data.url
                          } else {
                            this.getData();
                            this.snackbar = "success";
                            this.message = "Subscription Successfully!";
                            this.setTimeOutNotification();
                            // setTimeout(() => {
                            //     const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
                            //     var url = location.protocol + '//' + location.host+"/"+merchantCode+"/subscriptions";
                            //     window.location.href = url;
                            // }, 3000);
                          }
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.isLoadDone = true;
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                    }
                );
            } else {
                this.snackbar = "danger";
                this.message = "Please select one subscription";
                this.hideLoader();
                this.setTimeOutNotification();
            }
        },
        getCurrenlySubscriptionById() {
            return this.subscriptions.find(x => x.id === this.picked);
        },
        getCurrenlySubscriptionByStatus() {
            if(this.subscriptions && this.subscriptions.length > 0) {
                var data = this.subscriptions.find(x => x.active === true);
                if(data && data.active === true) {
                    return true;
                }
            }
            return false;
        },
        checkCancelSubscription() {
            if(this.subscriptions && this.subscriptions.length > 0) {
                var data = this.subscriptions.find(x => x.active === true && x.isCancel === true);
                if(data && data.active === true) {
                    return true;
                }
            }
            return false;
        },
        viewMonthlyReport() {
            this.$router.push("/view-report-month-subscriptions");
        },
        getData() {
            this.subscriptions = [];
            this.showLoader();
            var dataForm = {
                
            };
            Subscriptions.getDataSubscriptions(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.subscriptions = response.data.data;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        cancelSubscription(subscription_id) {
            // var data = this.subscriptions.find(x => x.active === true);
            if(confirm("Do you really want to cancel Subscription?")){
                Subscriptions.cancelSubscription(subscription_id).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.getData();
                            this.snackbar = "success";
                            this.message = "Cancel Subscription Successfully!";
                            this.setTimeOutNotification();
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                        this.setTimeOutNotification();
                    }
                );
            };
        },
        changePackage() {
            this.purchase();
        }
    },
  };
  </script>
  <style scoped>
  .text-title {
    color: #133067  !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .text-label {
    color: #5b848d !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .checkbox-auto-save label {
    padding-top: 11px !important;
  }
  
  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
  }
  
  input[type="search"]::placeholder {
    color: #bbb;
    text-align: left;
    font-weight: normal;
  }
  
  input[type="search"]:focus {
    border-color: #72959d;
    outline: none;
    border: none;
    border-bottom: 1px solid #72959d;
  }
  
  .nosubmit-parent {
    border: none;
    padding: 0;
  }
  
  input.nosubmit {
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    padding: 9px 4px 9px 40px;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }
  
  .custom-title {
    word-break: break-all;
    max-width: 200px;
    white-space: normal;
  }
  .float-right {
      float: right;
  }

  .margin-right-btn {
      margin-right: 5px;
  }

  .text-underline {
    text-decoration-line: underline;
    /* padding-left: 0px; */
    padding: 0px;
    cursor: pointer;
  }
  </style>