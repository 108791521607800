<template>
  <div>
    <button
      style="display: none"
      type="button"
      id="show-modal-cancel-booking"
      data-bs-toggle="modal"
      data-bs-target="#modalCancelBooking"
    ></button>
    <div
      class="modal fade"
      id="modalCancelBooking"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Cancel Booking</h5>
          </div>
          <div class="modal-body" v-if="bookingInfo">
            <div>
              Booking No:
              <span class="booking-no">{{ bookingInfo.booking_code }}</span>
            </div>
            <div class="mt-2">Are you sure to cancel this booking?</div>
            <div class="row d-flex bd-highlight mt-2">
              <div class="col-lg-4 col-4 align-self-center">
                Refunded Amount
              </div>
              <div
                class="col-lg-8 col-8 d-flex bd-highlight d-flex align-items-center"
              >
                <material-input
                  extraClass="ml-5"
                  placeholder="Enter refund amount"
                  class="w-60"
                  v-model="refundAmount"
                  :type="'number'"
                  @input="validateRefundAmount"
                  @keydown.prevent.up="preventUpDown"
                  @keydown.prevent.down="preventUpDown"
                  :disabled="'disabled'"
                />
              </div>
            </div>
          </div>
          <!-- Modal content-->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="cancelBooking"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-primary btn-close-modal btn-sm"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import BookingService from "../services/booking.service";
import $ from "jquery";

export default {
  name: "CancelBooking",
  components: {
    MaterialInput,
  },
  props: {
    bookingInfo: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loaderActive: false,
      refundAmount: 0,
    };
  },
  mounted() {},
  methods: {
    validateRefundAmount(event) {
      var amount = parseFloat(event.target.value);

      if (amount < 0) {
        this.refundAmount = 0;
      } else {
        if (amount > this.bookingInfo.total_price) {
          this.refundAmount = this.bookingInfo.total_price;
        }
      }
    },

    preventUpDown(event) {
      // Check if the up or down arrow key was pressed
      if (event.keyCode === 38 || event.keyCode === 40) {
        // Prevent the default behavior of the key event
        event.preventDefault();
      }
    },

    cancelBooking() {
      this.showLoader();
      BookingService.cancelBooking(this.bookingInfo.booking_code).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.$emit("cancel-booking-success");
            $(".btn-close-modal").click();
          } else {
            console.log(response.data.result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
      this.hideLoader();
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
  },
  watch: {},
};
</script>

<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}

.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}

.booking-no {
  font-weight: bold;
  color: #344767;
}
</style>
