<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-customize-audience-list-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalCustomizeAudienceList"
    ></button>
    <div
      class="modal fade"
      id="modalCustomizeAudienceList"
      tabindex="-1"
      aria-labelledby="modalCustomizeAudienceListLabel"
      aria-hidden="true"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Customize Audience List</h5>
            <button
              type="button"
              class="btn-close btn-close-customize-audience-list-modal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div class="modal-body">
            <div
              class="page-header align-items-start min-vh-100 my-transacions"
            >
              <span class="mask bg-white"></span>
              <div class="container">
                <div class="row">
                  <div class="notifications">
                    <material-alert
                      v-if="error"
                      color="danger"
                      icon="ni ni-like-2 ni-lg"
                      dismissible
                    >
                      {{ error }}
                    </material-alert>
                  </div>
                  <div
                    class="col-lg-12 col-md-12 col-12 mx-auto"
                  >
                    <div class="row filter-row-1">
                        <div class="col-lg-4 col-md-12">
                        </div>
                        <div class="col-lg-8 col-md-12 d-flex align-items-center">
                          <input
                            class="nosubmit"
                            type="search"
                            id="keyword_search"
                            v-model="searchKeyword"
                            extraClass
                            name="keyword_search"
                            placeholder="Search for member name, id, phone number"
                            @input="onChangesearchKeyword"
                          />
                          <div class="btn btn-filter mb-0 ms-2" @click="collapseFilter($event)">
                            Filter Option
                          </div>
                          <div class="btn btn-clear mb-0" @click="clearFilter()">
                            clear
                          </div>
                        </div>
                      </div>
                    <div class="filter-list d-none">
                      <div class="row mt-3 filter-row-2">
                        <div class="col-lg-3 col-md-12">
                          <Multiselect
                            v-model="time_type"
                            :options="dateFilter"
                            @change="changeTimeType"
                            :canClear="false"
                          />
                        </div>
                        <div class="col-lg-3 col-md-12" v-if="isShowDateCustom">
                          <Datepicker v-model="from_date" :enableTimePicker="false" :format="format" class="date-time pe-2"/>
                        </div>
                        <div class="col-lg-3 col-md-12" v-if="isShowDateCustom">
                          <Datepicker v-model="to_date" :enableTimePicker="false" :format="format" class="date-time pe-2"/>
                        </div>
                        <div class="col-lg-3 col-md-12">
                          <Multiselect
                            v-model="limit_member"
                            :options="limitFilter"
                            @change="changeLimit"
                            :canClear="false"
                          />
                        </div>
                        <div class="col-lg-3 col-md-12">
                          <Multiselect
                            v-model="tier_id"
                            :options="tiers"
                            @change="changeTierId"
                            :canClear="false"
                          />
                        </div>
                        <div class="col-lg-3 col-md-12">
                          <Multiselect
                            v-model="gender"
                            :options="genders"
                            @change="chagnegGender"
                            :canClear="false"
                          />
                        </div>
                      </div>
                      <div class="row mt-3 filter-row-3">
                        <div class="col-3 d-flex align-items-center">
                          <MaterialRadio
                            v-model="most_type"
                            class="ps-0 mb-0"
                            id="status_1"
                            value="1"
                            @change="changeMostType($event)"
                          >
                            Most Spending Members
                          </MaterialRadio>
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <MaterialRadio
                            v-model="most_type"
                            id="status_2"
                            value="2"
                            @change="changeMostType($event)"
                            class="ps-0 mb-0"
                          >
                            Most Visiting Members
                          </MaterialRadio>
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <MaterialRadio
                            v-model="most_type"
                            id="status_3"
                            value="3"
                            @change="changeMostType($event)"
                            class="ps-0 mb-0"
                          >
                            Most frequently buy certain product
                          </MaterialRadio>
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <Multiselect
                            v-model="product_code"
                            :options="productCodeFilter"
                            @change="changeProductCode"
                            :canClear="false"
                            v-show="isShowProductCode"
                            label="sku"
                            mode="tags"
                            placeholder="Select Product codes"
                            :searchable="true"
                          />
                        </div>
                      </div>
                      <div class="row mt-3 filter-row-3">
                        <div class="col-2 d-flex align-items-center">
                          Last spend
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <Multiselect
                            v-model="last_spend_compare"
                            :options="compareFilter"
                            @change="changeLastSpendCompare"
                            :canClear="false"
                            class="dropdown-custom px-0"
                          />
                        </div>
                        <div class="col-2 d-flex align-items-center">
                          <material-input v-model="last_spend_compare_value" @input="changeLastSpendCompareValue"/>
                        </div>
                        <div class="col-1 d-flex align-items-center">
                          at stores
                        </div>
                        <div class="col-4 d-flex align-items-center">
                          <Multiselect
                            v-model="last_store"
                            :options="branchFilter"
                            @change="lastStore"
                            :canClear="false"
                            mode="tags"
                            placeholder="Select branches"
                          />
                        </div>
                      </div>
                      <div class="row mt-3 d-flex" v-if="isShowLastSpendCustom">
                        <div class="col-2">
                        </div>
                        <div class="col-1 d-flex justify-content-end">
                          From
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <material-input v-model="last_spend_from" type="number" @input="changeLastSpendFrom"/>
                        </div>
                        <div class="col-1 d-flex">
                          To
                        </div>
                        <div class="col-3">
                          <material-input v-model="last_spend_to" type="number" @input="changeLastSpendTo"/>
                        </div>
                      </div>
                      <div class="row mt-3 d-flex" v-if="isLastSpendRequire">
                        <div class="col-3">
                        </div>
                        <div class="col-7 d-flex align-items-center">
                          <span class="is-invalid">{{ textCustomLastSpendError }}</span>
                        </div>
                      </div>
                      <div class="row mt-3 filter-row-3">
                        <div class="col-2 d-flex align-items-center">
                          Top Up
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <Multiselect
                            v-model="top_up_compare"
                            :options="compareFilter"
                            @change="changeTopUpCompare"
                            :canClear="false"
                            class="dropdown-custom px-0"
                          />
                        </div>
                        <div class="col-2 d-flex align-items-center">
                          <material-input v-model="top_up_value" @input="changeToUpValue"/>
                        </div>
                      </div>
                      <div class="row mt-3 d-flex" v-if="isShowTopUpCustom">
                        <div class="col-2">
                        </div>
                        <div class="col-1 d-flex justify-content-end">
                          From
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <material-input v-model="top_up_from" type="number" @input="changeToUpFrom"/>
                        </div>
                        <div class="col-1 d-flex">
                          To
                        </div>
                        <div class="col-3">
                          <material-input v-model="top_up_to" type="number" @input="changeToUpTo"/>
                        </div>
                      </div>
                      <div class="row mt-3 d-flex" v-if="isTopUpRequire">
                        <div class="col-3">
                        </div>
                        <div class="col-7 d-flex align-items-center">
                          <span class="is-invalid">{{ textCustomTopUpError }}</span>
                        </div>
                      </div>
                      <div class="row mt-3 d-flex">
                        <div class="col-2 d-flex align-items-center">
                          Common Visit Hour
                        </div>
                        <div class="col-1 d-flex justify-content-end">
                          From
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <Datepicker
                            v-model="common_visit_start_hour"
                            timePicker
                          />
                        </div>
                        <div class="col-1 d-flex">
                          To
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <Datepicker
                            v-model="common_visit_end_hour"
                            timePicker
                          />
                        </div>
                      </div>
                      <div class="row mt-3 d-flex">
                        <div class="col-2">
                          Ages
                        </div>
                        <div class="col-1 d-flex justify-content-end">
                          From
                        </div>
                        <div class="col-3 d-flex align-items-center">
                          <material-input v-model="ages_from" type="number" @input="changeAgeFrom"/>
                        </div>
                        <div class="col-1 d-flex">
                          To
                        </div>
                        <div class="col-3">
                          <material-input v-model="ages_to" type="number" @input="changeAgeTo"/>
                        </div>
                      </div>
                      <div class="row mt-3 d-flex" v-if="isAgeRequire">
                        <div class="col-3">
                        </div>
                        <div class="col-7 d-flex align-items-center">
                          <span class="is-invalid">{{ textAgeUpError }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4" v-if="isLoadData">
                      <div class="col-12">
                        <div class="card-custom">
                          <div class="table-responsive p-0 custom-table">
                            <table class="table align-items-center mb-0">
                              <thead>
                                <tr>
                                  <th
                                    class="w-2 text-center font-weight-bolder text-label"
                                  >
                                    <material-checkbox
                                      @click="checkAll"
                                      :checked="isCheckedAll"
                                      class="align-items-center checkbox-custom"
                                    ></material-checkbox>
                                  </th>
                                  <th
                                    class="w-5 text-center font-weight-bolder"
                                  >
                                    ID
                                  </th>
                                  <th class="text-center font-weight-bolder">
                                    Phone No
                                  </th>
                                  <th class="text-center font-weight-bolder">
                                    Name
                                  </th>
                                  <th class="text-center font-weight-bolder">
                                    Email
                                  </th>
                                  <th class="text-center font-weight-bolder">
                                    DOB
                                  </th>
                                  <th class="text-center font-weight-bolder">
                                    Gender
                                  </th>
                                  <th class="text-center font-weight-bolder">
                                    Join Date
                                  </th>
                                  <th class="text-center font-weight-bolder">
                                    Last Purchase
                                  </th>
                                  <th class="text-center font-weight-bolder">
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(value, key) in members" :key="key">
                                  <td class="text-center text-xss">
                                    <MaterialCheckbox
                                      @click="checkItem(value.id)"
                                      :checked="idChecked.includes(value?.id)"
                                      :value="value.id"
                                      class="align-items-center checkbox-custom"
                                    />
                                  </td>
                                  <td class="text-center text-xss w-5">
                                    {{ value.id_text }}
                                  </td>
                                  <td class="text-center text-xss">
                                    {{ value.phone_number }}
                                  </td>
                                  <td class="text-left text-xss custom-title">
                                    {{ value.name }}
                                  </td>
                                  <td class="text-left text-xss">
                                    {{ value.email }}
                                  </td>
                                  <td class="text-center text-xss">
                                    {{ value.date_of_birth }}
                                  </td>
                                  <td class="text-center text-xss">
                                    {{ value.gender }}
                                  </td>
                                  <td class="text-center text-xss">
                                    {{ value.join_date }}
                                  </td>
                                  <td class="text-center text-xss">
                                    {{ value.last_purchase }}
                                  </td>
                                  <td class="text-center text-xss w-5">
                                    <i
                                      class="fa fa-lock"
                                      v-if="value.block == 0"
                                      style="color: #ff3300"
                                    ></i>
                                    <i
                                      class="fa fa-check-square-o"
                                      style="color: #33cc33"
                                      v-else
                                    ></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="row d-flex justify-content-end mt-3">
                            <div class="col-lg-5 col-md-5 col-12">
                              <material-input
                                v-model="group_name"
                                id="group_name"
                                name="group_name"
                                class="align-items-end"
                                placeholder="Enter group name"
                              />
                              <span class="is-invalid" v-if="isGroupNameRequire"
                                >The field is required.</span
                              >
                            </div>
                            <div class="col-lg-3 col-md-3 col-12 text-end">
                              <button
                                type="button"
                                class="btn btn-success btn-background btn-sm ms-2"
                                @click="createGroup()"
                                :disabled="disabled"
                              >
                                Create Group
                              </button>
                            </div>
                          </div>
                          <pagination
                            v-if="pagination.last_page > 1"
                            :pagination="pagination"
                            :current_page="pagination.current_page"
                            :last_page="pagination.last_page"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="position-fixed top-1 end-1 z-index-2 message-success">
                <material-snackbar
                  v-if="snackbar === 'danger' || snackbar === 'success'"
                  title=""
                  date=""
                  :description="message"
                  :icon="{ component: 'campaign', color: 'white' }"
                  :color="snackbar"
                  :close-handler="closeSnackbar"
                />
              </div>
              <material-loading :active="loaderActive" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="get_target_audience"
      style="display: none"
      @click="getTargetAudience"
    ></button>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import CampaignService from "../services/settingCampaign.service.js";
import MemberService from "../services/member.service";
import ProductService from "../services/product.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import $ from "jquery";
import Pagination from "@/components/Pagination.vue";
import moment from "moment";
import "bootstrap/js/dist/modal";
import CommonFunctionService from "../services/commonFunction.service";

export default {
  name: "ExchangeStatus",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialInput,
    Pagination,
    MaterialCheckbox,
    Multiselect,
    MaterialRadio,
    Datepicker,
  },
  props: {
    getTargetAudience: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      members: [],
      searchKeyword: "",
      searchTimerId: null,
      pagination: {},
      page: 1,
      memberId: null,
      isLoadData: false,
      merchantCode: "",
      total: 0,
      idChecked: [],
      isCheckedAll: false,
      filter_tier: 0,
      filter_gender: 0,
      tiers: [],
      tier_id: 0,
      genders: [],
      gender: 0,
      vouchers: [],
      voucherId: 0,
      group_name: "",
      isVoucherRequire: false,
      disabled: false,
      isGroupNameRequire: false,
      isLastSpendRequire: false,
      textCustomLastSpendError: "",
      isTopUpRequire: false,
      textCustomTopUpError: "",
      isAgeRequire: false,
      textAgeUpError: "",
      memberIds: [],
      from_date: null,
      to_date: null,
      dateFilter: [],
      limitFilter: [],
      time_type: 1,
      limit_member: 0,
      most_type: 1,
      ages_from: '',
      ages_to: '',
      isShowDateCustom: false,
      isShowProductCode: false,
      isShowLastSpendCustom: false,
      isShowTopUpCustom: false,
      productCodeFilter: [],
      product_code: [],
      branchFilter: [],
      compareFilter: [],
      last_purchase_within_store: [],
      last_spend_compare: 0,
      last_spend_compare_value: '',
      last_store: [],
      top_up_compare: 0,
      top_up_value: '',
      common_visit_start_hour: null,
      common_visit_end_hour: null,
      last_spend_from: '',
      last_spend_to: '',
      top_up_from: '',
      top_up_to: '',
    };
  },
  mounted() {
    this.getList(this.page);
    this.getProductDropDown()
  },
  methods: {
    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },

    changeProductCode(productIds) {
      this.product_code = productIds
      this.page = 1;
      this.getList(this.page);
    },

    changeLastSpendCompare(value) {
      this.last_spend_compare = value
      if(value == 4) {
        this.isShowLastSpendCustom = true
      } else {
        this.isShowLastSpendCustom = false
      }
      this.page = 1;
      this.getList(this.page);
    },

    changeLastSpendCompareValue() {
      this.page = 1;
      this.getList(this.page);
    },

    changeLastSpendFrom($event) {
      this.last_spend_from = $event.target.value
      this.page = 1;
      this.getList(this.page);
    },

    changeLastSpendTo($event) {
      this.last_spend_to = $event.target.value
      this.page = 1;
      this.getList(this.page);
    },

    lastStore(branchIds) {
      this.last_store = branchIds
      this.page = 1;
      this.getList(this.page);
    },

    changeTopUpCompare(value) {
      this.top_up_compare = value
      if(value == 4) {
        this.isShowTopUpCustom = true
      } else {
        this.isShowTopUpCustom = false
      }
      this.page = 1;
      this.getList(this.page);
    },

    changeToUpValue() {
      this.page = 1;
      this.getList(this.page);
    },

    changeToUpFrom($event) {
      this.top_up_from = $event.target.value
      this.page = 1;
      this.getList(this.page);
    },

    changeToUpTo($event) {
      this.top_up_to = $event.target.value
      this.page = 1;
      this.getList(this.page);
    },

    changeAgeFrom() {
      this.page = 1;
      this.getList(this.page);
    },

    changeAgeTo() {
      this.page = 1;
      this.getList(this.page);
    },

    getProductDropDown() {
      this.productCodeFilter = []
      this.isLoadData = false;
      this.showLoader();
      ProductService.getProductDropdown().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.productCodeFilter = response.data.data;
            console.log('productCodeFilter', this.productCodeFilter);
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    collapseFilter(event) {
      event.target.classList.toggle("hide-filter");
      $('.filter-list').toggleClass('d-none');
    },
    clearFilter() {
      this.searchKeyword = "";
      this.time_type = 1;
      this.isShowDateCustom = false;
      this.limit_member = 0;
      this.tier_id = 0;
      this.gender = 0;
      this.most_type = 1;
      this.product_code = [],
      this.last_purchase_within_store = [],
      this.last_spend_compare = 0
      this.last_spend_compare_value = ''
      this.last_spend_from = ''
      this.last_spend_to = ''
      this.last_store = [],
      this.top_up_compare = 0
      this.top_up_value = ''
      this.top_up_from = ''
      this.top_up_to = ''
      this.common_visit_start_hour = null
      this.common_visit_end_hour = null
      this.ages_from = '';
      this.ages_to = '';
      this.memberIds = [];
      this.members = [];
      this.getList(this.page);
    },
    changeTimeType(timeType) {
      this.time_type = timeType;
      this.page = 1;
      if (timeType == 10) {
        this.isShowDateCustom = true;
      } else {
        this.isShowDateCustom = false;
      }
      this.getList(this.page);
    },

    changeLimit(memberLimitValue) {
      this.limit_member = memberLimitValue;
      this.page = 1;
      this.getList(this.page);
    },

    changeMostType($event) {
      if($event.target.value == 3) {
        this.isShowProductCode = true
      } else {
        this.isShowProductCode = false
      }
      this.page = 1;
      this.getList(this.page);
    },

    changeTierId(tierId) {
      this.tier_id = tierId;
      this.page = 1;
      this.getList(this.page);
    },

    chagnegGender(gender) {
      this.gender = gender;
      this.page = 1;
      this.getList(this.page);
    },

    dateFormat(date) {
      if (date == null) {
        return null;
      } else {
        return moment(date).format("YYYY-MM-DD");
      }
    },

    timeFormat(time) {
      if (time == null) {
          return null
        } else {
          return moment(time).format("H:mm") + ":00"
        }
    },

    createGroup() {
      if (this.processValidate()) {
        this.disabled = true;
        this.idChecked = this.idChecked.filter((x) =>
          this.memberIds.includes(x)
        );
        var dataForm = {
          group_name: this.group_name,
          condition_filter: {
            search_keyword: this.searchKeyword,
            tier_id: this.tier_id,
            gender: this.gender,
            most_type: this.most_type,
            time_type: this.time_type,
            from_date: this.dateFormat(this.from_date),
            to_date: this.dateFormat(this.to_date),
            product_code: this.product_code,
            last_spend_compare: this.last_spend_compare,
            last_spend_compare_value: this.last_spend_compare_value,
            last_spend_from: this.last_spend_from,
            last_spend_to: this.last_spend_to,
            last_store: this.last_store,
            top_up_compare: this.top_up_compare,
            top_up_value: this.top_up_value,
            top_up_from: this.top_up_from,
            top_up_to: this.top_up_to,
            common_visit_start_hour: this.timeFormat(this.common_visit_start_hour),
            common_visit_end_hour: this.timeFormat(this.common_visit_end_hour),
            limit_member: this.limit_member,
            ages_from: this.ages_from,
            ages_to: this.ages_to,
            member_ids: this.idChecked,
          },
        };
        CampaignService.createGroupAudience(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              $("#get_target_audience").click();
              $(".btn-close-customize-audience-list-modal").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.disabled = false;
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
          }
        );
      }
    },

    resetRequired(action = '') {
      if(action != 'get-list') {
        this.isGroupNameRequire = false;
      }
      this.isLastSpendRequire = false;
      this.isTopUpRequire = false;
      this.isAgeRequire = false;
    },

    processValidate(action = '') {
      this.resetRequired(action);
      var isValid = true;
      if(action != 'get-list') {
        if (this.group_name == "" || this.group_name == null) {
        this.isGroupNameRequire = true;
        isValid = false;
      }
      }

      if(this.last_spend_compare && this.last_spend_from && this.last_spend_to && this.last_spend_compare == 4 && parseInt(this.last_spend_from) >= parseInt(this.last_spend_to)) {
        this.isLastSpendRequire = true;
        this.textCustomLastSpendError = "You must enter the start spend that is less than the end spend."
        isValid = false;
      }

      if(this.top_up_compare && this.top_up_from && this.top_up_to && this.top_up_compare == 4 && parseInt(this.top_up_from) >= parseInt(this.top_up_to)) {
        this.isTopUpRequire = true;
        this.textCustomTopUpError = "You must enter the start top up that is less than the end top up."
        isValid = false;
      }

      if(this.ages_from && this.ages_to && parseInt(this.ages_from) >= parseInt(this.ages_to)) {
        this.isAgeRequire = true;
        this.textAgeUpError = "You must enter the start ages that is less than the end ages."
        isValid = false;
      }
      return isValid;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      this.page = 1;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    getList(page) {
      if (this.processValidate('get-list')) {
        this.isLoadData = false;
        this.members = [];
        this.memberIds = [];
        this.showLoader();
        var dataForm = {
          search_keyword: this.searchKeyword,
          page: page,
          gender: this.gender,
          tier_id: this.tier_id,
          most_type: this.most_type,
          time_type: this.time_type,
          from_date: this.dateFormat(this.from_date),
          to_date: this.dateFormat(this.to_date),
          product_code: this.product_code,
          last_spend_compare: this.last_spend_compare,
          last_spend_compare_value: this.last_spend_compare_value,
          last_spend_from: this.last_spend_from,
          last_spend_to: this.last_spend_to,
          last_store: this.last_store,
          top_up_compare: this.top_up_compare,
          top_up_value: this.top_up_value,
          top_up_from: this.top_up_from,
          top_up_to: this.top_up_to,
          common_visit_start_hour: this.timeFormat(this.common_visit_start_hour),
          common_visit_end_hour: this.timeFormat(this.common_visit_end_hour),
          limit_member: this.limit_member,
          ages_from: this.ages_from,
          ages_to: this.ages_to,
        };
        MemberService.getDataMembers(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.members = response.data.data.members.items;
              if (this.members.length == 0) {
                this.disabled = true;
              } else {
                this.disabled = false;
              }
              this.tiers = response.data.data.tiers;
              this.genders = response.data.data.genders;
              this.dateFilter = response.data.data.date_filter;
              this.limitFilter = response.data.data.limit_filter;
              this.branchFilter = response.data.data.branch_filter
              this.compareFilter = response.data.data.compare_filter
              this.pagination = response.data.data.members;
              this.page = this.pagination.current_page;
              this.total = this.pagination.total;
              this.memberIds = response.data.data.memberIds;
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.checkAllStatus();
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    async checkAll() {
      await this.members.forEach((value) => {
        this.deleteItem(value.id);
      });
      if (!this.isCheckedAll) {
        await this.members.forEach((value) => {
          this.idChecked.push(value.id);
        });
      }
      this.checkAllStatus();
    },
    checkItem(memberId) {
      if (this.idChecked.includes(memberId)) {
        this.deleteItem(memberId);
      } else {
        this.idChecked.push(memberId);
      }
      this.checkAllStatus();
    },
    deleteItem(memberId) {
      this.idChecked.forEach((value, index) => {
        if (value == memberId) {
          this.idChecked.splice(index, 1);
        }
      });
    },
    async checkAllStatus() {
      this.isCheckedAll = true;
      await this.members.forEach((value) => {
        if (!this.idChecked.includes(value.id)) {
          this.isCheckedAll = false;
          return;
        }
      });
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
  watch: {
    from_date() {
      if(this.from_date && this.to_date) {
        this.page = 1;
        this.getList(this.page);
      }
    },
    to_date() {
      if(this.from_date && this.to_date) {
        this.page = 1;
        this.getList(this.page);
      }
    },
    common_visit_start_hour() {
      this.page = 1;
      this.getList(this.page);
    },
    common_visit_end_hour() {
      this.page = 1;
      this.getList(this.page);
    }
  },
};
</script>
<style scoped>
input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 1px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.btn-close {
  color: #222222;
}
.btn-close:hover {
  color: #222222;
}

.btn-filter {
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  padding: 6px 15px;
  border-radius: unset;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border: 1px solid #fff !important;
  background: #133067;
  color: #fff;
}

.btn-filter::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  content: "\f078";
  margin-left: auto;
  color: #ffffff !important;
}

.btn-filter.hide-filter::after {
  content: "\f077";
}

.btn-clear {
  min-width: 70px;
  padding: 0;
  padding: 6px 15px;
  border-radius: unset;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: #133067;
  color: #fff;
}

.filter-row-2 > div:nth-child(5),
.filter-row-2 > div:nth-child(6) {
    margin-top: 16px;
  }

.age-form-title {
  min-width: 120px;
}
.s-age {
  max-width: 150px;
}

.dropdown-custom {
  min-width: 100px;
}

.dp__input_wrap input {
  min-width: 100px !important;
}
.multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label {
  padding-right: 0px !important;
}
@media only screen and (max-width: 1465px) {
  .filter-row-3 .col-3 {
    width: 50%;
  }
  .filter-row-3 .col-6 {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .filter-row-2 > div {
    margin-top: 16px;
  }

  .filter-row-3 .col-6 {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 60%;
    margin: 1.75rem auto;
  }
}
</style>
