<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4
          class="
            col-lg-10 col-md-10 col-12
            text-back
            d-flex
            align-items-center
            ps-2
          "
        >
          <i
            class="fa fa-chevron-circle-left pe-2"
            aria-hidden="true"
            @click="backDiscountList()"
            style="cursor: pointer"
          ></i>
          <div @click="backDiscountList()" style="cursor: pointer">BACK</div>
        </h4>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container">
        <div class="row card-custom-new pt-3">
          <div
            class="col-lg-12 col-md-12 col-12 mx-auto pb-5"
            v-if="isLoadDoneData"
          >
            <h3 class="title-page ps-2">
              {{ discount.id ? "EDIT DISCOUNT" : "CREATE DISCOUNT" }}
            </h3>
            <div class="table-responsive custom-table-noborder">
              <div
                class="
                  table-responsive
                  p-2
                  custom-table-noborder
                  col-lg-12 col-md-12 col-12
                "
              >
                <table class="table align-items-center mb-0">
                  <tbody>
                    <tr>
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Discount Rule Name</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="6">
                        <material-input
                          v-model="discount.rule_name"
                          id="rule_name"
                          name="rule_name"
                          placeholder="Enter discount rule name"
                        />
                        <span class="is-invalid" v-if="isRuleNameRequire"
                          >The field is required.</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle text-left text-sm" colspan="2">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Discount Code</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="6">
                        <material-input
                          v-model="discount.rule_code"
                          id="rule_code"
                          name="rule_code"
                          placeholder="Enter discount rule code"
                        />
                        <span class="is-invalid" v-if="isRuleCodeRequire"
                          >The field is required.</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2" style="width: 20%">
                        <div class="d-flex">
                          <div class="ps-0 label-form">Discount Amount</div>
                          <span class="text-danger ms-2">*</span>
                        </div>
                      </td>
                      <td colspan="3" style="width: 40%">
                        <Multiselect
                          v-model="discount.discount_amount_type"
                          :options="discountAmuontType"
                          :canClear="false"
                        />
                        <span
                          class="is-invalid"
                          v-if="isDiscountAmountTypeRequire"
                          >The field is required.</span
                        >
                      </td>
                      <td colspan="3" style="width: 40%">
                        <div class="d-flex align-items-center">
                          <material-input
                            type="number"
                            v-model="discount.discount_amount_value"
                            id="discount_amount_value"
                            name="discount_amount_value"
                            placeholder="Enter discount amuont value"
                          />
                          <div
                            v-if="
                              discount.discount_amount_type === 1 ||
                              discount.discount_amount_type === 3 ||
                              discount.discount_amount_type === 6
                            "
                          >
                            %
                          </div>
                        </div>
                        <span
                          class="is-invalid"
                          v-if="isDiscountAmountValueRequire"
                          >The field is required.</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="ps-0 label-form">
                          Where is this discount valid ?
                        </div>
                      </td>
                      <td colspan="6">
                        <Multiselect
                          v-model="discount.apply_for_store"
                          mode="tags"
                          :close-on-select="false"
                          :searchable="true"
                          :create-option="true"
                          :options="discountApplyForStore"
                          :canClear="false"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="ps-0 label-form">
                          Which product is included in this discount ?
                        </div>
                      </td>
                      <td colspan="6">
                        <Multiselect
                          v-model="discount.apply_for_product"
                          mode="tags"
                          :close-on-select="false"
                          :searchable="true"
                          :create-option="true"
                          :options="discountApplyForProducts"
                          :canClear="false"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="ps-0 label-form">Order mininum</div>
                      </td>
                      <td colspan="1">
                        <MaterialRadio
                          class="ps-0"
                          v-model="discount.order_minimum"
                          id="none"
                          value="1"
                        >
                          None
                        </MaterialRadio>
                        <MaterialRadio
                          class="ps-0"
                          v-model="discount.order_minimum"
                          id="number_item"
                          value="2"
                        >
                          Number of item
                        </MaterialRadio>
                        <MaterialRadio
                          class="ps-0"
                          v-model="discount.order_minimum"
                          id="order_total"
                          value="3"
                        >
                          Order total
                        </MaterialRadio>
                      </td>
                      <td colspan="4" class="align-items-end">
                        <material-input
                          v-model="discount.number_item"
                          id="number_item"
                          name="number_item"
                          class="align-items-end"
                          placeholder="Enter number of item"
                          style="margin-top: 32px"
                        />
                        <material-input
                          v-model="discount.order_total"
                          id="order_total"
                          name="order_total"
                          class="align-items-end"
                          placeholder="Enter order total"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2"></td>
                      <td colspan="1"></td>
                      <td colspan="4" class="align-items-end pt-0">
                        <span class="is-invalid" v-if="isOrderMinimumRequire">{{ textRequiredOrderMinimumCustom }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2" style="width: 20%">
                        <div class="ps-0 label-form">Duration</div>
                      </td>
                      <td style="width: 10%">From</td>
                      <td style="width: 20%">
                        <Datepicker
                          v-model="discount.start_date"
                          :enableTimePicker="false"
                          class="date-time"
                          :format="format"
                        />
                      </td>
                      <td style="width: 10%">To</td>
                      <td style="width: 20%">
                        <Datepicker
                          v-model="discount.end_date"
                          :enableTimePicker="false"
                          class="date-time"
                          :format="format"
                        />
                      </td>
                      <td style="width: 20%">
                        <material-checkbox
                          v-model="discount.no_end_date"
                          :checked="discount.no_end_date ? true : false"
                          value="1"
                          id="checkboxId"
                          class="checkbox-auto-save"
                          style="padding-left: 1.73em !important"
                        >
                          <template v-slot:label> No end date </template>
                        </material-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2" style="width: 40%">
                        <div class="ps-0 label-form">Hours</div>
                      </td>
                      <td style="width: 10%">From</td>
                      <td style="width: 10%">
                        <Datepicker
                          v-model="discount.start_time"
                          timePicker
                          class="date-time"
                        />
                      </td>
                      <td style="width: 10%">To</td>
                      <td style="width: 10%">
                        <Datepicker
                          v-model="discount.end_time"
                          timePicker
                          class="date-time"
                        />
                      </td>
                      <td style="width: 20%"></td>
                    </tr>
                    <tr v-if="isDateRequire">
                      <td class="align-middle" colspan="2" style="width: 40%">
                        <div class="ps-0 label-form"></div>
                      </td>
                      <td style="width: 60%" colspan="6">
                        <div>
                          <span class="is-invalid">{{
                            textRequiredDateCustom
                          }}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2" style="width: 20%">
                        <div class="ps-0 label-form">Renew after use</div>
                      </td>
                      <td colspan="6" style="width: 80%">
                        <MaterialRadio
                          class="ps-0"
                          v-model="discount.renew_type"
                          id="no_renew"
                          value="0"
                        >
                          No renew. One time for one member
                        </MaterialRadio>
                        <div class="d-flex yes_renew">
                          <MaterialRadio
                            v-model="discount.renew_type"
                            id="yes_renew"
                            class="radio-custom d-flex align-items-center ps-0"
                            value="1"
                          >
                            <div class="d-flex align-items-center div-custom">
                              Custom
                            </div>
                          </MaterialRadio>
                          <div class="d-flex align-items-center ms-3">1</div>
                          <div class="ms-3 d-flex align-items-center">Time</div>
                          <material-input
                            v-model="discount.renew_time"
                            id="renew_time"
                            name="renew_time"
                            class="align-items-end ms-3"
                            placeholder="Enter time detail"
                          />
                          <Multiselect
                            class="ms-3"
                            v-model="discount.renew_unit"
                            :options="timeUnits"
                            :canClear="false"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                      </td>
                      <td colspan="1">
                      </td>
                      <td colspan="5" class="pt-0" style="padding-left: 32px;">
                        <span class="is-invalid" v-if="isRenewTimeRequire"
                          >The field is required.</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="ps-0 label-form">Max Total Use</div>
                      </td>
                      <td colspan="3" class="p-0">
                        <material-input
                          v-model="discount.max_total_use"
                          id="max_total_use"
                          name="max_total_use"
                          placeholder="Enter max total use"
                          :disabled="maxTotalUseDisable"
                        />
                      </td>
                      <td colspan="3" class="p-0">
                        <material-checkbox
                          v-model="discount.max_total_use_unlimited"
                          :checked="discount.max_total_use_unlimited ? true : false"
                          value="1"
                          id="max_total_use_unlimited"
                          class="checkbox-auto-save ps-3 pt-3"
                          @change="changeMaxTotalUseUnlimited($event)"
                        >
                          <template v-slot:label>Unlimited</template>
                        </material-checkbox>
                      </td>
                    </tr>
                    <tr v-if="isMaxTotalUseRequire">
                      <td colspan="2">
                      </td>
                      <td colspan="6"  class="pt-0 ps-0">
                        <span class="is-invalid"
                          >The field is required.</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="ps-0 label-form">Max Total Use per Outlet</div>
                      </td>
                      <td colspan="3" class="p-0">
                        <material-input
                          v-model="discount.max_total_use_per_outlet"
                          id="max_total_use_per_outlet"
                          name="max_total_use_per_outlet"
                          placeholder="Enter total use per outlet"
                          :disabled="maxTotalUsePerOutletDisable"
                        />
                      </td>
                      <td colspan="3" class="p-0">
                        <material-checkbox
                          v-model="discount.max_total_use_per_outlet_unlimited"
                          :checked="discount.max_total_use_per_outlet_unlimited ? true : false"
                          value="1"
                          id="max_total_use_per_outlet_unlimited"
                          class="checkbox-auto-save ps-3 pt-3"
                          @change="changeMaxTotalUsePerOutletUnlimited($event)"
                        >
                          <template v-slot:label>Unlimited</template>
                        </material-checkbox>
                      </td>
                    </tr>
                    <tr v-if="isMaxTotalUsePerOutletRequire">
                      <td colspan="2">
                      </td>
                      <td colspan="6"  class="pt-0 ps-0">
                        <span class="is-invalid"
                          >The field is required.</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="align-middle" colspan="2">
                        <div class="ps-0 label-form">Max Total Use per Outlet per Day</div>
                      </td>
                      <td colspan="3" class="p-0">
                        <material-input
                          v-model="discount.max_total_use_per_outlet_per_day"
                          id="max_total_use_per_outlet_per_day"
                          name="max_total_use_per_outlet_per_day"
                          placeholder="Enter max total use per outlet per day"
                          :disabled="maxTotalUsePerOutletPerDayDisable"
                        />
                      </td>
                      <td colspan="3" class="p-0">
                        <material-checkbox
                          v-model="discount.max_total_use_per_outlet_per_day_unlimited"
                          :checked="discount.max_total_use_per_outlet_per_day_unlimited ? true : false"
                          value="1"
                          id="max_total_use_per_outlet_per_day_unlimited"
                          class="checkbox-auto-save ps-3 pt-3"
                          @change="changeMaxTotalUsePerOutletPerDayUnlimited($event)"
                        >
                          <template v-slot:label>Unlimited</template>
                        </material-checkbox>
                      </td>
                    </tr>
                    <tr v-if="isMaxTotalUsePerOutletPerDayRequire">
                      <td colspan="2">
                      </td>
                      <td colspan="6"  class="pt-0 ps-0">
                        <span class="is-invalid"
                          >The field is required.</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="8">
                        <div class="ps-0 label-form">Terms & Conditions</div>
                        <material-textarea
                          v-model="discount.terms_conditions"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="10">
                        <div class="d-flex justify-content-center pt-3">
                          <material-button
                            class="mx-2 btn-background"
                            :style="`width: 300px;`"
                            @click="saveDiscount()"
                          >
                            Save
                          </material-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import SettingDiscountService from "../../../services/settingDiscount.service";
import CommonFunctionService from "../../../services/commonFunction.service";
import moment from "moment";
// import $ from "jquery";
export default {
  name: "DiscountDetail",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    MaterialInput,
    MaterialRadio,
    Multiselect,
    Datepicker,
    MaterialTextarea,
    MaterialCheckbox,
  },
  data() {
    return {
      discount: {},
      form: {},
      loaderActive: true,
      discountAmuontType: [],
      discountApplyForStore: [],
      discountApplyForProducts: [],
      timeUnits: [],
      textRequiredOrderMinimumCustom: "",
      textRequiredDateCustom: "",
      isRuleNameRequire: false,
      isRuleCodeRequire: false,
      isDiscountAmountTypeRequire: false,
      isDiscountAmountValueRequire: false,
      isOrderMinimumRequire: false,
      isRenewTimeRequire: false,
      isDateRequire: false,
      isMaxTotalUseRequire: false,
      isMaxTotalUsePerOutletRequire: false,
      isMaxTotalUsePerOutletPerDayRequire: false,
      snackbar: null,
      maxTotalUseDisable: false,
      maxTotalUsePerOutletDisable: false,
      maxTotalUsePerOutletPerDayDisable: false,
    };
  },
  created() {
    this.getDiscountDetail();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },

    changeMaxTotalUseUnlimited($event) {
      if($event.target.value == 'true') {
        this.maxTotalUseDisable = true
      } else {
        this.maxTotalUseDisable = false
      }
    },
    changeMaxTotalUsePerOutletUnlimited($event) {
      if($event.target.value == 'true') {
        this.maxTotalUsePerOutletDisable = true
      } else {
        this.maxTotalUsePerOutletDisable = false
      }
    },
    changeMaxTotalUsePerOutletPerDayUnlimited($event) {
      if($event.target.value == 'true') {
        this.maxTotalUsePerOutletPerDayDisable = true
      } else {
        this.maxTotalUsePerOutletPerDayDisable = false
      }
    },
    getDiscountDetail() {
      var discountId = this.$route.params.id ?? 0;
      this.isLoadDoneData = false;
      this.showLoader();
      SettingDiscountService.getDataDiscountDetail(discountId).then(
        (response) => {
          this.isLoadDoneData = true;
          if (response.data.result.isSuccess) {
            const discountData = response.data.data;
            this.discount = discountData.discount_setting;
            this.maxTotalUseDisable = this.discount.max_total_use_unlimited == true ? true : false
            this.maxTotalUsePerOutletDisable = this.discount.max_total_use_per_outlet_unlimited == true ? true : false
            this.maxTotalUsePerOutletPerDayDisable = this.discount.max_total_use_per_outlet_per_day_unlimited == true ? true : false
            this.discountAmuontType =
              discountData.dropdown_discount_amount_type;
            this.timeUnits = discountData.dropdown_time_unit;
            this.discountApplyForStore = discountData.dropdown_branches;
            this.discountApplyForProducts = discountData.dropdown_items;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveDiscount() {
      var page = this.$route.query.page ?? 1;
      this.discount.start_date = this.dateTimeFormat(
        this.discount.start_date,
        this.discount.start_time
      );
      this.discount.end_date = this.dateTimeFormat(
        this.discount.end_date,
        this.discount.end_time
      );
      if (this.processValidate()) {
        this.showLoader();
        this.discount.max_total_use = this.discount.max_total_use_unlimited ? null : this.discount.max_total_use
        this.discount.max_total_use_per_outlet = this.discount.max_total_use_per_outlet_unlimited ? null : this.discount.max_total_use_per_outlet
        this.discount.max_total_use_per_outlet_per_day = this.discount.max_total_use_per_outlet_per_day_unlimited ? null : this.discount.max_total_use_per_outlet_per_day
        SettingDiscountService.addOrUpdateDiscount(this.discount).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Discount Success";
              this.discount.id = response.data.data.id;
              // this.discount.apply_for_store =
              //   response.data.data.apply_for_store;
              this.discount.apply_for_product =
                response.data.data.apply_for_product;
              this.setTimeOutNotification();
              this.$router.push("/discounts/?page=" + page);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },
    dateTimeFormat(startDate, startTime) {
      if (startDate == null) {
        return null;
      } else {
        if (startTime == null) {
          return moment(startDate).format("YYYY-MM-DD") + " " + "00:00:00";
        } else {
          return (
            moment(startDate).format("YYYY-MM-DD") +
            " " +
            moment(startTime).format("H:m")
          );
        }
      }
    },
    backDiscountList() {
      var page = this.$route.query.page;
      if (page) {
        this.$router.push("/discounts" + "?page=" + page);
      } else {
        this.$router.push("/discounts");
      }
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.discount.rule_name == "" || this.discount.rule_name == null) {
        this.isRuleNameRequire = true;
        isValid = false;
      }
      if (this.discount.rule_code == "" || this.discount.rule_code == null) {
        this.isRuleCodeRequire = true;
        isValid = false;
      }
      if (
        this.discount.discount_amount_type == "" ||
        this.discount.discount_amount_type == null
      ) {
        this.isDiscountAmountTypeRequire = true;
        isValid = false;
      }
      if (
        this.discount.discount_amount_value == "" ||
        this.discount.discount_amount_value == null
      ) {
        this.isDiscountAmountValueRequire = true;
        isValid = false;
      }

      if (
        this.discount.order_minimum == 2 &&
        (this.discount.number_item == "" || this.discount.number_item == null)
      ) {
        this.isOrderMinimumRequire = true;
        this.textRequiredOrderMinimumCustom = 'Please enter number of item!'
        isValid = false;
      }

      if (
        this.discount.order_minimum == 3 &&
        (this.discount.order_total == "" || this.discount.order_total == null)
      ) {
        this.isOrderMinimumRequire = true;
        this.textRequiredOrderMinimumCustom = 'Please enter order total!'
        isValid = false;
      }

      const dateFrom = this.dateTimeFormat(
        this.discount.start_date,
        this.discount.start_time
      );
      const dateTo = this.dateTimeFormat(
        this.discount.end_date,
        this.discount.end_time
      );
      if (dateFrom != null && dateTo != null && dateFrom >= dateTo) {
        this.isDateRequire = true;
        this.textRequiredDateCustom = "The end date must be a date after start date.";
        isValid = false;
      }

      if (!this.discount.max_total_use_unlimited
      && (this.discount.max_total_use == '' || this.discount.max_total_use == null)) {
        this.isMaxTotalUseRequire = true;
        isValid = false;
      }

      if (!this.discount.max_total_use_per_outlet_unlimited
      && (this.discount.max_total_use_per_outlet == '' || this.discount.max_total_use_per_outlet == null)) {
        this.isMaxTotalUsePerOutletRequire = true;
        isValid = false;
      }

      if (!this.discount.max_total_use_per_outlet_per_day_unlimited
      && (this.discount.max_total_use_per_outlet_per_day == '' || this.discount.max_total_use_per_outlet_per_day == null)) {
        this.isMaxTotalUsePerOutletPerDayRequire = true;
        isValid = false;
      }

      if (
        this.discount.renew_type == 1 &&
        (this.discount.renew_time == "" || this.discount.renew_time == null)
      ) {
        this.isRenewTimeRequire = true;
        isValid = false;
      }
      return isValid;
    },

    resetRequired() {
      this.isRuleNameRequire = false;
      this.isRuleCodeRequire = false;
      this.isDiscountAmountTypeRequire = false;
      this.isDiscountAmountValueRequire = false;
      this.isOrderMinimumRequire = false;
      this.isRenewTimeRequire = false;
      this.isDateRequire = false
      this.isMaxTotalUseRequire = false
      this.isMaxTotalUsePerOutletRequire = false
      this.isMaxTotalUsePerOutletPerDayRequire = false
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}
div.div-custom {
  padding-top: 10px;
}
</style>