<template>
    <div>
      <div class="row mb-2 pe-4">
        <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
          <div class="color-common">
            <h3 class="title-page mb-0 ps-3">SMS Credit</h3>
          </div>
        </div>
      </div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            <div class="notifications">
              <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                {{ error }}
              </material-alert>
            </div>
            <h6 class="mt-4 ps-3" style="color: green;width: fit-content;cursor: pointer;" @click="showSmsSumary()">CURRENT SMS CREDIT BALANCE: {{ smsBal }}</h6>
            <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="card-custom">
                    <div class="px-0 pb-2">
                      <div class="table-responsive p-0 custom-table">
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th class="text-center font-weight-bolder w-4">
                                Quantity
                              </th>
                              <th class="text-center font-weight-bolder w-4">
                                Price
                              </th>
                              <th class="text-center font-weight-bolder w-4">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="data.length">
                            <tr v-for="(value, key) in data" :key="key">
                              <td class="text-center text-xss">
                                {{ value.quantityLabel }}
                              </td>
                              <td class="text-center text-xss">
                                {{ value.priceLabel }}
                              </td>
                              <td class="text-center text-xss">
                                <material-button @click="addSmsCredit(value)" class="btn-background">
                                    Pay
                                </material-button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="5" class="text-center">
                                No item available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a id="nav_item_2" class="nav-link" href="/sms-payment-history" style="color: blue; text-decoration: underline;">
                Payment History
            </a>
          </div>
        </div>
        <div>
          <button
            style="display: none"
            type="button"
            id="show-sms-sumary-method-modal"
            data-bs-toggle="modal"
            data-bs-target="#modalSmsSumary"
          ></button>
          <div class="modal fade" id="modalSmsSumary" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">SMS Sumary</h5>
                </div>
                <div class="modal-body">
                  <div style="margin-bottom: 30px;">
                    Begining Bal of the month({{ currentFirstMonth }}) : {{ begining }}
                  </div>
                  <div style="margin-bottom: 30px;">
                    Total Purchase this month: {{ total_purchase }}
                  </div>
                  <div style="margin-bottom: 30px;">
                    Total Use Month-to-Date: {{ total_use }}
                  </div>
                  <div style="margin-bottom: 30px;">
                    Current Balance: {{ smsBal }}
                  </div>
                </div>
                <!-- Modal content-->
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary btn-close-modal btn-sm"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date="" :description="message"
        :icon="{ component: 'campaign', color: 'white' }" :color="snackbar" :close-handler="closeSnackbar" />
    </div>
    <material-loading :active="loaderActive" />
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import "bootstrap/js/dist/modal";
  import MaterialButton from "@/components/MaterialButton.vue";
  import "@vuepic/vue-datepicker/dist/main.css";
  import SmsSetupService from "../../../services/smsSetup.service";
  import TokenService from "../../../services/token.service";
  import moment from "moment";
  
  export default {
    name: "find-store",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      MaterialButton
    },
    data() {
        return {
            message: "",
            snackbar: null,
            loaderActive: false,
            data: [
                {
                    id: 1,
                    price: 100, // 500 sms
                    priceLabel: 'S$ 100',
                    quantity: 1000,
                    quantityLabel: '1000 SMS Credits'
                },
                {
                    id: 2,
                    price: 180, // 2000 sms
                    priceLabel: 'S$ 180',
                    quantity: 2000,
                    quantityLabel: '2000 SMS Credits'
                },
                {
                    id: 3,
                    price: 380, // 5000 sms
                    priceLabel: 'S$ 380',
                    quantity: 5000,
                    quantityLabel: '5000 SMS Credits'
                },
                // {
                //     id: 4,
                //     price: 625, // 10000 sms
                //     priceLabel: 'S$ 625',
                //     quantity: 10000,
                //     quantityLabel: '10000 SMS Credits'
                // }
            ],
            isLoadData: false,
            token: TokenService.getLocalAccessToken(),
            merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
            smsBal: 0,
            currentFirstMonth: moment().startOf('month').format('DD/MM/YYYY')
        };
    },
    created() {
        var session_id = this.$route.query.session_id;
        var quantity = this.$route.query.quantity;
        var price = this.$route.query.price;
        if(session_id && price) { // call back after pay success - stripe
            this.showLoader();
            this.isLoadDone = false;
            SmsSetupService.addSmsBal({sms_bal: quantity, session_id, price}).then(
                (response) => {
                if (response.data.result.isSuccess && response.data.data) {
                    this.smsBal = response.data.data.sms_balance;
                    this.snackbar = "success";
                    this.message = "Sms Credit Successfully!";
                    this.setTimeOutNotification();
                } else if (response.data.result.isSuccess && !response.data.data) {
                    window.location.href = '/sms-setup'
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.isLoadDone = true;
                this.hideLoader();
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                }
            );
        }

        if(this.merchantCodeExport === "luckypanda" && process.env.VUE_APP_NODE_ENV !== "staging") {
          this.data = [
                {
                    id: 1,
                    price: 100, // 500 sms
                    priceLabel: 'S$ 100',
                    quantity: 1000,
                    quantityLabel: '1000 SMS Credits'
                },
                {
                    id: 2,
                    price: 140, // 2000 sms
                    priceLabel: 'S$ 140',
                    quantity: 2000,
                    quantityLabel: '2000 SMS Credits'
                },
                {
                    id: 3,
                    price: 295, // 5000 sms
                    priceLabel: 'S$ 295',
                    quantity: 5000,
                    quantityLabel: '5000 SMS Credits'
                },
            ]
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
  
        closeSnackbar() {
            this.snackbar = null;
        },
  
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
  
        getList() {
            this.isLoadData = false;
            this.showLoader();
            SmsSetupService.getSmsBal().then(
            (response) => {
                if (response.data.result.isSuccess) {
                    this.smsBal = response.data.data.smsBal;
                    this.begining = response.data.data.begining;
                    this.total_purchase = response.data.data.total_purchase;
                    this.total_use = response.data.data.total_use;
                    this.isLoadData = true;
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
            },
            (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
            }
            );
        },

        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        addSmsCredit(item) {
            // console.log(item);
            var url = window.location.href;
            var data = {
                success_url: url+"?session_id={CHECKOUT_SESSION_ID}&price="+item.price+"&quantity="+item.quantity,
                cancel_url: url,
                images: '',
                name: item.quantityLabel,
                unit_amount: item.price,
                quantity: 1
            }
            this.showLoader();
            SmsSetupService.checkoutSessionStripeForSms(data).then(
              (response) => {
                if (response.data.result.isSuccess) {
                    window.location.href = response.data.data.url
                } else {
                  this.snackbar = "danger";
                  this.message = response.data.result.message;
                  this.setTimeOutNotification();
                }
                this.hideLoader();
              },
              (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
              }
            );
        },
        showSmsSumary() {
          const modalSmsSumary = document.getElementById("show-sms-sumary-method-modal");
          modalSmsSumary.click();
        }
    },
  };
  </script>
  <style scoped>
  .text-title {
    color: #133067 !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .text-label {
    color: #5b848d !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .checkbox-auto-save label {
    padding-top: 11px !important;
  }
  
  .custom-name {
    word-break: break-all;
    max-width: 200px;
    white-space: normal;
  }
  
  .wrap-action .form-check:first-child {
    padding-left: 2px;
  }
  
  .multiselect {
    min-width: 135px;
  }
  
  .r-no-expiry .form-check {
    padding-left: 0 !important;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 50%;
      margin: 1.75rem auto;
    }
  }
  </style>