<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-12 col-md-12 col-12 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">DASHBOARD AND REPORTS</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto mt-4">
            <div class="row">
              <strong> WALLET REPORT </strong>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    Time
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect
                      v-model="searchForm.time_type"
                      :options="times"
                      :canClear="false"
                      @change="timeChange($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    Branch
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect
                      v-model="searchForm.branch_id"
                      :options="branches"
                      :canClear="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    Type
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect
                      v-model="searchForm.type"
                      :options="types"
                      :canClear="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="isShowTimeCustom">
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    From
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Datepicker
                      :enableTimePicker="false"
                      class="date-time-dashboad"
                      v-model="searchForm.from_date"
                      :format="format"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12 pt-custtom">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    To
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Datepicker
                      :enableTimePicker="false"
                      class="date-time-dashboad"
                      v-model="searchForm.to_date"
                      :format="format"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-1 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    Search
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <input
                      class="nosubmit"
                      type="search"
                      id="keyword_search"
                      v-model="searchForm.search_keyword"
                      extraClass
                      name="keyword_search"
                      placeholder="Enter member ID, name, phone number ..."
                      @input="onChangesearchKeyword"
                      @keydown.enter="handleSearchEnter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 ps-3">
              <div class="col-lg-9 col-md-9 col-3 d-flex">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle btn-sm"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    :disabled="disabled"
                  >
                    Download
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        @click="exportFile('csv')"
                        href="#"
                        >Export CSV</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        @click="exportFile('xlsx')"
                        href="#"
                        >Export Excel</a
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        @click="exportFile('pdf')"
                        href="#"
                        >Export PDF</a
                      >
                    </li>
                  </ul>
                </div>
                <div style="margin-left: 3vh">
                  <material-button
                    class="btn-background btn-sm"
                    @click="filterData()"
                    style="width: 162.52px"
                  >
                    Run
                  </material-button>
                </div>
              </div>
            </div>
            <div class="row ps-3">
              <div
                class="col-lg-9 col-md-12 col-12 d-flex justify-content-lg-start align-items-end"
              >
                Count: {{ totalRecords }}
              </div>
              <div class="col-lg-3 col-md-3 col-3 d-flex justify-content-end">
                <ShowHideColumn
                  :columns="columns"
                  :changeCheckbox="changeCheckbox"
                  :columnCheckedCache="columnChecked"
                  :key="key"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th
                              class="text-center font-weight-bolder w-5"
                              v-if="columnShowed.includes('row_no')"
                            >
                              Row No
                            </th>
                            <template
                              v-for="(item, index) in headersData"
                              :key="index"
                            >
                              <th class="text-center font-weight-bolder" v-if="columnShowed.includes(item.field)">
                                <span
                                  :class="[
                                    !!item.hasSortBy
                                      ? 'wrapper-sortby pointer'
                                      : '',
                                    ESortDirectionSelect.idx == (index + 1)
                                      ? ESortDirectionSelect.direction === ESortDirection.Desc
                                        ? 'sort-desc'
                                        : 'sort-asc'
                                      : '',
                                  ]"
                                  @click="
                                    () => {
                                      !!item.hasSortBy &&
                                        handleChangeSortColumn(index + 1);
                                    }
                                  "
                                >{{ item.label }}</span>
                              </th>
                            </template>
                          </tr>
                        </thead>
                        <tbody v-if="transactions.length">
                          <tr v-for="(value, key) in transactions" :key="key">
                            <td
                              class="text-center text-xss w-5"
                              v-if="columnShowed.includes('row_no')"
                            >
                              {{ key + 1 }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('uuid')"
                            >
                              {{ value.uuid }}
                            </td>
                            <td
                              class="text-center text-xss w-7"
                              v-if="columnShowed.includes('receipt_no')"
                            >
                              {{ value.receipt_no }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('created_at')"
                            >
                              {{ value.created_at_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_id')"
                            >
                              {{ value.member_id_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_name')"
                            >
                              {{ value.member_name }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('phone_number')"
                            >
                              {{ value.phone_number }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('amount_direct_deduct')"
                            >
                              {{ value.transaction_type == 3 ? value.payment_amount_format : value.amount_direct_deduct_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('topup_direct_deduct')"
                            >
                            {{ value.topup_direct_deduct_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('cashback_direct_deduct')"
                            >
                            {{ value.cashback_direct_deduct_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('cashback_earned')"
                            >
                            {{ value.cashback_earned_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('branch_name')"
                            >
                              {{ value.branch_name }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('transaction_type')"
                            >
                              {{ value.transaction_type_format }}
                            </td>
                            <td
                              class="text-left text-xss w-25"
                              v-if="columnShowed.includes('transaction_id')"
                            >
                              {{ value.transaction_id }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('payment_method')"
                            >
                              {{ value.payment_method }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="12" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
    <form
      :action="`${actionDownload}/merchant/download-wallet-report`"
      method="POST"
    >
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCode" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="list_field_show" :value="columnShowed" />
      <input type="hidden" name="search_keyword" :value="searchForm.search_keyword" />
      <input type="hidden" name="time_type" :value="searchForm.time_type" />
      <input
        type="hidden"
        name="from_date"
        :value="dateFormat(searchForm.from_date)"
      />
      <input
        type="hidden"
        name="to_date"
        :value="dateFormat(searchForm.to_date)"
      />
      <input type="hidden" name="type" :value="searchForm.type" />
      <input type="hidden" name="branch_id" :value="searchForm.branch_id" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input type="hidden" name="checkExport" :value="checkExport" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import "@vuepic/vue-datepicker/dist/main.css";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialButton from "@/components/MaterialButton.vue";
import ShowHideColumn from "@/components/ShowHideColumn.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import DashboardReportService from "../../services/dashboardReport.service";
import $ from "jquery";
import TokenService from "../../services/token.service";
import CommonFunctionService from "../../services/commonFunction.service";

export default {
  name: "campaign-list",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Pagination,
    Multiselect,
    Datepicker,
    ShowHideColumn,
  },
  data() {
    return {
      token: TokenService.getLocalAccessToken(),
      actionDownload: process.env.VUE_APP_BASE_URL,
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCode: localStorage.getItem("MERCHANT_CODE", ""),
      message: "",
      snackbar: null,
      loaderActive: false,
      searchTimerId: null,
      pagination: {},
      page: 1,
      columns: [
        { value: "all", label: "ALL" },
        { value: "row_no", label: "Row No" },
        { value: 'uuid', label: 'UUID' },
        { value: 'receipt_no', label: 'Receipt No' },
        { value: "created_at", label: "Date time" },
        { value: "member_id", label: "Member ID" },
        { value: "member_name", label: "Member Name" },
        { value: "phone_number", label: "Phone No" },
        { value: 'amount_direct_deduct', label: 'Deducted Amount' },
        { value: 'topup_direct_deduct', label: 'Deducted Topup' },
        { value: 'cashback_direct_deduct', label: 'Deducted Cashback' },
        { value: 'cashback_earned', label: 'Cashback Earned' },
        { value: "branch_name", label: "Branch" },
        { value: "transaction_type", label: "Transaction Type" },
        { value: "transaction_id", label: "Transaction ID" },
        { value: "payment_method", label: "Payment Method" },
      ],
      times: [
        { value: 0, label: "Select Time" },
        { value: 1, label: "Today" },
        { value: 2, label: "Yesterday" },
        { value: 3, label: "Last 7 days" },
        { value: 4, label: "Last 30 days" },
        { value: 5, label: "This month" },
        { value: 6, label: "Last 3 months" },
        { value: 7, label: "This year" },
        { value: 8, label: "Last year" },
        { value: 9, label: "Custom" },
      ],
      types: [
        { value: 0, label: "ALL" },
        { value: 1, label: "Topup" },
        { value: 2, label: "Cashback" },
      ],
      action: "list",
      action_export: "csv",
      totalRecords: 0,
      columnShowed: [],
      transactions: [],
      branches: [],
      searchForm: {
        time_type: 0,
        from_date: null,
        to_date: null,
        search_keyword: "",
        branch_id: 0,
        page: 1,
        type: 0,
      },
      columnChecked: [],
      checkExport: false,
      key: 'key',
      ESortDirectionSelect: {
        idx: null,
        direction: null
      },
      ESortDirection: {
        Asc: "asc",
        Desc: "desc",
      },
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (window.localStorage.getItem("WalletColumn")) {
      this.columnChecked = window.localStorage
        .getItem("WalletColumn")
        .split(",");
      this.key = Math.random();
    }
    this.getData();
  },
  computed: {
    headersData() {
      return [
        {
          label: "UUID",
          field: "uuid",
          hasSortBy: true,
        },
        {
          label: "Receipt No",
          field: "receipt_no",
          hasSortBy: true,
        },
        {
          label: "Date time",
          field: "created_at",
          hasSortBy: true,
        },
        {
          label: "Member ID",
          field: "member_id",
          hasSortBy: true,
        },
        {
          label: "Member Name",
          field: "member_name",
          hasSortBy: true,
        },
        {
          label: "Phone No",
          field: "phone_number",
          hasSortBy: true,
        },
        {
          label: "Deducted Amount",
          field: "amount_direct_deduct",
          hasSortBy: true,
        },
        {
          label: "Deducted Topup",
          field: "topup_direct_deduct",
          hasSortBy: true,
        },
        {
          label: "Deducted Cashback",
          field: "cashback_direct_deduct",
          hasSortBy: true,
        },
        {
          label: "Cashback Earned",
          field: "cashback_earned",
          hasSortBy: true,
        },
        {
          label: "Branch",
          field: "branch_name",
          hasSortBy: true,
        },
        {
          label: "Transaction Type",
          field: "transaction_type",
        },
        {
          label: "Transaction ID",
          field: "transaction_id",
          hasSortBy: true,
        },
        {
          label: "Payment Method",
          field: "payment_method",
        }
      ];
    },
  },
  methods: {
    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },
    exportFile(type = "csv") {
      if (this.columnShowed.length == 0) {
        this.snackbar = "danger";
        this.message = "You must select least 1 column to export file.";
        this.setTimeOutNotification();
      } else {
        this.action_export = type;
        setTimeout(() => {
          $("#downloadForm").click();
        }, 150);
      }
    },
    changeCheckbox(columnShowed) {
      this.columnShowed = columnShowed;
      window.localStorage.setItem("WalletColumn", columnShowed);
    },
    timeChange($event) {
      if ($event == 9) {
        this.isShowTimeCustom = true;
      } else {
        this.isShowTimeCustom = false;
      }
    },

    getData() {
      this.showLoader();
      DashboardReportService.getFilterDataTopupReport().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branches = response.data.data.branches;
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    filterData() {
      this.getList(this.page);
    },

    dateFormat(date) {
      if (date == null) {
        return null;
      } else {
        return moment(date).format("YYYY-MM-DD");
      }
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    onChangesearchKeyword(event) {
      this.searchForm.search_keyword = event.target.value;
    },

    handleSearchEnter() {
      this.getList(this.page);
    },
    
    handleChangeSortColumn(idx) {
      if (this.ESortDirectionSelect.idx && this.ESortDirectionSelect.idx == idx) {
        this.ESortDirectionSelect.direction = this.ESortDirectionSelect.direction === this.ESortDirection.Asc ? this.ESortDirection.Desc : this.ESortDirection.Asc;
      } else {
        this.ESortDirectionSelect = {
          idx: idx,
          direction: this.ESortDirection.Asc
        }
      }
      
      if (this.transactions.length) {
        this.getList(this.page);
      }
    },

    getList(page) {
      this.totalRecords = 0;
      this.showLoader();

      this.searchForm.from_date = this.dateFormat(this.searchForm.from_date);
      this.searchForm.to_date = this.dateFormat(this.searchForm.to_date);
      this.searchForm.page = page;
      this.searchForm.sortCol = this.ESortDirectionSelect.idx;
      this.searchForm.sortType = this.ESortDirectionSelect.direction;

      DashboardReportService.getDataWalletReport(this.searchForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.transactions = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.totalRecords = this.pagination.total;
            if (this.pagination.current_page > this.pagination.last_page) {
              this.page = 1;
              this.getList(this.page);
            }
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            });
            this.checkExport = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
table thead th:not(:first-child) {
  min-width: 170px;
}

table thead th > span {
  position: relative;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.date-time-dashboad .dp__input_wrap {
  min-width: 200px !important;
  width: 250px;
}

.custom-control-label label {
  padding-top: 14px !important;
}

@media only screen and (max-width: 991px) {
  .pt-custtom {
    padding-top: 7px;
  }
}
</style>
