<template>
  <div>
    <div class="row my-2 d-flex">
      <div class="col-lg-6 col-md-6 col-12 text-title">PAYMENT</div>
      
    </div>
    <div class="col-lg-12 col-md-12 col-12">Your're getting our highest level of payment protection and support while offering buyers the most payment options.</div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="row mt-3">
              <div class="col-12">
                <h3 class="title-page">MERCHANT</h3>
              </div>
              <div class="col-12 mt-3">
                <div class="card-custom">
                  <div class="table-responsive p-0 custom-table">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center font-weight-bolder">{{ $t('name') }}</th>
                          <th class="text-center font-weight-bolder">{{ $t('merchantCode') }}</th>
                          <th class="text-center font-weight-bolder">{{ $t('stripeAccount') }}</th>
                          <th class="text-center font-weight-bolder"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center text-xss w-5">
                            {{ this.merchantName }}
                          </td>
                          <td class="text-center text-xss w-5">
                            {{ this.merchantCodeFormated }}
                          </td>
                          <td class="text-center text-xss w-5">
                            {{ this.stripe_user_id }}
                          </td>
                          <td class="text-center text-xss w-5">
                            <div class="ps-2">
                              <material-button
                                class="btn mb-0 btn-success btn-md btn-background"
                                @click="handleStripeAccount()"
                              >
                                {{ !this.stripe_user_id ? "Connect" : $t('viewPayout') }}
                              </material-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <h3 class="title-page">PHYSICAL BRANCH</h3>
              <div class="col-12 mt-3">
                <div class="card-custom">
                  <div class="table-responsive p-0 custom-table">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center font-weight-bolder">Physical Branch Name</th>
                          <th class="text-center font-weight-bolder">Physical Branch ID</th>
                          <th class="text-center font-weight-bolder">{{ $t('stripeAccount') }}</th>
                          <th class="text-center font-weight-bolder"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(physicalBranch, index) in physicalBranches" :key="index">
                          <td class="text-center text-xss w-5">
                            {{ physicalBranch.name }}
                          </td>
                          <td class="text-center text-xss w-5">
                            {{ physicalBranch.id }}
                          </td>
                          <td class="text-center text-xss w-5">
                            {{ physicalBranch.stripe_user_id }}
                          </td>
                          <td class="text-center text-xss w-5">
                            <div class="ps-2">
                              <material-button
                                class="btn mb-0 btn-success btn-md btn-background"
                                @click="handleStripeAccount(physicalBranch.id, physicalBranch.stripe_user_id)"
                              >
                                {{ !physicalBranch.stripe_user_id ? "Connect" : $t('viewPayout') }}
                              </material-button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import "@vuepic/vue-datepicker/dist/main.css";
import PaymentService from "../../services/payment.service";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    Pagination,
    MaterialButton
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      members: [],
      searchKeyword: "",
      searchTimerId: null,
      pagination: {},
      page: 1,
      memberId: null,
      isLoadData: false,
      merchantCode: "",
      total: 0,
      idChecked: [],
      memberIds: "",
      isCheckedAll: false,
      merchantCodeFormated: "",
      merchantId: "",
      merchantName: "",
      stripe_access_token: "",
      stripe_refresh_token: "",
      stripe_user_id: "",
      url: location.protocol + '//' + location.host+"/",
      client_id: process.env.VUE_APP_STRIPE_CLIENT_ID,
      physicalBranches: []
    };
  },
  async created() {
    const merchant_code = localStorage.getItem("MERCHANT_CODE", "");
    const name = localStorage.getItem("MERCHANT_ADMIN_NAME", "");
    const user_id = localStorage.getItem("MERCHANT_ID", "");
    this.merchantCode = merchant_code ? `/${merchant_code}` : "";
    this.merchantCodeFormated = merchant_code;
    this.merchantName = name;
    this.merchantId = user_id;

    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }

    if(this.$route.query.code) {
      const physical_branch_id = localStorage.getItem("PHYSICAL_BRANCH_ID", "");
      var code = this.$route.query.code;
      var params = {
        id: user_id,
        code: code,
        physical_branch_id: physical_branch_id
      }

      await PaymentService.connectStripeAccount(params, merchant_code).then(
        (response) => {
          if (response.data.result.isSuccess) {
            localStorage.removeItem('PHYSICAL_BRANCH_ID');
            this.snackbar = "success";
            this.message = "Connected Success";
            this.setTimeOutNotification();
            this.$router.replace({'query': null});
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    }

    this.getList(this.page);
  },
  methods: {
    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    handleStripeAccount(physicalBranchId = 0, physicalBranchStripeUserId = '') {
      var url = this.url;
      var client_id = this.client_id;
      var callbackUrl = url + 'payments';
      var isConnect = false;
      var stripeUserId = this.stripe_user_id;

      if (physicalBranchId) {
        stripeUserId = physicalBranchStripeUserId;
        if (!physicalBranchStripeUserId) {
          localStorage.setItem('PHYSICAL_BRANCH_ID', physicalBranchId);
          isConnect = true;
        }
      } else {
        if (!this.stripe_user_id) {
          localStorage.removeItem('PHYSICAL_BRANCH_ID');
          isConnect = true;
        }
      }

      if (isConnect) {
        window.location.href = 'https://connect.stripe.com/express/oauth/v2/authorize?redirect_uri='+callbackUrl+'&client_id='+client_id+'&state=1014';
      } else {
        PaymentService.getLinkPayout(stripeUserId, this.merchantCodeFormated).then(
          (response) => {
            if (response.data.result.isSuccess) {
              window.open(response.data.data.url);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    getList(page) {
      this.showLoader();
      PaymentService.getStripeConnectInfoByMerchant({
        page: page
      }).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.stripe_access_token = response.data.data.merchant_admin.stripe_access_token;
              this.stripe_refresh_token = response.data.data.merchant_admin.stripe_refresh_token;
              this.stripe_user_id = response.data.data.merchant_admin.stripe_user_id;
              this.physicalBranches = response.data.data.physical_branches.items;
              this.pagination = response.data.data.physical_branches;
              this.page = this.pagination.current_page;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
    }
  },
};
</script>
<style scoped>
.text-title {
  color: #133067  !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.text-label {
  color: #5b848d !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.checkbox-auto-save label {
  padding-top: 11px !important;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 1px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.custom-title {
  word-break: break-all;
  max-width: 200px;
  white-space: normal;
}
</style>