<template>
  <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="row page-header min-vh-100">
          <div class="col-12">
            <div class="row vh-100 page-current">
              <div class="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center align-items-center background-minty">
                <div class="card card-plain">
                  <div class="card-body name-minty align-self-center">
                    MINTY
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
                <div class="card card-plain">
                  <div class="card-body">
                    <div class="welcome-minty d-flex justify-content-center pb-6">RESET PASSWORD
                    </div>
                    <form @submit.prevent="sendEmail" v-if="isShowPageSendMail">
                      <div class="input-group input-group-static mb-3">
                        <label for="form-control" class="ms-0 lable-input">
                          Email</label><span class="text-danger ms-2">*</span>
                        <div class="form-control p-0" id="form-control">
                          <material-input v-model="form1.email" extraClass="p-2" id="email" type="email" name="email"
                            size="lg" />
                        </div>
                        <span class="is-invalid" v-if="required.isEmailRequire">The field is
                          required.</span>
                      </div>
                      <div class="d-flex justify-content-end forgot-pass" @click="backToLogin()"><a>Back to Login</a>
                      </div>
                      <div class="text-center">
                        <material-button class="mt-4 px-6" variant="gradient" color="success"
                          :disabled="disableBtnSubmitSendMail">Send Mail Reset Password
                        </material-button>
                      </div>
                    </form>
                    <form @submit.prevent="resetPassword" v-else>
                      <div class="input-group input-group-static mb-3">
                        <label for="form-control" class="ms-0 lable-input">
                          New Password</label><span class="text-danger ms-2">*</span>
                        <div class="form-control p-0" id="form-control">
                          <material-input v-model="form2.password" extraClass="p-2" id="password" type="password"
                            name="password" size="lg" />
                        </div>
                      </div>
                      <div class="input-group input-group-static mb-3">
                        <label for="form-control" class="ms-0 lable-input">
                          Confirm New Password</label><span class="text-danger ms-2">*</span>
                        <div class="form-control p-0" id="form-control">
                          <material-input v-model="form2.confirm_password" extraClass="p-2" id="confirm_password"
                            type="password" name="password" size="lg" />
                        </div>
                        <span class="is-invalid" v-if="required.isPasswordRequire">{{ messageErrorPassword }}</span>
                      </div>
                      <div class="text-center">
                        <material-button class="mt-4 px-6" variant="gradient" color="success"
                          :disabled="disableBtnResetPassword">Reset Password
                        </material-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date=""
              :description="message" :icon="{ component: 'campaign', color: 'white' }" :color="snackbar"
              :close-handler="closeSnackbar" />
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ResetPasswordService from "../services/resetPassword.service";

export default {
  name: "reset-password",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialSnackbar,
    MaterialLoading
  },
  data() {
    return {
      message: '',
      form1: {
        email: '',
      },
      form2: {
        password: '',
        confirm_password: '',
        token: ''
      },
      snackbar: null,
      loaderActive: false,
      merchantCode: "",
      isShowPageSendMail: true,
      disableBtnSubmitSendMail: false,
      disableBtnResetPassword: false,
      required: {
        isEmailRequire: false,
        isPasswordRequire: false
      },
      messageErrorPassword: ''
    };
  },
  created() {
    this.form2.token = this.$router.currentRoute.value.params.token ?? ''
    if (this.form2.token != '') {
      this.isShowPageSendMail = false
    }
  },
  mounted() {
    const code = localStorage.getItem('MERCHANT_CODE', '');
    this.merchantCode = code ? `/${code}` : ''
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    sendEmail() {
      this.disableBtnSubmitSendMail = true
      this.closeSnackbar()
      if (this.processValidateEmail()) {
        this.showLoader()
        ResetPasswordService.sendEmailResetPassword(this.form1.email).then(
          (response) => {
            console.log('response', response)
            if (response.data.result.isSuccess) {
              this.snackbar = 'success'
              this.message = "We have e-mailed your password reset link!"
              this.setTimeOutNotification()
            } else {
              this.snackbar = 'danger'
              this.message = response.data.result.message
              this.setTimeOutNotification()
            }
            this.hideLoader()
            this.disableBtnSubmitSendMail = false
          },
          (error) => {
            this.snackbar = 'danger'
            this.message = error
            this.setTimeOutNotification()
            this.hideLoader()
            this.disableBtnSubmitSendMail = false
          }
        );
      } else {
        this.disableBtnSubmitSendMail = false
      }
    },
    resetPassword() {
      this.disableBtnResetPassword = true
      this.closeSnackbar()
      if (this.processValidatePassword()) {
        this.showLoader()
        ResetPasswordService.resetPassword(this.form2.token, this.form2.password).then(

          (response) => {
            console.log('response', response)
            if (response.data.result.isSuccess) {
              this.snackbar = 'success'
              this.message = "Password has been updated successfully!"
              setTimeout(() => {
                this.snackbar = null;
                this.backToLogin()
              }, 3000);
            } else {
              this.snackbar = 'danger'
              this.message = response.data.result.message
              this.setTimeOutNotification()
            }
            this.hideLoader()
            this.disableBtnResetPassword = false
          },
          (error) => {
            this.snackbar = 'danger'
            this.message = error
            this.setTimeOutNotification()
            this.hideLoader()
            this.disableBtnResetPassword = false
          }
        );
      }
      else {
        this.disableBtnResetPassword = false
      }
    },
    setTimeOutNotification() {
      setTimeout(() => { this.snackbar = null; }, 3000);
    },
    backToLogin() {
      this.$router.push('/sign-in');
    },
    closeSnackbar() {
      this.snackbar = null;
    },
    showSnackbarError() {
      this.snackbar = 'danger'
      setTimeout(() => { this.snackbar = null; }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    resetRequiredEmail() {
      this.required.isEmailRequire = false;
    },
    resetRequiredPassword() {
      this.required.isPasswordRequire = false;
    },
    processValidateEmail() {
      this.resetRequiredEmail();
      var isValid = true;
      if (this.form1.email == '') {
        this.required.isEmailRequire = true;
        isValid = false;
      }
      return isValid;
    },
    processValidatePassword() {
      this.resetRequiredPassword();
      var isValid = true;
      if (this.form2.password == '' || this.form2.confirm_password == '') {
        this.required.isPasswordRequire = true;
        this.messageErrorPassword = "The field is required"
        isValid = false;
      }
      if (this.form2.password != '' && this.form2.confirm_password != '' && (this.form2.password != this.form2.confirm_password)) {
        this.required.isPasswordRequire = true;
        this.messageErrorPassword = "Passwords must match"
        isValid = false;
      }
      if (this.form2.password && (this.form2.password == this.form2.confirm_password) && this.form2.password.length < 6) {
        this.required.isPasswordRequire = true;
        this.messageErrorPassword = "Your password must be at least 6 characters long"
        isValid = false;
      }
      return isValid;
    },
  },
};
</script>
<style>
.name-minty {
  font-size: 60px;
  font-weight: 600;
  color: rgb(219, 211, 211) !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.background-minty {
  background-color: #133067 ;
}

.welcome-minty {
  font-size: 25px;
  font-weight: 600;
  color: #133067 ;
  font-family: "Roboto Slab", sans-serif !important;
}

.lable-input {
  font-size: 18px;
  font-weight: 500;
  color: #133067 ;
  font-family: "Roboto Slab", sans-serif;
}

.forgot-pass {
  color: #007BFF;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
</style>
