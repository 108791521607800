import api from './api';

class BookingService {
    listBooking(dataForm) {
        return api.post('/merchant/booking',  dataForm);
    }

    listVenue(dataForm) {
        return api.post('/merchant/booking/venue',  dataForm);
    }

    venueDetail(dataForm) {
        return api.post('/merchant/booking/venue/detail',  dataForm);
    }

    updateOrCreateVenue(dataForm) {
        return api.post('/merchant/booking/venue/update-or-create',  dataForm, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    }

    changeStatusVenue(venueId) {
        return api.post('/merchant/booking/venue/change-status/' + venueId);
    }

    listTimeSlot(dataForm) {
        return api.post('/merchant/booking/venue/get-list-timeslot', dataForm);
    }

    listTimeSlotByTime(dataForm) {
        return api.post('/merchant/booking/venue/get-list-timeslot-by-time', dataForm);
    }

    saveTimeSlot(dataForm) {
        return api.post('/merchant/booking/venue/save-timeslot', dataForm);
    }

    cancelBooking(bookingCode) {
        return api.post('/merchant/booking/cancel/' + bookingCode);
    }
}

export default new BookingService();