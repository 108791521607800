<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddGroupMultipleOption"
      id="show-modal-add-group-multiple-option"
    ></material-button>
    <material-button
      class="d-none"
      data-bs-dismiss="modal"
      data-bs-target="#modalAddGroupMultipleOption"
      id="hide-modal-add-group-multiple-option"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddGroupMultipleOption"
      tabindex="-1"
      aria-hidden="true"
      style="z-index: 9999"
    >
      <div class="modal-dialog modal-dialog-centered modal-option">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{
                isEditGroupMultipleOption
                  ? "Edit Group of Multiple Options"
                  : "Create Group of Multiple Options"
              }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="w-100">
              <ul id="general-tab" class="nav nav-tabs">
                <li class="nav-item">
                  <a
                    id="nav_item_1"
                    class="nav-link"
                    :class="{ active: type == 1 }"
                    @click="changeTab(1)"
                    >Options</a
                  >
                </li>
                <li class="nav-item" v-if="this.isEditGroupMultipleOption">
                  <a id="nav_item_2" class="nav-link" @click="changeTab(2)"
                    >Attached Items</a
                  >
                </li>
              </ul>
            </div>
            <div v-if="type == 1">
              <div class="row align-items-center w-100 m-0 mt-3">
                <div class="col-4 ps-0">
                  Name<span class="text-danger">*</span>
                </div>
                <div class="col-8">
                  <material-input
                    v-model="groupMultipleOption.name"
                    type="text"
                    id="option_group_name"
                    name="option_group_name"
                    placeholder="Enter name"
                  />
                  <span
                    class="is-invalid"
                    v-if="fieldRequired.isGroupMultipleOptionpNameRequired"
                    >The field is required.</span
                  >
                </div>
              </div>
              <div
                class="row align-items-center w-100 m-0 mt-3"
                v-for="(item, index) in groupMultipleOption.options"
                :key="index"
              >
                <div class="col-4 ps-0">
                  {{ index + 1 + "st Option"
                  }}<span class="text-danger" v-if="index <= 1">*</span>
                </div>
                <div class="col-8">
                  <div class="w-100 d-flex align-items-center">
                    <Multiselect
                      :searchable="true"
                      v-model="groupMultipleOption.options[index]"
                      :options="options"
                      placeholder="Select option"
                      :canClear="false"
                    />
                    <i
                      class="material-icons ms-2 cursor-pointer"
                      v-if="index > 1"
                      @click="removeOption(index)"
                      >delete</i
                    >
                  </div>
                  <span
                    class="is-invalid"
                    v-if="
                      fieldRequired.isOptionRequired &&
                      fieldRequired.isOptionRequired[index]
                    "
                    >The field is required.</span
                  >
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4"></div>
                <div class="col-8 is-invalid" v-if="fieldRequired.isOptionDuplicate">
                  Options must not be duplicated.
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-4"></div>
                <dev
                  class="col-8 tag-a-custom text-end"
                  @click="addMoreOption()"
                >
                  Add More Option
                </dev>
              </div>
            </div>
            <div v-else>
              <div class="w-100 text-end mt-5">
                <material-button
                  class="mx-2 btn btn-sm btn-background"
                  @click="addItem()"
                >
                  Add Items
                </material-button>
              </div>
              <div class="list-item" v-if="attachedToItem.length">
                <div
                  v-for="(item, index) in attachedToItem"
                  :key="index"
                  class="item-css"
                >
                  <i
                    class="material-icons text-danger"
                    @click="removeItem(index, item.item_id)"
                    >clear</i
                  >
                  <img class="item-img" :src="item.item_image" alt="" />
                  <div style="position: relative; width: 100%">
                    <label>{{ item?.item_no }}</label
                    ><br />
                    <label>{{ item?.item_name }}</label>
                  </div>
                </div>
              </div>
              <div v-else class="text-center w-100 mt-3">No item available</div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex mt-3">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveGroupMultipleOption()"
              >
                Save
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import ModifierOptionGroupService from "../services/product/modifierOptionGroup";
import SettingGeneralService from "../services/settingGeneral.service";

export default {
  name: "Group Multiple Option",
  components: {
    MaterialButton,
    MaterialLoading,
    MaterialSnackbar,
    Multiselect,
    MaterialInput,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    isEditGroupMultipleOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: null,
      message: "",
      loaderActive: false,
      groupMultipleOption: {
        id: 0,
        set_id: 0,
        name: "",
        pos_name: "",
        button_color: "#000000",
        button_font_color: "#ff0000",
        is_auto_created: 0,
        options: [0, 0],
      },
      options: [],
      diningOptionDataList: [
        { label: "All", value: 1 },
        { label: "Dine in", value: 2 },
        { label: "Takeaway", value: 3 },
      ],
      fieldRequired: {},
      priceList: [],
      type: 1, // Add
      attachedToItem: [],
      attachedToItemRemove: [],
    };
  },
  methods: {
    changeTab(type) {
      this.type = type;
      this.attachedToItem = [];
      this.attachedToItemRemove = [];
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_" + type).addClass("active");

      if (type == 2) {
        this.getAttachedToItem();
        $(".modal-option").css("min-width", "80%");
      } else {
        $(".modal-option").css("min-width", "unset");
      }
    },
    async getOptionList() {
      this.showLoader();
      var dataForm = {
        option_list: 1,
      };

      await SettingGeneralService.masterData(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.options = response.data.data.option_list;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    groupMultipleOptionDetail() {
      this.showLoader();
      ModifierOptionGroupService.detail(
        this.isEditGroupMultipleOption,
        0,
        2
      ).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.groupMultipleOption = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    saveGroupMultipleOption() {
      if (this.processValidate()) {
        this.showLoader();
        this.groupMultipleOption.pos_name = this.groupMultipleOption.name;
        this.groupMultipleOption.type = 2;
        this.groupMultipleOption.attached_to_item_remove = this.attachedToItemRemove;

        ModifierOptionGroupService.store(this.groupMultipleOption).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.$emit(
                "reload-list-group-multiple-option",
                this.isEditGroupMultipleOption
                  ? "Update Group Multiple Option Success"
                  : "Add Group Multiple Option Success"
              );
              $("#hide-modal-add-group-multiple-option").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.changeTab(1);
      }
    },
    getAttachedToItem() {
      this.attachedToItemRemove = [];
      this.attachedToItem = [];
      if (this.groupMultipleOption.id > 0) {
        this.showLoader();
        var data = {
          modifier_option_group_id: this.groupMultipleOption.id,
        };

        ModifierOptionGroupService.getAttachedToItem(data).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.attachedToItem = response.data.data;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },
    addMoreOption() {
      this.groupMultipleOption.options.push({
        id: 0,
      });
    },
    removeOption(index) {
      if (index > 1) {
        this.groupMultipleOption.options.splice(index, 1);
      }
    },
    removeItem(index, id) {
      this.attachedToItemRemove.push(id);
      this.attachedToItem.splice(index, 1);
    },
    addItem() {
      $("#hide-modal-add-group-multiple-option").click();
      this.$emit("attached-item", this.isEditGroupMultipleOption, 2, this.groupMultipleOption);
    },
    resetRequired() {
      this.fieldRequired.isGroupMultipleOptionpNameRequired = false;
      this.fieldRequired.isOptionRequired = [false, false];
      this.fieldRequired.isOptionDuplicate = false;
    },
    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (
        this.groupMultipleOption.name == "" ||
        this.groupMultipleOption.name == null
      ) {
        this.fieldRequired.isGroupMultipleOptionpNameRequired = true;
        isValid = false;
      }

      if (!this.groupMultipleOption.options[0]) {
        this.fieldRequired.isOptionRequired[0] = true;
        isValid = false;
      }

      if (!this.groupMultipleOption.options[1]) {
        this.fieldRequired.isOptionRequired[1] = true;
        isValid = false;
      }

      const optionsSelected = this.groupMultipleOption.options.filter(value => value !== 0);
      const checkDuplicate = new Set(optionsSelected).size !== optionsSelected.length;

      if (checkDuplicate) {
        this.fieldRequired.isOptionDuplicate = true;
        isValid = false;
      }

      return isValid;
    },
    clearData() {
      this.resetRequired();
      this.type = 1;
      this.groupMultipleOption = {
        id: 0,
        set_id: 0,
        name: "",
        pos_name: "",
        button_color: "#000000",
        button_font_color: "#ff0000",
        is_auto_created: 0,
        options: [0, 0],
      };
      this.options = [];
      this.attachedToItem = [];
      this.attachedToItemRemove = [];
      $(".modal-option").css("min-width", "unset");
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_1").addClass("active");
    },
    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.clearData();
      this.getOptionList();

      if (this.isEditGroupMultipleOption) {
        this.groupMultipleOptionDetail();
      }
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 120px;
}

.modal-dialog {
  max-width: 650px;
}

#general-tab {
  border-bottom: none;
}

#general-tab .nav-item {
  width: fit-content;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
}

#general-tab li a.active,
#general-tab li a.nav-link:hover {
    border: none !important;
    border-bottom: 5px solid #4853EB !important;
    color: #4853EB !important;
    background-color: unset;
}

.list-item {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.item-css {
  position: relative;
  display: flex;
  border: 1px solid #d2d2d2;
  margin-top: 12px;
  border-radius: 6px;
  padding: 30px 30px 10px 10px;
  width: calc(25% - 10px);
  min-height: 90px;
}

.item-css > i {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.item-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}
</style>
