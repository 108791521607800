<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4
          class="col-lg-10 col-md-10 col-12 text-back d-flex align-items-center ps-2"
        >
          <i
            class="fa fa-chevron-circle-left pe-2"
            aria-hidden="true"
            @click="backVoucherList()"
            style="cursor: pointer"
          ></i>
          <div @click="backVoucherList()" style="cursor: pointer">BACK</div>
        </h4>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container">
        <div class="row card-custom-new pt-3">
          <div
            class="col-lg-12 col-md-12 col-12 mx-auto pb-5"
            v-if="isLoadDoneData"
          >
            <h3 class="title-page ps-2">
              {{ voucher.id ? "EDIT VOUCHER SET" : "CREATE VOUCHER SET" }}
            </h3>
            <div class="table-responsive custom-table-noborder">
              <table class="table align-items-center mb-0">
                <tbody>
                  <tr>
                    <td class="align-middle text-left text-sm" colspan="10">
                      <div class="ps-0 text-title">
                        {{ voucher.id ? "Created on " : "Create on "
                        }}{{
                          voucherCreateAt + " by " + voucherCreateBy + " admin"
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex">
                        <div class="ps-0 label-form">Voucher Set ID</div>
                      </div>
                    </td>
                    <td colspan="8">VOU{{ voucher.id ?? voucherIdDummy }}</td>
                  </tr>
                  <tr>
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex">
                        <div class="ps-0 label-form">Voucher Set title</div>
                        <span class="text-danger ms-2">*</span>
                      </div>
                    </td>
                    <td colspan="8">
                      <material-input
                        id="reward_title"
                        v-model="voucher.reward_title"
                        name="reward_title"
                        maxLength="100"
                        placeholder="Enter reward title"
                      />
                      <span
                        class="is-invalid"
                        v-if="fieldRequired.isRewardTitleRequire"
                        >The field is required.</span
                      >
                    </td>
                  </tr>
                  <tr v-if="rewardPerTransactionType">
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex row">
                        <div class="label-form col-9">Exchange Cashback</div>
                        <div
                          class="col-3 d-flex justify-content-end pe-1"
                          style="margin-top: 1px"
                        >
                          $
                        </div>
                      </div>
                    </td>
                    <td colspan="8">
                      <material-input
                        id="amount_convert"
                        type="number"
                        v-model="voucher.amount_convert"
                        name="amount_convert"
                        placeholder="Enter exchange Amount"
                      />
                    </td>
                  </tr>
                  <tr v-if="rewardPerTransactionType">
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex row">
                        <div class="label-form col-9">Exchange Point</div>
                      </div>
                    </td>
                    <td colspan="8">
                      <material-input
                        id="points_convert"
                        type="number"
                        v-model="voucher.points_convert"
                        name="points_convert"
                        placeholder="Enter exchange Point"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">Voucher Set Type</div>
                    </td>
                    <td colspan="" style="line-height: 30px; padding-top: 22px">
                      <MaterialRadio
                        v-model="voucher.reward_type"
                        id="percentage_off"
                        value="1"
                        class="align-items-center"
                      >
                        Percentage Off
                      </MaterialRadio>
                      <MaterialRadio
                        v-model="voucher.reward_type"
                        id="fixed_amount_off"
                        value="2"
                        class="align-items-center"
                      >
                        Fixed Amount Off
                      </MaterialRadio>
                      <MaterialRadio
                        v-model="voucher.reward_type"
                        id="free_item"
                        value="3"
                        class="align-items-center"
                      >
                        Free Item
                      </MaterialRadio>
                    </td>
                    <td colspan="7" class="align-items-end">
                      <div class="d-flex">
                        <div class="pe-1 d-flex align-items-center">%</div>
                        <material-input
                          type="number"
                          v-model="voucher.reward_unit_percentage"
                          id="reward_unit_percentage"
                          name="reward_unit_percentage"
                          class="align-items-end"
                          placeholder="Enter number of percentage"
                        />
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="pe-1 d-flex align-items-center mt-2">$</div>
                        <material-input
                          type="number"
                          v-model="voucher.reward_unit_fixed_amount"
                          id="reward_unit_fixed_amount"
                          name="reward_unit_fixed_amount"
                          class="align-items-end mt-2"
                          placeholder="Enter fixed amount"
                        />
                      </div>
                      <Multiselect
                        v-model="voucher.free_item"
                        mode="tags"
                        :searchable="true"
                        :options="items"
                        placeholder="Select items"
                        class="mt-2"
                        :canClear="false"
                        :filterResults="false"
                        @search-change="getDropdownFreeItem"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2"></td>
                    <td></td>
                    <td colspan="8" class="align-items-end pt-0">
                      <span
                        class="is-invalid"
                        v-if="fieldRequired.isRewardUnitRequire"
                        >{{ textRequiredRewardUnitCustom }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8">
                      <div class="ps-0 label-black c-bundle-reward"> <material-checkbox v-model="voucher.bundle_reward" :checked="voucher.bundle_reward ? true : false" value="1"
                        id="bundle_reward" name="bundle_reward" class="align-items-center checkbox-custom">
                          <template v-slot:label>
                            Bundle reward
                          </template>
                        </material-checkbox>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="voucher.reward_type != 3">
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">
                        Who can see this voucher?
                      </div>
                    </td>
                    <td colspan="6">
                      <Multiselect
                        v-model="voucher.member_group_see"
                        mode="tags"
                        :close-on-select="true"
                        :searchable="true"
                        :options="tiers"
                        placeholder="Select tiers can see"
                        :canClear="false"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">
                        Who can exchange/obtain this voucher?
                      </div>
                    </td>
                    <td colspan="6">
                      <Multiselect
                        v-model="voucher.member_group_exchange"
                        mode="tags"
                        :close-on-select="true"
                        :searchable="true"
                        :options="tiers"
                        placeholder="Select tiers can exchange"
                        :canClear="false"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">
                        Where is this voucher valid?
                      </div>
                    </td>
                    <td colspan="6">
                      <Multiselect
                        v-model="voucher.apply_for_store"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :options="branches"
                        placeholder="Select branches to apply"
                        :canClear="false"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">
                        Which product is this voucher applied to?
                      </div>
                    </td>
                    <td colspan="6">
                      <Multiselect
                        v-model="voucher.apply_for_product"
                        mode="tags"
                        :close-on-select="true"
                        :searchable="true"
                        :options="items"
                        placeholder="Select items to apply"
                        :canClear="false"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">Order mininum</div>
                    </td>
                    <td colspan="1">
                      <MaterialRadio
                        v-model="voucher.order_minimum"
                        id="none"
                        value="1"
                      >
                        None
                      </MaterialRadio>
                      <MaterialRadio
                        v-model="voucher.order_minimum"
                        id="order_total_minimum"
                        value="2"
                      >
                        Order total
                      </MaterialRadio>
                    </td>
                    <td colspan="7" class="align-items-end">
                      <material-input
                        type="number"
                        v-model="voucher.order_total"
                        id="order_total"
                        name="order_total"
                        class="align-items-end"
                        style="margin-top: 26px"
                        placeholder="Enter order total"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2"></td>
                    <td colspan="1"></td>
                    <td colspan="7" class="align-items-end pt-0">
                      <span
                        class="is-invalid"
                        v-if="fieldRequired.isOrderTotalRequire"
                        >The field is required.</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">Number of Redemption</div>
                    </td>
                    <td colspan="1">
                      <MaterialRadio
                        v-model="voucher.usability_type"
                        id="one_time_per_member"
                        value="1"
                      >
                        One time per member
                      </MaterialRadio>
                      <MaterialRadio
                        v-model="voucher.usability_type"
                        id="mutiple_times_per_member"
                        value="2"
                      >
                        Mutiple times per member
                      </MaterialRadio>
                    </td>
                    <td colspan="1">
                      <div style="margin-top: 1.7rem;">
                        <material-input
                          type="number"
                          v-model="voucher.usability_number"
                          id="usability_number"
                          name="usability_number"
                          class="align-items-end"
                          placeholder="Enter usability number"
                        />
                      </div>
                    </td>
                    <td colspan="1">
                      <div style="margin-top: 1.7rem;">Times for every</div>
                    </td>
                    <td colspan="3">
                      <Multiselect
                        v-model="voucher.usability_unit"
                        :options="usabilityUnit"
                        placeholder="Select Time Use After"
                        style="margin-top: 1.7rem;"
                        :canClear="false"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2"></td>
                    <td colspan="1"></td>
                    <td colspan="7" class="align-items-end pt-0">
                      <span
                        class="is-invalid"
                        v-if="fieldRequired.isUsabilityRequire"
                        >The field is required.</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form d-flex">Expiry Date <span class="text-danger ms-2">*</span></div>
                    </td>
                    <td colspan="1">
                      <material-checkbox
                        v-model="voucher.fixed_date"
                        :checked="parseInt(voucher.fixed_date) ? true : false"
                        name="fixed_date"
                        id="fixed_date"
                        class="align-items-center px-3"
                      >
                        <template v-slot:label
                          >Fixed Date</template
                        ></material-checkbox
                      >
                    </td>
                    <td colspan="5">
                      <Datepicker
                        v-model="voucher.expiry_date"
                        :enableTimePicker="false"
                        class="date-time"
                        :format="format"
                      />
                      <span
                        class="is-invalid"
                        v-if="fieldRequired.isFixedDateRequire"
                        >{{ textRequiredDateCustom }}</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2"></td>
                    <td colspan="1" class="d-flex">
                      <material-checkbox
                        v-model="voucher.expired_after"
                        :checked="
                          parseInt(voucher.expired_after) ? true : false
                        "
                        name="expired_after"
                        id="expired_after"
                        class="checkbox-custom px-3 pt-2"
                      >
                        <template v-slot:label
                          >Expired after</template
                        ></material-checkbox
                      >
                      <material-input
                        class="d-flex align-items-center"
                        id="time_number"
                        type="number"
                        v-model="voucher.time_number"
                        name="time_number"
                        placeholder="Enter time number"
                        style="width: 150px"
                      />
                    </td>
                    <td colspan="2">
                      <Multiselect
                        v-model="voucher.time_unit"
                        :options="timeUnits"
                        placeholder="Select Time Unit"
                        :canClear="false"
                      />
                    </td>
                    <td>of issue</td>
                  </tr>
                  <tr v-if="fieldRequired.isDateRequire">
                    <td class="align-middle" colspan="2"></td>
                    <td colspan="1"></td>
                    <td colspan="7" class="align-items-end pt-0">
                      <span
                        class="is-invalid"
                        >Expiry date is required.</span
                      >
                    </td>
                  </tr>
                  <tr v-if="fieldRequired.isTimeNumberRequire">
                    <td class="align-middle" colspan="2"></td>
                    <td class="ps-4"><span
                        class="is-invalid"
                        >{{textExpiredAfterCustom}}</span
                      ></td>
                    <td colspan="7" class="align-items-end pt-0">
                    </td>
                  </tr>

                  <tr>
                    <td colspan="10" style="width: 100%">
                      <div class="ps-0 label-form">Terms & Conditions</div>
                      <material-textarea v-model="voucher.terms_conditions" />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2" style="width: 20%">
                      <div class="d-flex">
                        <div class="ps-0 label-form">Voucher Image</div>
                      </div>
                    </td>
                    <td colspan="2">
                      <div
                        class="d-flex justify-content-left align-items-center"
                      >
                        <div class="align-items-center">
                          <material-input
                            style="display: none"
                            type="file"
                            id="image"
                            @change="handleFileUpload($event)"
                            accept="image/*"
                          />
                          <material-button
                            @click="uploadFile()"
                            :disabled="false"
                            class="btn-background btn btn-sm capitalize"
                          >
                            Upload Image
                          </material-button>
                          <div
                            class="is-invalid d-flex align-items-center pe-2"
                            v-if="fieldRequired.isImageRequire"
                          >
                            {{ messageImageError }}
                          </div>
                          <div class="text-size-13">
                            Uploaded image minimum 100 x 100.
                          </div>
                        </div>
                        <div
                          id="preview"
                          class="ps-2"
                          style="position: relative"
                        >
                          <img
                            v-if="urlImagePreview || voucher.icon"
                            :src="
                              urlImagePreview ? urlImagePreview : voucher.icon
                            "
                          />
                          <i
                            class="fa fa-times icon-close-sidenav-main danger"
                            v-if="urlImagePreview || voucher.icon"
                            style="
                              position: absolute;
                              top: -8px;
                              left: 130px;
                              color: #f44335;
                              font-size: 20px;
                            "
                            aria-hidden="true"
                            @click="clearImage()"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">Voucher Status</div>
                    </td>
                    <td colspan="8" class="d-flex">
                      <MaterialRadio
                        class="ps-0"
                        v-model="voucher.status"
                        id="active"
                        value="1"
                      >
                        Active
                      </MaterialRadio>
                      <MaterialRadio
                        v-model="voucher.status"
                        id="deactive"
                        value="0"
                      >
                        Inactive
                      </MaterialRadio>
                    </td>
                  </tr>
                  <tr v-if="voucher.id">
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">Stock:</div>
                    </td>
                    <td colspan="8" class="d-flex"></td>
                  </tr>
                  <tr v-if="voucher.id" class="voucher-report">
                    <td class="align-middle" colspan="10">
                      <div class="ps-0">Overview</div>
                    </td>
                  </tr>
                  <tr v-if="voucher.id" class="voucher-report">
                    <td class="text-center text-sm">
                      <strong>{{ voucher.reward_quantity }}</strong>
                    </td>
                    <td class="text-center text-sm">
                      <strong>{{
                        voucher.total_member_reward_issued ?? 0
                      }}</strong>
                    </td>
                    <td class="text-center text-sm">
                      <strong>{{ balanceToIssued }}</strong>
                    </td>
                    <td
                      class="text-center text-sm link-overview"
                      @click="getVoucherExchanged(1)"
                    >
                      <strong>{{
                        voucher.total_member_reward_used ?? 0
                      }}</strong>
                    </td>
                    <td
                      class="text-center text-sm link-overview"
                      @click="getVoucherExchanged(2)"
                    >
                      <strong> {{ voucher.total_rev_text }}</strong>
                    </td>
                    <td
                      class="text-center text-sm link-overview"
                      @click="getVoucherExchanged(3)"
                    >
                      <strong>{{ voucher.total_expired ?? 0 }}</strong>
                    </td>
                    <td
                      class="text-center text-sm link-overview"
                      @click="getVoucherExchanged(4)"
                    >
                      <strong>{{ voucher.total_redeemable ?? 0 }}</strong>
                    </td>
                  </tr>
                  <tr class="voucher-report" v-if="voucher.id">
                    <td class="text-center text-sm text-bold">Total</td>
                    <td class="text-center text-sm text-bold">Issued</td>
                    <td class="text-center text-sm text-bold">Balance to issued</td>
                    <td class="text-center text-sm text-bold">Redeemed</td>
                    <td class="text-center text-sm text-bold">Total revenue</td>
                    <td class="text-center text-sm text-bold">Total Expired</td>
                    <td class="text-center text-sm text-bold">Redeemable</td>
                  </tr>
                  <!-- <tr>
                        <td colspan="10">
                          <material-checkbox v-model="voucher.profile_sharing_reward" value="1"
                            id="profile_sharing_reward" style="padding-left: 1.73em !important;">
                            Profile sharing reward (Voucher sent when user inputs or shares profile to merchant)
                          </material-checkbox>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="10">
                          <material-checkbox v-model="voucher.friend_inviting_reward" value="1"
                            id="friend_inviting_reward" style="padding-left: 1.73em !important;">
                            Friend inviting reward (Voucher sent when use's friend sign up)</material-checkbox>
                        </td>
                      </tr> -->
                </tbody>
              </table>
              <div class="table-responsive p-0 custom-table">
                <table
                  style="border-top: 1px solid #d9d9d9"
                  class="table align-items-center mb-0 mt-2"
                  v-if="rewardHistoryList.length"
                >
                  <thead>
                    <tr>
                      <th class="text-center text-xss">Date Time</th>
                      <th class="text-center text-xss">Stock In Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in rewardHistoryList" :key="key">
                      <td class="text-center text-xss">
                        {{ value.created_at }}
                      </td>
                      <td class="text-center text-xss">
                        {{ value.type == 1 ? "-" : "" }}
                        {{ value.stock_in }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <table class="table align-items-center mb-0 mt-3">
                <tbody>
                  <tr v-if="voucher.id">
                    <td class="text-center text-sm" colspan="9"></td>
                    <td
                      class="text-center text-sm d-flex justify-content-end"
                      colspan="1"
                    >
                      <material-button
                        :disabled="disableBtnSubmit"
                        data-bs-toggle="modal"
                        data-bs-target="#modalChangeVoucherQuantity"
                        @click="confirmChangeQuantity(1)"
                        class="mx-2 btn-background btn-sm capitalize"
                        :style="`background: ${this.$store.state.buttonColor} !important`"
                      >
                        Stock Out
                      </material-button>
                      <material-button
                        :disabled="disableBtnSubmit"
                        data-bs-toggle="modal"
                        data-bs-target="#modalChangeVoucherQuantity"
                        @click="confirmChangeQuantity(2)"
                        class="mx-2 btn-background btn-sm capitalize"
                        :style="`background: ${this.$store.state.buttonColor} !important`"
                      >
                        Stock In
                      </material-button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10">
                      <div class="d-flex justify-content-center pt-3">
                        <material-button
                          @click="saveVoucher(index)"
                          :disabled="disableBtnSubmit"
                          class="mx-2 btn-background"
                          :style="`width: 300px; background: ${this.$store.state.buttonColor} !important`"
                        >
                          Save
                        </material-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm
        message="Are you sure you want to delete ?"
        :process-yes="processYes"
      />
      <VoucherExchanged
        :id="voucher.id"
        :vouchers="voucherExchange"
        :typeStock="typeStock"
        :totalRevenue="totalRevenue"
      />
      <div
        class="modal fade"
        id="modalChangeVoucherQuantity"
        tabindex="-1"
        aria-labelledby="modalChangeVoucherQuantityLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ headerChangeQuantity }}</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div v-if="typeChangeQuantity == 1" class="col-lg-12 pb-4">
                  Maximum Quantity to be Cutdown: {{ balanceToIssued }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 d-flex align-items-center">
                  {{ labelBodyChangeQuantity }}
                </div>
                <div class="col-lg-8">
                  <material-input
                    type="number"
                    v-model="rewardQuantityVolatility"
                    placeholder="Enter reward quantity"
                    @input="onChangeQuantity"
                  />
                  <span class="is-invalid" v-if="!isChangeQuantityRequire">{{
                    messageErrorChangeQuantity
                  }}</span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success btn-background"
                @click="processOk"
                :disabled="!isChangeQuantityRequire && true"
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-primary btn-close-modal"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import SettingVoucherService from "../../../services/settingVoucher.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import VoucherExchanged from "./../../components/VoucherExchanged.vue";
import CommonFunctionService from "../../../services/commonFunction.service";
import moment from "moment";
import $ from "jquery";
export default {
  name: "Voucher",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    MaterialInput,
    MaterialRadio,
    Multiselect,
    Datepicker,
    MaterialTextarea,
    MaterialCheckbox,
    VoucherExchanged,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      discounts: [],
      form: [],
      fieldRequired: {},
      indexItem: 0,
      textRequiredDateCustom: "",
      textRequiredRewardUnitCustom: "",
      textExpiredAfterCustom: "",
      urlImagePreview: null,
      image: null,
      messageImageError: "",
      isErrorUploadImage: false,
      isImageTooSmall: false,
      voucher: {
        id: null,
        reward_title: "",
        amount_convert: 0,
        points_convert: 0,
        reward_code: "",
        reward_quantity: 0,
        reward_type: 1,
        reward_unit_percentage: null,
        reward_unit_fixed_amount: null,
        member_group_see: [],
        member_group_exchange: [],
        free_item: [],
        apply_for_store: [],
        apply_for_product: [],
        order_minimum: 1,
        usability_type: 1,
        usability_number: null,
        usability_unit: 1,
        order_total: null,
        expiry_date: null,
        fixed_date: 0,
        expired_after: 0,
        time_unit: 1,
        terms_conditions: "",
        icon: null,
        status: 0,
        profile_sharing_reward: 0,
        friend_inviting_reward: 0,
        bundle_reward: 0
      },
      items: [],
      itemsDefault: [],
      branches: [],
      tiers: [],
      voucherIdDummy: "",
      isLoadDoneData: false,
      merchantCode: "",
      voucherCreateAt: "",
      voucherCreateBy: "",
      time_number: null,
      timeUnits: [],
      headerChangeQuantity: "",
      labelBodyChangeQuantity: "",
      typeChangeQuantity: 0,
      rewardQuantityVolatility: 0,
      rewardHistoryList: [],
      isChangeQuantityRequire: true,
      quantityExchanged: 0,
      voucherExchange: [],
      typeStock: 1,
      totalRevenue: "S$0",
      balanceToIssued: 0,
      usabilityUnit: [],
      rewardPerTransactionType: 0
    };
  },
  created() {
    this.getVoucherDetail();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
    if (this.rewardQuantityVolatility == 0) {
      this.isChangeQuantityRequire = false;
    }
  },
  methods: {
    getDropdownFreeItem(keyword) {
      var dataSearch = this.itemsDefault;
      var dataResult = dataSearch.filter(
        (item) => (item.label.toLowerCase().indexOf(keyword.toLowerCase()) > -1 || (item.item_no + '').indexOf(keyword) > -1)
      );

      this.items = dataResult;
    },
    format(date) {
      return CommonFunctionService.formatDateShow(date)
    },
    confirmChangeQuantity(type) {
      this.rewardQuantityVolatility = 0;
      this.isChangeQuantityRequire = true;
      this.typeChangeQuantity = 0;
      if (type == 1) {
        this.typeChangeQuantity = type;
        this.headerChangeQuantity = "Quantity Cutdown";
        this.labelBodyChangeQuantity = "Cutdown Quantity";
      } else {
        this.typeChangeQuantity = type;
        this.headerChangeQuantity = "Quantity Increase";
        this.labelBodyChangeQuantity = "Increased Quantity";
      }
    },

    onChangeQuantity(event) {
      this.isChangeQuantityRequire = true;
      if (
        this.typeChangeQuantity == 1 &&
        event.target.value > this.voucher.reward_quantity
      ) {
        this.isChangeQuantityRequire = false;
        this.messageErrorChangeQuantity =
          "Voucher quantity cutdown should not be greater than the remaining quantity.";
      }
      if (this.typeChangeQuantity == 1 && event.target.value <= 0) {
        this.isChangeQuantityRequire = false;
        this.messageErrorChangeQuantity =
          "Voucher quantity cutdown should not be greater than 0.";
      }
    },

    processValidateChangeQuantity() {
      this.isChangeQuantityRequire = true;
      var isValid = true;
      if (this.typeChangeQuantity == 1) {
        if (this.rewardQuantityVolatility > this.balanceToIssued) {
          isValid = false;
          this.isChangeQuantityRequire = false;
          this.messageErrorChangeQuantity =
            "Voucher quantity cutdown should not be greater than the remaining quantity.";
        }
        if (this.rewardQuantityVolatility <= 0) {
          isValid = false;
          this.isChangeQuantityRequire = false;
          this.messageErrorChangeQuantity =
            "Voucher quantity cutdown should not be greater than 0.";
        }
      } else {
        if (this.rewardQuantityVolatility <= 0) {
          isValid = false;
          this.isChangeQuantityRequire = false;
          this.messageErrorChangeQuantity =
            "Voucher quantity cutdown should not be greater than 0.";
        }
      }

      return isValid;
    },

    getVoucherExchanged(type) {
      this.showLoader();
      let dataForm = {
        id: this.voucher.id,
        type: type,
      };
      SettingVoucherService.voucherExchanged(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.voucherExchange = response.data.data.data_voucher_exchanged;
            this.typeStock = type;
            $(".btn-voucher-exchnaged").click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    processOk() {
      let dataForm = {
        id: this.voucher.id,
        reward_quantity_volatility: this.rewardQuantityVolatility,
        type_change_quantity: this.typeChangeQuantity,
      };
      if (
        this.isChangeQuantityRequire &&
        this.processValidateChangeQuantity()
      ) {
        $(".btn-close-modal").click();
        this.showLoader();
        SettingVoucherService.changeQuantity(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.voucher.reward_quantity =
                response.data.data.reward.reward_quantity;
              this.rewardHistoryList = response.data.data.reward_history_list;
              this.quantityExchanged =
                response.data.data.reward.quantity_exchanged;
              this.balanceToIssued =
                response.data.data.reward.reward_quantity -
                this.voucher.total_member_reward_issued;
              this.snackbar = "success";
              this.message = "Update Voucher Quantity Success";
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.voucher.icon = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.fieldRequired.isImageRequire = true;
          this.isErrorUploadImage = false;
          this.messageImageError = `File must be an image.`;
        } else {
          this.voucher.icon = file;
          this.urlImagePreview = URL.createObjectURL(this.voucher.icon);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.voucher.icon = null;
        this.urlImagePreview = null;
        this.fieldRequired.isImageRequire = true;
        this.isErrorUploadImage = false;
        this.messageImageError = `File must be an image.`;
      }
    },

    clearImage() {
      this.voucher.icon = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    dateFormat(expiryDate) {
      if (expiryDate == null) {
        return null;
      } else {
        return moment(expiryDate).format("YYYY-MM-DD") + " " + "00:00:00";
      }
    },
    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    getVoucherDetail() {
      var voucherId = this.$route.params.id;
      this.isLoadDoneData = false;
      this.showLoader();
      if (voucherId) {
        SettingVoucherService.getDataVoucherDetail(voucherId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.voucherCreateAt = response.data.data.create_at;
              this.voucherCreateBy = response.data.data.create_by;
              this.voucher = response.data.data.reward;
              this.balanceToIssued =
                this.voucher.reward_quantity -
                this.voucher.total_member_reward_issued;
              this.quantityExchanged = this.voucher.quantity_exchanged;
              this.rewardHistoryList = response.data.data.reward_history_list;
              this.totalRevenue = this.voucher.total_rev_text;
              if (this.voucher.reward_type == 1) {
                this.voucher.reward_unit_percentage = this.voucher.reward_unit;
              } else if (this.voucher.reward_type == 2) {
                this.voucher.reward_unit_fixed_amount =
                  this.voucher.reward_unit;
              }
              this.timeUnits = response.data.data.time_unit;
              this.items = response.data.data.items;
              this.itemsDefault = this.items;
              this.branches = response.data.data.branches;
              this.tiers = response.data.data.tiers;
              this.usabilityUnit = response.data.data.usabilityUnit;
              this.rewardPerTransactionType = response.data.data.reward_per_transaction_type;
              this.isLoadDoneData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        SettingVoucherService.createNewVoucher().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.voucherCreateAt = response.data.data.create_at;
              this.voucherCreateBy = response.data.data.create_by;
              this.voucher.reward_code = response.data.data.reward_code;
              this.items = response.data.data.items;
              this.itemsDefault = this.items;
              this.branches = response.data.data.branches;
              this.tiers = response.data.data.tiers;
              this.usabilityUnit = response.data.data.usabilityUnit;
              this.voucherIdDummy = response.data.data.reward_id;
              this.isLoadDoneData = true;
              this.timeUnits = response.data.data.time_unit;
              this.voucher.free_item = response.data.data.free_item;
              this.voucher.member_group_see = response.data.data.member_group_see;
              this.voucher.member_group_exchange = response.data.data.member_group_exchange;
              this.voucher.apply_for_store = response.data.data.apply_for_store;
              this.voucher.apply_for_product = response.data.data.apply_for_product;
              this.rewardPerTransactionType = response.data.data.reward_per_transaction_type;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    saveVoucher() {
      var page = this.$route.query.page ?? 1;
      if (this.isImageTooSmall) {
        this.isErrorUploadImage = true;
        this.fieldRequired.isImageRequire = true;
      } else {
        this.fieldRequired.isImageRequire = false;
      }
      this.voucher.expiry_date = this.dateFormat(this.voucher.expiry_date);
      this.showLoader();
      if (this.processValidate() && !this.isErrorUploadImage) {
        this.voucher.profile_sharing_reward = this.voucher
          .profile_sharing_reward
          ? 1
          : 0;
        this.voucher.friend_inviting_reward = this.voucher
          .friend_inviting_reward
          ? 1
          : 0;
        this.voucher.bundle_reward = this.voucher
          .bundle_reward
          ? 1
          : 0;
        this.voucher.fixed_date = this.voucher.fixed_date ? 1 : 0;
        this.voucher.expired_after = this.voucher.expired_after ? 1 : 0;
        SettingVoucherService.addOrUpdateVoucher(this.voucher).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Voucher Success";
              this.voucher.id = response.data.data.merchant_reward
                ? response.data.data.merchant_reward.id
                : null;
              // this.voucher.apply_for_store = response.data.data.apply_for_store;
              this.setTimeOutNotification();
              this.$router.push("/vouchers/?page=" + page);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.hideLoader();
      }
    },

    resetRequired() {
      this.fieldRequired.isRewardTitleRequire = false;
      this.fieldRequired.isRewardQuantityRequire = false;
      this.fieldRequired.isRewardUnitRequire = false;
      this.fieldRequired.isOrderTotalRequire = false;
      this.fieldRequired.isUsabilityRequire = false;
      this.fieldRequired.isDateRequire = false;
      this.fieldRequired.isTimeNumberRequire = false;
      this.fieldRequired.isFixedDateRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (
        this.voucher.reward_title == "" ||
        this.voucher.reward_title == null
      ) {
        this.fieldRequired.isRewardTitleRequire = true;
        isValid = false;
      }
      if (
        this.voucher.reward_type == 1 &&
        (this.voucher.reward_unit_percentage == "" ||
          this.voucher.reward_unit_percentage == null)
      ) {
        this.fieldRequired.isRewardUnitRequire = true;
        this.textRequiredRewardUnitCustom =
          "Please enter number of percentage!";
        isValid = false;
      } else if (
        this.voucher.reward_type == 2 &&
        (this.voucher.reward_unit_fixed_amount == "" ||
          this.voucher.reward_unit_fixed_amount == null)
      ) {
        this.fieldRequired.isRewardUnitRequire = true;
        this.textRequiredRewardUnitCustom = "Please enter fixed amount!";
        isValid = false;
      }
      if (
        this.voucher.order_minimum == 2 &&
        (this.voucher.order_total == "" || this.voucher.order_total == null)
      ) {
        this.fieldRequired.isOrderTotalRequire = true;
        isValid = false;
      }
      if (
        this.voucher.usability_type == 2 &&
        (this.voucher.usability_number == "" || this.voucher.usability_number == null)
      ) {
        this.fieldRequired.isUsabilityRequire = true;
        isValid = false;
      }
      const expiryDate = this.dateFormat(this.voucher.expiry_date);
      if(expiryDate == null && !parseInt(this.voucher.time_number)) {
        this.fieldRequired.isDateRequire = true;
        isValid = false;
      }
      if(this.voucher.expired_after && !parseInt(this.voucher.time_number)) {
        this.fieldRequired.isTimeNumberRequire = true;
        this.textExpiredAfterCustom = "Time number is required"
        isValid = false;
      }
      if(!this.voucher.expired_after && parseInt(this.voucher.time_number)) {
        this.fieldRequired.isTimeNumberRequire = true;
        this.textExpiredAfterCustom = "Expired after is required"
        isValid = false;
      }

      if(!this.voucher.fixed_date && expiryDate != null || this.voucher.fixed_date && expiryDate == null) {
        this.fieldRequired.isFixedDateRequire = true;
        this.textRequiredDateCustom = "Fixed date is required"
        isValid = false;
      }
      // const expiryDate = this.dateFormat(this.voucher.expiry_date);
      // const dataNow = this.dateFormat(new Date());
      // if (expiryDate != null && expiryDate < dataNow) {
      //   this.fieldRequired.isDateRequire = true;
      //   this.textRequiredDateCustom =
      //     "The expiry date must be one day after today.";
      //   isValid = false;
      // }
      return isValid;
    },

    backVoucherList() {
      var page = this.$route.query.page;
      if (page) {
        this.$router.push("/vouchers" + "?page=" + page);
      } else {
        this.$router.push("/vouchers");
      }
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    uploadFile() {
      $("#image").click();
    },
  },
};
</script>
<style scoped>
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

#preview img {
  max-width: 150px;
  max-height: 60px;
}

.voucher-report {
  background-color: #eeeeee;
  color: #133067 ;
}
.link-overview {
  color: #203ace;
  text-decoration: underline;
}
.link-overview:hover {
  cursor: pointer;
}
</style>