<template>
  <div class="bg-white">
    <main class="mt-0 main-content">
      <section>
        <div class="row page-header min-vh-100" v-if="isLoadData">
          <div class="col-12">
            <div class="row vh-100 page-current">
              <div class="col-xl-7 col-lg-7 col-md-7 d-flex justify-content-center ps-0 pe-0"
                style="border-right: 1px solid #E0E0E0">
                <div class="card card-plain" style="min-width: 90%; width: 90%;">
                  <div class="card-body p-0 my-3">
                    <div class="d-flex justify-content-center pb-6 welcome-minty">POS Simulator
                    </div>
                    <div class="row g-3 align-items-center pb-2 pt-0">
                      <div class="col-4">
                        <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Phone_Number') }}</label><span class="text-danger ms-2">*</span>
                      </div>
                      <div class="col-5 pe-0">
                        <material-input v-model="form.phone_number" extraClass="p-2" id="phone_number" type="text"
                          name="phone_number" size="lg" />
                      </div>
                      <div class="col-xl-3 col-lg-3 col-md-12 col-12 ps-0 d-flex justify-content-end">
                        <material-button style="padding: 6px 30px !important;" @click="getMember(false)" class="btn-sm btn-background"
                          color="success">Validate
                        </material-button>
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2">
                      <div class="col-4">
                      </div>
                      <div class="col-8 pe-0">
                        <div
                          class="is-invalid"
                          v-if="fieldRequired.phone_number"
                        >
                          The field is required.
                        </div>
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2">
                      <div class="col-4">
                        <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Branch') }}</label><span class="text-danger ms-2">*</span>
                      </div>
                      <div class="col-8">
                        <Multiselect v-model="form.branch_id" :options="branchs" :canClear="false" />
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2">
                      <div class="col-4">
                      </div>
                      <div class="col-8 pe-0">
                        <div
                          class="is-invalid"
                          v-if="fieldRequired.branch_id"
                        >
                          The field is required.
                        </div>
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2">
                      <div class="col-4">
                        <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Staff') }}</label><span class="text-danger ms-2">*</span>
                      </div>
                      <div class="col-8">
                        <Multiselect v-model="form.employee_name" :options="staffs" :canClear="false" :searchable="true"/>
                        <!-- <material-input v-model="form.employee_name_enter" extraClass="p-2" id="employee_name_enter" type="text"
                          name="employee_name_enter" size="lg" placeholder="Select an option above or enter Staff Name here" /> -->
                      </div>
                    </div>
                    <div class="row g-3 align-items-center">
                      <div class="col-4">
                      </div>
                      <div class="col-8 pe-0">
                        <div
                          class="is-invalid"
                          v-if="fieldRequired.employee_name"
                        >
                          The field is required.
                        </div>
                      </div>
                    </div>
                    <div v-if="showBlockPayment" style="margin-bottom: 200px;">
                      <ul class="member-detail-voucher-tab ps-0 pe-0 mt-5">
                        <li class="nav-item" :class="{'active': typeTab == 1, 'w-50': !isMember}" id="nav_item_1" @click="changeType(1)">
                            <strong>{{ $t('POS_Transaction') }}</strong>
                        </li>
                        <li class="nav-item" :class="{'active': typeTab == 2}" id="nav_item_2" @click="changeType(2)" v-if="isMember">
                            <strong>{{ $t('Top_Up') }}</strong>
                        </li>
                        <li class="nav-item" :class="{'active': typeTab == 3}" id="nav_item_3" @click="changeType(3)" v-if="isMember">
                            <strong>{{ $t('Cashback_Adjustment') }}</strong>
                        </li>
                        <li class="nav-item" :class="{'active': typeTab == 4, 'w-50': !isMember}" id="nav_item_4" @click="changeType(4)">
                            <strong>{{ $t('Update_POS_Transaction') }}</strong>
                        </li>
                      </ul>
                      <div class="row g-3 align-items-center pb-2 pt-3" v-if="[2, 4].includes(typeTab)">
                        <div class="col-4">
                          <div class="row">
                            <div class="col-11">
                              <label for="inputPassword6" class="col-form-label lable-input">Transaction ID</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-5">
                          <material-input v-model="topup_transaction_id" extraClass="p-2" id="transaction_id" type="text" name="transaction_id" size="lg" />
                        </div>
                        <div class="col-3 text-end">
                          <material-button v-if="typeTab == 2" style="position: unset;" @click="voidTopup()" class="ms-2 px-6 z-index btn-background" color="success">VOID</material-button>
                          <material-button v-else style="position: unset;" @click="getTransaction()" class="ms-2 px-6 z-index btn-background" color="success">SEARCH</material-button>
                        </div>
                      </div>
                      <!-- <div class="row g-3 align-items-center pb-2 mt-1 pt-3" v-if="typeTab == 1">
                        <div class="col-4">
                        </div>
                        <div class="col-8">
                          <div class="d-flex">
                          <MaterialRadio
                            class="ps-0"
                            v-model="form.pos_transaction_type"
                            id="sub_total"
                            value="2"
                            @change="onChangePosTransactionType($event)"
                          >
                            {{ $t('By_Total_Amount') }}
                          </MaterialRadio>
                          <MaterialRadio
                            class="ps-3"
                            v-model="form.pos_transaction_type"
                            id="product"
                            value="1"
                            @change="onChangePosTransactionType($event)"
                          >
                            {{ $t('By_product') }}
                          </MaterialRadio>
                        </div>
                        </div>
                      </div> -->
                      <!-- <div class="row g-3 align-items-center pb-2" v-if="[1, 4].includes(typeTab)">
                        <div v-if="showByProduct">
                          <div class="row g-3 align-items-center pb-2" v-for="(item, index) in form.products" :key="item">
                            <div class="col-4">
                              <label for="inputPassword6" class="col-form-label lable-input">Product {{ index + 1 }}</label>
                            </div>
                            <div class="col-3">
                              <Multiselect v-model="item.item_no" :options="products" @change="changeProduct(index, $event)" :canClear="false"/>
                            </div>
                            <div class="col-2">
                              {{ $t('Price') }}: S$ {{ item.item_price }}
                            </div>
                            <div class="col-2 d-flex align-items-center">
                              <i v-if="item.item_price" class="fa fa-times" aria-hidden="true"></i>
                              <material-input v-model="item.quantity" extraClass="p-2" id="quantity" type="number"
                                placeholder="Quantity" name="quantity" size="lg" />
                            </div>
                            <div class="col-1">
                              <i class="fas fa-trash-alt cursor-pointer" @click="removeProduct(index)" v-if="form.products.length > 1"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-1" v-if="[1, 4].includes(typeTab) && showByProduct">
                        <div class="col-12 d-flex justify-content-end">
                          <a @click="addProduct()"
                          class="text-decoration-underline tag-a-custom">Add more product</a>
                        </div>
                      </div> -->
                      <div v-if="[1, 4].includes(typeTab)">
                        <div class="row g-3 align-items-center pb-2 pt-3" v-if="showByTotalAmuont">
                          <div class="col-4">
                            <div class="row">
                              <div class="col-11">
                                <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Sub_Total') }}</label>
                              </div>
                              <div class="col-1 pe-0">
                                <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <material-input v-model="form.sub_total" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="sub_total" type="number"
                            name="sub_total" size="lg" />
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="[1, 4].includes(typeTab)">
                        <div class="col-4">
                          <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Discount_Code') }}</label>
                        </div>
                        <div class="col-8">
                          <material-input v-model="form.discount_code" extraClass="p-2" id="discount_code" type="text"
                            name="discount_code" size="lg" />
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="[1, 4].includes(typeTab)">
                        <div class="col-4">
                          <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Voucher_Code') }}</label>
                        </div>
                        <div class="col-8">
                          <material-input v-model="form.voucher_code" extraClass="p-2" id="voucher_code" type="text"
                            name="voucher_code" size="lg" />
                        </div>
                      </div>
                      <div v-if="typeTab == 4">
                        <div class="row g-3 align-items-center pb-2 pt-3" v-if="showByTotalAmuont">
                          <div class="col-4">
                            <div class="row">
                              <div class="col-11">
                                <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Total_Before_Tax') }}</label>
                              </div>
                              <div class="col-1 pe-0">
                                <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <material-input v-model="form.total_before_tax" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="total_before_tax" type="number"
                            name="total_before_tax" size="lg" />
                          </div>
                        </div>
                      </div>
                      <div v-if="typeTab == 4">
                        <div class="row g-3 align-items-center pb-2 pt-3" v-if="showByTotalAmuont">
                          <div class="col-4">
                            <div class="row">
                              <div class="col-11">
                                <label for="inputPassword6" class="col-form-label lable-input pdl-30">- {{ $t('GST') }}</label>
                              </div>
                              <div class="col-1 pe-0">
                                <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <material-input v-model="form.gst" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="gst" type="number"
                            name="gst" size="lg" />
                          </div>
                        </div>
                        <div class="row g-3 align-items-center pb-2 pt-3" v-if="showByTotalAmuont">
                          <div class="col-4">
                            <div class="row">
                              <div class="col-11">
                                <label for="inputPassword6" class="col-form-label lable-input pdl-30">- {{ $t('Cess') }}</label>
                              </div>
                              <div class="col-1 pe-0">
                                <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <material-input v-model="form.cess" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="cess" type="number"
                            name="cess" size="lg" />
                          </div>
                        </div>
                        <div class="row g-3 align-items-center pb-2 pt-3" v-if="showByTotalAmuont">
                          <div class="col-4">
                            <div class="row">
                              <div class="col-11">
                                <label for="inputPassword6" class="col-form-label lable-input pdl-30">- {{ $t('Service_Charge') }}</label>
                              </div>
                              <div class="col-1 pe-0">
                                <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <material-input v-model="form.service_charge" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="service_charge" type="number"
                            name="service_charge" size="lg" />
                          </div>
                        </div>
                        <div class="row g-3 align-items-center pb-2 pt-3" v-if="showByTotalAmuont">
                          <div class="col-4">
                            <div class="row">
                              <div class="col-11">
                                <label for="inputPassword6" class="col-form-label lable-input pdl-30">- {{ $t('Take_Away_Charge') }}</label>
                              </div>
                              <div class="col-1 pe-0">
                                <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <material-input v-model="form.take_away_charge" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="take_away_charge" type="number"
                            name="take_away_charge" size="lg" />
                          </div>
                        </div>
                      </div>
                      <div v-if="typeTab == 4">
                        <div class="row g-3 align-items-center pb-2 pt-3" v-if="showByTotalAmuont">
                          <div class="col-4">
                            <div class="row">
                              <div class="col-11">
                                <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Receipt_Total') }}</label>
                              </div>
                              <div class="col-1 pe-0">
                                <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-8">
                            <material-input v-model="form.total_amount" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="total_amount" type="number"
                            name="total_amount" size="lg" />
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2 pt-3" v-if="typeTab == 2">
                        <div class="col-4">
                          <div class="row">
                            <div class="col-11">
                              <label for="inputPassword6" class="col-form-label lable-input">Top Up Name</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-5">
                          <Multiselect v-model="form.topup_id" :options="topUps" @change="changeTopUp" placeholder="Amount" label="topup_name" :canClear="false" />
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="typeTab == 2">
                        <div class="col-4">
                        </div>
                        <div class="col-5">
                          <div
                            class="is-invalid"
                            v-if="fieldRequired.topup_id"
                          >
                            The field is required.
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="typeTab == 2 && form.payment_amount">
                        <div class="col-4">
                          <div class="row">
                            <div class="col-12">
                              <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Payment_Amount_Topup') }} <span class="text-danger ms-2">*</span></label>
                            </div>
                          </div>
                        </div>
                        <div class="col-5">
                          S$ {{ form.payment_amount }}
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="typeTab == 2 && form.bonus_amount">
                        <div class="col-4">
                          <div class="row">
                            <div class="col-12">
                              <label for="inputPassword6" class="col-form-label lable-input">Bonus Amount</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-5">
                          S$ {{ form.bonus_amount }}
                        </div>
                      </div>

                      <div class="row g-3 align-items-center pb-2" style="padding-top: 15px !important;" v-if="typeTab == 3">
                        <div class="col-4">
                          <div class="row">
                            <div class="col-11">
                              <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Total_Amount') }} <span class="text-danger ms-2">*</span></label>
                            </div>
                            <div class="col-1 pe-0">
                              <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-5">
                          <material-input v-model="form.amount_direct_increased" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="payment_amount" type="number"
                            name="amount_direct_increased" size="lg" />
                        </div>
                      </div>

                      <div class="row g-3 align-items-center pb-2" v-if="typeTab == 3">
                        <div class="col-4">
                        </div>
                        <div class="col-5">
                          <div
                            class="is-invalid"
                            v-if="fieldRequired.amount_direct_increased"
                          >
                            The field is required.
                          </div>
                        </div>
                      </div>

                      <div class="row g-3 align-items-center pb-2" v-if="typeTab == 2">
                        <div class="col-4">
                          <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Top_Up_Method') }} <span class="text-danger ms-2">*</span></label>
                        </div>
                        <div class="col-5">
                          <Multiselect v-model="form.payment_method" :options="paymentMethods" :canClear="false" placeholder="Payment method" />
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="typeTab == 2">
                        <div class="col-4">
                        </div>
                        <div class="col-5">
                          <div
                            class="is-invalid"
                            v-if="fieldRequired.payment_method"
                          >
                            The field is required.
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="[1, 4].includes(typeTab)">
                        <div class="col-12">
                          <label for="inputPassword6" class="col-form-label lable-input">{{ $t('Deduct_from_Wallet') }}</label>
                        </div>
                        <div class="col-4">
                          <div class="row">
                            <div class="col-11">
                              <label for="inputPassword6" class="col-form-label lable-input ps-3"> - {{ $t('Paid_Topup') }}</label>
                            </div>
                            <div class="col-1 pe-0">
                              <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-8">
                          <material-input v-model="form.deducted_topup" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="deducted_topup" type="number"
                            name="deducted_topup" size="lg"/>
                        </div>
                        <div class="col-4">
                          <div class="row">
                            <div class="col-11">
                              <label for="inputPassword6" class="col-form-label lable-input ps-3"> - {{ $t('Cashback') }}</label>
                            </div>
                            <div class="col-1 pe-0">
                              <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-8">
                          <material-input v-model="form.deducted_cashback" @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" extraClass="p-2" id="deducted_cashback" type="number"
                            name="deducted_cashback" size="lg" />
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="[1, 4].includes(typeTab)">
                        <div class="row g-3 align-items-center pb-2" v-for="(item, index) in form.others_amount" :key="item">
                            <div class="col-4">
                              <div class="row">
                                <div class="col-11">
                                  <label for="inputPassword6" class="col-form-label lable-input" v-if="index == 0">{{ $t('Payment') }}</label>
                                </div>
                                <div class="col-1 pe-0">
                                  <label for="inputPassword6" class="col-form-label d-flex justify-content-end">$</label>
                                </div>
                              </div>
                            </div>
                            <div class="col-3">
                              <material-input @keydown.prevent.up="preventUpDown" @keydown.prevent.down="preventUpDown" v-model="item.amount" extraClass="p-2" id="item_amount" type="number"
                              name="item_amount" size="lg" />
                            </div>
                            <div class="col-4">
                              <Multiselect v-model="item.payment_method" :options="paymentMethods" :canClear="false" />
                            </div>
                            <div class="col-1">
                              <i class="fas fa-trash-alt cursor-pointer" @click="removeOtherAmount(index)" v-if="form.others_amount.length > 1"></i>
                            </div>
                          </div>
                      </div>
                      <div class="row g-3 align-items-center pb-2" v-if="[1, 4].includes(typeTab)">
                        <div class="col-4">
                        </div>
                        <div class="col-5">
                          <div
                            class="is-invalid"
                            v-if="!othersAmountError.status"
                          >
                            {{ othersAmountError.message }}
                          </div>
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pb-1" v-if="[1, 4].includes(typeTab)">
                        <div class="col-12 d-flex justify-content-end">
                          <a @click="addOtherAmount()"
                          class="text-decoration-underline tag-a-custom">{{ $t('Add_more_payment_amount') }}</a>
                        </div>
                      </div>
                      <div class="text-center" v-if="typeTab == 4">
                        <material-button style="position: unset;" class="mt-4 px-6 z-index btn-background" data-bs-toggle="modal" data-bs-target="#modalConfirmDelete" href="javascript:;" @click="confirmDelete()"
                          color="success">Void
                        </material-button>
                        <material-button style="position: unset;" class="ms-2 mt-4 px-6 z-index btn-background" data-bs-toggle="modal" data-bs-target="#modalConfirm" href="javascript:;" @click="confirmUpdate()"
                          color="success">Update
                        </material-button>
                      </div>
                      <div class="text-center" v-else>
                        <material-button style="position: unset;" v-if="typeTab == 1" @click="checkout(1)" class="mt-4 px-6 z-index btn-background"
                          color="success">Preview
                        </material-button>
                        <material-button style="position: unset;" @click="typeTab == 1 ? checkout(0) : AddAmountForAccount(true)" class="ms-2 mt-4 px-6 z-index btn-background"
                          color="success">Run
                        </material-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 d-flex justify-content-center align-items-center ps-0 pe-0">
                <div class="card card-plain w-100" style="align-self: flex-start;">
                  <div class="card-body">
                    <div class="welcome-minty d-flex justify-content-center pb-4">Results
                    </div>
                    <div class="row g-3 align-items-center py-2" v-if="typeTab == 2 || typeTab == 3">
                      <div class="col-12" v-bind:class="colorStatus">
                        {{ message }}
                      </div>
                    </div>
                    <div class="row g-3 align-items-center py-2" v-else>
                      <div class="col-12" v-bind:class="colorStatus">
                        
                      </div>
                    </div>
                    <div class="row g-3 align-items-center py-2" v-if="[1, 4].includes(typeTab)">
                      <div class="col-12 1111" v-bind:class="colorStatus">
                        {{ message }}
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2" v-if="memberInfo.member_id">
                      <div class="col-12 member-info">
                        Member Info
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2" v-if="memberInfo.member_id">
                      <div class="col-12">
                        <div class="table-responsive p-0 custom-table" style="border-top: 1px solid #E0E0E0;">
                          <table class="table align-items-center mb-0">
                            <tbody>
                              <tr>
                                <td class="text-xss">
                                  {{ $t('status') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.status_text ?? 'Deactive' }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  {{ $t('Wallet_Balance') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.wallet_balance_format }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.wallet_balance_by_type">
                                <td class="text-xss pdl-30">
                                  - {{ $t('Paid_Topup') }}
                                </td>
                                <td class="text-left text-xss pdl-30">
                                  {{ memberInfo.wallet_balance_by_type.topup_format }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.wallet_balance_by_type">
                                <td class="text-xss pdl-30">
                                  - {{ $t('Topup_Bonus') }}
                                </td>
                                <td class="text-left text-xss pdl-30">
                                  {{ memberInfo.wallet_balance_by_type.bonus_format }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.wallet_balance_by_type">
                                <td class="text-xss pdl-30">
                                  - {{ $t('Cashback') }}
                                </td>
                                <td class="text-left text-xss pdl-30">
                                  {{ memberInfo.wallet_balance_by_type.cashback_format }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.hasOwnProperty('point')">
                                <td class="text-xss">
                                  {{ $t('Point') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.point ?? 0}}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.member_address_1">
                                <td class="text-xss">
                                  {{ $t('Address_1') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.member_address_1 }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.member_address_2">
                                <td class="text-xss">
                                  {{ $t('Address_2') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.member_address_2 }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.member_email">
                                <td class="text-xss">
                                  {{ $t('Email') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.member_email }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.member_id">
                                <td class="text-xss">
                                  {{ $t('Member_ID') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.member_id }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  {{ $t('Name') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.member_name ?? "" }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  {{ $t('DOB') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.date_of_birth ?? "" }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.tier_name">
                                <td class="text-xss">
                                  {{ $t('Tier') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.tier_name }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.member_remark">
                                <td class="text-xss">
                                  {{ $t('Remarks') }}
                                </td>
                                <td class="text-left text-xss">
                                  {{ memberInfo.member_remark }}
                                </td>
                              </tr>
                              <tr v-if="memberInfo.memberPreferences">
                                <td class="text-xss">
                                  {{ $t('Preference') }}
                                </td>
                                <td class="text-left text-xss">
                                  <div  v-for="(value, key) in memberInfo.memberPreferences" :key="key">
                                      <div>
                                          {{ key+1 +". "+value.label+ ": " + value.value }}
                                      </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2" v-if="colorStatus == 'text-success' && showTransactionInfo">
                      <div class="col-12">
                        <div class="table-responsive p-0 custom-table" style="border-top: 1px solid #E0E0E0;">
                          <table class="table align-items-center mb-0">
                            <tbody>
                              <tr v-if="!isPaymentPreview">
                                <td class="text-xss">
                                Transaction Status
                                </td>
                                <td class="text-center text-xss">
                                 {{ results.transaction_status }}
                                </td>
                              </tr>
                              <tr v-if="!isPaymentPreview">
                                <td class="text-xss">
                                Transaction ID
                                </td>
                                <td class="text-center text-xss">
                                 {{ results.transaction_id }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  Discount Deducted
                                </td>
                                <td class="text-center text-xss">
                                 S$ {{ results.discount_deducted ?? 0 }}
                                </td>
                              </tr>
                              <tr v-if="results.voucher_title">
                                <td class="text-xss">
                                Voucher Title
                                </td>
                                <td class="text-center text-xss">
                                 {{ results.voucher_title }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  Voucher Deducted
                                </td>
                                <td class="text-center text-xss">
                                 S$ {{ results.voucher_deducted ?? 0 }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                Deduct from Wallet
                                </td>
                                <td class="text-center text-xss">
                                S$ {{ results.amount_direct_deduct ?? 0 }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                Wallet Balance
                                </td>
                                <td class="text-center text-xss">
                                S$ {{ results.old_wallet_balance ?? 0 }}
                                </td>
                              </tr>
                              <tr v-if="rewardCashback">
                                <td class="text-xss">
                                  Cashback Earned
                                </td>
                                <td class="text-center text-xss">
                                  S$ {{ results.cashback_earned ?? 0 }}
                                </td>
                              </tr>
                              <tr v-if="results.hasOwnProperty('new_wallet_balance')">
                                <td class="text-xss">
                                  New Wallet Balance
                                </td>
                                <td class="text-center text-xss">
                                  S$ {{ results.new_wallet_balance ?? 0 }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                Point
                                </td>
                                <td class="text-center text-xss">
                                  {{ results.old_point ?? 0 }}
                                </td>
                              </tr>
                              <tr v-if="rewardPoint">
                                <td class="text-xss">
                                  Point Earned
                                </td>
                                <td class="text-center text-xss">
                                  {{ results.point_earned ?? 0 }}
                                </td>
                              </tr>
                              <tr v-if="results.hasOwnProperty('new_point')">
                                <td class="text-xss">
                                  New Point
                                </td>
                                <td class="text-center text-xss">
                                  {{ results.new_point ?? 0 }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  {{ $t('Payment_Amount_Topup') }}
                                </td>
                                <td class="text-center text-xss">
                                  S$ {{ results.payment_amount ?? 0 }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2 pt-3" v-if="colorStatus == 'text-success' && showTransactionInfo && !isPaymentPreview && results?.orthers_amount.length">
                      <div class="col-12">
                        <div class="table-responsive p-0 custom-table" style="border-top: 1px solid #E0E0E0;">
                          <table class="table align-items-center mb-0">
                            <tbody>
                              <template v-if="!isPaymentPreview" >
                                <tr>
                                  <td class="text-xss">
                                    Payment Method
                                  </td>
                                  <td class="text-center text-xss">
                                    {{ $t('Payment_Amount_Topup') }}
                                  </td>
                                </tr>
                                <tr v-for="(item, index) in results.orthers_amount" :key="index">
                                  <td class="text-xss">
                                    {{ item.payment_method }}
                                  </td>
                                  <td class="text-center text-xss">
                                    S$ {{ item.amount }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-xss">
                                    Total
                                  </td>
                                  <td class="text-center text-xss">
                                    S$ {{ results.payment_amount ?? 0 }}
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row g-3 align-items-center py-2" v-if="typeTab == 2 && loadCashbackAdjustmentDone">
                      <div class="col-12 member-info">
                        Transaction Details
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2" v-if="typeTab == 2 && loadCashbackAdjustmentDone">
                      <div class="col-12">
                        <div class="table-responsive p-0 custom-table" style="border-top: 1px solid #E0E0E0;">
                          <table class="table align-items-center mb-0">
                            <tbody>
                              <tr>
                                <td class="text-xss">
                                  Transaction ID
                                </td>
                                <td class="text-center text-xss">
                                  {{ cashbackAdjustmentData.transaction_id }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  New Wallet Balance
                                </td>
                                <td class="text-center text-xss">
                                  {{ cashbackAdjustmentData.new_wallet_balance_format }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  Payment Amount
                                </td>
                                <td class="text-center text-xss">
                                  {{ cashbackAdjustmentData.payment_amount_format }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  Cashback Earned
                                </td>
                                <td class="text-center text-xss">
                                  {{ cashbackAdjustmentData.bonus_amount_format }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  Top Up Amount
                                </td>
                                <td class="text-center text-xss">
                                  {{ cashbackAdjustmentData.topup_amount_format }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-xss">
                                  Payment Method
                                </td>
                                <td class="text-center text-xss">
                                  {{ cashbackAdjustmentData.payment_method }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row g-3 align-items-center py-2" v-if="memberInfo.vouchers && memberInfo.vouchers.length">
                      <div class="col-12 member-info">
                        Vouchers
                      </div>
                    </div>
                    <div class="row g-3 align-items-center pb-2" v-if="memberInfo.vouchers && memberInfo.vouchers.length">
                      <div class="col-12">
                        <div class="table-responsive p-0 custom-table" style="border-top: 1px solid #E0E0E0;">
                          <table class="table align-items-center mb-0">
                            <thead>
                              <tr>
                                <th class="text-center font-weight-bolder w-5">Voucher title</th>
                                <th class="text-center font-weight-bolder">
                                  Voucher code
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item) in memberInfo.vouchers" :key="item">
                                <td class="text-xss">
                                  {{ item.reward_title }}
                                </td>
                                <td class="text-center text-xss">
                                  {{ item.reward_code }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalConfirm
            message="Are you sure you want to update this transaction?"
            :process-yes="updateOrder"
          />
          <ModalConfirmDelete
            message="Are you sure you want to void this transaction?"
            :process-yes="voidTransaction"
          />
          <material-loading :active="loaderActive" />
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import MaterialLoading from "@/components/MaterialLoading.vue";
import TestService from "../services/test.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import TokenService from "../services/token.service";
// import MaterialRadio from "@/components/MaterialRadio.vue";
// import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
import $ from "jquery";

export default {
  name: "pos-api-testing",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialLoading,
    Multiselect,
    // MaterialRadio,
    ModalConfirm,
    ModalConfirmDelete
    // MaterialToggleButton
  },
  data() {
    return {
      loaderActive: false,
      // products: [],
      // productsFullInfo: [],
      branchs: [],
      isLoadData: false,
      merchantCode: "",
      message: '',
      colorStatus: '',
      form: {
        phone_number: "",
        discount_code: "",
        merchant_code: "",
        voucher_code: "",
        branch_id: 0,
        employee_name: '',
        // employee_name_enter: '',
        pos_id: "1",
        payment_preview: 1,
        payment_method: 0,
        payment_amount: "",
        top_up_amount: 0,
        topup_id: null,
        amount_direct_increased: 0,
        bonus_amount: "",
        deducted_cashback: 0,
        deducted_topup: 0,
        products: [],
        sub_total: 0,
        total_amount: 0,
        total_before_tax: 0,
        gst: 0,
        cess: 0,
        service_charge: 0,
        take_away_charge: 0,
        pos_transaction_type: "2",
        type_cashback: 1,
        others_amount: [
          {
            amount: 0,
            payment_method: ''
          }
        ],
      },
      userLogin: {},
      isPaymentPreview: true,
      results: {},
      productIdDefault: 0,
      memberInfo: {},
      statusStimulator: false,
      typeTab: 1,
      // showByProduct: false,
      showByTotalAmuont: true,
      showBlockPayment: false,
      fieldRequired: [],
      paymentMethods: [],
      topupAmounts: [
        { label: 'S$ 200', value: 200 },
        { label: 'S$ 100', value: 100 },
        { label: 'S$ 50', value: 50 },
        { label: 'S$ 20', value: 20 },
      ],
      topUps: [],
      cashbackAdjustmentData: {},
      loadCashbackAdjustmentDone: false,
      othersAmountError: {
        status: true,
        message: "",
      },
      staffs: [],
      topup_transaction_id: '',
      isMember: false,
      showTransactionInfo: false
    };
  },
  created() {
    // this.getData();
    this.getBranches();
    this.getPaymentMethods();
    this.getTopUps();
    this.getStaffs();
    this.userLogin = TokenService.getMember()
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.form.merchant_code = code ? code : "";
  },
  computed: {
    rewardCashback: function () {
      if (this.memberInfo.reward_type && this.memberInfo.reward_type == 1) {
        return true;
      }

      return false;
    },

    rewardPoint: function () {
      if (this.memberInfo.reward_type && this.memberInfo.reward_type == 2) {
        return true;
      }

      return false;
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    changeTopUp(topUpId) {
      this.topUps.forEach((item) => {
        if(item.id == topUpId) {
          this.form.top_up_amount = new Intl.NumberFormat("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(parseFloat(item.topup_amount ? item.topup_amount : 0))
          this.form.payment_amount = new Intl.NumberFormat("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(parseFloat(item.payment_amount ? item.payment_amount : 0))
          this.form.bonus_amount = new Intl.NumberFormat("en", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }).format(parseFloat(item.bonus_amount ? item.bonus_amount : 0))
        }
      })
    },

    triggerEventPaymentPreview(value) {
      if (typeof value == "boolean") {
        this.isPaymentPreview = !this.isPaymentPreview;
        this.form.payment_preview = this.isPaymentPreview ? 1 : 0;
      }
    },

    // onChangePosTransactionType(event) {
    //   if(event.target.value == 1) {
    //     if(this.form.products.length == 0) {
    //       this.addProduct()
    //     }
    //     this.showByProduct = true
    //     this.showByTotalAmuont = false
    //   } else {
    //     this.showByProduct = false
    //     this.showByTotalAmuont = true
    //   }
    // },

    // async getData() {
    //   this.isLoadData = false;
    //   this.products = []
    //   this.productsFullInfo = []
    //   this.staffs = []
    //   this.showLoader();
    //   await TestService.getData().then(
    //     (response) => {
    //       if (response.data.result.isSuccess) {
    //         this.products = response.data.data.products;
    //         this.productsFullInfo = response.data.data.products_full_info
    //         this.productIdDefault = this.products[0] ? this.products[0].value : 0
    //         this.addProduct()
    //         this.isLoadData = true;
    //       } else {
    //         this.message = response.data.result.message;
    //       }
    //       this.hideLoader();
    //     },
    //     (error) => {
    //       this.message = error;
    //       this.hideLoader();
    //     }
    //   );
    // },

    getTopUps() {
      this.isLoadData = false;
      this.topUps = []
      this.showLoader();
      TestService.getDataTopUp().then(
        (response) => {
          if (response.data.result.isSuccess) {
            console.log(response.data.data)
            this.topUps = response.data.data;
            this.isLoadData = true;
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    getBranches() {
      this.isLoadData = false;
      this.showLoader();
      TestService.getBranches().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branchs = response.data.data;
            this.form.branch_id = this.branchs[0] ? this.branchs[0].value : 0
            this.isLoadData = true;
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    getPaymentMethods() {
      this.isLoadData = false;
      this.showLoader();
      TestService.getPaymentMethods().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.paymentMethods = response.data.data;
            this.isLoadData = true;
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    // async changeProduct(index, productId) {
    //   var searchProduct = await this.productsFullInfo.find((product) => product.item_no == productId);
    //   this.form.products[index].item_price = searchProduct.price
    // },

    // async addProduct() {
    //   await this.form.products.push({
    //     item_no: this.productIdDefault,
    //     item_price: this.productsFullInfo[0] ? this.productsFullInfo[0].price : 0 ,
    //     quantity: 1,
    //   });
    // },

    async addOtherAmount() {
      await this.form.others_amount.push({
        amount: 0,
        payment_method: ''
      });
    },

    // removeProduct(index) {
    //   if (this.form.products.length > 0) {
    //     this.form.products.splice(index, 1);
    //   }
    // },

    removeOtherAmount(index) {
      if (this.form.others_amount.length > 0) {
        this.form.others_amount.splice(index, 1);
      }
    },

    async getMember(isValidate = false) {
      this.showBlockPayment = true;
      this.showTransactionInfo = false;
      this.memberInfo = {
        'reward_type': this.memberInfo.reward_type ?? 0
      }
      this.results = {}
      this.showLoader();
      if (this.form.phone_number) {
        this.isMember = true;
        if (!isValidate) {
          this.loadCashbackAdjustmentDone = false;
        }
        if(this.processValidate(isValidate)) {
          await TestService.getMember(this.form).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.message = ''
                this.memberInfo = response.data.data
                if (this.memberInfo.reward_type && this.memberInfo.reward_type  == 1) {
                  this.formatMemberMoney();
                }
              } else {
                this.message = response.data.result.message;
                this.colorStatus = 'text-danger'
              }
              this.hideLoader();
            },
            (error) => {
              this.message = error.response.data.result.message;
              this.colorStatus = 'text-danger'
              this.hideLoader();
            }
          ).finally(() => {
            $('ul.member-detail-voucher-tab >li').removeClass('active');
            $('#nav_item_'+this.typeTab).addClass("active")
          });
        }
      } else {
        this.isMember = false;
        this.message = '';
        this.changeType(1);
      }
      this.hideLoader();
    },

    async voidTopup() {
      if(this.typeTab == 2) {
        var dataForm = {
          phone_number: this.form.phone_number,
          transaction_id: this.topup_transaction_id
        }

        await TestService.voidTopup(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.topup_transaction_id = this.cashbackAdjustmentData.transaction_id ?? '';
                this.getMember(false);
                setTimeout(() => {
                  this.topup_transaction_id = '';
                }, 100)
              } else {
                this.message = response.data.result.message;
                this.colorStatus = 'text-danger'
                this.loadCashbackAdjustmentDone = false;
              }
              this.hideLoader();
            },
            (error) => {
              this.message = error.response.data.result.message;
              this.colorStatus = 'text-danger'
              this.hideLoader();
              this.loadCashbackAdjustmentDone = false;
            }
          );
      }
    },

    async AddAmountForAccount(isValidate = false) {
      if(this.processValidate(isValidate, true)) {
        this.showTransactionInfo = false
        this.memberInfo = {
        'reward_type': this.memberInfo.reward_type ?? 0
      }
        this.results = {}
        this.showLoader();
        await this.processaddDirectAmount(isValidate);
      }
    },

    async processaddDirectAmount(isValidate) {
      let paymentSuccess = false;

      if(this.typeTab == 2) {
        await TestService.addTopupAmount(this.form).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.cashbackAdjustmentData = response.data.data;
                this.topup_transaction_id = this.cashbackAdjustmentData.transaction_id ?? '';
                this.colorStatus = 'text-success'
                this.loadCashbackAdjustmentDone = true;
                paymentSuccess = true
                setTimeout(() => {
                  this.clearFormData();
                }, 100)
              } else {
                this.message = response.data.result.message;
                this.colorStatus = 'text-danger'
                this.loadCashbackAdjustmentDone = false;
              }
              this.hideLoader();
            },
            (error) => {
              this.message = error.response.data.result.message;
              this.colorStatus = 'text-danger'
              this.hideLoader();
              this.loadCashbackAdjustmentDone = false;
            }
          );
      }

      if(this.typeTab == 3) {
        await TestService.addDirectAmount(this.form).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.message = "";
                this.cashbackAdjustmentData = response.data.data; 
                this.colorStatus = 'text-success'
                this.loadCashbackAdjustmentDone = true;
                paymentSuccess = true
                setTimeout(() => {
                  this.clearFormData();
                }, 100)
              } else {
                this.message = response.data.result.message;
                this.colorStatus = 'text-danger'
                this.loadCashbackAdjustmentDone = false;
              }
              this.hideLoader();
            },
            (error) => {
              this.message = error.response.data.result.message;
              this.colorStatus = 'text-danger'
              this.hideLoader();
              this.loadCashbackAdjustmentDone = false;
            }
          );
      }

      this.memberInfo = {
        'reward_type': this.memberInfo.reward_type ?? 0
      };
      if (paymentSuccess) {
        this.getMember(isValidate)
      }
    },
    
    formatMemberMoney() {
      this.memberInfo.wallet_balance_by_type.cashback = Number.parseFloat(this.memberInfo.wallet_balance_by_type.cashback).toFixed(2);
      this.memberInfo.wallet_balance_by_type.topup = Number.parseFloat(this.memberInfo.wallet_balance_by_type.topup).toFixed(2);
      this.memberInfo.wallet_balance_by_type.topup_bonus = Number.parseFloat(this.memberInfo.wallet_balance_by_type.topup_bonus).toFixed(2);
    },

    async checkout(isPreview) {
      if(this.processValidate(false, true, isPreview ? true : false)) {
        this.showTransactionInfo = false
        this.statusStimulator = false
        this.colorStatus = ''
        this.results = {}
        this.showLoader();
        this.form.payment_preview = isPreview;
        let dataForm = this.form;
        delete dataForm.total_amount;
        delete dataForm.total_before_tax;
        this.isPaymentPreview = isPreview ? true : false;
        if(this.showByTotalAmuont) {
          this.form.products = []
        }

        await TestService.checkout(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.message = "";
              this.colorStatus = 'text-success'
              this.results = response.data.data
              this.statusStimulator = true
              this.showTransactionInfo = true
              if (!isPreview) {
                setTimeout(() => {
                  this.clearFormData();
                }, 100)
              }
            } else {
              this.message = response.data.result.message;
              this.colorStatus = 'text-danger'
            }
            this.hideLoader();
          },
          (error) => {
            this.message = error.response.data.result.message;
            this.colorStatus = 'text-danger'
            this.hideLoader();
          }
        );

        this.memberInfo = {
        'reward_type': this.memberInfo.reward_type ?? 0
      }
        if(this.statusStimulator && this.form.phone_number) {
          TestService.getMember(this.form).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.message = "";
                this.colorStatus = 'text-success'
                this.memberInfo = response.data.data
                // this.memberInfo.wallet_balance = this.results.new_wallet_balance;
                if (isPreview) {
                  this.caculationCashbackWhenPreview(this.results.amount_direct_deduct)
                  this.memberInfo.wallet_balance_by_type.cashback = parseInt(this.memberInfo.wallet_balance_by_type.cashback) + parseInt(this.results.cashback_earned);
                  this.memberInfo.wallet_balance = this.results.new_wallet_balance;
                }

                if (this.memberInfo.reward_type && this.memberInfo.reward_type  == 1) {
                  this.formatMemberMoney();
                }
              } else {
                this.message = response.data.result.message;
                this.colorStatus = 'text-danger'
              }
            },
            (error) => {
              this.message = error.response.data.result.message;
              this.colorStatus = 'text-danger'
            }
          );
        }
      }
    },

    caculationCashbackWhenPreview(value) {
      let amountDirectDeduct = parseInt(value);
      if (amountDirectDeduct > 0) {
        if (amountDirectDeduct < this.memberInfo.wallet_balance_by_type.topup) {
          this.memberInfo.wallet_balance_by_type.topup = parseInt(this.memberInfo.wallet_balance_by_type.topup) - amountDirectDeduct;
          this.memberInfo.wallet_balance_by_type.topup = Number.parseFloat(this.memberInfo.wallet_balance_by_type.topup).toFixed(2);
        } else {
          amountDirectDeduct -= parseInt(this.memberInfo.wallet_balance_by_type.topup);
          this.memberInfo.wallet_balance_by_type.topup = '0.00';

          if (amountDirectDeduct < this.memberInfo.wallet_balance_by_type.topup_bonus) {
            this.memberInfo.wallet_balance_by_type.topup_bonus = parseInt(this.memberInfo.wallet_balance_by_type.topup_bonus) - amountDirectDeduct;
            this.memberInfo.wallet_balance_by_type.topup_bonus = Number.parseFloat(this.memberInfo.wallet_balance_by_type.topup_bonus).toFixed(2);
          } else {
            amountDirectDeduct -= parseInt(this.memberInfo.wallet_balance_by_type.topup_bonus);
            this.memberInfo.wallet_balance_by_type.topup_bonus = '0.00';

            this.memberInfo.wallet_balance_by_type.cashback = parseInt(this.memberInfo.wallet_balance_by_type.cashback) - amountDirectDeduct;
            this.memberInfo.wallet_balance_by_type.cashback = Number.parseFloat(this.memberInfo.wallet_balance_by_type.cashback).toFixed(2);
          }
        }
      }
    },

    resetRequired() {
      this.fieldRequired.phone_number = false;
      this.fieldRequired.branch_id = false;
      this.fieldRequired.employee_name = false;
      this.fieldRequired.topup_id = false;
      this.fieldRequired.payment_method = false;
      this.fieldRequired.payment_amount = false;
      this.fieldRequired.amount_direct_increased = false;
      this.othersAmountError.status = true;
      this.othersAmountError.message = "";
    },

    processValidate(isValidate = false, isCheckout = false, isPreview = false) {
      this.resetRequired();
      var isValid = true;
      // if (this.form.phone_number == null || this.form.phone_number == '') {
      //   this.fieldRequired.phone_number = true;
      //   isValid = false;
      // }

      if (isCheckout && (this.form.branch_id == null || this.form.branch_id == 0)) {
        this.fieldRequired.branch_id = true;
        isValid = false;
      }

      if (isCheckout && (this.form.employee_name == null || this.form.employee_name == 0)) {
        this.fieldRequired.employee_name = true;
        isValid = false;
      }

      //validate others amount
      if (isCheckout && this.typeTab == 1 && !isPreview) {
        let totalAmount = 0;

        if (this.form.pos_transaction_type == 2) {
          totalAmount = parseFloat(this.form.sub_total)
        } else {
          this.form.products.map((product) => {
            totalAmount += parseFloat(product.item_price.replace('S$ ', '')) * product.quantity;
          });
        }

        if ((parseFloat(this.form.deducted_cashback) + parseFloat(this.form.deducted_topup)) < totalAmount) {
          let totalOthersAmount = parseFloat(this.form.deducted_cashback) + parseFloat(this.form.deducted_topup);
          this.form.others_amount.map((item) => {
            totalOthersAmount += parseFloat(item.amount);
          });

          if (this.othersAmountError.status) {
            if (totalOthersAmount != totalAmount) {
              this.othersAmountError.status = false;
              this.othersAmountError.message = "Total payment amount invalid.";
              isValid = false;
            }
          }
        }
      }

      if(isValidate && this.typeTab == 2) {
        if(this.form.topup_id == null || this.form.topup_id == 0) {
          this.fieldRequired.topup_id = true
          isValid = false;
        }
        if (this.form.payment_method == null || this.form.payment_method == '' || this.form.payment_method == 0) {
          this.fieldRequired.payment_method = true;
          isValid = false;
        }
        if (this.form.topup_id && (this.form.payment_amount == "" || this.form.payment_amount == 0 || this.form.payment_amount == null)) {
          isValid = false;
          this.message = 'payment amount undefine.';
          this.colorStatus = 'text-danger'
        }
      }

      // if(isValidate && this.typeTab == 3) {
      //    if (this.form.amount_direct_increased <= 0) {
      //     this.fieldRequired.amount_direct_increased = true
      //     isValid = false;
      //   }
      // }

      return isValid;
    },

    changeType(type) {
      this.form.type_cashback = type;
      this.loadCashbackAdjustmentDone = false;
      $('ul.member-detail-voucher-tab >li').removeClass('active');
      $('#nav_item_' + type).addClass("active")
      this.results = {}
      this.showTransactionInfo = false;
      this.message = ''
      this.typeTab = type
      this.showBlockPayment = true;
      this.topup_transaction_id = '';
      this.clearFormData();
    },

    clearFormData() {
      this.showByTotalAmuont = true;
      // this.showByProduct = false;
      var oldPhoneNumber = this.form.phone_number;
      this.form = {
        phone_number: oldPhoneNumber,
        discount_code: "",
        merchant_code: "",
        voucher_code: "",
        branch_id: 0,
        employee_name: '',
        pos_id: "1",
        payment_preview: 1,
        payment_method: 0,
        payment_amount: "",
        top_up_amount: 0,
        topup_id: null,
        amount_direct_increased: 0,
        bonus_amount: "",
        deducted_cashback: 0,
        deducted_topup: 0,
        products: [],
        sub_total: 0,
        total_amount: 0,
        total_before_tax: 0,
        gst: 0,
        cess: 0,
        service_charge: 0,
        take_away_charge: 0,
        pos_transaction_type: "2",
        type_cashback: 1,
        others_amount: [
          {
            amount: 0,
            payment_method: ''
          }
        ],
      };
      this.form.branch_id = this.branchs[0] ? this.branchs[0].value : 0;
      this.form.employee_name = this.staffs[0] ? this.staffs[0].value : 0;
      if (this.typeTab != 2) {
        this.topup_transaction_id = '';
      }
      this.resetRequired()
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    preventUpDown(event) {
      // Check if the up or down arrow key was pressed
      if (event.keyCode === 38 || event.keyCode === 40) {
        // Prevent the default behavior of the key event
        event.preventDefault();
      }
    },
    getStaffs() {
      this.isLoadData = false;
      this.showLoader();
      TestService.getStaffs().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.staffs = response.data.data;
            this.form.employee_name = this.staffs[0] ? this.staffs[0].value : 0
            this.isLoadData = true;
          } else {
            this.message = response.data.result.message;
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },
    getTransaction() {
      this.showTransactionInfo = false;
      this.statusStimulator = false;
      this.isPaymentPreview = false;
      this.results = {};
      this.showLoader();
      let dataForm = {
        phone_number: this.form.phone_number,
        transaction_id: this.topup_transaction_id
      };

      if (this.form.phone_number) {
        this.isMember = true;
      } else {
        this.isMember = false;
        this.memberInfo = {
        'reward_type': this.memberInfo.reward_type ?? 0
      }
      }

      TestService.getTransaction(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.message = '';
            this.colorStatus = 'text-success';
            this.results = response.data.data;
            this.showTransactionInfo = true;
            this.statusStimulator = true;
            this.form.sub_total = this.results.sub_total;
            this.form.total_amount = this.results.total_amount;
            this.form.total_before_tax = this.results.total_before_tax;
            this.form.gst = this.results.gst;
            this.form.cess = this.results.cess;
            this.form.service_charge = this.results.service_charge;
            this.form.take_away_charge = this.results.take_away_charge;
            this.form.discount_code = this.results.discount_code;
            this.form.voucher_code = this.results.voucher_code;
            this.form.deducted_topup = this.results.topup_direct_deduct;
            this.form.deducted_cashback = this.results.cashback_direct_deduct;
            this.form.others_amount = this.results.orthers_amount;
            this.form.pos_id = this.results.pos_id;
          } else {
            this.message = response.data.result.message;
            this.colorStatus = 'text-danger'
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error.response.data.result.message;
          this.colorStatus = 'text-danger'
          this.hideLoader();
        }
      );
    },
    async updateOrder() {
      $(".btn-close-modal").click();
      if(this.processValidate(false, true, false)) {
        this.showTransactionInfo = false
        this.statusStimulator = false
        this.colorStatus = ''
        this.results = {}
        this.showLoader();
        let dataForm = this.form;
        dataForm.transaction_id = this.topup_transaction_id;
        dataForm.payment_preview = false;
        this.isPaymentPreview = false;
        let reloadData = false;
        if(this.showByTotalAmuont) {
          this.form.products = []
        }

        await TestService.updateOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.results = response.data.data
              this.showTransactionInfo = true
              reloadData = true;
            } else {
              this.message = response.data.result.message;
              this.colorStatus = 'text-danger'
            }
            this.hideLoader();
          },
          (error) => {
            this.message = error.response.data.result.message;
            this.colorStatus = 'text-danger'
            this.hideLoader();
          }
        );
        
        if (reloadData) {
          await TestService.getMember(dataForm).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.isMember = true;
                this.message = "";
                this.colorStatus = 'text-success'
                this.memberInfo = response.data.data
                // this.memberInfo.wallet_balance = this.results.new_wallet_balance;
                
                if (this.memberInfo.reward_type && this.memberInfo.reward_type  == 1) {
                  this.formatMemberMoney();
                }
              } else {
                this.message = response.data.result.message;
                this.colorStatus = 'text-danger'
              }
            },
            (error) => {
              this.message = error.response.data.result.message;
              this.colorStatus = 'text-danger'
            }
          );
        }
      }
    },
    async voidTransaction() {
      $(".btn-close-modal").click();
      this.statusStimulator = true;
      this.isPaymentPreview = false;
      this.showLoader();
      let dataForm = {
        phone_number: this.form.phone_number,
        transaction_id: this.topup_transaction_id
      };

      await TestService.voidTransaction(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.message = '';
            this.colorStatus = 'text-success';
            this.showTransactionInfo = false;
            setTimeout(() => {
              this.clearFormData();
            }, 100)
          } else {
            this.message = response.data.result.message;
            this.colorStatus = 'text-danger'
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error.response.data.result.message;
          this.colorStatus = 'text-danger'
          this.hideLoader();
        }
      );

      this.memberInfo = {
        'reward_type': this.memberInfo.reward_type ?? 0
      }
      if(this.statusStimulator && this.form.phone_number) {
        TestService.getMember(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.isMember = true;
              this.message = "";
              this.colorStatus = 'text-success'
              this.memberInfo = response.data.data
              // this.memberInfo.wallet_balance = this.results.new_wallet_balance;
              
              if (this.memberInfo.reward_type && this.memberInfo.reward_type  == 1) {
                this.formatMemberMoney();
              }
            } else {
              this.message = response.data.result.message;
              this.colorStatus = 'text-danger'
            }
          },
          (error) => {
            this.message = error.response.data.result.message;
            this.colorStatus = 'text-danger'
          }
        );
      }
    },
    confirmUpdate() {
      $(".btn-confirm-modal").click();
    },
    confirmDelete() {
      $(".btn-confirm-modal-delete").click();
    }
  },
};
</script>
<style scoped>
.page-header {
  overflow: auto;
}

.welcome-minty {
  font-size: 25px;
  font-weight: 600;
  color: #133067 ;
  font-family: "Roboto Slab", sans-serif !important;
}

.lable-input {
  font-size: 16px;
  font-weight: 500;
  color: #133067  !important;
  font-family: "Roboto Slab", sans-serif;
}

.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.text-success {
  color: #28A745 !important;
}

.member-info {
  font-weight: bold !important;
}

.member-detail-voucher-tab {
    margin: 0px;
    padding: 0px;
    width: 100% !important;
    margin-top: 20px;
    display: flex;
    overflow-x: auto;
    padding-bottom: 5px;
}

.member-detail-voucher-tab::-webkit-scrollbar {
  height: 7px;
}

.member-detail-voucher-tab::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.member-detail-voucher-tab::-webkit-scrollbar-thumb {
  background: #888; 
}

.member-detail-voucher-tab::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.member-detail-voucher-tab li {
    list-style: none;
    float: left;
    width: 25%;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    min-width: 200px;
}

.member-detail-voucher-tab li.active {
    border-bottom: 2px solid #133067 ;
    color: #133067 ;
}
.member-detail-voucher-tab li {
    border-bottom: 2px solid rgb(238, 234, 234);
}

@media (max-width: 500px) {
  .card-body {
    padding-top: 0px !important;
  }

  .card-body>.welcome-minty {
    padding-bottom: 12px !important;
  }
}

.pdl-30 {
  padding-left: 30px !important;
}
</style>
