<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">Report Group</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button
          class="mx-2 btn btn-sm btn-background"
          @click="addGroupAndType(true)"
        >
          Add
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="d-flex justify-content-between mt-3">
              <input
                class="nosubmit"
                type="search"
                id="keyword_search"
                extraClass
                name="keyword_search"
                placeholder="Search ..."
                v-model="search_keyword"
                @input="onChangesearchKeyword"
              />
            </div>
            <div class="row mt-3">
              <div class="col-12" v-if="isLoadData">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-left font-weight-bolder w-75">
                              {{ $t("Group") }}
                            </th>
                            <th class="w-25"></th>
                          </tr>
                        </thead>
                        <tbody v-if="typeList?.length">
                          <template
                            v-for="(type, index) in typeList"
                            :key="index"
                          >
                            <tr style="background-color: #f5f5f5">
                              <td
                                class="text-left text-xss w-15 font-weight-bold cl-22"
                              >
                                {{ type.group_name }}
                              </td>
                              <td class="w-5 align-middle text-end text-sm">
                                <a
                                  class="btn btn-link text-dark px-2 mb-0"
                                  @click="addGroupAndType(true, type.id)"
                                >
                                  <i
                                    class="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Edit
                                </a>
                                <a
                                  class="btn btn-link text-danger text-gradient px-3 mb-0"
                                  href="javascript:;"
                                  @click="deleteGroupAndType(true, type.id)"
                                >
                                  <i
                                    class="far fa-trash-alt me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Delete
                                </a>
                                <a
                                  class="btn btn-link text-info px-2 mb-0"
                                  @click="addGroupAndType(false, type.id, true)"
                                >
                                  <i
                                    class="fas fa-solid fa-plus text-info me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Add
                                </a>
                              </td>
                            </tr>
                            <tr
                              v-for="(group, index2) in type.groups"
                              :key="index2"
                            >
                              <td class="text-left text-xss w-15 ps-5">
                                <span class="ps-3">{{ group.group_name }}</span>
                              </td>
                              <td class="w-5 align-middle text-end text-sm">
                                <a
                                  class="btn btn-link text-dark px-2 mb-0"
                                  @click="addGroupAndType(false, group.id)"
                                >
                                  <i
                                    class="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Edit
                                </a>
                                <a
                                  class="btn btn-link text-danger text-gradient px-3 mb-0"
                                  href="javascript:;"
                                  @click="deleteGroupAndType(false, group.id)"
                                >
                                  <i
                                    class="far fa-trash-alt me-2"
                                    aria-hidden="true"
                                  ></i
                                  >Delete
                                </a>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="10" class="text-center">
                              No group & type available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
      <ModalConfirmDelete
        message="Are you sure you want to delete?"
        :process-yes="handleDeleteGroupType"
      />
      <AddGroupAndTypeModal
        :keyReload="keyReloadAddGroupAndType"
        :idSelected="idSelected"
        :isType="isType"
        :isAddGroup="isAddGroup"
        @reload-data="reloadData"
      ></AddGroupAndTypeModal>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "@vueform/multiselect/themes/default.css";
import ReportGroupService from "../../services/product/reportGroup.service";
import $ from "jquery";
import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";
import AddGroupAndTypeModal from "@/components/AddGroupAndType.vue";

export default {
  name: "campaign-list",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirmDelete,
    AddGroupAndTypeModal,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      isLoadData: true,
      typeList: [],
      isType: true,
      idSelected: 0,
      isAddGroup: false,
      search_keyword: "",
      searchTimerId: null,
      keyReloadAddGroupAndType: "key-reload-add-group-and-type",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    addGroupAndType(isType, idSelected = 0, isAddGroup = false) {
      this.isType = isType;
      this.idSelected = idSelected;
      this.isAddGroup = isAddGroup;
      this.keyReloadAddGroupAndType = Math.random();
      $("#show-modal-add-group-and-type").click();
    },

    deleteGroupAndType(isType, idSelected = 0) {
      this.isType = isType;
      this.idSelected = idSelected;
      $(".btn-confirm-modal-delete").click();
    },

    reloadData(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.isType = true;
      this.idSelected = 0;
      this.isAddGroup = false;
      this.setTimeOutNotification();
      this.getList();
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    getList() {
      this.isLoadData = false;
      this.showLoader();
      var dataForm = {
        keyword: this.search_keyword,
      };
      ReportGroupService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.typeList = response.data.data;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
      this.hideLoader();
    },

    handleDeleteGroupType() {
      this.showLoader();
      ReportGroupService.delete(this.idSelected).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reloadData(
              "Deleted " + (this.isType ? "type" : "group") + " successfully"
            );
            $(".btn-close-modal").click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    onChangesearchKeyword(event) {
      this.search_keyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList();
      }, 1000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.css-icon {
  position: absolute;
  left: 17px !important;
  font-size: 18px !important;
  top: 7px !important;
}

.label-header {
  font-size: 16px;
  color: #222;
  font-weight: 500;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  max-width: 350px;
  border-bottom: 1px solid #d2d2d2;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 1px solid #72959d;
}

.dropdown:not(.dropdown-hover) .dropdown-menu.show {
  margin-top: 3.5rem !important;
}

.form-check {
  padding: 0px;
}
.cl-22 {
  color: #222 !important;
}
</style>
