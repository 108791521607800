import axiosInstance from "./api";
import TokenService from "./token.service";
import router from '@/router'
const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      }
      var merchantId = localStorage.getItem('MERCHANT_ID', '');
      var merchantCode = localStorage.getItem('MERCHANT_CODE', '');
      if(merchantCode != "") {
        config.headers["MerchantCode"] = merchantCode;  // for Spring Boot back-end
      }
      if(merchantId != "") {
        config.headers["MerchantId"] = merchantId;  // for Spring Boot back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response.status === 401 && !originalConfig._retry) {
        TokenService.removeUser();
        if(router && router.currentRoute && router.currentRoute.value && router.currentRoute.value.name != "Stock") {
          router.push('/sign-in');
        }
      }
      // if (originalConfig.url !== "/auth/login" && err.response) {
      //   // Access Token was expired
      //   if (err.response.status === 401 && !originalConfig._retry) {
      //     originalConfig._retry = true;

      //     try {
      //       const rs = await axiosInstance.post("/auth/refreshtoken", {
      //         refreshToken: TokenService.getLocalRefreshToken(),
      //       });

      //       const { accessToken } = rs.data;

      //       store.dispatch('auth/refreshToken', accessToken);
      //       TokenService.updateLocalAccessToken(accessToken);

      //       return axiosInstance(originalConfig);
      //     } catch (_error) {
      //       return Promise.reject(_error);
      //     }
      //   }
      // }

      return Promise.reject(err);
    }
  );
};

export default setup;