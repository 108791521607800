<template>
    <div>
        <div class="row mb-2 pe-4">
            <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
                <h4
                class="
                    col-lg-10 col-md-10 col-12
                    text-back
                    d-flex
                    align-items-center
                    ps-2
                "
                >
                <i
                    class="fa fa-chevron-circle-left pe-2"
                    aria-hidden="true"
                    @click="backSmsSetup()"
                    style="cursor: pointer"
                ></i>
                <div @click="backSmsSetup()" style="cursor: pointer">BACK</div>
                </h4>
            </div>
        </div>
      <div class="row mb-2 pe-4">
        <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
          <div class="color-common">
            <h3 class="title-page mb-0 ps-3">SMS Payment History</h3>
          </div>
        </div>
      </div>
      <div class="page-header align-items-start min-vh-100 my-transacions">
        <span class="mask bg-white"></span>
        <div class="container">
          <div class="row">
            <div class="notifications">
              <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                {{ error }}
              </material-alert>
            </div>
            <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="card-custom">
                    <div class="px-0 pb-2">
                      <div class="table-responsive p-0 custom-table">
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th class="text-center font-weight-bolder w-4">
                                Date Time
                              </th>
                              <th class="text-center font-weight-bolder w-4">
                                Payment Amount
                              </th>
                              <th class="text-center font-weight-bolder w-4">
                                SMS Credit
                              </th>
                              <th class="text-center font-weight-bolder w-4">
                                Payment Stripe Id
                              </th>
                              <!-- <th class="text-center font-weight-bolder w-4">
                                Invoice
                              </th> -->
                            </tr>
                          </thead>
                          <tbody v-if="data.length">
                            <tr v-for="(value, key) in data" :key="key">
                              <td class="text-center text-xss" >
                                {{ value.created_date }}
                              </td>
                              <td class="text-center text-xss" >
                                S$ {{ value.price }}
                              </td>
                              <td class="text-center text-xss" >
                                {{ value.quantity }} SMS Credits
                              </td>
                              <td class="text-center text-xss" >
                                {{ value.payment_id }}
                              </td>
                              <!-- <td class="text-center text-xss" >
                                <material-button class="btn-background">
                                    View Invoice
                                </material-button>
                              </td> -->
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="5" class="text-center">
                                No item available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <pagination
                            v-if="pagination.last_page > 1"
                            :pagination="pagination"
                            :current_page="pagination.current_page"
                            :last_page="pagination.last_page"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar v-if="snackbar === 'danger' || snackbar === 'success'" title="" date="" :description="message"
        :icon="{ component: 'campaign', color: 'white' }" :color="snackbar" :close-handler="closeSnackbar" />
    </div>
    <material-loading :active="loaderActive" />
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import "bootstrap/js/dist/modal";
  import "@vuepic/vue-datepicker/dist/main.css";
  import SmsSetupService from "../../../services/smsSetup.service";
  import TokenService from "../../../services/token.service";
  import Pagination from "@/components/Pagination.vue";
  
  export default {
    name: "find-store",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      Pagination
    },
    data() {
      return {
        message: "",
        snackbar: null,
        loaderActive: false,
        data: [],
        isLoadData: false,
        token: TokenService.getLocalAccessToken(),
        merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
        pagination: {},
        page: 1,
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
  
      closeSnackbar() {
        this.snackbar = null;
      },
  
      setTimeOutNotification() {
        setTimeout(() => {
          this.snackbar = null;
        }, 3000);
      },
  
      getList() {
        this.isLoadData = false;
        this.data = [];
        this.showLoader();
        SmsSetupService.getSmsPaymentHistory().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.data = response.data.data.items;
              this.pagination = response.data.data;
              this.page = this.pagination.current_page;
              this.$router.push({
                query: Object.assign({}, this.$route.query, { page: this.page }),
              });
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      },

      showLoader() {
        this.loaderActive = true;
      },
      hideLoader() {
        this.loaderActive = false;
      },
      addSmsCredit(item) {
        console.log(item);
        this.showLoader();
        // TopUpService.detail(topUpId).then(
        //   (response) => {
        //     if (response.data.result.isSuccess) {
             
        //     } else {
        //       this.snackbar = "danger";
        //       this.message = response.data.result.message;
        //       this.setTimeOutNotification();
        //     }
        //     this.hideLoader();
        //   },
        //   (error) => {
        //     this.snackbar = "danger";
        //     this.message = error;
        //     this.hideLoader();
        //     this.setTimeOutNotification();
        //   }
        // );
      },
      backSmsSetup() {
            this.$router.push("/sms-setup");
        },
    },
  };
  </script>
  <style scoped>
  .text-title {
    color: #133067 !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .text-label {
    color: #5b848d !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    font-family: "Roboto Slab", sans-serif !important;
  }
  
  .checkbox-auto-save label {
    padding-top: 11px !important;
  }
  
  .custom-name {
    word-break: break-all;
    max-width: 200px;
    white-space: normal;
  }
  
  .wrap-action .form-check:first-child {
    padding-left: 2px;
  }
  
  .multiselect {
    min-width: 135px;
  }
  
  .r-no-expiry .form-check {
    padding-left: 0 !important;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 50%;
      margin: 1.75rem auto;
    }
  }
  .text-back {
    color: black !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    font-family: "Roboto Slab", sans-serif !important;
    }
  </style>