<template>
  <div>
    <div class="mb-2 pe-4">
      <div class="d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">BRANCH : {{ branchSelect.branch_name }}</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="mt-4">
              <div class="d-flex align-items-center waiting-time">
                <Multiselect
                  v-model="waitingTimeSelect"
                  :options="waitingTime"
                  placeholder="Select Waiting Time"
                  :canClear="false"
                  @change="changeWaitingTime"
                />
                <material-button class="ms-3 btn btn-sm btn-background" @click="stopOrdering()"
                  >{{ branchSelect.stop_ordering ? 'Start Ordering' : 'Stop Ordering' }}</material-button
                >
              </div>
            </div>
            <div class="mt-5">
              <div class="row d-flex justify-content-center">
                <ul id="general-tab" class="nav nav-tabs">
                  <li class="nav-item nav-first">
                    <a
                      id="nav_item_1"
                      class="nav-link"
                      :class="{ active: type == 1 }"
                      @click="changeTab(1)"
                      >Preparing</a
                    >
                    <div class="count-order" v-if="type == 1 && orders.length > 0">{{ orders.length }}</div>
                  </li>
                  <li class="nav-item">
                    <a id="nav_item_2" class="nav-link" @click="changeTab(2)"
                      >Ready</a
                    >
                  </li>
                  <li class="nav-item">
                    <a id="nav_item_0" class="nav-link" @click="changeTab(0)"
                      >Pending</a
                    >
                  </li>
                </ul>
              </div>
              <div class="row mt-5">
                <div class="px-0 d-flex justify-content-end">
                  <input
                    class="nosubmit"
                    type="search"
                    v-model="searchKeyword"
                    id="keyword_search"
                    extraClass
                    name="keyword_search"
                    placeholder="Search ..."
                    @input="onChangesearchKeyword"
                  />
                </div>
                <div class="px-0 mt-4">
                  <div class="table-responsive p-0 custom-table">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-center font-weight-bolder w-5"></th>
                          <th class="text-center font-weight-bolder">No.</th>
                          <th class="text-center font-weight-bolder">Order Time</th>
                          <th v-if="type == 2" class="text-center font-weight-bolder">Ready Time</th>
                          <th class="text-center font-weight-bolder">Total</th>
                          <th class="text-center font-weight-bolder">
                            Details
                          </th>
                          <th class="text-center font-weight-bolder w-20"></th>
                        </tr>
                      </thead>
                      <tbody v-if="orders.length">
                        <tr v-for="(order, index) in orders" :key="index" :id="'order-' + order.transaction_id">
                          <td>
                            <i class="material-icons-round opacity-10 mt-1 show-order-info" @click="orderDetail(order)"
                              >add</i
                            >
                          </td>
                          <td
                            class="text-center text-xss font-weight-bold cl-22"
                          >
                            {{ order.receipt_no }}
                          </td>
                          <td class="text-center text-xss cl-22">
                            {{ order.created_at_format }}
                          </td>
                          <td v-if="type == 2"  class="text-center text-xss cl-22">
                            {{ order.updated_at_format }}
                          </td>
                          <td class="text-center text-xss cl-22">
                            {{ order.payment_amount_format }}
                          </td>
                          <td class="text-center text-xss cl-22">
                            {{ order.member_name }} <br />
                            {{ order.phone_number }}
                          </td>
                          <td class="text-center text-xss cl-22">
                            <material-button
                              v-if="type == 1"
                              class="btn-confirm-order"
                              @click="moveOrderToReady(order)"
                            >
                              <i class="material-icons-round opacity-10 mt-1"
                                >local_mall</i
                              >
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else class="no-data">
                        <tr>
                          <td colspan="10" class="text-center">
                            Empty, no order.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
  <ModalConfirm :message="messageConfirm" :process-yes="handleMoveOrder" />
  <div>
    <button
      style="display: none"
      type="button"
      id="action-group-stock"
      data-bs-toggle="modal"
      data-bs-target="#modalCheckOrderPasscodePage"
    ></button>
    <div class="modal fade" id="modalCheckOrderPasscodePage" tabindex="-1" aria-hidden="true" data-bs-backdrop='static'>
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">PASSCODE REQUIRED</h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="control-label" style="margin-left: 0px"
                ><strong> Please enter passcode to continue.</strong></label
              >
              <div>
                <material-input
                  v-model="passcodeOrderValue"
                  extraClass="ml-5"
                  placeholder="Enter passcode"
                />
                <span class="is-invalid mt-1" v-if="passcodeIncorrect"
                  >{{ passcodeIncorrect }}</span
                >
              </div>
            </div>
          </div>
          <!-- Modal content-->
          <div class="modal-footer justify-content-center">
            <button
              style="display: none"
              type="button"
              class="btn btn-primary btn-close-modal btn-sm"
              data-bs-dismiss="modal"
            ></button>
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="checkPasscode"
            >
              Click Here to Start
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import SettingStoreService from "../../services/settingStore.service";
import Multiselect from "@vueform/multiselect";
import ModalConfirm from "@/components/ModalConfirm.vue";
import TokenService from "../../services/token.service";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialInput from "@/components/MaterialInput.vue";
import $ from "jquery";

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Multiselect,
    ModalConfirm,
    MaterialInput
  },
  data() {
    return {
      isLoadDone: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      type: 1,
      branchSelect: {},
      branchCode: "",
      merchantId: 0,
      orders: [],
      waitingTime: [],
      waitingTimeSelect: 0,
      interval: null,
      searchKeyword: "",
      searchTimerId: null,
      messageConfirm: "",
      orderSelect: {},
      orderInfo: {},
      token: TokenService.getLocalAccessToken(),
      passcodeOrderValue: "",
      passcodeIncorrect: ""
    };
  },
  created() {
    this.branchCode = this.$route.params.branch_code ?? '';
    this.merchantId = this.$route.params.merchant_id ?? 0;
  },
  mounted() {
    if (this.token) {
      this.getOrderByBranch(true);
    } else {
      this.passcodeIncorrect = "";
      $('#action-group-stock').click();
    }
    this.interval = setInterval(() => {
      if (this.isLoadDone) {
        $('.order-detail').remove();
        $('.show-order-info').html('add');
        this.getOrderByBranch();
      }
    }, 15000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    changeTab(type) {
      this.searchKeyword = "";
      this.orders = {};
      this.type = type;
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_" + type).addClass("active");
      this.getOrderByBranch();
    },

    checkPasscode() {
      this.passcodeIncorrect = "";
      this.isLoadData = false;
      var dataForm = {
        merchant_id: this.merchantId,
        branch_code: this.branchCode,
        passcode: this.passcodeOrderValue
      };

      SettingStoreService.checkPasscodeOrderPage(dataForm).then(
      (response) => {
          if (response.data.result.isSuccess) {
            this.passcodeIncorrect = "";
            this.getOrderByBranch(true);
            $(".btn-close-modal").click();
          } else {
            this.passcodeIncorrect = "Passcode incorrect!";
          }
      },
      (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
      }
      );
    },

    changeWaitingTime(waitingTime) {
      if (this.branchCode) {
        this.showLoader();
        var dataForm = {
          merchant_id: this.merchantId,
          branch_code: this.branchCode,
          waiting_time: waitingTime
        };

        SettingStoreService.changeWaitingTime(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Change success";
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },

    getOrderByBranch(isFirstLoad = false) {
      if (this.branchCode) {
        this.showLoader();
        var dataForm = {
          merchant_id: this.merchantId,
          branch_code: this.branchCode,
          type: this.type,
          search_keyword: this.searchKeyword,
        };

        SettingStoreService.getOrderByBranch(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.branchSelect = response.data.data.branch;
              this.waitingTimeSelect = this.branchSelect.waiting_time;
              this.orders = response.data.data.orders;
              this.waitingTime = response.data.data.waiting_time;

              if (isFirstLoad) {
                this.isLoadDone = true;
              }
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getOrderByBranch();
      }, 1000);
    },

    moveOrderToReady(order) {
      this.orderSelect = order;
      this.messageConfirm =
        "Confirm to change the status for order " +
        order.receipt_no +
        " to ready for collection?";
      $(".btn-confirm-modal").click();
    },

    handleMoveOrder() {
      this.showLoader();
      $(".btn-close-modal").click();

      if (this.orderSelect && this.orderSelect.transaction_id) {
        var dataForm = {
          merchant_id: this.merchantId,
          branch_code: this.branchCode,
          transaction_id: this.orderSelect.transaction_id,
        };

        SettingStoreService.moveOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.orderSelect = {};
              this.snackbar = "success";
              this.message = "Change success";
              this.setTimeOutNotification();
              this.getOrderByBranch();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    orderDetail(order) {
      var orderDetailElement = $('.order-detail.order-' + order.transaction_id)

      if (orderDetailElement.length) {
        orderDetailElement.remove();
        $('#order-' + order.transaction_id + ' i.show-order-info').html('add');
      } else {
        this.showLoader();
        var dataForm = {
          merchant_id: this.merchantId,
          branch_code: this.branchCode,
          transaction_id: order.transaction_id,
        };

        SettingStoreService.orderDetail(dataForm).then(
          async (response) => {
            if (response.data.result.isSuccess) {
              $('#order-' + order.transaction_id + ' i.show-order-info').html('remove');
              $('#order-' + order.transaction_id).after(response.data.data);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    stopOrdering() {
      if (!this.branchSelect.stop_ordering) {
        if(!confirm("Confirm to stop the ordering for today?")){
          return;
        }
      }

      if (this.branchCode) {
        this.showLoader();
        var dataForm = {
          merchant_id: this.merchantId,
          branch_code: this.branchCode,
        };

        SettingStoreService.stopOrdering(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              if (this.branchSelect.stop_ordering) {
                this.branchSelect.stop_ordering = 0;
              } else {
                this.branchSelect.stop_ordering = 1;
              }

              this.snackbar = "success";
              this.message = "Change success";
              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
table thead th:not(:first-child) {
  min-width: 150px;
}

.page-header .container {
  color: #222 !important;
}

.nav-first {
  position: relative;
}
.nav-link {
  font-weight: bold;
}

.nav-first .count-order {
  position: absolute;
  top: 5px;
  right: 5px;
  border: 1px solid red;
  background: red;
  color: #ffffff;
  border-radius: 5px;
  padding: 0px 7px;
}


.multiselect {
  margin: 0 !important;
  max-width: 400px !important;
}

#general-tab .nav-item {
  width: 25%;
  text-align: center;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  width: 100%;
  max-width: 500px;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.cl-22 {
  color: #222 !important;
}

.show-order-info {
  font-size: 28px !important;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}
.btn-confirm-order {
  min-width: 50px !important;
  padding: 5px 10px !important;
  color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-confirm-order:hover {
  background: #28a745 !important;
  color: #ffffff !important;
}

tbody:not(.no-data) > tr:nth-child(2n + 1) {
  background: #f0f2f5;
}

tbody:not(.no-data) > tr:hover {
  background: #ebecee;
}

@media (max-width: 500px) {
  .waiting-time {
    display: block !important;
    text-align: right;
  }

  .waiting-time button {
    margin-top: 15px;
  }

  #general-tab .nav-item {
    width: calc(100% / 3) !important;
  }
}
</style>
