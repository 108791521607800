<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddCategory"
      id="show-modal-add-category"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddCategory"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ isEditCategory ? 'Edit Category' : 'Add Category' }}</h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="w-100" :class="{ 'mb-3': !fieldRequired.isCategoryNameRequire}">
              <label class="fw-bold" for="">Name
                <span class="text-danger">*</span>
              </label>
              <material-input
                v-model="category.name"
                type="text"
                id="category_name"
                name="category_name"
                placeholder="Enter name"
              />
            </div>
            <div class="w-100 mb-3">
              <span
                class="is-invalid"
                v-if="fieldRequired.isCategoryNameRequire"
                >The field is required.</span
              >
            </div>
            <div class="w-100 mb-3">
              <div class="col-lg-12 col-md-12 col-12" style="display: inline-flex;align-items: center;">
                Featured Category
                  <material-checkbox
                   @change="changeFeature(category.is_feature)"
                    v-model="category.is_feature"
                    :checked="category.is_feature"
                    name="sms"
                    class="align-items-center px-3"
                  ></material-checkbox>
                </div>
            </div>
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Description</label>
              <material-textarea
                rows="6"
                v-model="category.details"
                id="category_description"
                name="category_description"
                placeholder="Enter description"
              />
            </div>
            <div class="w-100" :class="{ 'mb-3': !fieldRequired.isDefaultPriceRequire}">
              <label class="fw-bold" for="">Type
                <span class="text-danger">*</span>
              </label>
              <Multiselect
                v-model="category.type"
                :options="types"
                placeholder="Select type"
                :canClear="false"
                :disabled="true"
              />
            </div>
            <!-- <div class="w-100" :class="{ 'mb-3': !fieldRequired.isDefaultPriceRequire}">
              <label class="fw-bold" for="">Default Price
                <span class="text-danger">*</span>
              </label>
              <Multiselect
                v-model="category.price_key_default"
                :options="priceList"
                placeholder="Select price"
                :canClear="false"
              />
            </div>
            <div class="w-100 mb-3">
              <span
                class="is-invalid"
                v-if="fieldRequired.isDefaultPriceRequire"
                >The field is required.</span
              >
            </div> -->
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Category image</label>
              <div class="dowload-img" v-show="urlImagePreview || category.image">
                <div id="preview" style="position: relative">
                  <img
                    class="image-url"
                    v-if="urlImagePreview || category.image"
                    :src="urlImagePreview || category.image"
                  />
                  <i
                    class="fa fa-times icon-close-sidenav-main danger"
                    v-if="urlImagePreview || category.image"
                    style="
                      position: absolute;
                      color: #f44335;
                      font-size: 20px;
                      right: 5px;
                      top: 8px;
                    "
                    aria-hidden="true"
                    @click="clearImage()"
                  ></i>
                  <div class="btn-edit-img">
                    <i
                      class="fas fa-pencil-alt text-dark"
                      v-if="urlImagePreview || category.image"
                      aria-hidden="true"
                      @click="uploadFile()"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                class="dowload-img"
                @click="uploadFile()"
                v-show="!urlImagePreview && !category.image"
              >
                <material-input
                  style="display: none"
                  type="file"
                  id="imageCategory"
                  @change="handleFileUpload($event)"
                  accept="image/*"
                />
                <i class="material-icons css-icon-upload">backup</i>
                <h6>Click here to upload an image.</h6>
              </div>
              <div
                class="is-invalid d-flex align-items-center pe-2"
                v-if="fieldRequired.isImageRequire"
              >
                {{ messageImageError }}
              </div>
            </div>
            <div
              class="w-100 mb-3"
            >
              <label class="fw-bold" for=""
                >Status
              </label>
              <div class="d-flex">
                <MaterialRadio
                  id="status_1"
                  value="1"
                  class="ps-0"
                  v-model="category.status"
                >
                  Active
                </MaterialRadio>
                <MaterialRadio
                  id="status_2"
                  value="0"
                  v-model="category.status"
                >
                  Inactive
                </MaterialRadio>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex mt-3">
              <material-button
                v-if="!isEditCategory"
                class="mx-2 btn btn-sm btn-background"
                @click="saveCategory()"
              >
                Add category
              </material-button>
              <material-button
                v-else
                class="mx-2 btn btn-sm btn-background"
                @click="editCategory()"
              >
                Save
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MenusetService from "../services/product/menuset.service";
import Multiselect from "@vueform/multiselect";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";

export default {
  name: "add-category",
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    MaterialLoading,
    Multiselect,
    MaterialCheckbox,
    MaterialRadio,
    MaterialSnackbar
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    categoryType: {
      type: Number,
      required: true,
    },
    isEditCategory: {
      type: Boolean,
      default: false
    },
    categoryId: {
      type: Number,
      default: 0
    },
    setId: {
      type: Number,
      default: 0
    },
    isFeature: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      snackbar: null,
      message: "",
      urlImagePreview: null,
      isErrorUploadImage: false,
      isImageTooSmall: false,
      messageImageError: "",
      fieldRequired: {},
      loaderActive: false,
      category: {
        id: 0,
        name: "",
        details: "",
        image: null,
        // price_key_default: '',
        set_id: this.setId,
        type: this.categoryType,
        is_feature: 0,
        status: 1
      },
      priceList: [],
      types: [
        {
          value: 1,
          label: "Main"
        },
        {
          value: 2,
          label: "Modifiers"
        }
      ]
    };
  },
  created() {
    this.getPriceList();
  },
  methods: {
    uploadFile() {
      $("#imageCategory").click();
    },

    changeFeature(isFeature = 0) {
      if (typeof isFeature == "boolean") {
        this.category.is_feature = isFeature ? 1 : 0;
      }
    },

    clearImage() {
      this.category.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.category.image = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.isErrorUploadImage = false;
          this.fieldRequired.isImageRequire = true;
          this.messageImageError = `File must be an image.`;
        } else {
          this.category.image = file;
          this.urlImagePreview = URL.createObjectURL(file);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
                this.messageImageError = "";
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.category.image = null;
        this.urlImagePreview = null;
        this.isErrorUploadImage = false;
        this.messageImageError = "";
      }
    },

    async getPriceList() {
      this.showLoader();
      var dataForm = {
        set_id: this.setId,
        type: 'dropdown'
      };

      await MenusetService.getPriceList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.priceList = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    getCategoryDetail() {
      if (this.categoryId) {
        this.showLoader();
        MenusetService.categoryDetail(this.categoryId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              let category = response.data.data;
              this.category = category;
              this.category.name = category.second_name;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    saveCategory() {
      if (this.processValidate()) {
        this.showLoader();
        MenusetService.createCategory(this.category).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.category = {
                name: "",
                details: "",
                image: null,
                set_id: this.setId,
                is_feature: this.isFeature,
                type: this.category.type,
                status: 1
              }
              this.urlImagePreview = null;
              $("#imageCategory").val('');
              this.$emit('reload-list-category', 'Add Category Success');
              $(".btn-close-modal").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    editCategory() {
      if (this.processValidate()) {
        this.showLoader();
        MenusetService.updateCategory(this.category).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.category = {
                name: "",
                details: "",
                image: null,
                set_id: this.setId,
                is_feature: this.isFeature,
                type: this.category.type,
                status: 1
              }
              this.urlImagePreview = null;
              $("#imageCategory").val('');
              this.$emit('reload-list-category', 'Edit Category Success');
              $(".btn-close-modal").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    resetRequired() {
      this.fieldRequired.isCategoryNameRequire = false;
      this.fieldRequired.isDefaultPriceRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.category.name == "" || this.category.name == null) {
        this.fieldRequired.isCategoryNameRequire = true;
        isValid = false;
      }

      // if (this.category.price_key_default == "" || this.category.price_key_default == null) {
      //   this.fieldRequired.isDefaultPriceRequire = true;
      //   isValid = false;
      // }

      return isValid;
    },

    clearData() {
      this.resetRequired();
      this.category = {
        id: 0,
        name: "",
        details: "",
        image: null,
        // price_key_default: '',
        set_id: this.setId,
        is_feature: this.isFeature,
        type: this.categoryType,
        status: 1
      };
      this.urlImagePreview = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    async keyReload() {
      this.clearData();
      await this.getPriceList();
      this.getCategoryDetail();
    },
    categoryType(val) {
      this.category.type = val ?? 1; // 1 - Main, 2 - Modifiers
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.font-18 {
  font-size: 18px;
}

.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 300px;
}

.css-icon-upload {
  font-size: 45px !important;
  margin-top: 6rem;
}

.image-url {
  width: 100%;
  height: 300px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}
</style>
