<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddVenue"
      id="show-modal-add-venue"
    ></material-button>
    <div class="modal fade" id="modalAddVenue" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ isEditVenue ? "Edit Service" : "Add Service" }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="w-100">
              <label class="fw-bold" for=""
                >Service ID: {{ venue.venue_id_text }}
              </label>
            </div>
            <div
              class="w-100"
              :class="{ 'mb-3': !fieldRequired.isVenueNameRequire }"
            >
              <label class="fw-bold" for=""
                >Service Name
                <span class="text-danger">*</span>
              </label>
              <material-input
                v-model="venue.name"
                type="text"
                id="venue_name"
                name="venue_name"
                placeholder="Enter service name"
              />
            </div>
            <div class="w-100 mb-3">
              <span class="is-invalid" v-if="fieldRequired.isVenueNameRequire"
                >The field is required.</span
              >
            </div>
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Branch</label>
              <Multiselect
                v-model="venue.branchIds"
                mode="tags"
                :searchable="true"
                :options="branches"
                placeholder="Select branches"
                class="mt-2"
                :canClear="false"
                :filterResults="false"
                @search-change="getDropdownBranches"
              />
            </div>
            <div
              class="w-100 d-flex align-items-center"
              :class="{ 'mb-3': !fieldRequired.isImageRequire }"
            >
              <label class="fw-bold" for=""
                >Service image <span class="text-danger">*</span></label
              >
              <div class="mt-2 p-0 ms-5">
                <div class="dowload-img" v-if="urlImagePreview || venue.image">
                  <div id="preview" style="position: relative">
                    <img
                      class="image-url"
                      v-if="urlImagePreview || venue.image"
                      :src="urlImagePreview || venue.image"
                    />
                    <i
                      class="fa fa-times icon-close-sidenav-main danger"
                      v-if="urlImagePreview || venue.image"
                      style="
                        position: absolute;
                        color: #f44335;
                        font-size: 20px;
                        right: 5px;
                        top: 8px;
                      "
                      aria-hidden="true"
                      @click="clearImage()"
                    ></i>
                    <div class="btn-edit-img">
                      <i
                        class="fas fa-pencil-alt text-dark"
                        v-if="urlImagePreview || venue.image"
                        aria-hidden="true"
                        @click="uploadFile()"
                      ></i>
                    </div>
                  </div>
                </div>
                <div
                  class="dowload-img"
                  @click="uploadFile()"
                  v-show="!urlImagePreview && !venue.image"
                >
                  <material-input
                    style="display: none"
                    type="file"
                    id="imageVenue"
                    @change="handleFileUpload($event)"
                    accept="image/*"
                  />
                  <i class="material-icons css-icon-upload">backup</i>
                </div>
              </div>
            </div>
            <div
              class="is-invalid d-flex align-items-center pe-2 mb-3"
              v-if="fieldRequired.isImageRequire"
            >
              {{ messageImageError }}
            </div>
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Min Booking In Advance Time</label>
              <div class="d-flex">
                <material-input v-model="venue.min_advance_time" type="text" />
                <Multiselect
                  v-model="venue.min_advance_time_unit"
                  :options="timeUnit"
                  class="ms-3"
                  :canClear="false"
                />
              </div>
            </div>
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Max Booking In Advance Time</label>
              <div class="d-flex">
                <material-input v-model="venue.max_advance_time" type="text" />
                <Multiselect
                  v-model="venue.max_advance_time_unit"
                  :options="timeUnit"
                  class="ms-3"
                  :canClear="false"
                  :disabled="'disabled'"
                />
              </div>
            </div>
            <div
              class="d-flex justify-content-between align-items-center"
              :class="{ 'mb-3': !fieldRequired.isVenueTypeRequire }"
            >
              <label class="fw-bold mb-0" for=""
                >Type and Price <span class="text-danger">*</span></label
              >
              <div class="input-group mw-300px">
                <div
                  class="form-outline w-100"
                  style="width: calc(100% - 75px) !important"
                >
                  <input
                    type="text"
                    name="venue_type_name"
                    id="venue_type_name"
                    class="form-control"
                    style="
                      border: 1px solid #d2d6da;
                      padding: 8px !important;
                      border-top-right-radius: 0;
                      border-bottom-right-radius: 0;
                    "
                    placeholder="Name the Type"
                    @keydown.enter="addVenueType()"
                  />
                </div>
                <div
                  type="button"
                  class="btn mb-0"
                  style="border: 1px solid #d2d6da"
                  @click="addVenueType()"
                >
                  Add
                </div>
              </div>
            </div>
            <div class="w-100 mb-3">
              <span class="is-invalid" v-if="fieldRequired.isVenueTypeRequire"
                >The field is required.</span
              >
            </div>
            <div
              class="table-responsive p-0 custom-table mb-3"
              v-if="venue.types.length"
            >
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th class="text-center font-weight-bolder">Name</th>
                    <th class="text-center font-weight-bolder">Description</th>
                    <th class="text-center font-weight-bolde w-20">Price</th>
                    <th class="text-center font-weight-bolde w-5"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(type, index) in venue.types" :key="index">
                    <td class="fw-bold">{{ type.name }}</td>
                    <td>
                      <material-input
                        v-model="type.description"
                        type="text"
                        placeholder="Description"
                      />
                    </td>
                    <td><material-input v-model="type.price" type="text" /></td>
                    <td class="text-center">
                      <div
                        class="d-flex align-items-center"
                        @click="removeVenueType(index)"
                      >
                        <i class="fas fa-trash-alt cursor-pointer"></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Terms & Conditions</label>
              <material-textarea rows="6" v-model="venue.terms_conditions" />
            </div>
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Status</label>
              <div class="d-flex">
                <MaterialRadio
                  id="status_1"
                  value="1"
                  class="ps-0"
                  v-model="venue.status"
                >
                  Active
                </MaterialRadio>
                <MaterialRadio id="status_2" value="0" v-model="venue.status">
                  Inactive
                </MaterialRadio>
              </div>
            </div>
            <div class="d-flex">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveVenue()"
              >
                {{ isEditVenue ? "Edit Service" : "Add Service" }}
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import BookingService from "../services/booking.service";
import Multiselect from "@vueform/multiselect";
import MaterialRadio from "@/components/MaterialRadio.vue";
export default {
  name: "create-venue",
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    MaterialLoading,
    Multiselect,
    MaterialRadio,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    isEditVenue: {
      type: Boolean,
      default: false,
    },
    venueId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      urlImagePreview: null,
      isErrorUploadImage: false,
      isImageTooSmall: false,
      messageImageError: "",
      fieldRequired: {},
      loaderActive: false,
      venue: {
        id: 0,
        name: "",
        branchIds: [0], // 0 - All branches
        image: null,
        min_advance_time: 0,
        min_advance_time_unit: 3, // day
        max_advance_time: 0,
        max_advance_time_unit: 3, // day
        types: [],
        terms_conditions: "",
        status: 0,
      },
      branches: [],
      branchesDefault: [],
      timeUnit: [],
    };
  },
  methods: {
    uploadFile() {
      $("#imageVenue").click();
    },

    clearImage() {
      this.venue.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.venue.image = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.isErrorUploadImage = false;
          this.fieldRequired.isImageRequire = true;
          this.messageImageError = `File must be an image.`;
        } else {
          this.venue.image = file;
          this.urlImagePreview = URL.createObjectURL(file);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
                this.messageImageError = "";
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.venue.image = null;
        this.urlImagePreview = null;
        this.isErrorUploadImage = false;
        this.messageImageError = "";
      }
      $("#imageVenue")[0].value = "";
    },

    addVenueType() {
      var name = $("#venue_type_name").val();

      if (name) {
        $("#venue_type_name").val("");
        this.venue.types.push({
          id: 0,
          name: name,
          description: "",
          price: 0,
        });
      }
    },

    removeVenueType(index) {
      this.venue.types.splice(index, 1);
    },

    getDropdownBranches(keyword) {
      var dataSearch = this.branchesDefault;
      var dataResult = dataSearch.filter(
        (item) =>
          item.label.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
          (item.item_no + "").indexOf(keyword) > -1
      );

      this.branches = dataResult;
    },

    getVenueDetail() {
      this.showLoader();
      BookingService.venueDetail({
        id: this.venueId,
      }).then(
        (response) => {
          if (response.data.result.isSuccess) {
            let data = response.data.data;
            this.venue = data.venue;
            this.branches = data.branches;
            this.branchesDefault = data.branches;
            this.timeUnit = data.time_unit;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveVenue() {
      if (this.processValidate()) {
        this.showLoader();
        BookingService.updateOrCreateVenue(this.venue).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.clearData();
              $("#imageVenue").val("");
              this.$emit(
                "reload-list-venue",
                this.isEditVenue
                  ? "Edit Service Success"
                  : "Add Service Success"
              );
              $(".btn-close-modal").click();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    resetRequired() {
      this.fieldRequired.isVenueNameRequire = false;
      this.fieldRequired.isVenueTypeRequire = false;
      this.fieldRequired.isImageRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.venue.name == "" || this.venue.name == null) {
        this.fieldRequired.isVenueNameRequire = true;
        isValid = false;
      }

      if (this.venue.image == "" || this.venue.image == null) {
        this.fieldRequired.isImageRequire = true;
        this.messageImageError = "The field is required.";
        isValid = false;
      }

      if (this.venue.types.length == 0) {
        this.fieldRequired.isVenueTypeRequire = true;
        isValid = false;
      }

      return isValid;
    },

    preventUpDown(event) {
      // Check if the up or down arrow key was pressed
      if (event.keyCode === 38 || event.keyCode === 40) {
        // Prevent the default behavior of the key event
        event.preventDefault();
      }
    },

    clearData() {
      this.resetRequired();
      (this.venue = {
        id: 0,
        name: "",
        branchIds: [0], // 0 - All branches
        image: null,
        min_advance_time: null,
        min_advance_time_unit: 3, // day
        max_advance_time: null,
        max_advance_time_unit: 3, // day
        types: [],
        terms_conditions: "",
        status: 0,
      }),
        (this.urlImagePreview = null);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.clearData();
      this.getVenueDetail();
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}

.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}

.font-18 {
  font-size: 18px;
}

.dowload-img {
  cursor: pointer;
  background: #f0f2f5 !important;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-icon-upload {
  font-size: 45px !important;
}

#preview,
.image-url {
  width: 120px;
  height: 120px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 5px;
  background: #ffffff;
  border-radius: 50%;
}

.mw-300px {
  max-width: 300px !important;
}

.modal-body::-webkit-scrollbar {
  width: 7px;
}

.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #888;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }
}
</style>
