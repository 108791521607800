import api from "./api";
import TokenService from "./token.service";

class AuthService {
  logout() {
    TokenService.removeUser();
  }

  login({ email, password }) {
    return api
      .post("merchant/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          TokenService.setMerAdmin(response.data);
        }
        return response.data;
      });
  }

  checkCredentials({ email, password }) {
    return api
      .post("merchant/check-merchant-credentials", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          // TokenService.setMerAdmin(response.data);
        }
        return response.data;
      });
  }

  sendEmailVerify(dataForm) {
    return api.post('/merchant/send-email-verify', dataForm);
  }

  verifyMerchant(dataForm) {
    return api.post('/merchant/verify-merchant', dataForm);
  }

  resendEmailVerify(dataForm) {
    return api.post('/merchant/resend-email-verify', dataForm);
  }
}

export default new AuthService();
