class CommonFunctionService {
  formatDateShow(date) {
    const getDay = String(date.getDate())
    const day = getDay.padStart(2, '0')
    const getMonth = String(date.getMonth() + 1)
    const month = getMonth.padStart(2, '0')
    const year = String(date.getFullYear())
    return `${day}/${month}/${year}`
  }
}

export default new CommonFunctionService();
