<template>
  <div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="mt-3">
              <div class="text-center label-form" style="font-size: 32px !important;">Static QR Generating</div>
              <div class="qr-box">
                <div class="mt-5" v-if="!generateDone">
                  <div class="row align-items-center">
                    <div class="col-lg-3 col-md-6 col-12 label-form">
                      Restaurant
                    </div>
                    <div class="col-lg-9 col-md-6 col-12">
                      <material-input
                        v-model="branchInfo.branch_name"
                        type="text"
                        :readOnly="true"
                      />
                    </div>
                  </div>
                  <div class="row align-items-center mt-3">
                    <div class="col-lg-3 col-md-6 col-12 label-form">
                      Table No.
                    </div>
                    <div class="col-lg-9 col-md-6 col-12">
                      <material-input
                        v-model="form.table_no"
                        type="text"
                        id="table_no"
                        name="table_no"
                        placeholder="Enter table no"
                      />
                    </div>
                  </div>
                  <div class="row align-items-center mt-3">
                    <div class="col-lg-3 col-md-6 col-12 label-form">Pax</div>
                    <div class="col-lg-9 col-md-6 col-12">
                      <material-input
                        v-model="form.pax"
                        type="number"
                        id="pax"
                        name="pax"
                        placeholder="Enter pax"
                      />
                    </div>
                  </div>
                  <!-- <div class="row align-items-center mt-3">
                    <div class="col-lg-3 col-md-6 col-12 label-form">
                      Expiry
                    </div>
                    <div class="col-lg-9 col-md-6 col-12 d-flex">
                      <material-input v-model="form.expiry_number" type="number" id="expiry_number"
                        name="expiry_number"/>
                      <Multiselect
                        v-model="form.expiry_unit"
                        :options="expiryUnit"
                        class="ms-3"
                        :canClear="false"
                      />
                    </div>
                  </div> -->
                  <div class="text-center mt-5">
                    <material-button class="btn-background btn-sm" @click="generateQR()">
                      Generate
                    </material-button>
                  </div>
                </div>
                <div v-else class="mt-5">
                  <div id="printQR">
                    <div v-if="orderLink && tableInfo" class="text-center">
                      <img :src="tableInfo.qr" width="250" height="250" alt="error">
                    </div>
                    <div class="font-weight-bold text-center mt-5" style="font-size: 18px;">
                      <div>{{ tableInfo.code }}</div>
                      <div class="mt-2">Table No: {{ tableInfo.table_no }}</div>
                      <div class="mt-2">Time: {{ tableInfo.createdAt }}</div>
                      <div class="mt-2">Pax: {{ tableInfo.pax }}</div>
                      <div class="mt-2">How to order?</div>
                      <div class="mt-2">Scan QR code above</div>
                    </div>
                  </div>
                  <div class="text-center mt-5">
                    <material-button v-print="printObj" class="btn-background btn-sm">
                      Print
                    </material-button>
                  </div>
                  <div class="text-center mt-5">
                    <material-button class="btn-background btn-sm" @click="clearTable()">
                      Continue generate
                    </material-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import QRService from "../../services/qr.service";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialInput from "@/components/MaterialInput.vue";
// import Multiselect from "@vueform/multiselect";

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    MaterialInput,
    // Multiselect,
  },
  data() {
    return {
      isLoadDone: false,
      generateDone: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      branchCode: "",
      merchantId: 0,
      messageConfirm: "",
      branchInfo: {},
      tableInfo: null,
      orderLink: null,
      form: {
        table_no: "",
        pax: 1,
        expiry_number: 0,
        expiry_unit: 0,
      },
      expiryUnit: [
        {
          value: 1,
          label: "minutes",
        },
        {
          value: 2,
          label: "hours",
        },
        {
          value: 3,
          label: "days"
        },
      ],
      printObj: {
        id: "printQR",
        popTitle: 'STATIC QR',
      }
    };
  },
  created() {
    this.branchCode = this.$route.params.branch_code ?? "";
    this.merchantId = this.$route.params.merchant_id ?? 0;

    this.getBranchInfo();
  },
  mounted() {},
  methods: {
    getBranchInfo() {
      this.showLoader();
      var dataForm = {
        merchant_id: this.merchantId,
        branch_code: this.branchCode,
      };

      QRService.getBranchInfo(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branchInfo = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    generateQR() {
      this.showLoader();
      this.form.merchant_id = this.merchantId;
      this.form.branch_id = this.branchInfo ? this.branchInfo.id : 0; 
      
      QRService.generateQR(this.form).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.tableInfo = response.data.data.table;
            this.orderLink = response.data.data.order_link;
            this.generateDone = true;
            this.snackbar = "success";
            this.message = 'Generate Success';
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    clearTable() {
      this.generateDone = false;
      this.tableInfo = null;
      this.orderLink = null;
      this.form = {
        table_no: "",
        pax: 1,
        expiry_number: 60,
        expiry_unit: 1,
      };
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}

.qr-box {
  display: flex;
  justify-content: center;
}

.qr-box > div {
  width: 100%;
  max-width: 600px;
}

.cl-22 {
  color: #222 !important;
}
</style>
