<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">Option Group</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div style="margin-top: 15px; margin-bottom: 50px">
          <h3 class="title-page">Group of Multiple Options</h3>
          <div class="m-group-option-list">
            <div
              class="m-group-option-box"
              v-for="(groupOption, index) in groupMultipleoptions"
              :key="index"
            >
              <div class="d-flex justify-content-between header">
                <h3 class="title-page mb-0 color-white hide-text">
                  {{ "(" + (groupOption.id) + ") " + groupOption.name }}
                </h3>
                <i
                  class="fas fa-ellipsis-v text-dark dropdownMenuButton color-white"
                  id="dropdownCategory1"
                  data-bs-toggle="dropdown"
                ></i>
                <img
                  class="mx-1 cursor-pointer mt-1"
                  width="20"
                  height="20"
                  src="@/assets/img/option_on_main_icon.png"
                  alt="logo"
                  @click="preivewGroupMultipleOption(groupOption.id)"
                />
                <ul class="dropdown-menu" aria-labelledby="dropdownGroup1">
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="addGroupMultipleOption(groupOption.id)"
                      ><i class="material-icons me-1">edit</i
                      ><span>Edit</span></a
                    >
                  </li>
                  <li>
                    <a
                      @click="attachedItem(groupOption.id, 2, groupOption)"
                      class="dropdown-item"
                      href="#"
                      ><i class="material-icons me-1">add</i
                      ><span>Attached Items</span></a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="checkDeleteOption(groupOption.id, 2)"
                      ><i class="material-icons me-1">delete</i
                      ><span>Delete</span></a
                    >
                  </li>
                </ul>
              </div>
              <div class="g-option-list">
                <div
                  class="option-tag mt-3 hide-text"
                  v-for="(option, idx) in groupOption.options"
                  :key="idx"
                >
                  {{ "(" + (option.id) + ") " + option.name }}
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-center m-option-box border-0"
            >
              <material-button
                class="btn btn-sm btn-background"
                @click="addGroupMultipleOption()"
              >
                <i class="material-icons css-icon">addoutlined</i>
                ADD
              </material-button>
            </div>
          </div>
        </div>
        <div>
          <h3 class="title-page">Options</h3>
          <div class="m-option-list">
            <div
              class="m-option-box"
              v-for="(option, index) in options"
              :key="index"
            >
              <div class="d-flex justify-content-between header">
                <h3 class="title-page color-white mb-0 hide-text">
                  {{ "(" + (option.id) + ") " + option.name }}
                </h3>
                <i
                  class="fas fa-ellipsis-v text-dark dropdownMenuButton color-white"
                  id="dropdownCategory1"
                  data-bs-toggle="dropdown"
                ></i>
                <img
                  class="mx-1 cursor-pointer mt-1"
                  width="20"
                  height="20"
                  src="@/assets/img/option_on_main_icon.png"
                  alt="logo"
                  @click="preivewOption(option.id)"
                />
                <ul class="dropdown-menu" aria-labelledby="dropdownGroup1">
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="editOption(option.id)"
                      ><i class="material-icons me-1">edit</i
                      ><span>Edit</span></a
                    >
                  </li>
                  <li>
                    <a
                      @click="attachedItem(option.id, 1, option)"
                      class="dropdown-item"
                      href="#"
                      ><i class="material-icons me-1">add</i
                      ><span>Attached Items</span></a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click="checkDeleteOption(option.id, 1)"
                      ><i class="material-icons me-1">delete</i
                      ><span>Delete</span></a
                    >
                  </li>
                </ul>
              </div>
              <div class="content">
                <div class="row justify-content-center">
                  <div class="col-8 row p-0">
                    <div class="col-6 p-0">Min:</div>
                    <div class="col-6 p-0 text-bold">{{ option?.min_qty }}</div>
                  </div>
                  <div class="col-4 row p-0">
                    <div class="col-6 p-0">Max:</div>
                    <div class="col-6 p-0 text-bold">{{ option?.max_qty }}</div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8 row p-0">
                    <div class="col-6 p-0">Item Max:</div>
                    <div class="col-6 p-0 text-bold">{{ option?.item_max_qty }}</div>
                  </div>
                  <div class="col-4 row p-0"></div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8 row p-0">
                    <div class="col-6 p-0">Quantity:</div>
                    <div class="col-6 p-0 text-bold">{{ option?.quantity }}</div>
                  </div>
                  <div class="col-4 row p-0"></div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8 row p-0">
                    <div class="col-6 p-0">Dining Opt:</div>
                    <div class="col-6 p-0 text-bold">{{ option?.dining_option_label }}</div>
                  </div>
                  <div class="col-4 row p-0"></div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8 row p-0">
                    <div class="col-6 p-0">Remark:</div>
                    <div class="col-6 p-0 text-bold">{{ option?.is_remark ? "Yes" : "No" }}</div>
                  </div>
                  <div class="col-4 row p-0"></div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-8 row p-0">
                    <div class="col-6 p-0">Price:</div>
                    <div class="col-6 p-0 text-bold">{{ option?.price_label }}</div>
                  </div>
                  <div class="col-4 row p-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddGroupMultipleOption
    :keyReload="keyReloadAddGroupMultipleOption"
    :isEditGroupMultipleOption="isEditGroupMultipleOption"
    @reload-list-group-multiple-option="reloadListGroupMultipleOption"
    @attached-item="attachedItem"
  >
  </AddGroupMultipleOption>
  <AddOptionModal
    :keyReload="keyReloadAddOption"
    :isEditOption="isEditOption"
    :categoryId="categoryIdSelect"
    @reload-list-option="reloadListOption"
    @attached-item="attachedItem"
  ></AddOptionModal>
  <ModalSelectItemForModiferOptionGroup
    :keyReload="keyReloadModalSelectItem"
    :modifierOptionGroupId="modifierOptionGroupId"
    :modifierOptionGroupSectionId="modifierOptionGroupSectionId"
    :itemObject="itemObject"
  ></ModalSelectItemForModiferOptionGroup>
  <ModalPreviewOption
    :keyReload="keyReloadModalPreviewOption"
    :optionId="modifierOptionGroupSectionId"
  ></ModalPreviewOption>
  <ModalPreviewGroupMultipleOption
    :keyReload="keyReloadModalPreviewGroupMultipleOption"
    :groupMultipleOptionId="modifierOptionGroupId"
  ></ModalPreviewGroupMultipleOption>
  <ModalConfirmDelete
    message="Are you sure you want to delete?"
    :process-yes="deleteOption"
  />
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import ModifierOptionGroupService from "../../services/product/modifierOptionGroup";
import MaterialButton from "@/components/MaterialButton.vue";
import AddGroupMultipleOption from "@/components/AddGroupMultipleOption.vue";
import $ from "jquery";
import ModalSelectItemForModiferOptionGroup from "@/components/ModalSelectItemForModiferOptionGroup.vue";
import ModalPreviewOption from "@/components/ModalPreviewOption.vue";
import ModalPreviewGroupMultipleOption from "@/components/ModalPreviewGroupMultipleOption.vue";
import AddOptionModal from "@/components/AddOption.vue";
import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";

export default {
  name: "option-group",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    AddGroupMultipleOption,
    ModalSelectItemForModiferOptionGroup,
    ModalPreviewOption,
    ModalPreviewGroupMultipleOption,
    AddOptionModal,
    ModalConfirmDelete
  },
  data() {
    return {
      isLoadData: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      groupMultipleoptions: [],
      options: [],
      isEditGroupMultipleOption: 0,
      modifierOptionGroupId: 0,
      modifierOptionGroupSectionId: 0,
      keyReloadAddGroupMultipleOption: "keyReloadAddGroupMultipleOption",
      keyReloadModalSelectItem: "keyReloadModalSelectItem",
      keyReloadModalPreviewOption: "key-reload-modal-preview-option",
      keyReloadModalPreviewGroupMultipleOption: "key-reload-modal-preview-group-multiple-option",
      keyReloadAddOption: "key-reload-add-option",
      isEditOption: 0,
      idSeletected: 0,
      typeSeletected: 0,
    };
  },
  created() {
    this.getListGroupMultipleoptions();
    this.getListOption();
  },
  methods: {
    getListGroupMultipleoptions() {
      this.showLoader();
      var dataForm = {
        type: 2,
      };

      ModifierOptionGroupService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.groupMultipleoptions = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    getListOption() {
      this.showLoader();
      var dataForm = {
        type: 1,
      };

      ModifierOptionGroupService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.options = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    checkDeleteOption(id, type = 0) {
      this.showLoader();
      ModifierOptionGroupService.checkDeleteOption(id, type).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.idSeletected = id;
            this.typeSeletected = type;
            $('.btn-confirm-modal-delete').click();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    deleteOption() {
      this.showLoader();
      ModifierOptionGroupService.deleteOption(this.idSeletected, this.typeSeletected).then(
        (response) => {
          if (response.data.result.isSuccess) {
            $(".btn-close-modal").click();
            this.snackbar = "success";

            if (this.typeSeletected == 1) {
              this.message = "Delete Option Success";
              this.getListOption();
            }

            if (this.typeSeletected == 2) {
              this.message = "Delete Group Multiple Option Success";
              this.getListGroupMultipleoptions();
            }

            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    addGroupMultipleOption(id = 0) {
      if (id) {
        this.isEditGroupMultipleOption = id;
      } else {
        this.isEditGroupMultipleOption = 0;
      }

      this.keyReloadAddGroupMultipleOption = Math.random();
      $("#show-modal-add-group-multiple-option").click();
    },

    editOption(id) {
      this.isEditOption = id;
      this.keyReloadAddOption = Math.random();
      $("#show-modal-add-option").click();
    },

    preivewOption(id) {
      this.modifierOptionGroupSectionId = id;
      this.keyReloadModalPreviewOption = Math.random();
      $("#show-modal-preview-option").click();
    },

    preivewGroupMultipleOption(id) {
      this.modifierOptionGroupId = id;
      this.keyReloadModalPreviewGroupMultipleOption = Math.random();
      $("#show-modal-preview-group-multiple-option").click();
    },

    reloadListGroupMultipleOption(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getListGroupMultipleoptions();
    },

    reloadListOption(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getListOption();
    },

    attachedItem(id, type = 0, itemObject = null) {
      if (type == 1) {
        this.modifierOptionGroupId = 0;
        this.modifierOptionGroupSectionId = id;
      } else {
        this.modifierOptionGroupId = id;
        this.modifierOptionGroupSectionId = 0;
      }

      this.itemObject = itemObject;
      this.keyReloadModalSelectItem = Math.random();
      $(".btn-select-item-for-modifier-option-group-modal").click();
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}

.m-option-list,
.m-group-option-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.m-group-option-box {
  position: relative;
  width: fit-content;
  border: 1px solid #d5d6d8;
  border-radius: 10px;
  flex: 1 0 15%;
  max-width: 15% !important;
  margin: 10px calc(10% / 10);
  min-width: 220px;
}

.m-option-box {
  position: relative;
  width: fit-content;
  border: 1px solid #d5d6d8;
  border-radius: 10px;
  flex: 1 0 23%;
  max-width: 23% !important;
  margin: 10px calc(8% / 8);
  min-width: 220px;
}

.m-option-box .header,
.m-group-option-box .header {
  padding: 10px 30px 10px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #4853EB;
}

.m-option-box .content,
.m-group-option-box .g-option-list {
  padding: 10px 20px 10px 20px;
}

.dropdownMenuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 30px;
  position: absolute;
  top: 11px;
  right: 10px;
  cursor: pointer;
}

.css-icon {
  position: absolute;
  left: 17px !important;
  font-size: 18px !important;
  top: 7px !important;
}

.option-tag {
  border: 2px solid red;
  padding: 5px 10px;
  border-radius: 3px;
  color: #222;
  font-size: 18px;
  font-weight: bold;
}

.g-option-list .option-tag:nth-child(3n + 1) {
  border-color: #0984e3;
}

.g-option-list .option-tag:nth-child(3n + 2) {
  border-color: #44bd32;
}

.g-option-list .option-tag:nth-child(3n) {
  border-color: #fbc531;
}

.dropdown-item {
  display: flex;
  align-items: center;
}

.color-white {
  color: #fff !important;
}
</style>
