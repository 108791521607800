<template>
  <div>
    <div class="row">
      <div
        class="col-lg-4 col-md-4 col-12 label-form d-flex align-items-center"
      >
        Data Backup Frequency
      </div>
      <div class="col-lg-8 col-md-8 col-12 d-flex align-items-center">
        <Multiselect
          v-model="backupTime"
          :options="backupTimes"
          :canClear="false"
          @update:model-value="onChangeParameter(backupTime, 'backup_time')"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div
        class="col-lg-4 col-md-4 col-12 label-form d-flex align-items-center"
      >
        Backup Data Retention Period
      </div>
      <div class="col-lg-8 col-md-8 col-12 d-flex align-items-center">
        <material-input
          v-model="backupRetentionTime"
          class="me-3"
          type="number"
          @update:model-value="
            onChangeParameter(backupRetentionTime, 'backup_retention_time')
          "
        />
        <Multiselect
          v-model="backupRetentionTimeType"
          :options="backupRetentionTimes"
          :canClear="false"
          @update:model-value="
            onChangeParameter(
              backupRetentionTimeType,
              'backup_retention_time_type'
            )
          "
        />
      </div>
    </div>
    <div class="row mt-3" v-if="form.last_backup_time">
      <div class="font-italic">Last backup {{ form.last_backup_time }}</div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  name: "data-backup",
  components: {
    Multiselect,
    MaterialInput,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    onChangeParameter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      backupTime: this.form.backup_time ?? 2,
      backupTimes: [
        {
          label: "Daily",
          value: 1,
        },
        {
          label: "Weekly",
          value: 2,
        },
        {
          label: "Monthly",
          value: 3,
        },
      ],
      backupRetentionTime: this.form.backup_retention_time ?? 6,
      backupRetentionTimeType: this.form.backup_retention_time_type ?? 2,
      backupRetentionTimes: [
        {
          label: "Days",
          value: 1,
        },
        {
          label: "Months",
          value: 2,
        },
        {
          label: "Years",
          value: 3,
        },
      ],
    };
  },
  methods: {},
};
</script>
