import api from './api';

class PrinterService {
  getList(dataForm) {
    return api.post('/merchant/printers', dataForm);
  }

  update(dataForm) {
    return api.post('/merchant/printers/update', dataForm);
  }
}

export default new PrinterService();
