<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalListMenuset"
      id="show-modal-list-menuset"
    ></material-button>
    <div
      class="modal fade"
      id="modalListMenuset"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Menuset</h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mt-3">
              <div class="label-header">
                Showing {{ pagination.number_items }} of
                {{ pagination.total }} Menus
              </div>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                data-bs-toggle="modal"
                data-bs-target="#modalListMenuset"
                @click="openModalAddMenuset()"
              >
                <i class="material-icons css-icon">addoutlined</i>
                ADD
              </material-button>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder">
                              {{ $t("Name") }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t("Created at") }}
                            </th>
                            <th class="text-center font-weight-bolder w-15">
                              {{ $t("action") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="menuset.length">
                          <tr v-for="(value, key) in menuset" :key="key">
                            <td
                              class="text-left text-xss cursor-pointer font-weight-bolder cl-22"
                              data-bs-toggle="modal"
                              data-bs-target="#modalListMenuset"
                              @click="viewMenuset(value.id)"
                            >
                              {{ value.name }}
                            </td>
                            <td class="text-center text-xss">
                              {{ value.created_at_format }}
                            </td>
                            <td
                              class="text-center text-xss"
                              style="border-width: 0 !important"
                            >
                              <a
                                class="btn btn-link cl-22 px-2 mb-0"
                                data-bs-toggle="modal"
                                data-bs-target="#modalListMenuset"
                                @click="openModalAddMenuset('edit', value.id)"
                              >
                                <i
                                  class="fas fa-pencil-alt text-dark me-2"
                                  aria-hidden="true"
                                ></i
                                >Edit
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="10" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <pagination
              v-if="pagination.last_page > 1"
              :pagination="pagination"
              :current_page="pagination.current_page"
              :last_page="pagination.last_page"
              class="pt-0 pb-0"
            />
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "@vueform/multiselect/themes/default.css";
import MenusetService from "../services/product/menuset.service";

export default {
  name: "add-menuset",
  components: {
    MaterialButton,
    MaterialLoading,
    MaterialSnackbar,
    Pagination,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      pagination: {},
      page: 1,
      isActive: true,
      menuset: [],
    };
  },
  methods: {
    getList(page) {
      this.showLoader();
      var dataForm = {
        page: page,
      };
      MenusetService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menuset = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    menusetDetail(id) {
      this.$router.push("/menu-set/detail/" + id);
    },

    viewMenuset(id) {
      this.$emit("view-menuset", id);
    },

    openModalAddMenuset(type = "add", id = 0) {
      this.$emit("open-modal-add-menuset", type, id, "list");
    },

    clearData() {
      this.menuset = [];
      this.pagination = {};
      this.page = 1;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    async keyReload() {
      this.clearData();
      this.getList();
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}

.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}

.modal-dialog {
  max-width: 900px;
}

.label-header {
  font-size: 16px;
  color: #222;
  font-weight: 500;
}

.css-icon {
  position: absolute;
  left: 17px !important;
  font-size: 18px !important;
  top: 7px !important;
}

.cl-22 {
  color: #222 !important;
}
</style>
