<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">Import</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto mt-3">
            <div class="d-flex align-items-center">
              <h3 class="title-page mb-0 me-3">Main Items or Modifier</h3>
              <div class="sample-file" @click="downloadSampleFile()">sample.xlsx</div>
            </div>
            <div class="d-flex mt-4 s-menuset align-items-center">
              <div class="me-3">File import</div>
              <material-input
                hidden
                type="file"
                id="file_import_items"
                name="file_import_items"
                @change="onFileChangeItems($event)"
                accept=".xlsx"
              />
              <material-button
                @click="chooseFile('file_import_items')"
                class="btn btn-sm btn-background mw-185px me-3"
              >
                Choose file
              </material-button>
              <div>{{ noFileChosen.items }}</div>
            </div>
            <div class="d-flex mt-2 s-menuset" v-if="messageFileError.items">
              <div class="me-3"></div>
              <div class="is-invalid">
                {{ messageFileError.items }}
              </div>
            </div>
            <div class="d-flex s-menuset align-items-center mt-3">
              <div class="me-3">Menu Set</div>
              <Multiselect
                v-model="menuSetIdSelect"
                :options="menusets"
                placeholder="Select menu"
                :canClear="false"
              />
            </div>
            <div
              class="d-flex mt-2 s-menuset"
              v-if="fieldRequired.isMenusetRequire"
            >
              <div class="me-3"></div>
              <div class="is-invalid">The field is required.</div>
            </div>
            <!-- <div class="d-flex mt-4 s-menuset align-items-center">
              <div class="me-3">Type</div>
              <Multiselect
                v-model="type"
                :options="typeArr"
                placeholder="Select type"
                :canClear="false"
              />
            </div> -->
            <div class="mt-5">
              <material-button
                @click="importMenu(1)"
                class="btn btn-sm btn-background mw-185px"
              >
                Upload
              </material-button>
            </div>
            <div class="err-mess mt-4">
              <div
                v-for="(mess, index) in errMessage"
                :key="index"
                class="is-invalid"
              >
                {{ mess }}
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12 mx-auto mt-3">
            <div>
              <h3 class="title-page">Item Images (.zip only, each image 480 x 480px)</h3>
            </div>
            <div class="d-flex mt-3 s-menuset align-items-center">
              <div class="me-3">File import</div>
              <material-input
                hidden
                type="file"
                id="file_import_images"
                name="file_import_images"
                @change="onFileChangeImages($event)"
                accept=".xlsx"
              />
              <material-button
                @click="chooseFile('file_import_images')"
                class="btn btn-sm btn-background mw-185px me-3"
              >
                Choose file
              </material-button>
              <div>{{ noFileChosen.images }}</div>
            </div>
            <div class="d-flex mt-2 s-menuset" v-if="messageFileError.images">
              <div class="me-3"></div>
              <div class="is-invalid">
                {{ messageFileError.images }}
              </div>
            </div>
            <div class="mt-5">
              <material-button
                @click="importMenu(2)"
                class="btn btn-sm btn-background mw-185px"
              >
                Upload
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form
      :action="`${actionDownload}/merchant/menu-set/download-sample-file`"
      method="POST"
    >
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCode" />
      <input type="hidden" name="token" :value="token" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MenusetService from "../../services/product/menuset.service";
import Multiselect from "@vueform/multiselect";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import $ from "jquery";
import TokenService from "../../services/token.service";

export default {
  name: "menu-import",
  components: {
    Multiselect,
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    MaterialInput,
  },
  data() {
    return {
      token: TokenService.getLocalAccessToken(),
      actionDownload: process.env.VUE_APP_BASE_URL,
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCode: localStorage.getItem("MERCHANT_CODE", ""),
      isLoadData: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      menusets: [],
      menuSetIdSelect: 0,
      fileImport: {
        items: null,
        images: null,
      },
      noFileChosen: {
        items: "No file chosen",
        images: "No file chosen",
      },
      fieldRequired: {},
      messageFileError: {},
      errMessage: {},
      // type: 1,
      // typeArr: [
      //   { 
      //     value: 1,
      //     label: "Main"
      //   },
      //   { 
      //     value: 2,
      //     label: "Modifier"
      //   }
      // ]
    };
  },
  created() {
    this.getMenusets();
  },
  methods: {
    chooseFile(name) {
      $("#" + name).click();
    },

    onFileChangeItems(e) {
      this.noFileChosen.items = "No file chosen";
      this.fileImport.items = null;
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      let file = files[0];

      if (file != null || file != undefined) {
        if (
          ![
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ].includes(file.type)
        ) {
          this.messageFileError.items = `File must be an xlsx or xls.`;
        } else {
          this.noFileChosen.items = file.name;
          this.fileImport.items = file;
          this.messageFileError.items = "";
        }
      }

      $("#file_import_items")[0].value = "";
    },

    onFileChangeImages(e) {
      this.noFileChosen.images = "No file chosen";
      this.fileImport.images = null;
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        return;
      }

      let file = files[0];

      if (file != null || file != undefined) {
        if (!["application/x-zip-compressed"].includes(file.type)) {
          this.messageFileError.images = `Only .zip files are allowed.`;
        } else {
          this.noFileChosen.images = file.name;
          this.fileImport.images = file;
          this.messageFileError.images = "";
        }
      }

      $("#file_import_images")[0].value = "";
    },

    getMenusets() {
      this.showLoader();
      var dataForm = {
        type: "select",
      };
      MenusetService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menusets = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    importMenu(importType = 1) {
      this.errMessage = [];
      if (this.processValidate(importType)) {
        this.showLoader();

        var dataForm = {
          menuset_id: this.menuSetIdSelect,
          // type: this.type,
          file_import: this.fileImport,
          import_type: importType
        };

        MenusetService.import(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {

              if (importType == 1) {
                this.fileImport.items = null;
                this.noFileChosen.items = "No file chosen";
                this.menuSetIdSelect = 0;
                this.type = 1;
              }

              if (importType == 2) {
                this.fileImport.images = null;
                this.noFileChosen.images = "No file chosen";
              }

              if (response.data.data.status) {
                this.snackbar = "success";
                this.message = "Upload success!";
              } else {
                this.snackbar = "danger";
                this.message = "Upload fail!";
                this.errMessage = response.data.data.err_message;
              }

              this.setTimeOutNotification();
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    downloadSampleFile() {
      $("#downloadForm").click();
    },

    resetRequired() {
      this.fieldRequired.isMenusetRequire = false;
      this.messageFileError = {};
    },

    processValidate(importType) {
      this.resetRequired();
      var isValid = true;

      if (importType == 1) {
        if (!this.menuSetIdSelect) {
          this.fieldRequired.isMenusetRequire = true;
          isValid = false;
        }

        if (this.fileImport.items == null || this.fileImport.items == undefined) {
          this.fieldRequired.isFileImportRequire = true;
          this.messageFileError.items = "The field is required.";
          isValid = false;
        }
      }

      if (importType == 2) {
        if (this.fileImport.images == null || this.fileImport.images == undefined) {
          this.fieldRequired.isFileImportRequire = true;
          this.messageFileError.images = "The field is required.";
          isValid = false;
        }
      }

      return isValid;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}

.s-menuset > div:first-child {
  min-width: 100px;
}

.s-menuset .multiselect {
  margin: 0 !important;
  max-width: 500px !important;
}

.sample-file {
  cursor: pointer;
  text-decoration: underline;
  color: #133067;
}
</style>
