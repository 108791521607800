<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-select-item-for-modifier-option-group-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalSelectItemOptionGroupModal"
    ></button>
    <div
      class="modal fade"
      id="modalSelectItemOptionGroupModal"
      tabindex="-1"
      aria-hidden="true"
      style="z-index: 9999"
    >
      <div class="modal-dialog modal-dialog-centered modal-width">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ itemObject && itemObject.name ? itemObject.name+" - " : "" }}Add Items</h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal btn-close-modal-select-item justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div
            class="modal-body"
            style="overflow-y: unset; padding: 0px; padding-top: 10px"
          >
            <div class="mt-3 s-menuset">
              <Multiselect
                :searchable="true"
                v-model="menusetId"
                :options="menusets"
                placeholder="Select menu"
                @change="selectMenuset"
              />
            </div>
            <div
              class="mt-3"
              style="display: flex; overflow-x: auto; white-space: nowrap"
            >
              <ul id="general-tab" class="nav nav-tabs">
                <li class="nav-item" v-for="(item, key) in categoryDropdown" :key="key">
                  <a
                    :id="'nav_item_' + key"
                    class="nav-link category-tab"
                    :class="{ active: categoryId == item.value }"
                    @click="changeCategory(item.value)"
                    >{{ item.label }}</a
                  >
                </li>
              </ul>
            </div>
            <div v-if="menusetId">
              <div class="search">
                <div class="row align-items-center mt-0">
                  <div class="col-xl-3 col-12" style="margin-top: 0px">
                    <input
                      class="nosubmit"
                      type="search"
                      id="keyword_search"
                      v-model="searchKeyword"
                      extraClass
                      name="keyword_search"
                      placeholder="search"
                      @input="onChangesearchKeyword"
                    />
                  </div>
                  <div class="col-xl-2 col-12" style="margin-top: 0px">
                    <label
                      >Showing {{ items.length ?? 0 }} of
                      {{ items.length ?? 0 }} items</label
                    >
                  </div>
                  <div class="col-xl-2 col-12" style="margin-top: 0px">
                    <Multiselect
                      :searchable="true"
                      v-model="tag"
                      :options="tags"
                      placeholder="Tag"
                      @change="searchItem"
                    />
                  </div>
                  <div class="col-xl-5 col-12" style="margin-top: 0px">
                    <!-- <material-button
                      class="action-btn"
                      @click="removeAllItem()"
                    >
                      Remove all
                    </material-button> -->
                    <material-button
                      class="action-btn"
                      @click="selectAllItem()"
                    >
                      Select all
                    </material-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-list" >
              <div class="content-item">
                <div class="col-12 list-item" v-if="items.length > 0">
                  <div
                    class="item-css d-flex"
                    v-for="item in items"
                    :key="item.item_id"
                    :class="'item-' + item.item_id"
                    @click="selectItem(item.item_id, 'click')"
                    :style="{
                      background: idsSelected.includes(item.item_id)
                        ? 'lightgray !important'
                        : '',
                    }"
                  >
                    <img class="item-img" :src="item.image" alt="" />
                    <div style="position: relative; width: 100%">
                      <label>{{ item?.item_no }}</label
                      ><br />
                      <label>{{ item?.item_name }}</label>
                      <label>{{ item?.price_format }} </label>
                      <!-- <div
                        style="
                          gap: 10px;
                          position: absolute;
                          bottom: 0px;
                          display: flex;
                          right: 0px;
                        "
                        v-if="isModifiderOptionGroup"
                      >
                        <div
                          v-for="(itemVariations, key) in item.variations"
                          :key="key"
                          style="border: 2px solid white; border-radius: 50%"
                        >
                          <div
                            @click="
                              selectVariation(
                                item.item_id,
                                itemVariations.id,
                                $event
                              )
                            "
                            style="
                              width: 25px;
                              height: 100%;
                              text-align: center;
                              color: white;
                              border-radius: 50%;
                              height: 25px;
                            "
                          >
                            {{ itemVariations.name }}
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-12 list-item" v-else>
                  <div class="text-center w-100 mt-3">No item available</div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer"
            style="align-items: center; justify-content: center"
          >
            <!-- <material-button
              class="mx-2 btn btn-sm btn-background"
              @click="addItem()"
              v-if="idActive.length == 0"
            >
              Add {{ idActive.length == 0 ? "" : idActive.length }}
            </material-button> -->
            <material-button
              class="mx-2 btn btn-sm btn-background"
              @click="addItem()"
            >
              Add {{ "(" + this.idActive.length + ")" }}
            </material-button>
            <material-button
              class="mx-2 btn btn-sm btn-background"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </material-button>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialButton from "@/components/MaterialButton.vue";
import ItemService from "../services/product/item.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import ModifierOptionGroupService from "../services/product/modifierOptionGroup";
import Multiselect from "@vueform/multiselect";
import SettingGeneralService from "../services/settingGeneral.service";

export default {
  name: "select-item",
  components: {
    MaterialButton,
    MaterialLoading,
    MaterialSnackbar,
    Multiselect,
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    modifierOptionGroupId: {
      type: Number,
      required: false,
    },
    modifierOptionGroupSectionId: {
      type: Number,
      required: false,
    },
    itemObject: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      snackbar: null,
      idActive: [],
      items: [],
      allItemId: [],
      searchKeyword: "",
      searchTimerId: null,
      type: 2,
      tags: [],
      tag: "",
      categoryDropdown: [],
      categoryId: 0,
      loaderActive: false,
      idsSelected: [],
      menusets: [],
      menusetId: 0,
      isCloseModal: false
    };
  },
  methods: {
    async getDataFilter() {
      var dataForm = {
        tag_list: 1,
        menuset_list: 1,
      };

      await SettingGeneralService.masterData(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menusets = response.data.data.menuset_list;
            this.tags = response.data.data.tag_list;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },
    async getListItem(type = "") {
      console.log(type);
      this.showLoader();
      var dataForm = {
        keyword: this.searchKeyword,
        tag: this.tag,
        set_id: this.menusetId,
        type: this.type,
        category_id: this.categoryId,
        isModifierOptionGroup: true,
      };

      await ItemService.getMainItem(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.items = response.data.data.items;
            this.allItemId = response.data.data.allItemId;
            this.reloadItemActive();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    reloadItemActive() {
      setTimeout(() => {
        this.idActive.forEach((item) => {
          var name = ".item-" + item.itemId;
          if (!$(name).hasClass("active")) {
            $(name).addClass("active");
          }
        });
      }, 100);
    },

    removeDuplicates(arr) {
      const uniqueItems = arr.filter(
        (item) =>
          typeof item !== "number" ||
          !arr.some((other) => other === item && typeof other === "number")
      );
      return uniqueItems;
    },

    addItem() {
      this.showLoader();
      var dataForm = {
        // categoryId: this.categoryId,
        set_id: this.menusetId,
        itemsId: this.removeDuplicates(this.idActive),
        modifier_option_group_id: this.modifierOptionGroupId,
        modifier_option_group_section_id: this.modifierOptionGroupSectionId,
      };

      ModifierOptionGroupService.attachedToItem(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            $(".btn-close-modal-select-item").click();
            this.snackbar = "success";
            this.message = "Attached Items Success";
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.message = error;
          this.hideLoader();
        }
      );
    },

    onChangesearchKeyword(event) {
      if (this.isCloseModal) {
        return;
      }

      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getListItem();
      }, 1000);
    },

    selectMenuset(id) {
      if (id) {
        clearTimeout(this.searchTimerId);
        this.searchTimerId = setTimeout(async () => {
          this.idActive = [];
          this.categoryId = 0;
          await this.getCategoryByMenuSetDropdown();
          await this.getAttachedToItem(),
          this.getListItem();
        }, 100);
      } else {
        this.clearData(false);
      }
    },

    searchItem() {
      if (this.isCloseModal) {
        return;
      }

      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(async () => {
        this.getListItem();
      }, 100);
    },

    selectAllItem() {
      this.idActive = [];
      var idsNotSelected = this.allItemId.filter(
        (id) => !this.idsSelected.includes(id)
      );

      idsNotSelected.forEach((id) => {
        this.idActive.push({
          itemId: id,
          variationIds: [],
        });

        var name = ".item-" + id;
        if (!$(name).hasClass("active")) {
          $(name).addClass("active");
        }
      });
    },

    // removeAllItem() {
    //   this.idActive = [];
    //   $(".item-css").removeClass("active");
    // },

    selectItem(id, type = "") {
      if (this.idsSelected.includes(id) && type === "click") {
        alert(
          "To remove this item, close this popup and use 'delete' icon from the Attached Items list."
        );
      } else {
        var name = ".item-" + id;
        var indexToDelete = this.idActive.findIndex(function (item) {
          return item.itemId === id;
        });

        if (indexToDelete > -1) {
          this.idActive.splice(indexToDelete, 1);
          $(name).removeClass("active");
        } else {
          this.idActive.push({
            itemId: id,
            variationIds: [],
          });
          $(name).addClass("active");
        }
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    isVariationSelected(itemId, variationId) {
      const item = this.idActive.find((item) => item.itemId === itemId);
      return item && item.variationIds.includes(variationId);
    },

    selectVariation(itemId, variationId, event) {
      if (event) {
        event.stopPropagation();
      }

      const index = this.idActive.findIndex((item) => item.itemId === itemId);

      if (index !== -1) {
        if (!this.idActive[index].variationIds.includes(variationId)) {
          this.idActive[index].variationIds.push(variationId);
        } else {
          const variationIndex = this.idActive[index].variationIds.findIndex(
            (id) => id === variationId
          );
          this.idActive[index].variationIds.splice(variationIndex, 1);
          if (
            this.idActive[index].variationIds &&
            this.idActive[index].variationIds.length == 0
          ) {
            this.selectItem(itemId);
          }
        }
      } else {
        this.idActive.push({
          itemId,
          variationIds: [variationId],
        });
        this.selectItem(itemId);
      }
    },

    clearData(clearAll = true) {
      this.idActive = [];
      this.items = [];
      this.allItemId = [];
      this.searchKeyword = "";
      this.type = 0;
      this.tag = "";
      if (clearAll) {
        this.menusets = [];
        this.idsSelected = [];
        this.tags = [];
      }
      this.menusetId = 0;
      this.categoryDropdown = [];
      this.categoryId = 0;
      $(".item-css").removeClass("active");
      $(".category-tab").addClass("active");
    },
    async getCategoryByMenuSetDropdown() {
      var dataForm = {
        menu_set_id: this.menusetId,
        merchantId: this.$route.params.merchant_id,
        isModifierOptionGroup: true,
      };

      await ItemService.getCategoryByMenuSetDropdown(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            var categoryDropdown = response.data.data;

            if (categoryDropdown.length) {
              this.categoryId = categoryDropdown[0].value;
              
            }

            this.categoryDropdown = categoryDropdown;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
    },

    async getAttachedToItem() {
      this.showLoader();
      var dataForm = {
        set_id: this.menusetId,
        modifier_option_group_id: this.modifierOptionGroupId,
        modifier_option_group_section_id: this.modifierOptionGroupSectionId,
        type: 'Array'
      };

      await ModifierOptionGroupService.getAttachedToItem(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.idsSelected = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    changeCategory(categoryId) {
      this.categoryId = categoryId;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getListItem("init");
      }, 100);
    },
  },
  watch: {
    async keyReload() {
      this.isCloseModal = true;
      this.clearData();
      setTimeout(() => {
        this.isCloseModal = false;
      }, 500);
      this.showLoader();
      await Promise.all([
        this.getDataFilter(),
        this.getCategoryByMenuSetDropdown(),
        this.getAttachedToItem(),
      ]);
      this.hideLoader();
    },
  },
};
</script>
<style scoped>
.modal-content {
  max-height: 85vh;
  overflow-y: auto;
  padding-bottom: 25px;
}
.modal-body .row {
  margin-top: 10px;
}
.modal-width {
  min-width: 80%;
}
.search {
  padding: 15px 5px;
  border-bottom: 1px solid #dee2e6;
}
.search > .row > div {
  margin-top: 15px;
}
input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}
input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}
input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}
input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}
.action-btn {
  float: right;
  margin-right: 17px;
  /* margin-top: 7px; */
}
.content-item {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.item-css {
  cursor: pointer;
  border: 1px solid #d2d2d2;
  margin-top: 12px;
  border-radius: 6px;
  padding: 10px;
  width: calc(25% - 10px);
  min-height: 90px;
}
.item-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.selected {
  background-color: #20c997;
}
.list-item {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}
.active {
  background: #44bd32;
}
.active label {
  color: #fff !important;
}
.item-list {
  overflow-x: hidden;
}
.item-list::-webkit-scrollbar {
  width: 7px;
}
.item-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.item-list::-webkit-scrollbar-thumb {
  background: #888;
}
.item-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.s-menuset .multiselect {
  margin: 0 !important;
  max-width: 500px !important;
}
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
#general-tab {
  border-bottom: none;
}
#general-tab .nav-item {
  width: fit-content;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
}
</style>
