<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalUpdateModifierOptionGroupForItem"
      id="show-modal-update-modifier-option-group-for-item"
    ></material-button>
    <div
      class="modal fade"
      id="modalUpdateModifierOptionGroupForItem"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 600px;">
        <div class="modal-content" style="padding: 0px;">
          <div class="modal-header" style="padding-bottom: 0px;">
            <button type="button" style="display: none" class="close btn-close-update-mog-for-item-modal" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div>
                <h5 class="modal-title" id="exampleModalLabel" style="color: black;">Modifier Options : <span style="color: #3632BD;">{{ item && item.item_no ? item.item_no : "" }}</span></h5>
                <h5 style="margin-bottom: 0px;color: black;" class="responsive-text">{{ item && item.item_name ? item.item_name : "" }}</h5>
                <p>{{ item && item.description ? item.description : "" }}</p>
            </div>
            <i
              style="cursor: pointer;position: absolute;top: 18px;right: 18px;"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
            <div style="cursor: pointer;position: absolute;top: 46px;right: 70px;font-weight: bold;color: black;">
                {{ item && item.price_format ? item.price_format : 'S$0.00' }}
            </div>
            </div>
            <div class="modal-body">
                <div v-for="(group, index) in groupsSelect" :key="index" :style="{ marginTop: index > 0 ? '15px' : '0px' }">
                    <div>
                        <h5 v-if="group.id">{{ "OPTION GROUP - " + '(' + group.id + ') ' + group.name.toUpperCase() }}</h5>
                        <div style="display: flex;align-items: flex-start;">
                            <div style="width: 90%; border: 1px solid lightgray;padding: 5px;border-radius: 5px;">
                                <div
                                    v-for="(
                                        section, index2
                                    ) in group.modifier_option_group_section"
                                    :key="index2"
                                    >
                                    <div v-if="index2 > 0" style="border-top: 5px solid lightgray;margin-bottom: 25px;margin-top: 15px;"></div>
                                    <div style="display: flex;align-items: center;justify-content: space-between;">
                                        <div style="display: flex;align-items: center;">
                                            <i class="material-icons me-1">edit</i>
                                            <div class="fs-16 font-weight-bold modifierGroupName" style="color: black;">{{ '(' + section.id + ') ' + section.name }}</div>
                                            <!-- <div class="d-flex me-2">
                                                <div class="fs-11 mt-1 ms-1">
                                                    <span class="font-inter">
                                                        Select 1
                                                    </span>
                                                </div>
                                            </div> -->
                                            <div class="fs-11 mt-1 ms-1">
                                                <span class="font-inter"
                                                v-if="section.min_qty == 0 && section.max_qty > 0" >
                                                Select up to {{ section.max_qty }}
                                                </span>
                                                <span class="font-inter" 
                                                v-else-if="section.min_qty == 1 && section.max_qty == 1"
                                                >
                                                Select 1
                                                </span> 
                                                <span class="font-inter" 
                                                v-else-if="section.min_qty == section.max_qty && section.max_qty > 1"
                                                >
                                                Select {{ section.max_qty }}
                                                </span>
                                                <span class="font-inter" 
                                                v-else
                                                >
                                                Select {{ section.min_qty }} to {{ section.max_qty }}
                                                </span> 
                                            </div>
                                            <!-- <div v-if="section.min_qty > 0 && section.total_selected >= section.min_qty" class="icon-check ms-1"></div> -->
                                        </div>
                                        <div>
                                            <Multiselect
                                                v-if="section.other_price_list_id"
                                                v-model="section.other_price_list_id"
                                                :options="section.price_list"
                                                placeholder="Select"
                                                :canClear="false"
                                                :canDeselect="false"
                                                class="responsive-box"
                                                @change="onchangePriceList($event, group.id, section.id)"
                                            >
                                                <template v-slot:option="{ option }">
                                                <!-- Use v-html to render HTML content -->
                                                <div v-if="option.value != 999999">{{ option.label }}</div>
                                                <div v-else v-html="option.html"></div>
                                                </template>
                                            </Multiselect>
                                            <Multiselect
                                                v-else
                                                v-model="section.price_list_id"
                                                :options="section.price_list"
                                                placeholder="Select"
                                                :canClear="false"
                                                :canDeselect="false"
                                                class="responsive-box"
                                                @change="onchangePriceList($event, group.id, section.id)"
                                            >
                                                <template v-slot:option="{ option }">
                                                <!-- Use v-html to render HTML content -->
                                                <div v-if="option.value != 999999">{{ option.label }}</div>
                                                <div v-else v-html="option.html"></div>
                                                </template>
                                            </Multiselect>
                                        </div>
                                    </div>
                                    <div class="d-inline-block signature-highlight modifierGroupSignature">
                                        <div style="font-weight: bold;">
                                            {{
                                                section.min_qty == 0
                                                ? "Optional"
                                                : "Compulsory"
                                            }}
                                        </div>
                                    </div>
                                    <div style="margin-top: 20px;"></div>
                                    <div v-for="(item, index3) in section.items" :key="index3">
                                        <div style="display: flex;justify-content: space-between;align-items: center;">
                                            <div style="display: flex;gap: 10px;">
                                                <div
                                                    :class="{ active: item.modifier_status || item.modifier_status === undefined }"
                                                    class="toggle_container pt-1"
                                                >
                                                    <MaterialToggleButton
                                                        labelEnableText
                                                        labelDisableText
                                                        :id="
                                                        'modifier-status-' +
                                                        index +
                                                        '_' +
                                                        index2 +
                                                        '_' +
                                                        index3
                                                        "
                                                        v-on:change="
                                                        triggerEventAppliedPrice(
                                                            index,
                                                            index2,
                                                            index3
                                                        )
                                                        "
                                                        class="mb-0"
                                                    />
                                                </div>
                                                <div style="margin-top: 4px;">{{ item.item_name_format }}</div>
                                            </div>
                                            <div>{{ item.item_price_format }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="width: 10%;align-items: center;justify-content: center;display: grid;margin-left: 10px;gap:10px;">
                                <div @click="confirmDelete(index, group.id, group.option_id ?? 0)" style="width: 30px;height: 30px;border: 1px solid lightgray;border-radius: 5px;align-items: center;justify-content: center;display: flex;cursor: pointer;">-</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <div style="color: black;">Option Groups</div>
                    <div style="display: flex;align-items: center;margin-top: 5px;">
                        <div style="width: 90%;">
                            <Multiselect
                                :searchable="true"
                                v-model="groupSelectedId"
                                :options="groupList"
                                placeholder="Select"
                                :canClear="false"
                                :canDeselect="false"
                            >
                                <template v-slot:option="{ option }">
                                <!-- Use v-html to render HTML content -->
                                <div v-if="option.value != 999999">{{ option.label }}</div>
                                <div v-else v-html="option.html"></div>
                                </template>
                            </Multiselect>
                        </div>
                        <div style="width: 10%;align-items: center;justify-content: center;display: grid;margin-left: 10px;gap:10px;">
                            <material-button
                                style="min-width: 20px;padding: 6px 12px !important;"
                                class="btn btn-sm btn-background"
                                @click="addMogToItem()"
                            >
                                Add
                            </material-button>
                        </div>
                    </div>
                </div>
                <div class="mt-3 mb-10">
                    <div style="color: black;">Options</div>
                    <div style="display: flex;align-items: center;margin-top: 5px;">
                        <div style="width: 90%;">
                            <Multiselect
                                :searchable="true"
                                v-model="optionSelectedId"
                                :options="optionList"
                                placeholder="Select"
                                :canClear="false"
                                :canDeselect="false"
                            >
                                <template v-slot:option="{ option }">
                                <!-- Use v-html to render HTML content -->
                                <div v-if="option.value != 999999">{{ option.label }}</div>
                                <div v-else v-html="option.html"></div>
                                </template>
                            </Multiselect>
                        </div>
                        <div style="width: 10%;align-items: center;justify-content: center;display: grid;margin-left: 10px;gap:10px;">
                            <material-button
                                style="min-width: 20px;padding: 6px 12px !important;"
                                class="btn btn-sm btn-background"
                                @click="addOptionToItem()"
                            >
                                Add
                            </material-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex" style="margin-top: 20px;align-items: center;justify-content: center;">
                    <material-button
                        class="mx-2 btn btn-sm btn-background"
                        @click="handleSave()"
                    >
                        Save
                    </material-button>
                    <material-button
                        class="mx-2 btn btn-sm btn-background"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        Cancel
                    </material-button>
                </div>
            </div>
            <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MenusetService from "../services/product/menuset.service";
import Multiselect from "@vueform/multiselect";
import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
import ItemService from "../services/product/item.service";
import $ from "jquery";
import ModifierOptionGroupService from "../services/product/modifierOptionGroup";

export default {
    name: "create-category",
    components: {
        MaterialButton,
        MaterialLoading,
        Multiselect,
        MaterialToggleButton
    },
    props: {
        keyReload: {
            type: String,
            required: true,
        },
        item: {
            type: Object
        },
        setId: {
            type: Number,
            default: 0
        },
        categoryId: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            messageImageError: "",
            loaderActive: false,
            data: [],
            snackbar: null,
            idPriceList: 0,
            groupsSelect: [],
            groupIdsSelect: [],
            itemData: {
                item_no: "",
                item_name: "",
                image: null,
                description: "",
                pos_name: "",
                pos_description: "",
                barcode: "",
                price: 0,
                min_order_qty: 0,
                max_order_qty: 1,
                is_disabled: false,
                item_type: 0,
                group_id: 0,
                type_id: 0,
                button_color: "#1871bd",
                font_color: "#1871bd",
                button_position: 0,
                others_price: [],
                delivery_tax: 0,
                takeaway_tax: 0,
                eat_in_tax: 0,
                category_id: this.categoryId,
                menuset_id: this.menusetId,
                group_item_id: this.groupId,
                sub_group_item_id: this.subGroupId,
                is_buy_one_get_one_free: false,
                variations: [],
                tag: 0,
                tags: [],
                variationIdDelete: [],
                modifier_option_group_ids: [],
                same_item_name: true,
                same_item_description: true,
            },
            groups: [],
            urlImagePreview: null,
            dataGroupByType: [],
            groupsSelectNew: [],
            mogDelete: [],
            moDelete: [],
            groupList: [],
            optionList: [],
            groupSelectedId: null,
            optionSelectedId: null
        };
    },
    methods: {
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        setTimeOutNotification() {
            setTimeout(() => {
                this.snackbar = null;
            }, 3000);
        },
        getItemDetail(id) {
            this.groupSelectedId = null;
            this.optionSelectedId = null;
            this.showLoader();
            ItemService.itemDetail(id, this.setId, this.categoryId).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    this.itemData = response.data.data;
                    this.groups = this.dataGroupByType[this.itemData.type_id] ?? [];
                    this.itemData.variations.forEach(() => {
                        this.fieldVariationRequired.push({
                            isNameRequire: false,
                            isImageRequire: false,
                        });
                    });
                    this.groupIdsSelect = this.itemData.modifier_option_group_ids;
                    this.itemData.modifier_option_groups.forEach((group) => {
                        if (group["modifier_option_group"]) {
                            this.groupsSelect.push(group["modifier_option_group"]);
                        }
                    });
                    this.urlImagePreview = this.itemData.image;
                    this.getModifierOptionGroup();
                    this.hideLoader();
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                    this.hideLoader();
                }
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error.response.data.result.message;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        loadData() {
            this.groupsSelect = [];
            this.moDelete = [];
            this.mogDelete = [];
            this.getItemDetail(this.item.id);
        },
        triggerEventAppliedPrice(index, index2, index3) {
            var value = $(
                "#modifier-status-" + index + "_" + index2 + "_" + index3 + "_button"
            ).is(":checked");
            if (typeof value == "boolean") {
                this.groupsSelect[index].modifier_option_group_section[index2].items[
                index3
                ].modifier_status = !value;
            }
        },
        handleSave() {
            this.showLoader();
            var data = {
                setId: this.setId,
                groupsSelect: this.groupsSelect,
                itemId: this.itemData.id,
                categoryId: this.categoryId,
                mogDelete: this.mogDelete,
                moDelete: this.moDelete
            };

            MenusetService.assignPriceListOtherForItem(data).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.snackbar = "success";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                        $(".btn-close-update-mog-for-item-modal").click();
                        this.$emit('reload-list-category', 'Save Successfully');
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        updateItemPriceFormatById(oldArray, newArray, groupId, sectionId) {
            // Lặp qua từng nhóm trong mảng mới
            newArray.forEach((newGroup, groupIndex) => {
                const oldGroup = oldArray[groupIndex]; // Lấy nhóm tương ứng trong mảng cũ

                if (oldGroup.id === groupId) {
                    if (oldGroup && newGroup.modifier_option_group_section) {
                        newGroup.modifier_option_group_section.forEach((newSection, sectionIndex) => {
                            const oldSection = oldGroup.modifier_option_group_section && oldGroup.modifier_option_group_section[sectionIndex]; // Lấy section tương ứng

                            if (oldSection && newSection.items) {
                                // Kiểm tra id của section trước khi cập nhật
                                if (newSection.id === sectionId) {
                                    newSection.items.forEach((newItem, itemIndex) => {
                                        const oldItem = oldSection.items && oldSection.items[itemIndex]; // Lấy item tương ứng
                                        
                                        if (oldItem) {
                                            // Cập nhật item_price_format
                                            oldItem.item_price_format = newItem.item_price_format;
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            });

            return oldArray;
        },
        onchangePriceList(selectedValue, group_id, section_id) {
            this.groupsSelectNew = [];
            ItemService.itemDetail(this.item.id, this.setId, this.categoryId, selectedValue).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    this.itemDataNew = response.data.data;
                    this.itemDataNew.modifier_option_groups.forEach((group) => {
                        if (group["modifier_option_group"]) {
                            this.groupsSelectNew.push(group["modifier_option_group"]);
                        }
                    });
                    this.groupsSelect = this.updateItemPriceFormatById(this.groupsSelect, this.groupsSelectNew, group_id, section_id);
                    this.hideLoader();
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                    this.hideLoader();
                }
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error.response.data.result.message;
                    this.hideLoader();
                    this.setTimeOutNotification();
                }
            );
        },
        confirmDelete(index, group_id, option_id = 0) {
            this.groupsSelect.splice(index, 1);

            if (option_id) {
                if (!this.moDelete.includes(option_id)) {
                    this.moDelete.push(option_id);
                }
            } else {
                if (!this.mogDelete.includes(group_id)) {
                    this.mogDelete.push(group_id);
                }
            }
            this.getModifierOptionGroup();
        },
        getModifierOptionGroup() {
            this.showLoader();
            var dataForm = {
                set_id: this.setId,
                categoryId: this.categoryId,
                id: this.item.id
            };
            ModifierOptionGroupService.getListSelectByMenuSet(dataForm).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    this.groupList = response.data.data.group_list;
                    this.optionList = response.data.data.option_list;
                    this.removeExisitingMog();
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                this.setTimeOutNotification();
                }
            );
        },
        addMogToItem() {
            var selectItem = this.groupList.find(item => item.value === this.groupSelectedId);
            if(selectItem) {
                var selectItemObject = selectItem.item;
                const existingItem = this.groupsSelect.find(existing => existing.id === selectItemObject.id);
                if (existingItem) {
                    console.log("Item already exists");
                } else {
                    this.groupsSelect.push(selectItemObject);
                    this.groupSelectedId = null;
                    this.removeExisitingMog();
                }
            }
        },
        addOptionToItem() {
            var selectItem = this.optionList.find(item => item.value === this.optionSelectedId);
            if(selectItem) {
                var selectItemObject = selectItem.item;
                const existingItem = this.groupsSelect.find(existing => existing.option_id === selectItemObject.option_id);
                
                if (existingItem) {
                    console.log("Item already exists");
                } else {
                    this.groupsSelect.push(selectItemObject);
                    this.optionSelectedId = null;
                    this.removeExisitingMog();
                }
            }
        },
        removeExisitingMog() {
            var compareIds = this.groupsSelect.map(item => item.id);
            this.groupList = this.groupList.filter(item => !compareIds.includes(item.value))
            compareIds = this.groupsSelect.map(item => item.option_id ?? 0);
            this.optionList = this.optionList.filter(item => !compareIds.includes(item.value))
        }
    },
    watch: {
        async keyReload() {
            this.loadData();
        }
    },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.font-18 {
  font-size: 18px;
}

.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 300px;
}

.css-icon-upload {
  font-size: 45px !important;
  margin-top: 6rem;
}

.image-url {
  width: 100%;
  height: 300px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.icon-check {
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('~@/assets/img/icon-check-green.svg');
  width: 16px;
  height: 16px;
}
.font-inter {
  font-family: 'Inter', sans-serif;
  color: #666666;
}

.fs-11 {
  font-size: 11px !important;
}

.signature-highlight {
    display: flex !important;
    align-items: center;
    border-radius: 10px;
    background-color: #96b7d8;
    height: 15px;
    color: black;
    width: fit-content;
    padding: 10px;
    font-size: 10px;
}
.responsive-box {
  width: 200px;
}

@media (max-width: 768px) {
  .responsive-box {
    width: 150px;
  }
}

.responsive-text {
    width: 100%;
}

@media (max-width: 768px) {
  .responsive-text {
    width: 60%;
  }
}
</style>
