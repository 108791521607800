<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddMenuset"
      id="show-modal-add-menuset"
    ></material-button>
    <material-button
      class="d-none"
      data-bs-dismiss="modal"
      data-bs-target="#modalAddMenuset"
      id="hide-modal-add-menuset"
    ></material-button>
    
    <div
      class="modal fade"
      id="modalAddMenuset"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ isEditMenuset ? "Edit Menuset" : "Add Menuset" }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              @click="closeModal()"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div
              class="w-100"
              :class="{ 'mb-3': !fieldRequired.isMenuNameRequire }"
            >
              <label class="fw-bold" for=""
                >Menu Name
                <span class="text-danger">*</span>
              </label>
              <material-input
                v-model="menuSet.name"
                type="text"
                id="menuset_name"
                name="menuset_name"
                placeholder="Enter name"
              />
            </div>
            <div class="w-100 mb-3">
              <span class="is-invalid" v-if="fieldRequired.isMenuNameRequire"
                >The field is required.</span
              >
            </div>
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Menu Description</label>
              <material-textarea
                rows="10"
                v-model="menuSet.description"
                id="menuset_description"
                name="menuset_description"
                placeholder="Enter description"
              />
            </div>
            <div class="w-100 mb-3">
              <label class="fw-bold" for="">Menu Image</label>
              <div
                class="dowload-img"
                v-show="urlImagePreview || menuSet.image"
              >
                <div id="preview" style="position: relative">
                  <img
                    class="image-url"
                    v-if="urlImagePreview || menuSet.image"
                    :src="urlImagePreview || menuSet.image"
                  />
                  <i
                    class="fa fa-times icon-close-sidenav-main danger"
                    v-if="urlImagePreview || menuSet.image"
                    style="
                      position: absolute;
                      color: #f44335;
                      font-size: 20px;
                      right: 5px;
                      top: 8px;
                    "
                    aria-hidden="true"
                    @click="clearImage()"
                  ></i>
                  <div class="btn-edit-img">
                    <i
                      class="fas fa-pencil-alt text-dark"
                      v-if="urlImagePreview || menuSet.image"
                      aria-hidden="true"
                      @click="uploadFile()"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                class="dowload-img"
                @click="uploadFile()"
                v-show="!urlImagePreview && !menuSet.image"
              >
                <material-input
                  style="display: none"
                  type="file"
                  id="imageMenuset"
                  @change="handleFileUpload($event)"
                  accept="image/*"
                />
                <i class="material-icons css-icon-upload">backup</i>
                <h6>Click here to upload an image.</h6>
              </div>
              <div
                class="is-invalid d-flex align-items-center pe-2"
                v-if="fieldRequired.isImageRequire"
              >
                {{ messageImageError }}
              </div>
            </div>
            <div
              class="w-100 mb-3"
            >
              <label class="fw-bold" for=""
                >Status
              </label>
              <div class="d-flex">
                <MaterialRadio
                  id="status_1"
                  value="1"
                  class="ps-0"
                  v-model="menuSet.status"
                >
                  Active
                </MaterialRadio>
                <MaterialRadio
                  id="status_2"
                  value="0"
                  v-model="menuSet.status"
                >
                  Inactive
                </MaterialRadio>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="d-flex mt-3">
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="saveMenu()"
              >
                {{ isEditMenuset ? "Save" : "Add Menuset" }}
              </material-button>
              <material-button
                class="mx-2 btn btn-sm btn-background"
                @click="closeModal()"
              >
                Cancel
              </material-button>
            </div>
          </div>
          <material-loading :active="loaderActive" />
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-9999 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MenusetService from "../services/product/menuset.service";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";

export default {
  name: "add-menuset",
  components: {
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    MaterialLoading,
    MaterialSnackbar,
    MaterialRadio
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    menusetId: {
      type: Number,
      default: 0,
    },
    isEditMenuset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: null,
      message: "",
      urlImagePreview: null,
      isErrorUploadImage: false,
      isImageTooSmall: false,
      messageImageError: "",
      fieldRequired: {},
      loaderActive: false,
      menuSet: {
        id: this.isEditMenuset ? this.menusetId : 0,
        name: "",
        description: "",
        image: null,
        status: 1,
      },
    };
  },
  methods: {
    uploadFile() {
      $("#imageMenuset").click();
    },

    clearImage() {
      this.menuset.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.menuSet.image = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.isErrorUploadImage = false;
          this.fieldRequired.isImageRequire = true;
          this.messageImageError = `File must be an image.`;
        } else {
          this.menuSet.image = file;
          this.urlImagePreview = URL.createObjectURL(file);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
                this.messageImageError = "";
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.menuSet.image = null;
        this.urlImagePreview = null;
        this.isErrorUploadImage = false;
        this.messageImageError = "";
      }
    },

    getMenuDetail() {
      this.showLoader();
      var menuId = this.menusetId;
      if (menuId) {
        MenusetService.menusetDetail(menuId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.menuSet = response.data.data;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        );
      }
    },

    saveMenu() {
      if (this.processValidate()) {
        this.showLoader();
        var menuId = this.isEditMenuset ? this.menusetId : 0;
        if (menuId) {
          this.menuSet.id = menuId;
          MenusetService.update(this.menuSet).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update MenuSet Success";
                this.setTimeOutNotification();
                this.hideLoader();
                this.$emit("reload-list-menuset");
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
                this.hideLoader();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error.response.data.result.message;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          console.log(this.menuSet);
          MenusetService.store(this.menuSet).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Create MenuSet Success";
                this.setTimeOutNotification();
                this.hideLoader();
                this.$emit("reload-list-menuset");
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
                this.hideLoader();
              }
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    resetRequired() {
      this.fieldRequired.isMenuNameRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.menuSet.name == "" || this.menuSet.name == null) {
        this.fieldRequired.isMenuNameRequire = true;
        isValid = false;
      }

      return isValid;
    },

    closeModal() {
      this.$emit("reload-list-menuset", "cancel");
    },

    clearData() {
      this.resetRequired();
      this.menuSet = {
        id: this.isEditMenuset ? this.menusetId : 0,
        name: "",
        description: "",
        image: null,
        status: 1,
      };
      this.urlImagePreview = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    keyReload() {
      this.clearData();

      if (this.isEditMenuset) {
        this.getMenuDetail();
      }
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}

.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}

.font-18 {
  font-size: 18px;
}

.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 300px;
}

.css-icon-upload {
  font-size: 45px !important;
  margin-top: 6rem;
}

.image-url {
  width: 100%;
  height: 300px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}
</style>
