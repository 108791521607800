<template>
  <div>
    <material-button
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#modalAddRecipients"
      id="show-modal-add-recipients"
    ></material-button>
    <div
      class="modal fade"
      id="modalAddRecipients"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-width">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ "Add Email Recipients" }}
            </h5>
            <i
              style="cursor: pointer"
              class="material-icons btn-close-modal justify-content-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              >clear</i
            >
          </div>
          <div class="modal-body">
            <div class="d-flex w-100 mb-3">
              <Multiselect
                v-model="tier_id"
                :options="tiers"
                placeholder="Select tier"
                :canClear="false"
                @change="changeTier"
              />
              <Multiselect
                v-model="gender"
                :options="genders"
                placeholder="Select gender"
                :canClear="false"
                class="ms-3"
                @change="changeGender"
              />
            </div>
            <div>
              <div class="mt-3 mb-3">
                <input
                  class="nosubmit"
                  type="search"
                  id="keyword_search"
                  extraClass
                  name="keyword_search"
                  placeholder="Search for id, member name, phone number"
                  v-model="search_keyword"
                  style="align-items: center; border-bottom: 1px solid #d2d2d2"
                  @input="onChangesearchKeyword"
                />
              </div>
              <div class="table-responsive p-0 custom-table mb-3">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-center font-weight-bolder">
                        <material-checkbox
                          @click="checkAll"
                          :checked="isCheckedAll"
                          class="align-items-center checkbox-custom"
                        ></material-checkbox>
                      </th>
                      <th class="text-center font-weight-bolder">ID</th>
                      <th class="text-center font-weight-bolder">Phone No</th>
                      <th class="text-center font-weight-bolder">Name</th>
                      <th class="text-center font-weight-bolder">Email</th>
                      <th class="text-center font-weight-bolder">DOB</th>
                      <th class="text-center font-weight-bolder">Gender</th>
                      <th class="text-center font-weight-bolder">Join Date</th>
                      <th class="text-center font-weight-bolder">
                        Last Purchase
                      </th>
                      <th class="text-center font-weight-bolder">Status</th>
                    </tr>
                  </thead>
                  <tbody v-if="members.length">
                    <tr v-for="(member, index) in members" :key="index">
                      <td class="text-center text-xss">
                        <material-checkbox
                          @click="checkMember(member)"
                          :checked="idsSelect.includes(member.id)"
                          class="align-items-center checkbox-custom"
                          :value="member.id"
                        ></material-checkbox>
                      </td>
                      <td class="text-center text-xss">{{ member.id_text }}</td>
                      <td class="text-center text-xss">
                        {{ member.phone_number }}
                      </td>
                      <td class="text-center text-xss">{{ member.name }}</td>
                      <td class="text-center text-xss">{{ member.email }}</td>
                      <td class="text-center text-xss">
                        {{ member.date_of_birth }}
                      </td>
                      <td class="text-center text-xss">{{ member.gender }}</td>
                      <td class="text-center text-xss">
                        {{ member.join_date }}
                      </td>
                      <td class="text-center text-xss">
                        {{ member.last_purchase }}
                      </td>
                      <td class="text-center text-xss">
                        <i
                          class="fa fa-lock"
                          v-if="member.block == 0"
                          style="color: #ff3300"
                        ></i>
                        <i
                          class="fa fa-check-square-o"
                          style="color: #33cc33"
                          v-else
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10" class="text-center">
                        No item available
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <pagination
                v-if="pagination.last_page > 1"
                :pagination="pagination"
                :current_page="pagination.current_page"
                :last_page="pagination.last_page"
              />
            </div>
            <div
              class="d-flex justify-content-between align-items-center mx-2 mb-5"
            >
              <div>
                <div class="d-flex align-items-center mb-2">
                  <material-checkbox
                    @click="saveAsGroup()"
                    :checked="isSaveAsGroup == 1"
                    class="align-items-center checkbox-custom mb-1"
                    value="1"
                  ></material-checkbox>
                  <span class="ms-2">Save As Recipient Group</span>
                </div>
                <div v-if="isSaveAsGroup">
                  <material-input
                    v-model="group_name"
                    type="text"
                    id="recipient_group_name"
                    name="recipient_group_name"
                    placeholder="Enter Recipient Name"
                  />
                  <div class="w-100">
                    <span
                      class="is-invalid"
                      v-if="fieldRequired.isGroupNameRequire"
                      >The field is required.</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <material-button
                  class="mx-2 btn btn-sm btn-background"
                  @click="saveRecipientGroup()"
                >
                  {{
                    "Add (" + idsSelect.length + " emails) to Email Recipient"
                  }}
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>
<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Multiselect from "@vueform/multiselect";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import EmailSetupService from "../../../services/emailSetup.service";
import $ from "jquery";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "create-category",
  components: {
    MaterialInput,
    MaterialButton,
    MaterialLoading,
    Multiselect,
    MaterialCheckbox,
    Pagination
  },
  props: {
    keyReload: {
      type: String,
      required: true,
    },
    membersIdSelected: {
      type: Array,
      required: true,
    },
    membersSelected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fieldRequired: {},
      loaderActive: false,
      searchTimerId: null,
      search_keyword: "",
      group_name: "",
      tiers: [],
      genders: [],
      tier_id: 0,
      gender: 0,
      members: [],
      idsSelect: [],
      membersSelect: [],
      isSaveAsGroup: 0,
      isCheckedAll: false,
      pagination: {},
      page: 1,
    };
  },
  async created() {
    await this.getDataFilter();
    await this.getMembers(this.page);
  },
  mounted() {},
  methods: {
    getList(page) {
      this.getMembers(page);
    },

    async getMembers(page) {
      this.isLoadData = false;
      this.showLoader();
      var dataFrom = {
        search_keyword: this.search_keyword,
        gender: this.gender,
        tier_id: this.tier_id,
        page: page,
      };

      await EmailSetupService.getMembers(dataFrom).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.members = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.checkAllStatus();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadData = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async getDataFilter() {
      this.isLoadData = false;
      this.showLoader();
      await EmailSetupService.getFilterData().then(
        (response) => {
          if (response.data.result.isSuccess) {
            var dataFilter = response.data.data;
            this.tiers = dataFilter.tiers;
            this.genders = dataFilter.genders;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadData = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveRecipientGroup() {
      if (this.isSaveAsGroup) {
        if (this.processValidate()) {
          this.showLoader();
          var dataFrom = {
            group_name: this.group_name,
            member_ids: this.idsSelect,
            is_save_as_group: this.isSaveAsGroup,
          };

          EmailSetupService.saveRecipientGroup(dataFrom).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.$emit('reload-recipient-group', 'Add Recipient Group Success');
                $(".btn-close-modal").click();
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      } else {
        this.$emit('select-recipients', this.idsSelect, this.membersSelect);
        $(".btn-close-modal").click();
      }
    },

    resetRequired() {
      this.fieldRequired.isGroupNameRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;

      if (this.isSaveAsGroup) {
        if (this.group_name == "" || this.group_name == null) {
          this.fieldRequired.isGroupNameRequire = true;
          isValid = false;
        }
      }

      return isValid;
    },

    changeTier(id) {
      this.tier_id = id;
      this.getMembers(this.page);
    },

    changeGender(id) {
      this.gender = id;
      this.getMembers(this.page);
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getMembers(this.page);
      }, 1000);
    },

    checkAll() {
      this.members.forEach((member) => {
        this.deleteItem(member.id);
      });
      if (!this.isCheckedAll) {
        this.members.forEach((member) => {
          this.idsSelect.push(member.id);
          this.membersSelect.push({
            name: member.name,
            email: member.email
          });
        });
      }

      this.checkAllStatus();
    },

    checkMember(member) {
      if (this.idsSelect.includes(member.id)) {
        this.deleteItem(member.id);
      } else {
        this.idsSelect.push(member.id);
        this.membersSelect.push({
          name: member.name,
          email: member.email
        });
      }

      this.checkAllStatus();
    },

    deleteItem(memberId) {
      this.idsSelect.forEach((value, index) => {
        if (value == memberId) {
          this.idsSelect.splice(index, 1);
          this.membersSelect.splice(index, 1);
        }
      });
    },

    checkAllStatus() {
      this.isCheckedAll = true;
      this.members.forEach((value) => {
        if (!this.idsSelect.includes(value.id)) {
          this.isCheckedAll = false;
          return;
        }
      });
    },

    saveAsGroup() {
      this.group_name = "";
      if (this.isSaveAsGroup) {
        this.isSaveAsGroup = 0;
      } else {
        this.isSaveAsGroup = 1;
      }
    },

    clearData() {
      this.search_keyword = "";
      this.tier_id = 0;
      this.gender = 0;
      this.group_name = "";
      this.idsSelect = [];
      this.membersSelect = [];
      this.isCheckedAll = false;
      this.isSaveAsGroup = 0;
      this.page = 1;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
  watch: {
    async keyReload() {
      this.resetRequired();
      this.clearData();
      this.membersIdSelected.forEach((item) => {
        this.idsSelect.push(item)
      })

      this.membersSelected.forEach((item) => {
        this.membersSelect.push(item)
      })
      await this.getDataFilter();
      await this.getMembers(this.page);
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
  padding-bottom: 25px;
}

.modal-body .row {
  margin-top: 10px;
}

.modal-width {
  min-width: 60%;
}

.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.font-18 {
  font-size: 18px;
}

.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 300px;
}

.css-icon-upload {
  font-size: 45px !important;
  margin-top: 6rem;
}

.image-url {
  width: 100%;
  height: 300px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 0px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 1px solid #72959d;
}

.custom-table {
  max-height: 700px;
}

.custom-table::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.custom-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-table::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-table tbody tr:last-child td {
    border-bottom-width: 3px !important;
}
</style>
