<template>
  <aside v-if="token" id="sidenav-main" class="sidenav navbar navbar-vertical navbar-expand-xs border-0" :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start'
    } ${sidebarType}`">
    <div class="sidenav-header d-flex px-0 py-2" style="height: 56px;">
      <i class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true" id="iconSidenav"></i>
      <div class="row col-12 d-flex">
        <div class="col-10 justify-content-center text-center logo-text">
          MINTY
        </div>
        <div class="col-1 mt-2">
          <i class="fa fa-times icon-close-sidenav-main" aria-hidden="true" @click="toggleSidebar()"></i>
        </div>
      </div>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logo-ct.png";
import logoDark from "@/assets/img/logo-ct-dark.png";
import { mapMutations, mapState } from "vuex";
import TokenService from "../../services/token.service";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      logoDark,
      token: TokenService.getLocalAccessToken()
    };
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"]),
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    toggleSidebar() {
      this.navbarMinimize();
    }
  }
};
</script>
<style scoped>
  .logo-text {
    font-weight: bold;
    font-size: 30px;
    color: #ffffff;
  }
</style>
