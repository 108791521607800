<template>
    <div>
      <button
        style="display: none"
        type="button"
        class="btn-confirm-modal-delete-category"
        data-bs-toggle="modal"
        data-bs-target="#modalConfirmDeleteCategory"
      ></button>
      <div
        class="modal fade"
        id="modalConfirmDeleteCategory"
        tabindex="-1"
        aria-labelledby="modalConfirmDeleteLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">{{ message }}</div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-success btn-background btn-sm"
                @click="processYes"
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-primary btn-close-modal btn-sm"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ExchangeStatus",
    props: {
      id: {
        type: String,
        required: true,
      },
      message: {
        type: String,
        default: "",
      },
      btnYes: {
        type: String,
        default: "",
      },
      processYes: {
        type: Function,
        default: () => {},
      },
    },
  };
  </script>