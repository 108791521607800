<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">Export</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="d-flex mt-4 s-menuset align-items-center">
              <div class="me-3">Menu Set</div>
              <Multiselect
                v-model="menuSetIdSelect"
                :options="menusets"
                placeholder="Select menu"
                :canClear="false"
                @change="selectMenuset"
              />
            </div>
            <!-- <div class="d-flex mt-4 s-menuset align-items-center">
              <div class="me-3">Type</div>
              <Multiselect
                v-model="type"
                :options="typeArr"
                placeholder="Select type"
                :canClear="false"
              />
            </div> -->
            <div class="mt-4">
              <material-button
                @click="exportMenu()"
                class="btn btn-sm btn-background mw-185px"
              >
                Export to excel
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form
    :action="`${actionDownload}/merchant/menu-set/export`"
    method="POST"
  >
    <input type="hidden" name="merchantId" :value="merchantIdExport" />
    <input type="hidden" name="merchant_code" :value="merchantCode" />
    <input type="hidden" name="token" :value="token" />
    <input type="hidden" name="menuset_id" :value="menuSetIdSelect" />
    <!-- <input type="hidden" name="type" :value="type" /> -->
    <input id="exportMenu" type="submit" class="d-none" />
  </form>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MenusetService from "../../services/product/menuset.service";
import Multiselect from "@vueform/multiselect";
import MaterialButton from "@/components/MaterialButton.vue";
import TokenService from "../../services/token.service";
import $ from "jquery";

export default {
  name: "create-menu",
  components: {
    Multiselect,
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton
  },
  data() {
    return {
      token: TokenService.getLocalAccessToken(),
      actionDownload: process.env.VUE_APP_BASE_URL,
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCode: localStorage.getItem("MERCHANT_CODE", ""),
      isLoadData: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      menusets: [],
      menuSetIdSelect: 0,
      // type: 1,
      // typeArr: [
      //   { 
      //     value: 1,
      //     label: "Main"
      //   },
      //   { 
      //     value: 2,
      //     label: "Modifier"
      //   }
      // ]
    }
  },
  created() {
    this.getMenusets();
  },
  methods: {
    getMenusets() {
      this.showLoader();
      var dataForm = {
        type: "select",
      };
      MenusetService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menusets = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    exportMenu() {
      if (this.menuSetIdSelect) {
        $("#exportMenu").click();
      } else {
        alert('You must select menuset.');
      }
      
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  }
}
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}

.s-menuset .multiselect {
  margin: 0 !important;
  max-width: 500px !important;
}

.s-menuset > div:first-child {
  min-width: 100px;
}
</style>
        