<template>
  <div class="tier-detail">
      <div v-for="item, index in form" :key="item">
        <div :class="`accordion accordion_${item.id}`" :id="`accordionRental_${index}`">
          <div class="accordion-item">
              <h5 class="accordion-header" id="headingOne">
                  <button class="accordion-button collapsed p-0" type="button" @click="showAccordion(index)"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#collapseOne_${index}`"
                      :aria-expanded="tiersOpenning.includes(String(item.id)) ? true : false"
                      :aria-controls="`collapseOne_${index}`">
                      <div class="shadow-none p-3 mb-2 bg-light rounded col-12 d-flex">
                        <div class="col-10 text-page font-bold-600">{{ item.tier_name ? item.tier_name.toUpperCase() : 'CREATE NEW' }} TIER</div>
                        <div class="col-2 d-flex justify-content-end"><i class="fas fa-chevron-down"></i></div>
                      </div>
                  </button>
              </h5>
              <div
                :id="`collapseOne_${index}`"
                class="accordion-collapse collapse"
                :tier_id="item.id ? item.id : null"
                aria-labelledby="headingOne"
                v-bind:class="[tiersOpenning.includes(String(item.id)) ? 'show' : '']"
                :data-bs-parent="`#accordionRental_${index}`">
                <div class="row mb-5 card-custom-new pt-0">
                  <div class="table-responsive p-2 custom-table-noborder">
                    <table class="table align-items-center mb-0">
                      <tbody>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="4">
                            <div class="ps-0 text-page font-bold-600">{{ item.tier_name.toUpperCase() }} TIER</div>
                          </td>
                          <td colspan="4">
                          </td>
                          <td colspan="2">
                            <div class="d-flex justify-content-end">
                              <material-button @click="saveTier(index)" :disabled="disableBtnSubmit" class="mx-2 btn-background btn-sm">
                                {{ $t('save') }}
                              </material-button>
                              <material-button @click="removeTier(index)" :disabled="disableBtnSubmit" class="mx-2 btn-sm btn-danger">
                                {{ $t('delete') }}
                              </material-button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="4">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Type') }}</div><span class="text-danger ms-2">*</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="3">
                            <Multiselect v-model="item.is_paid_member_ship" :options="memberType" :canClear="false" @change="changeMemberType($event, index)"/>
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="4">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Tier_Name') }}</div><span class="text-danger ms-2">*</span>
                            </div>
                          </td>
                          <td class="align-middle text-left text-sm" colspan="6">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Tier_Badge') }}</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <material-input v-model="item.tier_name" placeholder="Enter tier name" />
                            <span class="is-invalid" v-if="fieldRequired[index].isTierNameRequire">The field is
                              required.</span>
                          </td>
                          <td colspan="4">
                            <div class="d-flex">
                                <div id="preview" style="position: relative;">
                                  <img v-if="urlImagePreview[index] || item.tier_badge" :src="urlImagePreview[index] ? urlImagePreview[index] : item.tier_badge " />
                                  <div class="d-flex align-items-center">
                                    <div v-if="!urlImagePreview[index] && !item.tier_badge">Upload Badge for Tier</div><i v-if="!urlImagePreview[index] && !item.tier_badge" class="fa fa-arrow-right ps-2" aria-hidden="true"></i>
                                  </div>
                                  <i class="fa fa-times icon-close-sidenav-main danger" v-if="urlImagePreview[index] || item.tier_badge" style="position: absolute; top: 0px; left: 60px; color:#f44335; font-size: 20px;" aria-hidden="true" @click="clearImage(index)"></i>
                                </div>
                                <div class="ps-5">
                                  <material-input style="display: none;" type="file" :id="`tier_badge_${ index }`" @change="handleFileUpload($event, index)" accept="image/*"/>
                                  <material-button @click="uploadFile(index)" :disabled="disableBtnSubmit" class="btn-sm btn-background">
                                    {{ $t('UPLOAD_ICON') }}
                                  </material-button>
                                  <span class="is-invalid ms-2" v-if="fieldRequired[index].isTierBadgeRequire">{{ messageImageError[index] }}</span>
                                  <div class="d-flex justify-content-end pt-2 pe-6 text-size-13">{{ $t('Upload_icon_minumum_150_150') }}</div>
                                </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="item.is_paid_member_ship">
                          <td class="align-middle text-left text-sm" colspan="10">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Upgrade Link') }}</div>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="item.is_paid_member_ship">
                          <td colspan="10">
                            <div class="row col-12 col-md-6 mb-2">
                              <material-input :disabled="true" v-model="item.upgrade_tier_link" placeholder="This will be automatically generated once the tier has been created." style="padding-left: 0px;"/>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="10">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Description') }}</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="10">
                            <div class="row col-12 col-md-6 mb-2">
                              <material-textarea v-model="item.description" placeholder="Enter description" class="ps-0"/>
                            </div>
                            <material-checkbox v-model="item.show_tier_description" :checked="item.show_tier_description ? true : false" :name="`show_tier_description_${index}`" :id="`show_tier_description_${index}`"
                              class="align-items-center checkbox-custom">
                                <template v-slot:label>
                                  {{ $t('Show_tier_description_in_member_portal') }}
                                </template>
                              </material-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="10">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Condition_to_achieve_tier') }}</div><span
                                class="text-danger ms-2">*</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black"> <material-checkbox v-model="item.share_full_profile" :checked="item.share_full_profile ? true : false" value="1"
                              :id="`share_full_profile_${index}`" class="align-items-center checkbox-custom">
                                <template v-slot:label>
                                  {{ $t('Share_full_profile') }}
                                </template>
                              </material-checkbox>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black"> <material-checkbox v-model="item.show_tier_condition" :checked="item.show_tier_condition ? true : false" value="1"
                              :id="`show_tier_condition_${index}`" class="align-items-center checkbox-custom">
                                <template v-slot:label>
                                  {{ $t('Show_tier_condition_in_member_portal') }}
                                </template>
                              </material-checkbox>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="item.is_paid_member_ship">
                          <td colspan="2">
                            <div class="ps-0 label-black"> <material-checkbox v-model="item.already_achieved_a_paid_tier_previously" :checked="item.already_achieved_a_paid_tier_previously ? true : false" value="1"
                              :id="`already_achieved_a_paid_tier_previously_${index}`" class="align-items-center checkbox-custom">
                                <template v-slot:label>
                                  Already achieved a paid tier previously
                                </template>
                              </material-checkbox>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="item.is_paid_member_ship">
                          <td colspan="2">
                            <div class="ps-0 label-black">Membership Fee</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.membership_fee"
                              placeholder="Enter membership fee" @input="caculatorMembershipFeeTotal(index)"/>
                          </td>
                        </tr>
                        <tr v-if="item.is_paid_member_ship">
                          <td colspan="2">
                            <div class="ps-0 label-black">GST Rate (- for inclusive) %</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.gst"
                              placeholder="Enter gst" @input="caculatorMembershipFeeTotal(index)"/>
                          </td>
                        </tr>
                        <tr v-if="item.is_paid_member_ship">
                          <td colspan="2">
                            <div class="ps-0 label-black">Membership Fee Total</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.membership_fee_total"
                              placeholder="0"  :readOnly="true"/>
                          </td>
                          <td class="align-middle text-left text-sm" colspan="5">
                            <Multiselect v-model="item.membership_fee_unit" :options="membershipFeeUnit" :canClear="false" />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Spending') }}</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.spending_achieve_tier" placeholder="Enter spending achieve tier" />
                          </td>
                          <td colspan="5">
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Duration_of_membership') }}</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.duration_achieve_membership"
                              placeholder="Enter duration achieve membership unit" />
                          </td>
                          <td class="align-middle text-left text-sm" colspan="5">
                            <Multiselect v-model="item.duration_achieve_membership_unit" :options="durationUnit" :canClear="false" />
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="10">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Condition_to_maintain_tier') }}</div><span
                                class="text-danger ms-2">*</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Spending') }}</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.spending_maintain_tier" placeholder="Enter spending maintain tier" />
                          </td>
                          <td class="align-middle text-left text-sm" colspan="5">
                            <Multiselect v-model="item.spending_maintain_tier_unit" :options="timeUnit" :canClear="false" />
                          </td>
                        </tr>
                        <template v-if="rewardType == 1">
                          <tr>
                            <td class="align-middle text-left text-sm" colspan="10">
                              <div class="d-flex">
                                <div class="ps-0 label-form">{{ $t('Cashback_Rule') }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div class="ps-0 label-black">{{ $t('Standard_Rule') }}</div>
                            </td>
                            <td colspan="3">
                              <Multiselect v-model="item.cashback_standard_rule" :options="standardCashbackRule" :canClear="false" />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div class="ps-0 label-black">{{ $t('Superior_Rule') }}</div>
                            </td>
                            <td colspan="3">
                              <Multiselect v-model="item.cashback_superior_rule" :options="superiorCashbackRule" :canClear="false" />
                            </td>
                          </tr>
                        </template>
                        <template v-if="rewardType == 2">
                          <tr>
                            <td class="align-middle text-left text-sm" colspan="10">
                              <div class="d-flex">
                                <div class="ps-0 label-form">{{ $t('Point_Rule') }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div class="ps-0 label-black">{{ $t('Standard_Rule') }}</div>
                            </td>
                            <td colspan="3">
                              <Multiselect v-model="item.point_standard_rule" :options="standardPointRule" :canClear="false" />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <div class="ps-0 label-black">{{ $t('Superior_Rule') }}</div>
                            </td>
                            <td colspan="3">
                              <Multiselect v-model="item.point_superior_rule" :options="superiorPointRule" :canClear="false" />
                            </td>
                          </tr>
                        </template>
                        <!-- <tr>
                          <td class="align-middle text-left text-sm" colspan="10">
                            <div class="d-flex">
                              <div class="ps-0 label-form">Point Rule</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black">Standard Rule</div>
                          </td>
                          <td colspan="4">
                            <Multiselect v-model="item.point_standard_rule" :options="standardRule" :canClear="false" />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black">Superior Rule</div>
                          </td>
                          <td colspan="4">
                            <Multiselect v-model="item.point_superior_rule" :options="superiorRule" :canClear="false" />
                          </td>
                        </tr> -->
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="10">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Welcome_Benefit') }}</div>
                            </div>
                          </td>
                        </tr>
                        <tr v-for="(itemWelcomeVoucher, indexWelcomeVoucher) in item.welcome_voucher" :key="indexWelcomeVoucher">
                          <td colspan="2">
                            <div class="ps-0 label-black" v-if="indexWelcomeVoucher == 0">{{ $t('Voucher') }}</div>
                          </td>
                          <td colspan="3">
                            <Multiselect v-model="itemWelcomeVoucher.value" :options="dataVoucher" :canClear="false" />
                          </td>
                          <td colspan="1">
                            <a href="javascript:;" style="text-decoration: underline;" @click="removeMoreWelcomeVoucher(index, indexWelcomeVoucher)">Remove</a>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"></td>
                          <td colspan="3">
                            <a href="javascript:;" style="text-decoration: underline;" @click="addMoreWelcomeVoucher(index)">Add more voucher</a>
                          </td>
                        </tr>
                        <tr v-if="rewardType == 1 && (item.cashback_standard_rule || item.cashback_superior_rule)">
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Direct_Cashback') }}</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.welcome_direct_cashback" placeholder="Enter direct cashback" />
                          </td>
                        </tr>
                        <tr v-if="rewardType == 2 && (item.point_standard_rule || item.point_superior_rule)">
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Direct_Point') }}</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.welcome_direct_point" placeholder="Enter direct point" />
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="10">
                            <div class="d-flex">
                              <div class="ps-0 label-form">{{ $t('Birthday_Benefit') }}</div>
                            </div>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Voucher') }}</div>
                          </td>
                          <td colspan="3">
                            <Multiselect v-model="item.birthday_voucher" :options="dataVoucher" :canClear="false" />
                          </td>
                        </tr> -->
                        <tr v-for="(itemBirthdayVoucher, indexBirthdayVoucher) in item.birthday_voucher" :key="indexBirthdayVoucher">
                          <td colspan="2">
                            <div class="ps-0 label-black" v-if="indexBirthdayVoucher == 0">{{ $t('Voucher') }}</div>
                          </td>
                          <td colspan="3">
                            <Multiselect v-model="itemBirthdayVoucher.value" :options="dataVoucher" :canClear="false" />
                          </td>
                          <td colspan="1">
                            <a href="javascript:;" style="text-decoration: underline;" @click="removeMoreBirthdayVoucher(index, indexBirthdayVoucher)">Remove</a>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"></td>
                          <td colspan="3">
                            <a href="javascript:;" style="text-decoration: underline;" @click="addMoreBirthdayVoucher(index)">Add more voucher</a>
                          </td>
                        </tr>
                        <tr v-if="rewardType == 1 && (item.cashback_standard_rule || item.cashback_superior_rule)">
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Direct_Cashback') }}</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.birthday_direct_cashback" id="rule_code" :name="`cashback_rule_code_${index}`"
                              placeholder="Enter direct cashback" />
                          </td>
                        </tr>
                        <tr v-if="rewardType == 2 && (item.point_standard_rule || item.point_superior_rule)">
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Direct_Point') }}</div>
                          </td>
                          <td colspan="3">
                            <material-input v-model="item.birthday_direct_point" id="rule_code" :name="`point_rule_code_${index}`"
                              placeholder="Enter direct point" />
                          </td>
                        </tr>
                        <!-- <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black">Extra Rebate</div>
                          </td>
                          <td colspan="4">
                            <Multiselect v-model="item.birthday_extra_rebase" :options="rebateType" :canClear="false" />
                          </td>
                          <td colspan="4">
                            <material-checkbox v-model="item.birthday_extra_rebase_rule_only" :checked="item.birthday_extra_rebase_rule_only ? true : false" value="1"
                              :id="`birthday_extra_rebase_rule_only_${index}`" class="align-items-center checkbox-custom">
                                <template v-slot:label>
                                  Upon standard rule only
                                </template>
                              </material-checkbox>
                          </td>
                        </tr> -->
                        <tr v-if="rewardType == 1 && (item.cashback_standard_rule || item.cashback_superior_rule)">
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Extra_Cashback') }}</div>
                          </td>
                          <td colspan="3">
                            <div class="d-flex align-items-center ">
                               <material-input v-model="item.birthday_extra_rebase" placeholder="Enter birthday extra cashback" /><strong>%</strong>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="rewardType == 2 && (item.point_standard_rule || item.point_superior_rule)">
                          <td colspan="2">
                            <div class="ps-0 label-black">{{ $t('Extra_Point') }}</div>
                          </td>
                          <td colspan="3">
                            <div class="d-flex align-items-center ">
                               <material-input v-model="item.birthday_extra_point" placeholder="Enter birthday extra point" /><strong>%</strong>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle text-left text-sm" colspan="10">
                            <div class="d-flex">
                              <div class="ps-0 label-form">Access Benefit</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                          </td>
                          <td colspan="5">
                            <div class="d-flex flex-wrap">
                              <div v-for="(module, index2) in memberModule" :key="index2" class="d-flex align-items-center">
                                <MaterialCheckbox
                                  @click="checkModule(module.id, index)"
                                  :value="module.id"
                                  :checked="item.member_module.includes(module.id)"
                                  class="align-items-center checkbox-custom"
                                />
                                <span class="ms-3">{{ module.name }}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td colspan="2">
                            <div class="ps-0 label-black">Extra Point</div>
                          </td>
                          <td colspan="4">
                            <material-input v-model="item.birthday_extra_point" placeholder="Enter birthday extra point" />
                          </td>
                          <td colspan="4">
                            <material-checkbox v-model="item.birthday_extra_point_rule_only" value="1" :checked="item.birthday_extra_point_rule_only ? true : false"
                              :id="`birthday_extra_point_rule_only_${index}`" class="align-items-center checkbox-custom">
                              <template v-slot:label>
                                Upon standard rule only
                              </template>
                            </material-checkbox>
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td colspan="2">
                            <div class="d-flex">
                              <div class="ps-0 label-form">Discount Type</div>
                            </div>
                          </td>
                          <td colspan="4">
                            <Multiselect v-model="item.discount_type" :options="discountType" :canClear="false" />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="d-flex">
                              <div class="ps-0 label-form">Top Up Value Type</div>
                            </div>
                          </td>
                          <td colspan="4">
                            <Multiselect v-model="item.top_up_value_type" :options="topUpType" :canClear="false" />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="d-flex">
                              <div class="ps-0 label-form">Reward Store Price Level</div>
                            </div>
                          </td>
                          <td colspan="4">
                            <Multiselect v-model="item.reward_store_price_level" :options="rewardStorePriceLevel" :canClear="false" />
                          </td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/js/dist/modal'
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "./MaterialInput.vue";
import '@vuepic/vue-datepicker/dist/main.css'
import MaterialCheckbox from "./MaterialCheckbox.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css';
import $ from "jquery";
export default {
  name: "CashbackRule",
  data() {
    return {
      selectedVouchers: []
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    form: {
      type: Array,
      required: true,
    },
    removeRow: {
      type: Function,
      default: () => { },
    },
    saveTier: {
      type: Function,
      default: () => { },
    },
    checkModule: {
      type: Function,
      default: () => { },
    },
    changeMemberType: {
      type: Function,
      default: () => { },
    },
    removeTier: {
      type: Function,
      default: () => { },
    },
    handleFileUpload: {
      type: Function,
      default: () => { },
    },
    clearImage: {
      type: Function,
      default: () => { },
    },
    fieldRequired: {
      type: Array,
      required: true,
    },
    dataVoucher: {
      type: Array,
      required: true,
    },
    discountType: {
      type: Array,
      required: true,
    },
    rebateType: {
      type: Array,
      required: true,
    },
    rewardStorePriceLevel: {
      type: Array,
      required: true,
    },
    standardCashbackRule: {
      type: Array,
      required: true,
    },
    superiorCashbackRule: {
      type: Array,
      required: true,
    },
    standardPointRule: {
      type: Array,
      required: true,
    },
    superiorPointRule: {
      type: Array,
      required: true,
    },
    timeUnit: {
      type: Array,
      required: true,
    },
    durationUnit: {
      type: Array,
      required: true,
    },
    memberType: {
      type: Array,
      required: true,
    },
    membershipFeeUnit: {
      type: Array,
      required: true,
    },
    memberModule: {
      type: Array,
      required: true,
    },
    topUpType: {
      type: Array,
      required: true,
    },
    tiersOpenning: {
      type: Array
    },
    textRequiredDateCustom: {
      type: String,
      required: true,
    },
    urlImagePreview: {
      type: String,
      required: true,
    },
    messageImageError: {
      type: Array,
      required: true,
    },
    showAccordion: {
      type: Function,
      default: () => { },
    },
    rewardType: {
      type: Number,
      default: 0,
    },
  },
  components: {
    MaterialInput,
    MaterialButton,
    MaterialCheckbox,
    MaterialTextarea,
    Multiselect
  },
  methods: {
    uploadFile(index) {
      $('#tier_badge_' + index).click()
    },
    addMoreWelcomeVoucher(indexRoot) {
      const welcomeVoucher = this.form[indexRoot].welcome_voucher;
      welcomeVoucher.push({ value: 0 });
    },
    removeMoreWelcomeVoucher(indexRoot, indexChild) {
      const welcomeVoucher = this.form[indexRoot].welcome_voucher;
      welcomeVoucher.splice(indexChild, 1);
    },
    addMoreBirthdayVoucher(indexRoot) {
      const birthdayVoucher = this.form[indexRoot].birthday_voucher;
      birthdayVoucher.push({ value: 0 });
    },
    removeMoreBirthdayVoucher(indexRoot, indexChild) {
      const birthdayVoucher = this.form[indexRoot].birthday_voucher;
      birthdayVoucher.splice(indexChild, 1);
    },
    caculatorMembershipFeeTotal(indexRoot) {
      var membershipFeeTotal = this.form[indexRoot].membership_fee;

      if (this.form[indexRoot].gst >= 0) {
        membershipFeeTotal = parseFloat(this.form[indexRoot].membership_fee) + parseFloat((this.form[indexRoot].membership_fee / 100) * this.form[indexRoot].gst);
      }

      this.$emit('update-membership-fee-total', membershipFeeTotal, indexRoot)
    }
  }
};
</script>
<style scoped>
td .label-black {
  color: black !important;
  font-size: 14px;
}

#preview img {
  max-width: 60px;
  max-height: 60px;
}

.row {
  margin-right: 0rem;
  margin-left: 0rem;
}

.tier-detail [aria-expanded="true"] i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}
.bg-light {
  border: 1px solid #d5d6d8;
  background: #ebecee !important;
}

.d-flex.flex-wrap > div {
  flex: 50%;
  margin-bottom: 10px;
}
</style>
