<template>
  <div>
    <button
      style="display: none"
      type="button"
      id="btn-show-dashboard-component"
      data-bs-toggle="modal"
      data-bs-target="#modalAddDashboardElement"
    ></button>
    <div
      class="modal fade"
      id="modalAddDashboardElement"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">DASHBOARD COMPONENT</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                New Members
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <material-checkbox
                  v-model="form.newMember"
                  :checked="form.newMember"
                  name="new_members"
                  id="new_members"
                  class="checkbox-custom"
                  :isDisabled="isLimit ? !form.newMember : false"
                  @input="checkItem()"
                ></material-checkbox>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Topup
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalTopup"
                    :checked="form.totalTopup"
                    name="total_topup"
                    id="total_topup"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalTopup : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Member Txn
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalMemberTxn"
                    :checked="form.totalMemberTxn"
                    name="total_member_txn"
                    id="total_member_txn"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalMemberTxn : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Member Visit
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalMemberVisit"
                    :checked="form.totalMemberVisit"
                    name="total_member_visit"
                    id="total_member_visit"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalMemberVisit : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                New Members (Chart)
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.newMemberChart"
                    :checked="form.newMemberChart"
                    name="new_members_chart"
                    id="new_members_chart"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.newMemberChart : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Revenue (Chart)
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.revenueChart"
                    :checked="form.revenueChart"
                    name="revenue_chart"
                    id="revenue_chart"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.revenueChart : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Outlet Sales (Chart)
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.outletSales"
                    :checked="form.outletSales"
                    name="outlet_sales"
                    id="outlet_sales"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.outletSales : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div> -->
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Member
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalMember"
                    :checked="form.totalMember"
                    name="total_member"
                    id="total_member"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalMember : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Wallet Balance
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalWallet"
                    :checked="form.totalWallet"
                    name="total_wallet"
                    id="total_wallet"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalWallet : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Topup
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalTopupEveryTime"
                    :checked="form.totalTopupEveryTime"
                    name="total_wallet"
                    id="total_wallet"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalTopupEveryTime : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Bonus
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalBonus"
                    :checked="form.totalBonus"
                    name="total_bonus"
                    id="total_bonus"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalBonus : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Cashback
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalCashback"
                    :checked="form.totalCashback"
                    name="total_bonus"
                    id="total_bonus"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalCashback : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Discount Code Redeemed
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalDiscount"
                    :checked="form.totalDiscount"
                    name="total_discount"
                    id="total_discount"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalDiscount : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Total Donation
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.totalDonation"
                    :checked="form.totalDonation"
                    name="total_donation"
                    id="total_donation"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.totalDonation : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Gender
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.gender"
                    :checked="form.gender"
                    name="gender"
                    id="gender"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.gender : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Spending Leaderboard
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.memberSpend"
                    :checked="form.memberSpend"
                    name="member_spend"
                    id="member_spend"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.memberSpend : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Top-up Leaderboard
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.memberTopup"
                    :checked="form.memberTopup"
                    name="member_topup"
                    id="member_topup"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.memberTopup : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Member Tier Distribution
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.memberTier"
                    :checked="form.memberTier"
                    name="member_tier"
                    id="member_tier"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.memberTier : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-8 col-md-8 col-12 label-form d-flex align-items-center"
              >
                Revenue By Tier
              </div>
              <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                <div class="col-lg-4 col-md-4 col-12 d-flex align-items-center">
                  <material-checkbox
                    v-model="form.revenueTier"
                    :checked="form.revenueTier"
                    name="revenue_tier"
                    id="revenue_tier"
                    class="checkbox-custom"
                    :isDisabled="isLimit ? !form.revenueTier : false"
                    @input="checkItem()"
                  ></material-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal content-->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-success btn-background btn-sm"
              @click="saveConfig"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-primary btn-close-modal btn-sm"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialCheckbox from "./MaterialCheckbox.vue";
import DashboardService from "../services/dashboard.service";
import $ from "jquery";

export default {
  name: "DashboardComponet",
  components: {
    MaterialCheckbox,
  },
  data() {
    return {
      form: {
        newMember: false,
        totalTopup: false,
        totalMemberTxn: false,
        totalMemberVisit: false,
        newMemberChart: false,
        revenueChart: false,
        outletSales: false,
        totalMember: false,
        totalWallet: false,
        totalTopupEveryTime: false,
        totalBonus: false,
        totalCashback: false,
        totalDiscount: false,
        totalDonation: false,
        gender: false,
        memberSpend: false,
        memberTopup: false,
        memberTier: false,
        revenueTier: false,
      },
      isLimit: false,
    };
  },
  created() {
    this.getConfig()
  },
  methods: {
    checkItem() {
      let valueForm = Object.values(this.form);
      valueForm = valueForm.filter((value) => {
        return value;
      })

      if (valueForm.length >= 8) {
        this.isLimit = true
      } else {
        this.isLimit = false
      }
    },
    getConfig() {
      var data = {
        action: 'list',
      };

      DashboardService.config(data).then(
        (response) => {
          if (response.data.result.isSuccess) {
            if (response.data.data) {
              this.form = response.data.data;
              this.checkItem();
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    saveConfig() {
      var data = {
        action: 'createOrUpdate',
        config: this.form
      };

      DashboardService.config(data).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.$emit('reload-data', this.form);
            $(".btn-close-modal").click();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  },
};
</script>

<style scoped>
.modal-body {
  max-height: 75vh;
  overflow-y: auto;
}

.modal-body .row {
  margin-top: 10px;
}
</style>
