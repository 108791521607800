<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">Printers</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button
          class="mx-2 btn btn-sm btn-background"
          @click="updatePrinter()"
        >
          Save
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row mt-3">
          <div class="col-12" v-if="isLoadData">
            <div class="card-custom">
              <div class="px-0 pb-2">
                <div class="table-responsive p-0 custom-table">
                  <table class="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th class="text-left font-weight-bolder">
                          Printer Name
                        </th>
                        <th class="text-left font-weight-bolder">IP Address</th>
                        <th class="text-left font-weight-bolder">
                          Printing Method
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="printers.length">
                      <tr v-for="(printer, index) in printers" :key="index">
                        <td class="text-left text-xss font-weight-bolder cl-22">
                          {{ printer.name }}
                        </td>
                        <td class="align-middle text-end text-sm">
                          <material-input
                            v-model="printer.ip_address"
                            type="text"
                            id="ip_address"
                            name="ip_address"
                            placeholder="Enter IP address"
                          />
                        </td>
                        <td class="align-middle text-end text-sm">
                          <Multiselect
                            v-model="printer.printing_method"
                            :options="printingMethod"
                            placeholder="Select Printing Method"
                            :canClear="false"
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="10" class="text-center">
                          No printers available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "@vueform/multiselect/themes/default.css";
import PrinterService from "../../services/printer.service";
import Multiselect from "@vueform/multiselect";
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  name: "printers",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Multiselect,
    MaterialInput,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      isLoadData: true,
      printers: [],
      printingMethod: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.isLoadData = false;
      this.showLoader();
      PrinterService.getList({}).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.printers = response.data.data.printers;
            this.printingMethod = response.data.data.printing_method;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },

    updatePrinter() {
      this.showLoader();
      PrinterService.update({
        printers: this.printers
      }).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Update success";
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
          }
          this.setTimeOutNotification();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
      this.hideLoader();
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.cl-22 {
  color: #222 !important;
}
</style>
